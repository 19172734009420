// @flow
import { useQueryClient, queryOptions } from "@tanstack/react-query";
import * as React from "react";
import { ErrorToast } from "../../../../../libs/Notifications";
import { GetCodePhoneThunks } from "../../../../../store/companies/thunks";
import { useStoreCodePhones } from "../../../../../store/companies/useStoreCodePhone";
import { schemaUser } from "./validators/Validators";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import {
  Select,
  SelectItem,
  Input,
  Autocomplete,
  AutocompleteItem,
  Switch,
} from "@nextui-org/react";
import { keyFilterNumber } from "../../../../../helpers/KeyFIlterMovilPay";
import { IoEye, IoEyeOff } from "react-icons/io5";
type Props = {
  data: any;
  onSubmit: (data: any) => void;
  ref?: React.MutableRefObject<(() => void) | null>;
};
export const FormProfile = React.forwardRef(
  ({ onSubmit, data }: Props, ref) => {
    const { codePhone, setCodePhone } = useStoreCodePhones((state) => state);
    const queryClient = useQueryClient();
    const [isSelected, setIsSelected] = React.useState(false);
    const [isVisible, setIsVisible] = React.useState(false);

    const toggleVisibility = () => setIsVisible(!isVisible);

    const {
      control,
      handleSubmit,
      setValue,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(schemaUser),
      defaultValues: {
        code: "58",
      },
    });
    React.useImperativeHandle(ref, () => handleSubmit(onSubmit));
    const handleCodePhone = () => {
      queryClient.fetchQuery(
        queryOptions({
          queryKey: ["codePhone"],
          queryFn: () =>
            GetCodePhoneThunks()
              .then((d) => {
                setCodePhone(d.data);
              })
              .catch(({ response }) => {
                if (response?.status === 400) {
                  const errorMessage =
                    Object.keys(response.data || {})
                      .join(", ")
                      .concat(
                        ": " + Object.values(response.data || {}).join(", ")
                      ) ||
                    Object.values(response.data || {}).join(", ") ||
                    "Error desconocido";
                  return ErrorToast(errorMessage);
                }
              }),
          retry: false,
        })
      );
    };
    React.useEffect(() => {
      handleCodePhone();
    }, []);
    React.useEffect(() => {
      if (data) {
        setValue("name", data?.name);
        setValue("lastname", data?.lastname);
        setValue("email", data?.email);
        setValue("phone", data?.phone?.slice(2) || '');
      }
    }, [data]);
    return (
      <div>
        <form className="felx items-center justify-center gap-3 grid xl:grid-cols-2 ">
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <Input
                {...field}
                label="Nombre"
                isInvalid={!!errors.name}
                errorMessage={errors.name?.message}
                type="text"
                autoFocus
                variant="bordered"
              />
            )}
          />
          <Controller
            control={control}
            name="lastname"
            render={({ field }) => (
              <Input
                {...field}
                label="Apellido"
                isInvalid={!!errors.lastname}
                errorMessage={errors.lastname?.message}
                type="text"
                autoFocus
                variant="bordered"
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <Input
                {...field}
                label="Correo"
                isInvalid={!!errors.email}
                errorMessage={errors.email?.message}
                type="text"
                autoFocus
                variant="bordered"
              />
            )}
          />{" "}
          <div className="flex w-full gap-2">
            <Controller
              name="code"
              control={control}
              render={({ field }) => (
                <>
                  <div className="flex items-center">
                    <Autocomplete
                      {...field} // Pasa las propiedades del Controller
                      className="max-w"
                      variant="bordered"
                      defaultItems={codePhone}
                      label="Codigo"
                      placeholder="58"
                      isInvalid={!!errors.code}
                      errorMessage={errors.code?.message}
                      selectedKey={field.value}
                      onSelectionChange={(e) => field.onChange(e)}
                    >
                      {(item) => (
                        <AutocompleteItem key={item.phone_code}>
                          {item.phone_code + "/" + item.iso3_code}
                        </AutocompleteItem>
                      )}
                    </Autocomplete>
                  </div>
                </>
              )}
            />
            <Controller
              control={control}
              name="phone"
              render={({ field: { onChange, value, onBlur } }) => (
                <Input
                  isInvalid={!!errors.phone}
                  errorMessage={errors.phone?.message}
                  maxLength={10}
                  onChange={(e) => {
                    keyFilterNumber({
                      value: e,
                      onChange,
                    });
                  }}
                  onBlur={onBlur}
                  value={value}
                  autoFocus
                  label="Teléfono de contacto"
                  placeholder="XXX XXXXXX"
                  variant="bordered"
                />
              )}
            />
          </div>
          <div className="flex col-span-full my-4">
            <Switch isSelected={isSelected} onValueChange={setIsSelected}>
              Cambiar contraseña
            </Switch>
          </div>
          {isSelected && (
            <div className="col-span-full grid grid-cols-2 gap-3">
              <Controller
                control={control}
                name="password"
                rules={{ required: isSelected }}
                render={({ field }) => (
                  <Input
                    {...field}
                    label="Contraseña"
                    isInvalid={!!errors.password}
                    errorMessage={errors.password?.message}
                    autoFocus
                    className="items-center"
                    placeholder="Contraseña"
                    variant="bordered"
                    endContent={
                      <button
                        className="focus:outline-none"
                        type="button"
                        onClick={toggleVisibility}
                        aria-label="toggle password visibility"
                      >
                        {isVisible ? (
                          <IoEyeOff className="text-2xl text-default-400 pointer-events-none" />
                        ) : (
                          <IoEye className="text-2xl text-default-400 pointer-events-none" />
                        )}
                      </button>
                    }
                    type={isVisible ? "text" : "password"}
                  />
                )}
              />
              <Controller
                control={control}
                name="confirmPassword"
                rules={{ required: isSelected }}
                render={({ field }) => (
                  <Input
                    {...field}
                    label="Confirmar contraseña"
                    isInvalid={!!errors.confirmPassword}
                    errorMessage={errors.confirmPassword?.message}
                    type={isVisible ? "text" : "password"}
                    autoFocus
                    variant="bordered"
                  />
                )}
              />
            </div>
          )}
        </form>
      </div>
    );
  }
);
