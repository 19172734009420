import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";
import { TableLayout, valueType } from "../../../../layout/TableLayout";
import { PaginatorPayments } from "../../../finances/payments/components/table/PaginatorPayments";
import { usePermissions } from "../../../../../../context/PermissionContext";
import { useStoreCustomerGroup } from "../../../../../../store/settings/CustomerGroups/useStoreCustomerGroups";



export const TablePermissions = () => {
  const {count, customerGroup} =  useStoreCustomerGroup(state => state) 
  const { permissions, loading } = usePermissions();
  const actionPermissionEdit =permissions && permissions.includes('action_edit_group');
  const actionPermissionDelete =permissions && permissions.includes('action_delete_group');
  const actionPermissionViewPerm =permissions && permissions.includes('action_view_permissions');
  const dataTable: valueType[] = [
    { name: "Nro", value: "id" },
    {
      name: "Nombres",
      value: (item: any) => {
        return (
          <span>
            {item.name}
          </span>
        );
      },
    },
    { name: "Compañia", value: "company_name" },
    { name: "Descripción", value: "description" },
    {
      name: "Acción",
      value: (e: any) => {
        return (
          <>
          </>
        );
      },
    },
  ];
  return (
    <div className="space-y-7">
      <TableLayout
        isLoading={false}
        data={customerGroup}
        columns={dataTable}
        Paginator={<PaginatorPayments count={count} />}
      />
  </div>
  );
};
 