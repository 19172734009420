// @flow
import * as React from "react";
import { CompanyCore } from "../../companies/company/CompanyCore";
import { usePermissionsMovilPay } from "../../../../../hooks/useUserMovilPay";
import { usePermissions } from "../../../../../context/PermissionContext";
import { useStorePermissions } from "../../../../../store/permission/useStorePermission";
type Props = {};
export const MyCompany = (props: Props) => {
  const permissionCompany = usePermissionsMovilPay();
  const { setPermissions } = useStorePermissions((state) => state);
  React.useEffect(() => {
        setPermissions(permissionCompany);
  }, [permissionCompany]);
  return (
    <div>
      <CompanyCore />
    </div>
  );
};
