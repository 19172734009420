import { createAsyncThunk } from "@reduxjs/toolkit";
import { DollarParams } from "../../pages/core/params/dollar/DollarParams";
import { movilPayAPI } from "../../api/movilPayAPI";

export const dollarServices = createAsyncThunk(
  "dollar",
  async (params?: DollarParams) => {
    return await movilPayAPI
      .get("/base/dollar/", { params })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return [];
      });
  }
);
