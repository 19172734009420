import * as Yup from 'yup';

const bankAccountSchema = Yup.object().shape({
  bank: Yup.number().required('El campo banco es requerido.'),
  identification: Yup.string().required('El campo identificación es requerido.'),
  accountNumber: Yup.string()
    .required('El campo número de cuenta es requerido.')
    .matches(/^(?:\d{4}-?){0,5}\d{0,4}$/, 'El número de cuenta debe tener un formato válido (xxxx-xxxx-xxxx-xxxx-xxxx-xxxx).')
    .max(24, 'El número de cuenta no puede tener más de 24 caracteres.')
    .min(24, 'El número de cuenta debe tener un formato válido (xxxx-xxxx-xxxx-xxxx-xxxx-xxxx).'),
  phone: Yup.string().required('El campo teléfono es requerido.'),
});

export default bankAccountSchema;
