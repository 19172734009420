import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Link,
  Button,
  User,
} from "@nextui-org/react";
import { useThemeMovilPay } from "../../../hooks/useTheme";
import { CheckChangeTheme } from "./CheckChangeTheme";
import { Img } from "react-image";
import { LuLogOut } from "react-icons/lu";
import { configLogout } from "../../../utils/configLogout";
import { FaRegUser, FaUser } from "react-icons/fa6";
import { useUserMovilPay } from "../../../hooks/useUserMovilPay";
import { ActionMenuAccount } from "./ActionMenuAccount";
import { PiUserFocusFill } from "react-icons/pi";
import { CardDolar } from "./dollars/CardDolar";
import { BreadCrumbCore } from "./BreadCrumb/BreadCrumbCore";

export const NavbarCore = ({
  isMobile,
  children,
  path,
}: {
  isMobile: boolean;
  children: React.ReactNode;
  path?: React.ReactNode;
}) => {
  const { darkMode } = useThemeMovilPay();
  const img = darkMode
    ? "logotipo movil play letras todo blanco"
    : "logotipo_movil_play";
  const { name, lastname, identification } = useUserMovilPay();
  const username = `${name} ${lastname}`;
  const company = `${identification}`;

  const handleLogout = () => {
    configLogout();
  };

  return (
    <Navbar
      maxWidth="full"
      className="flex bg-transparent pt-8 items-end"
      style={{ zIndex: 8 }}
    >
      <NavbarContent justify="start">
        <NavbarBrand className="flex lg:hidden">
          <div className="flex lg:ml-1">{children} </div>
        </NavbarBrand>
        <NavbarContent
          justify="start"
          className="hidden sm:flex ml-4"
        >
          <NavbarItem>
            <BreadCrumbCore />
          </NavbarItem>
        </NavbarContent>
      </NavbarContent>
      <NavbarContent justify="center">
        <NavbarItem className="flex items-center gap-2">
          <CardDolar isMobile={isMobile} />
        </NavbarItem>
      </NavbarContent>
      <NavbarContent justify="end">
        <NavbarItem className="flex items-center">
          <CheckChangeTheme isMobile={isMobile} />
        </NavbarItem>
        <NavbarItem className="flex items-center">
          <ActionMenuAccount />
        </NavbarItem>
        <NavbarItem className="flex items-center">
          <Button
            as={Link}
            size="md"
            variant="light"
            isIconOnly
            radius="full"
            onPress={handleLogout}
          >
            <LuLogOut className="h-7 w-7" />
          </Button>
        </NavbarItem>
      </NavbarContent>
    </Navbar>
  );
};
