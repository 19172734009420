import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
    Select,
    SelectItem,
    Input,
    Button,
} from "@nextui-org/react";
import { IoIosAdd } from "react-icons/io";
import { schemaRetentions } from "../validators/RetentionsSchema";

type Props = {
    data?: any | {};
    setData?: (data: any) => void;
    onSubmit: (data: any) => void;
    ref?: React.MutableRefObject<(() => void) | null>;
};

export const FormRetentions = React.forwardRef((props: Props, ref) => {
    const [calculates, setCalculates] = useState([])
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
    } = useForm({
        defaultValues: {
            percentage: 0, // or any initial value you need
        },
        resolver: yupResolver(schemaRetentions),

    });



    React.useImperativeHandle(ref, () => handleSubmit(props.onSubmit));


   // Efecto para setear los valores del formulario cuando `props.data` cambia
   React.useEffect(() => {
    if (props.data) {
      setValue("name", props.data.name || "");
      setValue("description", props.data.description || "");
      setValue("percentage", props.data.percentage || "");
      setValue("calculate", props.data.calculate || "");
      // Asegúrate de que este valor coincida con el value de los SelectItem
    }
  }, [props.data, setValue]);




    return (
        <form className="flex flex-col space-y-3">

            <div>
                <Controller
                    control={control}
                    name="name"
                    render={({ field }) => (
                        <>
                            <Input
                                {...field}
                                label="Nombre"
                                placeholder="Nombre"
                                variant="bordered"
                                labelPlacement="outside"
                                isInvalid={!!errors.name}
                                errorMessage={errors.name?.message}
                            />
                        </>
                    )}
                />
            </div>

            
            <div>
                <Controller
                    control={control}
                    name="description"
                    render={({ field }) => (
                        <>
                            <Input
                                {...field}
                                label="Descripcion"
                                placeholder="Descripcion"
                                variant="bordered"
                                labelPlacement="outside"
                                isInvalid={!!errors.description}
                                errorMessage={errors.description?.message}
                            />
                        </>
                    )}
                />
            </div>
            <div>
                <Controller
                    control={control}
                    name="percentage"
                    render={({ field }) => (
                        <>
                            <Input
                                {...field}
                                value={String(field.value) ?? ""} // Convertir el valor a string
                                label="Porcentaje"
                                placeholder="Porcentaje"
                                variant="bordered"
                                labelPlacement="outside"
                                isInvalid={!!errors.percentage}
                                errorMessage={errors.percentage?.message}
                            />
                        </>
                    )}
                />
            </div>


            {/* Selección del servicio */}
            <div className="">
                <Controller
                    control={control}
                    name="calculate"
                    render={({ field }) => (
                        <>
                            <Select
                                {...field}
                                selectedKeys={field.value ? new Set([field.value]) : new Set()}
                                isInvalid={!!errors.calculate}
                                placeholder="Selecciona calculo"
                                variant="bordered"
                                errorMessage={errors.calculate?.message}
                            >
                                {calculates.map((data: any) => (
                                    <SelectItem key={data.id} value={data.id}>
                                        {data.name}
                                    </SelectItem>
                                ))}
                            </Select>

                        </>
                    )}
                />
            </div>
        </form>
    );
});
