export class UserParams {
  page?: number;
  search?: string;
  remove_pagination?: boolean;
  menu?: string | number;
}
export class ServicesParams {
  page?: number;
  search?: string;
  remove_pagination?: boolean;
  price_flag?: boolean;
}