import { createSlice } from "@reduxjs/toolkit";
import {  ServicesThunks } from "./thunks";
import { Payment } from "../../pages/core/interfaces/PaymentInterfaces";
import { Services } from "../../pages/core/interfaces/ServicesInterfaces";

export interface initialStateService {
  isLoading: boolean;
  services:Services[];
  count:number 
  page?:number
}

export const initialStateServices: initialStateService = {
  isLoading: false,
  count: 0,
  services:[]
};

export const ServicesSlice = createSlice({
  name: "services",
  initialState: initialStateServices,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ServicesThunks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ServicesThunks.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.services = payload.results
        state.count = payload.count
      });
  },
});

export const {} = ServicesSlice.actions;
