// icons.js

// Importa todos los íconos como un namespace (objeto)
import * as IoIcons from "react-icons/io5";
import * as FcIcons from "react-icons/fc";
import * as MdIcons from "react-icons/md";
import * as FaIcons from "react-icons/fa";
import * as LiaIcons from "react-icons/lia";
import * as BsIcons from "react-icons/bs";
import * as TbIcons  from "react-icons/tb";
import * as BiIcons from "react-icons/bi";
import * as PiIcons from "react-icons/pi";
import * as  Fa6 from "react-icons/fa6";
import * as Md2 from "react-icons/md";
import * as FaIcons6 from "react-icons/fa6";

// Combina todos los íconos de ambas librerías
const Icons = {
  ...IoIcons,  // Íconos de react-icons/io5
  ...FcIcons,  // Íconos de react-icons/fc
  ...MdIcons,  // Íconos de react-icons/md
  ...FaIcons,  // Íconos de react-icons/fa
  ...LiaIcons, // Íconos de react-icons/lia
  ...BsIcons, // Íconos de react-icons/bs
  ...TbIcons, // Íconos de react-icons/tb
  ...BiIcons, // Íconos de react-icons/bi
  ...PiIcons, // Íconos de react-icons/pi
  ...Fa6, // Íconos de react-icons/fa6
  ...Md2, // Íconos de react-icons/fa6
  ...FaIcons6,
};

export default Icons;
// Define un tipo que incluya los nombres de los íconos
export type IconNames = keyof typeof Icons;