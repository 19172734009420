import { getKeyValue, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react";
import useStorePaymentInvoice from "../../../../../../../../store/finances/useStorePaymentInvoice";
import { MdFormatListBulletedAdd } from "react-icons/md";

// Define tus columnas de la tabla
const columns = [
    {
        key: "id",
        label: "Factura Pendiente",
    },
    {
        key: "total",
        label: "Monto",
    },
    {
        key: "charged",
        label: "Monto Cargado",
    },
    {
        key: "amount",
        label: "Deuda",
    },
    {
        key: "accion",
        label: "Acción",
    },
];

export const TablePendingInvoices = () => {
    const { invoicesPending, addInvoice, invoices } = useStorePaymentInvoice();

    // Filtra las facturas pendientes para excluir las que ya están en invoices
    const filteredPendingInvoices = invoicesPending.filter(
        (pendingInvoice) => !invoices.some((invoice) => invoice.id === pendingInvoice.id)
    );

    // Función para agregar factura y actualizar la lista de pendientes
    const addInvoiceToPay = (invoice: any) => {
        addInvoice(invoice);
    };

    return (
        <>
            {filteredPendingInvoices.length > 0 && (
                <div>
                    <Table aria-label="Example table with dynamic content">
                        <TableHeader columns={columns}>
                            {(column) => (
                                <TableColumn key={column.key} className="bg-gray-500 text-white">
                                    {column.label}
                                </TableColumn>
                            )}
                        </TableHeader>
                        <TableBody items={filteredPendingInvoices}>
                            {(invoice) => (
                                <TableRow key={invoice.id}>
                                    {(columnKey) => {
                                        if (columnKey === "total") {
                                            // Muestra el botón de acción solo si no es el primer registro
                                            return (
                                                <TableCell key={columnKey}>
                                                    <span>
                                                        {invoice.amount + invoice.charged}
                                                    </span>
                                                </TableCell>
                                            );
                                        }

                                        if (columnKey === "amount") {
                                            // Muestra el botón de acción solo si no es el primer registro
                                            return (
                                                <TableCell key={columnKey}>
                                                    <span>
                                                        {invoice.amount.toFixed(2)}
                                                    </span>
                                                </TableCell>
                                            );
                                        }
                                        if (columnKey === "accion") {
                                            // Botón de acción para agregar factura
                                            return (
                                                <TableCell>
                                                    <button
                                                        onClick={() => addInvoiceToPay(invoice)}
                                                        className="text-primary hover:text-green-700"
                                                        aria-label={`Pagar Factura`}
                                                    >
                                                        <MdFormatListBulletedAdd className="h-5 w-5" />
                                                    </button>
                                                </TableCell>
                                            );
                                        }
                                        return <TableCell>{getKeyValue(invoice, columnKey)}</TableCell>;
                                    }}
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
            )}
        </>
    );
};
