import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Select, SelectItem, Input } from "@nextui-org/react";
import { AppDispatch, RootState } from "../../../../../../store/store";
import { ServicesThunks } from "../../../../../../store/services/thunks";
import { schemaPatchRates, schemaRates } from "../validators/RatesSchema";
import { schemaTarifa } from "../validators/TarifaSchema";
import { FaTrash } from "react-icons/fa6";

type Props = {
  data?: any | {};
  setData?: (data: any) => void;
  onSubmit: (data: any) => void;
  ref?: React.MutableRefObject<(() => void) | null>;
};

export const FormPatchRates = React.forwardRef((props: Props, ref) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schemaPatchRates),
  });

  // Efecto para setear los valores del formulario cuando `props.data` cambia
  React.useEffect(() => {
    if (props.data) {
      setValue("price", props.data.price || "");
    }
  }, [props.data, setValue]);
  React.useImperativeHandle(ref, () => handleSubmit(props.onSubmit));

  return (
    <form className="flex flex-col my-4">
      {/* Selección del servicio */}
      <div className="my-4">
        <Controller
          control={control}
          name="price"
          render={({ field }) => (
            <>
              <Input
                {...field}
                value={String(field.value)} // Convertir el valor a string
                isInvalid={!!errors.price}
                label="Precio"
                placeholder="Precio"
                variant="bordered"
              />
              {errors.price && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors.price.message}
                </span>
              )}
            </>
          )}
        />
      </div>
      {errors.price && (
        <span style={{ color: "red", fontSize: "12px" }}>
          {errors.price.message}
        </span>
      )}
    </form>
  );
});
