import * as yup from "yup";

export const schemaPermissions = yup.object({
  name: yup.string().required("Este campo es requerido"),
  permissions: yup.string().required("Este campo es requerido"),
  menu: yup.string().required("Este campo es requerido"),
  description: yup
    .string()
    .default("")
    .required("Este campo es requerido")
    .max(250, "La cantidad máxima de caracteres es de 250"),
});
