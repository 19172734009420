import { configureStore } from "@reduxjs/toolkit";
import { BanksSlice } from "./banks/BanksSlice";
import { PaymentsSlice } from "./payment/PaymentSlice";
import { CompaniesSlice } from "./companies/CompaniesSlice";
import { MethodPaymentsSlice } from "./payment/MethodPaymentsSlice";
import { DashboardSlice } from "./dashboard/DashboardSlice";
import { DashCompanySlice } from "./dashboard/DashCompanySlice";
import { CompanyIDSlice } from "./companies/CompanyIDSlice";
import { DashboardPaymentSlice } from "./dashboard/DashCompanyPaymentSlice";
import { InvoicesSlice } from "./invoices/InvoicesCompanySlice";
import { ServicesSlice } from "./services/ServicesSlice";
import { UserSlice } from "./settings/Users/UserSlice";
import { CustomerGroupslice } from "./settings/CustomerGroups/CustomerGroupsSlice";
import { DollarSlice } from "./dollar/DollarSlice";
import { WarehouseSlice } from "./managements/warehouse/WarehouseSlice";
import { ServicesCompanySlice } from "./companies/servicesCompany/ServicesCompanySlice";
import { ServicesTypeSlice } from "./services/ServicesTypeSlice";
import { ServicesConfigurationSlice } from "./companies/servicesConfiguration/ServicesConfigurationSlice";
import { ManufacturerSlice } from "./managements/manufacturers/ManufacturerSlice";
import { ModelSlice } from "./managements/model/ModelsSlice";
import { OcupationSlice } from "./options/Ocupation/OptionSlice";
import { TypeCpmpanySlice } from "./options/TypeCompany/TypeCompanySlice";
import { SectorCompanySlice } from "./options/SectorEconomic/SectorCompanySlice";
import { StateSlice } from "./location/States/States";
import { ParishSlice } from "./location/Parish/ParishSlice";
import { SectorSlice } from "./location/Sector/SectorSlice";
import { MethodPaymentsCompanySlice } from "./companies/PaymentMethodCompany/PaymentMethodSlice";

export const store = configureStore({
  reducer: {
    banks: BanksSlice.reducer,
    payments: PaymentsSlice.reducer,
    companies: CompaniesSlice.reducer,
    methodPayments: MethodPaymentsSlice.reducer,
    dashboard: DashboardSlice.reducer,
    dashCompany: DashCompanySlice.reducer,
    companyID: CompanyIDSlice.reducer,
    DashboardPaymentSlice: DashboardPaymentSlice.reducer,
    invoicesCompany: InvoicesSlice.reducer,
    services: ServicesSlice.reducer,
    users: UserSlice.reducer,
    customerGroups: CustomerGroupslice.reducer,
    dollar: DollarSlice.reducer,
    warehouse: WarehouseSlice.reducer,
    servicesCompany: ServicesCompanySlice.reducer,
    servicesType: ServicesTypeSlice.reducer,
    servicesConfiguration: ServicesConfigurationSlice.reducer,
    manufacturer: ManufacturerSlice.reducer,
    model: ModelSlice.reducer,
    ocupations: OcupationSlice.reducer,
    typeCompany: TypeCpmpanySlice.reducer,
    sectorEconomic: SectorCompanySlice.reducer,
    states: StateSlice.reducer,
    parish: ParishSlice.reducer,
    sector: SectorSlice.reducer,
    methodPaymentsCompany: MethodPaymentsCompanySlice.reducer,

  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
