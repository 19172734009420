import { createSlice } from "@reduxjs/toolkit";
import { ManufacturerThunks } from "./thunks";
import { ManufacturerInterface } from "../../../pages/core/interfaces/Manufacturer";

export interface InitialManufacturer{
    page?: number;
    manufacturer: ManufacturerInterface[];
    count: number;
    is_loading?: boolean;
}

export const initialStateManufacturer: InitialManufacturer = {
    manufacturer: [],
    count: 0,
    is_loading: true
};

export const ManufacturerSlice = createSlice({
    name: "manufacturer",
    initialState: initialStateManufacturer,
    reducers: {},
    extraReducers: (builder) => {
        builder
       .addCase(ManufacturerThunks.pending, (state) => {
            state.is_loading = true;
        })
       .addCase(ManufacturerThunks.fulfilled, (state, { payload }) => {
            state.is_loading = false;
            state.manufacturer = payload.results  || payload ;
            state.count = payload.count  || payload.length ;
        })
       .addCase(ManufacturerThunks.rejected, (state) => {
            state.is_loading = false;
        })
    }
 });

 export const { } = ManufacturerSlice.actions;