// @flow
import * as React from "react";
import { PaymentMethodsCompany } from "../../../../../interfaces/CompaniesInterfaces";
import { schemaAccountAffiliateZelle } from "../../services/snp/Validators/shemaSNP";
import { yupResolver } from "@hookform/resolvers/yup";
import { RootState } from "@react-three/fiber";
import { useQueryClient, queryOptions } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { ErrorToast } from "../../../../../../../libs/Notifications";
import { BanksThunks } from "../../../../../../../store/banks/thunks";
import { GetCodePhoneThunks } from "../../../../../../../store/companies/thunks";
import { useStoreCodePhones } from "../../../../../../../store/companies/useStoreCodePhone";
import { AppDispatch } from "../../../../../../../store/store";
import { PaymentParams } from "../../../../../params/payment/payments/paymentParams";
import { Input } from "@nextui-org/react";
type Props = {
  data?: PaymentMethodsCompany | any;
  onSubmit: (data: any) => void;
  ref?: React.MutableRefObject<(() => void) | null>;
};
export const FormZelle = React.forwardRef(({ onSubmit, data }: Props, ref) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schemaAccountAffiliateZelle),
  });
  React.useImperativeHandle(ref, () => handleSubmit(onSubmit));

  React.useEffect(() => {
    setValue("name", data?.name);
    setValue("email", data?.email);
  }, [data]);
  return (
        <div className=" w-full flex flex-col my-4 space-y-2 ">
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <>
              <Input
                {...field}
                maxLength={30}
                variant="bordered"
                isInvalid={!!errors.name}
                errorMessage={errors.name?.message}
                placeholder="Colocar el nombre del Titular "
              />
              <span></span>
            </>
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <>
              <Input
                {...field}
                maxLength={30}
                variant="bordered"
                isInvalid={!!errors.email}
                errorMessage={errors.email?.message}
                placeholder="Colocar el correo"
              />
              <span></span>
            </>
          )}
        />
      </div>
  );
});
