import * as yup from 'yup';

const DeviceSchema = yup.object().shape({
    device: yup.string().required('Service is required'),
    device_serial: yup.string().required('serial es requerido'),
    cant: yup.string().min(1).required('Quantity is required'),
    description: yup.string().required('Description is required'),
    price: yup.string().nullable().required("Precio es requerido"),
});

export default DeviceSchema;
