import { Input } from "@nextui-org/react";
import { useForm } from "react-hook-form";
import { useAllParams } from "../../../../../../../hooks/useAllParams";

import { useState } from "react";

import { ButtonsModalCompaniesDashboard } from "../../../../dashboard/components/ButtonsModalCompaniesDashboard";

export const CompaniesFilterPayments = () => {
  const { params, setSearchParams } = useAllParams();
  const [valueText, setValueText] = useState("");
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      company: params.company,
    },
  });
  const onSubmit = ({ id,name }: any) => {
    const { page, search, ...restParams } = params;
    const payload = { ...restParams, company: id };
    setSearchParams(payload);
    setValueText(name)
  };

  const handleClear=()=>{
    const { page, search,company ,...restParams } = params;
    const payload = { ...restParams };
    setSearchParams(payload);
    setValueText('')
  }

  return (
    <div className="flex items-center ">
      <Input
        type="text"
        isClearable
        className="mr-3"
        variant="bordered"
        value={valueText}
        placeholder="Introduce una compañía"
        readOnly
        onClear={handleClear}
      />
      <ButtonsModalCompaniesDashboard selectCompany={(e) => onSubmit(e)} />
    </div>
  );
};
