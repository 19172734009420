import { movilPayAPI } from "../../../api/movilPayAPI";

export const GetAccountAfiliateThunks = (params: any) => {
    return movilPayAPI.get(`/payments/company_payment_methods/`, {params});
  };
  
  export const PostAccountAfiliateThunks = (body: any) => {
    return movilPayAPI.post(`/payments/company_payment_methods/`, body);
  };
  export const PatchAccountAfiliateThunks = (body: any, id: number) => {
    return movilPayAPI.patch(`/payments/company_payment_methods/${id}/`, body);
  };
  export const GetIdAccountAfiliateThunks = (id: number) => {
    return movilPayAPI.get(`/payments/company_payment_methods/${id}/`);
  };
  export const DeleteIdAccountAfiliateThunks = (id: number) => {
    return movilPayAPI.delete(`/payments/company_payment_methods/${id}/`);
  };
  