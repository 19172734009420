// @flow
import { Chip } from "@nextui-org/react";
import * as React from "react";
import { ServicesCompany } from "../../../../../interfaces/ServicesCompany";
import { TableLayout, valueType } from "../../../../../layout/TableLayout";
import {
  FinishDetailService,
  ActionServicesCompany,
  FinishService,
  OpenStatusService,
} from "../ActionServicesCompany";
import { useStoreServiceCompanyCore } from "../../../../../../../store/companies/servicesCompany/useStoreServiceCompanyCore";
import { PaginatorPayments } from "../../../../finances/payments/components/table/PaginatorPayments";
import { usePermissions } from "../../../../../../../context/PermissionContext";
type Props = {
  reload?: () => void;
};
export const TableServicesCompany = ({ reload }: Props) => {
  const { serviceCompany, count } = useStoreServiceCompanyCore(
    (state) => state
  );
  const { permissions, loading } = usePermissions();
  const actionPermissionView =permissions && permissions.includes('action_view_company_services');
  const actionPermissionFinish =permissions && permissions.includes('action_end_company_service');
  const actionPermissionStatus =permissions && permissions.includes('action_view_change_company_service_status');

  const dataTable: valueType[] = [
    { name: "Servicio", value: "service_name" },
    {
      name: "Finalizado",
      value: (item: ServicesCompany) => {
        return (
          <>
            {item.date_end != null ? (
              <FinishDetailService e={item} />
            ) : (
              "Sin Finalizar"
            )}
          </>
        );
      },
    },
    {
      name: "Estado",
      value: (item: ServicesCompany) => {
        return (
          <>
            <Chip color={item.is_active ? "primary" : "danger"}>
              {item.is_active ? "Activo" : "Inactivo"}
            </Chip>
          </>
        );
      },
    },
    {
      name: "Acción",
      value: (e: ServicesCompany) => (
        <div className="flex gap-x-2 ">
          {actionPermissionView && <ActionServicesCompany e={e} reload={reload} />}
          {e.date_end == null && (
            <>
              {actionPermissionFinish &&<FinishService reload={reload} e={e} />}
              {actionPermissionStatus && <OpenStatusService e={e} reload={reload} />}
            </>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <TableLayout
        columns={dataTable}
        data={serviceCompany}
        isLoading={false}
        Paginator={<PaginatorPayments count={count} />}
      />
    </>
  );
};
