import { Tabs, Tab } from "@nextui-org/react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { companyIDRouters } from "../routers/companyIDRouters";
import { useEffect, useState } from "react";
import { usePermissions } from "../../../../../../context/PermissionContext";
export interface Props {
  reload?: () => void;
}

export const TabsCompany = ({ reload }: Props) => {
  const param = useParams();
  const navigate = useNavigate();
  const [path, setPath] = useState("");

  const handleNavigation = (path: any) => {
    setPath(path);
    navigate(path);
  };
  const { permissions, loading } = usePermissions();

  const getPermissions = (permission: any):Boolean => {
    return permissions && permissions.includes(permission);
  };

  useEffect(() => {
    const ruta: any = param["*"];
    const arrayRoute = ruta!.split("/").filter((d: any) => d !== "");
    const last = arrayRoute[arrayRoute.length - 1];
    // setPath(`${last}/`);
  }, []);

  return (
    <>
      <div className="flex w-full flex-col  bg-white px-4 py-5 shadow-md dark:bg-primaryDark rounded-lg sm:px-6 ">
        <Tabs
          items={companyIDRouters}
          selectedKey={path}
          color="primary"
          variant="underlined"
          onSelectionChange={handleNavigation}
          defaultSelectedKey={"info_company/"}
          classNames={{
            tabList:
              "gap-6 w-full relative rounded-none p-0 border-b border-gray-700 border-divider font-medium flex-wrap ",
            cursor: "w-full bg-gray-700 h-1",
            tab: "max-w-fit px-0 h-12 text-lg gap-x-4 ",
            tabContent: "group-data-[selected=true]:text-gray-700  ",
          }}
        >
          {(item) =>{
            return (
            getPermissions(item.permission) &&
             
              <Tab
                key={item.path}
                title={
                  <div className="flex items-center space-x-2">
                    <item.icon className="h-5 w-5" />
                    <span>{item.name}</span>
                  </div>
                }
              ></Tab>
            )}
          }
        </Tabs>
      </div>
      <Outlet />
    </>
  );
};
{
  /* {getPermissions(item.permission) ? <></> : <div>Prueba</div>} */
}
