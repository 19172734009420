import { createSlice } from "@reduxjs/toolkit";
import { ServicesConfigurationThunks } from "./thunks";
import { ServiceConfigurationCompany } from "../../../pages/core/interfaces/ServicesCompany";

export interface initialStateService {
  isLoading: boolean;
  servicesConfiguration:ServiceConfigurationCompany[];
  page?:number;
  count: number; // total number of items in the list, obtained from the API response
}

export const initialStateServices: initialStateService = {
  isLoading: false,
  count: 0,  // total number of items in the list, obtained from the API response
  servicesConfiguration:[]
};

export const ServicesConfigurationSlice = createSlice({
  name: "servicesConfiguration",
  initialState: initialStateServices,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ServicesConfigurationThunks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ServicesConfigurationThunks.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.servicesConfiguration = payload.results;
        state.count = payload.count
      });
  },
});

export const {} = ServicesConfigurationSlice.actions;
