import { createSlice } from "@reduxjs/toolkit";
import { StatesThunks } from "./thunks";

export interface initialState {
    isLoading: boolean;
    states:any[];
    count:number 
    page?:number
  }
  
  export const initialState: initialState = {
    isLoading: false,
    count: 0,
    states:[]
  };
  
  export const StateSlice = createSlice({
    name: "State",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(StatesThunks.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(StatesThunks.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          state.states = payload.results  || payload
          state.count = payload.count
        });
    },
  });
  
  export const {} = StateSlice.actions;