// @flow
import { Tabs, Tab, Card, CardBody } from "@nextui-org/react";
import * as React from "react";
import { LocationRouter } from "./routers/locations";
import { useParams, useNavigate, Outlet } from "react-router-dom";
import { usePermissions } from "../../../../../context/PermissionContext";
type Props = {};
export const LocationsCore = (props: Props) => {
  const param = useParams();
  const navigate = useNavigate();
  const [path, setPath] = React.useState("");

  const handleNavigation = (path: any) => {
    setPath(path);
    navigate(path);
  };
  const { permissions, loading } = usePermissions();

  const getPermissions = (permission: any): Boolean => {
    return permissions && permissions.includes(permission);
  };

  React.useEffect(() => {
    const ruta: any = param["*"];
    const arrayRoute = ruta!.split("/").filter((d: any) => d !== "");
    const last = arrayRoute[arrayRoute.length - 1];
    setPath(`${last}/`);
  }, []);
  return (
    <>
      <div className="flex w-full  px-6 py-5 sm:px-6 ">
        <Tabs
          fullWidth
          isVertical={true}
          items={LocationRouter}
          selectedKey={path}
          color="primary"
          size="lg"
          variant="underlined"
          placement="start"
          onSelectionChange={handleNavigation}
          defaultSelectedKey={"parish/"}
          classNames={{
            tabList:
              "gap-x-6 w-full relative rounded-none px-4 font-medium flex-wrap mx-4 ",
            cursor: "w-full h-1",
            tab: "max-w-fit px-0 h-12 text-lg gap-x-4 ",
            tabContent: "group-data-[selected=true]:text-gray-700  ",
          }}
        >
          {(item) => {
            return (
              //   getPermissions(item.permission) &&

              <Tab
                key={item.path}
                title={
                  <div className="flex items-center space-x-2">
                    {/* <item.icon className="h-5 w-5" /> */}
                    <span>{item.name}</span>
                  </div>
                }
              ></Tab>
            );
          }}
        </Tabs>
        <Outlet />
      </div>
    </>
  );
};
