import { movilPayAPI } from "../../../api/movilPayAPI";
import { UserParams } from "../../../pages/core/params/settings/userParams";

export const GetRolesThunks = (params?: UserParams) => {
  return movilPayAPI.get(`/permissions/auth_groups/`, { params });
};
// export const PostRolesThunks = (body: any) => {
//   return movilPayAPI.post(`/users/`, body);
// };
// export const PatchRolesThunks = (id: number, body:any) => {
//   return movilPayAPI.patch(`/users/${id}/`, body);
// };
