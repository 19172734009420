import create from 'zustand';

type FormData = {
    id: number;
    name: string;
    cant: number;
    description: string;
    price: number;
    iva: number;
    serial: string;
    exentoIVA: boolean;
    type:number
};

type FormDataCompany = {

    company: number;
    date_emission: string;
    date_expiration: string;
    month: number;
    year: number;
};

type StoreItem = {
    formData: FormData[];
    formDataCompany: FormDataCompany | null;
    setFormData: (data: FormData) => void;
    resetForm: () => void;
    removeFormData: (index: number) => void;
    setFormDataCompany: (data: FormDataCompany) => void;
    resetFormCompany: () => void;
};

export const useStoreCreateInvoice = create<StoreItem>((set) => ({
    formData: [],
    formDataCompany: null,

    // Add new form data to the array
    setFormData: (data) => set((state) => ({ formData: [...state.formData, data] })),

    // Reset all form data
    resetForm: () => set({ formData: [] }),

    // Remove a form data entry by index
    removeFormData: (index) => set((state) => {
        const newFormData = state.formData.filter((_, i) => i !== index);
        return { formData: newFormData };
    }),

    // Set company form data
    setFormDataCompany: (data) => set({ formDataCompany: data }),

    // Reset the company form data to null
    resetFormCompany: () => set({ formDataCompany: null }),
}));
