import { GrMoney } from "react-icons/gr";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { TbCalendarDollar } from "react-icons/tb";
import { RootState } from "../../../../../../store/store";
import { useSelector } from "react-redux";
import { VscDebugRestart } from "react-icons/vsc";

export const InvoiceInfo = () => {
  const { companies } = useSelector((d: RootState) => d.companyID);
  return (
    <>
      <div className="grid  md:grid-cols-2 sm:grid-cols-1 items-center gap-2 border-y-1.5  justify-between border-neutral-300 ">
        {/* Card 1 */}
        <div className=" px-4  justify-start items-center gap-2 inline-flex  my-4 border-r-1.5  border-neutral-300  ">
          <div
            className={`rounded-full flex justify-center overflow-hidden duration-300 h-14 w-14 shrink-0 bg-primary items-center`}
          >
            <GrMoney className="h-8 w-8 text-gray-50 text-center dark:text-white" />
          </div>
          <div className="flex-col justify-center items-center gap-2 flex">
            <div className="text-zinc-700 text-2xl dark:text-titleDark  font-medium flex items-center ">
              Deuda pendiente
            </div>
            <div className="text-lime-600 text-2xl font-medium ">
              {companies?.debt_amount?.balance_bs || 0} Bs.
            </div>
            <div className=" w-3/4 border-1"></div>
            <div className="text-gray-500 text-xl font-medium ">
              {companies?.debt_amount?.balance_usd || 0} USD
            </div>
          </div>
        </div>
        {/* <div className=" h-3/4 border-1.5 w-0"></div> */}
        {/* Card 2 */}
        <div className=" px-4  justify-start items-center gap-2 inline-flex my-4 ">
          <div
            className={`rounded-full flex justify-center overflow-hidden duration-300 h-14 w-14 shrink-0 bg-primary items-center`}
          >
            <VscDebugRestart className="h-8 w-8 text-gray-50 text-center dark:text-white" />
          </div>
          <div className="flex-col justify-center items-center gap-2 flex">
            <div className="text-zinc-700 text-2xl dark:text-titleDark  font-medium flex items-center ">
              Saldo a favor
            </div>
            <div className="text-lime-600 text-2xl font-medium "> {companies?.balance_amount?.balance_bs || 0} Bs.</div>
            <div className=" w-3/4 border-1"></div>
            <div className="text-gray-500 text-xl font-medium "> {companies?.balance_amount?.balance_usd || 0} USD</div>
          </div>
        </div>
      </div>
    </>
  );
};
