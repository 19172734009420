import { AddToBankAccount } from "./components/AddBankAccount";
import CardBanks from "./components/CardsBanks";
import { useEffect } from "react";
import { PaginatorTable } from "../../../components/table/PaginatorTable";
import { getBanksAccounts } from "../../../../../store/banks-accounts/Thunks";
import { BanksAccountsParams } from "../../../params/banks/BanksAccountsParams";
import { useStoreBanksAccounts } from "../../../../../store/banks-accounts/useStoreBanksAccounts";
import { useAllParams } from "../../../../../hooks/useAllParams";
import { queryOptions, useQueryClient } from "@tanstack/react-query";
import { FcSearch } from "react-icons/fc";
import { Button, Input } from "@nextui-org/react";
import { Controller, useForm } from "react-hook-form";
import { usePermissions } from "../../../../../context/PermissionContext";
import { BiSearch } from "react-icons/bi";

export const BanksCore = () => {
  const queryClient = useQueryClient();
  const { params, deleteParams, setSearchParams } = useAllParams();
  const { setBanksAccounts, count } = useStoreBanksAccounts((state) => state);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      search: params.search,
    },
  });
  const { permissions, loading } = usePermissions();
  const actionPermissionAdd =
    permissions && permissions.includes("action_add_bank_accounts");
  const handleConsultation = () => {
    const param = new BanksAccountsParams();
    if (params.page) param.page = Number(params.page);
    if (params.search) param.search = params.search;

    queryClient.fetchQuery(
      queryOptions({
        queryKey: ["banksAccounts"],
        queryFn: () =>
          getBanksAccounts(param).then((d) => {
            setBanksAccounts(d.data.results, d.data.count);
          }),
        retry: false,
      })
    );
  };

  useEffect(() => {
    handleConsultation();
  }, [params]); // Add filterUsed to the dependencies array

  const onSubmit = ({ search }: any) => {
    if (!search) {
      deleteParams(["search"]);
    } else {
      const { page, ...rest } = params;
      setSearchParams({ ...rest, search });
    }
  };

  return (
    <div className="animate-fade-up   px-4 py-5 dark:bg-primaryDark rounded-lg sm:px-6 my-4">
      <div className="space-y-7">
        <div className="lg:flex lg:justify-between lg:items-center">
          <div className="mb-7 lg:mb-0 grid grid-cols-2 gap-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="search"
                render={({ field }) => (
                  <div className="flex items-center">
                    <Input
                      {...field}
                      type="text"
                      variant="bordered"
                      placeholder="Introduce para buscar"
                      className="w-full lg:w-[500px] sm:w-full"
                      size="lg"
                    />
                    <Button
                      isIconOnly
                      color="primary"
                      type="submit"
                      className="ml-2"
                    >
                      <BiSearch className="h-6 w-6 text-white" />
                    </Button>
                  </div>
                )}
              />
            </form>
          </div>
          <div className="flex space-x-3 justify-between">
            {actionPermissionAdd && (
              <AddToBankAccount e={undefined} reload={handleConsultation} />
            )}
          </div>
        </div>
        <div className="animate-fade-up">
          <CardBanks reload={handleConsultation} />
          <PaginatorTable count={count} />
        </div>
      </div>
    </div>
  );
};
