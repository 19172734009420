import { createSlice } from "@reduxjs/toolkit";
import { ModelsThunks } from "./thunks";
import { ModelInterface } from "../../../pages/core/interfaces/Model";

export interface InitialModel {
  page?: number;
  model: ModelInterface[];
  count: number;
  is_loading?: boolean;
}

export const initialStateModel: InitialModel = {
  count: 0,
  model: [],
  is_loading: false,
};

export const ModelSlice= createSlice({
  name: "models",
  initialState: initialStateModel,
  reducers: {},
  extraReducers: (builder) => {
      builder
     .addCase(ModelsThunks.pending, (state) => {
          state.is_loading = true;
      })
     .addCase(ModelsThunks.fulfilled, (state, { payload }) => {
          state.is_loading = false;
          state.model = payload.results  || payload ;
          state.count = payload.count  || payload.length ;
      })
     .addCase(ModelsThunks.rejected, (state) => {
          state.is_loading = false;
      })
  }
});

export const { } = ModelSlice.actions;