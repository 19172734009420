import {
  today as hoy,
  getLocalTimeZone,
  DateFormatter,
  parseDate,
  CalendarDate,
} from "@internationalized/date";

export const todayInternalized = () => {
  return hoy(getLocalTimeZone());
};

//para formatear de normal a fecha internacionalize
export const DateInternalized = (dateString: any) => {
  const [year, month, day] = dateString.split("-").map((d: string) => Number(d));
  const dateFormatter= new CalendarDate(year, month, day);
  return dateFormatter
};

//para formatear de internacionalize a fecha norma

export const formaterTimeInternalized = (date: any) => {
  const dataFormater = new Date(date).toLocaleString('es-ES').split(',')[0];;
  return dataFormater;
};


//para formatear de internacionalize a fecha normal pero por rango
export const formaterTimeInternalizedRange = ({ start, end }: any) => {
  const formatter = new DateFormatter("az");

  const startDate = new Date(start.year, start.month - 1, start.day);

  const endDate = new Date(end.year, end.month - 1, end.day);

  // Formatea el rango de fechas
  const [since, until] = formatter.formatRange(startDate, endDate).split("–");
  return {
    since,
    until,
  };
};
