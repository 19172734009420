import { yupResolver } from "@hookform/resolvers/yup";
import {
  Input,
  Select,
  SelectItem,
} from "@nextui-org/react";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { DevicesParams } from "../../../../../params/management/devicesParams";
import { queryOptions, useQueryClient } from "@tanstack/react-query";
import {
  getDevicesRequest,
} from "../../../../../../../store/managements/devices/thunks";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../../store/store";
import { BanksThunks } from "../../../../../../../store/banks/thunks";
import { Device } from "../../../../../interfaces/DevicesInterface";
import { ModelsThunks } from "../../../../../../../store/managements/model/thunks";
import { PaymentParams } from "../../../../../params/payment/payments/paymentParams";
import { IconButton } from "@mui/material";
import { IoCloseCircleOutline } from "react-icons/io5";

type Props = {
  data?: any | {};
  setData?: (data: any) => void;
  onSubmit: (data: any) => void;
  ref?: React.MutableRefObject<(() => void) | null>;
};

let schemaWarehouse = yup.object({
  device_type: yup.number().required("Este campo es requerido"),
  serial: yup
    .string()
    .min(6, "El serial debe tener al menos 6 caracteres")
    .max(20, "El serial no puede tener más de 20 caracteres")
    .required("Este campo es requerido"),
  mac: yup
    .string()
    .matches(
      /^([0-9A-Fa-f]{2}[:\-]){5}[0-9A-Fa-f]{2}$/,
      "El formato de la dirección MAC es inválido"
    )
    .required("Este campo es requerido"),
  bank: yup.number(),
  model: yup.number().required("Este campo es requerido"),
  price: yup.number().required("Este campo es requerido"),
  device_payment_methods: yup.string().required("Este campo es requerido"),
});

export const FormWarehouse = React.forwardRef((props: Props, ref) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schemaWarehouse),
  });
  const dispatch = useDispatch<AppDispatch>();
  const queryClient = useQueryClient();
  React.useImperativeHandle(ref, () => handleSubmit(props.onSubmit));
  const [devices, setDevices] = useState<Device[]>([]);
  const { banks } = useSelector((state: RootState) => state.banks);
  const { model } = useSelector((state: RootState) => state.model);

  const handleConsultation = () => {
    const param = new DevicesParams();
    param.remove_pagination = true;

    const bankParam = new PaymentParams();
    bankParam.work = true;

    queryClient.fetchQuery(
      queryOptions({
        queryKey: ["warehouse"],
        queryFn: () =>
          getDevicesRequest(param).then((d) => {
            setDevices(d.data);
          }),
        retry: false,
      })
    );
    dispatch(BanksThunks(bankParam));
    dispatch(ModelsThunks());
  };

  React.useEffect(() => {
    handleConsultation();
  }, []);

  // Efecto para setear los valores del formulario cuando `props.data` cambia
  React.useEffect(() => {
    if (props.data) {
      setValue("serial", props.data.serial || "");
      setValue("mac", props.data.mac || "");
      setValue("bank", props.data.bank || null);
      setValue("model", props.data.model || "");
      setValue("price", props.data.model || "");
      setValue("device_type", props.data.device_type || ""); // Asegúrate de que este valor coincida con el value de los SelectItem
    }
  }, [props.data, setValue]);

  const methodOptions = [
    { id: 1, name: "Pago Móvil" },
    { id: 2, name: "Transferencia" },
    { id: 9, name: "Punto de Venta" },
  ];

  // Función para limpiar el valor de bank
  const handleClearBank = () => {
    setValue("bank", undefined);
  };

  // Observa el valor del campo "bank"
  const bankValue = watch("bank");

  return (
    <form className="flex flex-col my-4">
      <div className="my-4">
        <Controller
          control={control}
          name="device_type"
          render={({ field }) => (
            <>
              <Select
                {...field}
                items={devices}
                variant="bordered"
                placeholder="Seleccione un tipo de Dispositivo"
                className="max-w-full"
                key={field.value}
                selectedKeys={field.value ? new Set([field.value]) : new Set()} // Show placeholder if field.value is null or empty

              // onChange={(e) => handleFinish(e.target.value)}
              >
                {(device_type) => (
                  <SelectItem key={device_type.id}>{device_type.name}</SelectItem>
                )}
              </Select>
              {errors.device_type && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors.device_type.message}
                </span>
              )}
            </>
          )}
        />
      </div>
      <div className="my-4">
        <Controller
          control={control}
          name="serial"
          render={({ field }) => (
            <>
              <Input
                {...field}
                isInvalid={!!errors.serial}
                type="text"
                autoFocus
                placeholder="Serial del dispositivo"
                variant="bordered"
              />
              {errors.serial && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors.serial.message}
                </span>
              )}
            </>
          )}
        />
      </div>
      <div className="my-4">
        <Controller
          control={control}
          name="mac"
          render={({ field }) => (
            <>
              <Input
                {...field}
                isInvalid={!!errors.mac}
                type="text"
                placeholder="Mac address del dispositivo"
                variant="bordered"
              />
              {errors.mac && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors.mac.message}
                </span>
              )}
            </>
          )}
        />
      </div>

      <div className="my-4 flex items-center">
        <Controller
          control={control}
          name="bank"
          render={({ field }) => (
            <>
              <Select
                {...field}
                items={banks}
                variant="bordered"
                placeholder="Elija un Banco"
                className="max-w-full"
                key={field.value}
                selectedKeys={field.value ? new Set([field.value]) : new Set()} // Show placeholder if field.value is null or empty

              >
                {(bank) => (
                  <SelectItem key={bank.id}>{bank.name}</SelectItem>
                )}
              </Select>

              {bankValue && bankValue !== 0 && (
                <IconButton
                  aria-label="Limpiar banco"
                  onClick={handleClearBank}
                  style={{ marginLeft: 8 }} // Espaciado entre el ícono y el select
                >
                  <IoCloseCircleOutline />
                </IconButton>
              )}
            </>
          )}
        />
      </div>

      <div className="my-4">
        <Controller
          control={control}
          name="model"
          render={({ field }) => (
            <>
              <Select
                {...field}
                items={model}
                variant="bordered"
                placeholder="Elija un Modelo"
                className="max-w-full"
                key={field.value}
                selectedKeys={field.value ? new Set([field.value]) : new Set()} // Show placeholder if field.value is null or empty
                isInvalid={!!errors.model}
                errorMessage={errors.model?.message}
              >
                {(model) => (
                  <SelectItem key={model.id}>{model.name}</SelectItem>
                )}
              </Select>

            </>
          )}
        />
      </div>
      <div className="my-4">
        <Controller
          control={control}
          name="price"
          render={({ field }) => (
            <>
              <Input
                {...field}
                isInvalid={!!errors.price}
                type="number"
                placeholder="Precio del dispositivo"
                variant="bordered"
                value={field.value ? String(field.value) : ""} // Convierte el valor a string
              />
              {errors.price && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors.price.message}
                </span>
              )}
            </>
          )}
        />
      </div>
      <div className="my-4">
        <Controller
          control={control}
          name="device_payment_methods"
          render={({ field }) => (
            <>
              <Select
                {...field}
                items={methodOptions}
                placeholder="Seleccione los métodos"
                selectionMode="multiple"
                selectedKeys={field.value ? new Set(field.value.split(",")) : new Set()} // Usamos split para convertir la cadena en un Set
                onSelectionChange={(selected) => {
                  // Convertir la selección a una cadena con comas
                  const selectedIds = Array.from(selected).map(String); // Aseguramos que sean strings
                  field.onChange(selectedIds.length > 0 ? selectedIds.join(",") : ""); // Guarda como cadena
                }}
                variant="bordered"
              >
                {methodOptions.map((method) => (
                  <SelectItem key={method.id}>{method.name}</SelectItem>
                ))}
              </Select>
              {errors.device_payment_methods && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors.device_payment_methods.message}
                </span>
              )}
            </>
          )}
        />





      </div>

    </form>
  );
});
