import { yupResolver } from '@hookform/resolvers/yup';
import { Input, Select, SelectItem } from '@nextui-org/react';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { AppDispatch, RootState } from '../../../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { ServicesThunks } from '../../../../../../store/services/thunks';

type Props = {
    data?: any | {};
    setData?: (data: any) => void;
    onSubmit: (data: any) => void;
    ref?: React.MutableRefObject<(() => void) | null>;
};

let schemaDevices = yup.object({
    name: yup
        .string()
        .min(3, 'El nombre debe tener al menos 3 caracteres')
        .max(20, 'El nombre no puede tener más de 20 caracteres')
        .required('Este campo es requerido'),
    description: yup
        .string()
        .min(5, 'La descripción debe tener al menos 5 caracteres')
        .max(50, 'La descripción no puede tener más de 50 caracteres')
        .required('Este campo es requerido'),
    price: yup.number().required("Este campo es requerido"),

});

export const FormDevices = React.forwardRef((props: Props, ref) => {

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: yupResolver(schemaDevices),

    });



    // Usamos useImperativeHandle para exponer el handleSubmit a través del ref.
    React.useImperativeHandle(ref, () => handleSubmit(props.onSubmit));


    const { services, count, isLoading } = useSelector(
        (state: RootState) => state.services
    );
    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        dispatch(ServicesThunks());
    }, []);

    // Efecto para setear los valores del formulario cuando `props.data` cambia
    React.useEffect(() => {
        if (props.data) {
            setValue("name", props.data.name || "");
            setValue("description", props.data.detail || "");
            setValue("price", props.data.cost || "");

        }
    }, [props.data, setValue]);



    return (
        <form className="space-y-4">
            <Controller
                control={control}
                name="name"
                render={({ field }) => (
                    <>
                        <Input
                            {...field}
                            isInvalid={!!errors.name}
                            type="text"
                            autoFocus
                            placeholder="Nombres"
                            variant="bordered"
                        />
                        {errors.name && (
                            <span style={{ color: 'red', fontSize: '12px' }}>
                                {errors.name.message}
                            </span>
                        )}
                    </>
                )}
            />
            <Controller
                control={control}
                name="description"
                render={({ field }) => (
                    <>
                        <Input
                            {...field}
                            isInvalid={!!errors.description}
                            type="text"
                            placeholder="Descripción"
                            variant="bordered"
                        />
                        {errors.description && (
                            <span style={{ color: 'red', fontSize: '12px' }}>
                                {errors.description.message}
                            </span>
                        )}
                    </>
                )}
            />
              <div className="my-4">
        <Controller
          control={control}
          name="price"
          render={({ field }) => (
            <>
              <Input
                {...field}
                isInvalid={!!errors.price}
                type="number"
                placeholder="Precio de Facturacion"
                variant="bordered"
                value={field.value ? String(field.value) : ""} // Convierte el valor a string
              />
              {errors.price && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errors.price.message}
                </span>
              )}
            </>
          )}
        />
      </div>
        </form>
    );
});
