import { useNavigate } from 'react-router';



type Props = {
    data?: any;
    reload: () => void
};

export const PaymentInvoice = ({data,reload}:Props) => {
    const navigate = useNavigate();


    const payInvoice = async () => {
        navigate(`./payment/${data.id}/`);
    }



    return (
        <>
            <span
                style={{
                    cursor: 'pointer',
                }}
                onClick={payInvoice} // Abrir el modal al presionar el texto
            >
                Pagar Factura
            </span>


        </>
    );
};

