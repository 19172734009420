// store/slices/WarehouseSlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Warehouse } from "../../../pages/core/interfaces/WarehouseInterfaces";
import { fetchWarehouse } from "./thunks";

export interface InitialStateDevices {
  isLoading: boolean;
  warehouse: Warehouse[];
  count: number;
  page?: number;
}

const initialStateDevices: InitialStateDevices = {
  isLoading: true,
  warehouse: [],
  count: 0,
};

export const WarehouseSlice = createSlice({
  name: "warehouse",
  initialState: initialStateDevices,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchWarehouse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchWarehouse.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.warehouse = payload.results || payload ;
        state.count = payload.count || payload.length;
      })
      .addCase(fetchWarehouse.rejected, (state) => {
        state.isLoading = false;
        state.warehouse = [];
        state.count = 0;
      });
  },
});

export const { } = WarehouseSlice.actions;

export default WarehouseSlice.reducer;
