import * as yup from "yup";
const phoneRegExp = /^[0-9]{10}$/;
export const schemaSNP = yup.object({
  account_number: yup
    .string()
    .required("El campo número de cuenta es requerido.")
    .matches(
      /^(?:\d{4}-?){0,5}\d{0,4}$/,
      "El número de cuenta debe tener un formato válido (xxxx-xxxx-xxxx-xxxx-xxxx-xxxx)."
    )
    .max(24, "El número de cuenta no puede tener más de 24 caracteres.")
    .min(
      24,
      "El número de cuenta debe tener un formato válido (xxxx-xxxx-xxxx-xxxx-xxxx-xxxx)."
    ),

  account_holder_name: yup
    .string()
    .required("Debes colocar el Titular de la cuenta"),
  phone: yup
    .string()
    .required("Debes colocar el numero de teléfono")
    .max(10, "El teléfono tiene un máximo de 10 caracteres")
    .matches(phoneRegExp, "Formato XXXXXXXXXXX"),
  code: yup.string().required("Debes selecionar el codigo"),
  bank: yup.string().required("Debes selecionar el banco"),
  payment_method: yup.array().required("Debes selecionar al menos una opción"),
  email: yup
    .string()
    .required("Este campo es requerido")
    .email("debe contener el formarto de correo"),
});
export const schemaMethodZelle = yup.object({
  bank: yup.string().required("Debes selecionar el banco"),
  account_holder_name: yup
    .string()
    .required("Debes colocar el Titular de la cuenta"),
    email: yup
    .string()
    .required("Este campo es requerido")
    .email("debe contener el formarto de correo"),
});
export const schemaAccountAffiliateMovil = yup.object({
  name: yup
    .string()
    .required("Debes colocar el Titular de la cuenta"),
  sender: yup
    .string()
    .required("Debes colocar el numero de teléfono")
    .max(10, "El teléfono tiene un máximo de 10 caracteres")
    .matches(phoneRegExp, "Formato XXXXXXXXXXX"),
  code: yup.string().required("Debes selecionar el codigo"),
});
export const schemaAccountAffiliateTransf = yup.object({
  name: yup
  .string()
  .required("Debes colocar el Titular de la cuenta"),
  sender: yup
    .string()
    .required("El campo número de cuenta es requerido.")
    .matches(
      /^(?:\d{4}-?){0,5}\d{0,4}$/,
      "El número de cuenta debe tener un formato válido (xxxx-xxxx-xxxx-xxxx-xxxx-xxxx)."
    )
    .max(24, "El número de cuenta no puede tener más de 24 caracteres.")
    .min(
      24,
      "El número de cuenta debe tener un formato válido (xxxx-xxxx-xxxx-xxxx-xxxx-xxxx)."
    ),
  code: yup.string().required("Debes selecionar el codigo"),
});
export const schemaAccountAffiliateZelle = yup.object({
  name: yup
    .string()
    .required("Debes colocar el Titular de la cuenta"),
  email: yup
    .string()
    .required("Este campo es requerido")
    .email("debe contener el formarto de correo"),
});
