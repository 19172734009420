// @flow 
import { Button } from '@nextui-org/react';
import { IoIosAdd } from 'react-icons/io';

import { useNavigate } from 'react-router';



type Props = {
  e?: any
  reload: () => void
};

export const AddInvoice = ({ e,reload }: Props) => {
  const navigate = useNavigate();


  const createInvoice =() =>{
    navigate('/finance/invoices/create/')
  }



  return (
    <>
      <Button
        size="md"
        color="primary"
        className="shadow-lg"
        endContent={<IoIosAdd className="h-7 w-7" />}
        onPress={createInvoice}
      >
       Crear  Factura

      </Button>

 
    </>
  );
};

