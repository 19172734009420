import {
  Checkbox,
  cn,
  User,
  Card,
} from "@nextui-org/react";
import { Control, Controller } from "react-hook-form";
import { useThemeMovilPay } from "../../../../../../hooks/useTheme";
import { Services } from "../../../../interfaces/ServicesInterfaces";

interface CheckboxListProps {
  items: Services[];
  control: Control<any>;
  name: string; // Nombre del campo en el formulario
  data?: Services;
  fnCheck?: (service: Services, checked: boolean) => void; // Actualiza la firma aquí
}
type ServicesProps = {
  data?: Services;
};
export const CustomCheckbox = ({
  items,
  control,
  name,
  data,
  fnCheck,
}: CheckboxListProps) => {
  const { darkMode } = useThemeMovilPay();
  const img = darkMode
    ? "isotipo movil play todo blanco"
    : "isotipo_movil play";
  return (
    <div className="grid xl:grid-cols-2 sm:grid gap-2 ">
      {items.map((item) => ( 
        <div key={item.id} style={{ marginBottom: "8px" }}>
          <Controller
            name={`${name}[${item.id}]`}
            control={control}
            defaultValue={false}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Card className="  space-y-2 w-full">
                <Checkbox
                  checked={value}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    onChange(checked);
                    fnCheck?.(item, checked); // Call fnCheck with service and checked status
                  }}
                  // isSelected={value}
                  onBlur={onBlur}
                  ref={ref}
                  classNames={{
                    base: cn(
                      "flex border-2  max-w-full  m-0",
                      " items-center justify-start",
                      "cursor-pointer rounded-lg gap-2 p-4 border-2 border-transparent",
                      "data-[selected=true]:border-primary"
                    ),
                    label: "w-full",
                  }}
                >
                  <User
                    avatarProps={{
                      size: "lg",
                      radius: "full",
                      className: "p-2 bg-content3",
                      src: require(`../../../../../../assets/img/${img}.png`),
                    }}
                    name={item.name}
                    description={
                      <span className="text-wrap py-1">{item.description}</span>
                    }
                  />
                </Checkbox>
              </Card>
            )}
          />
        </div>
      ))}
    </div>
  );
};
