import { createSlice } from "@reduxjs/toolkit";
import { Users } from "../../../pages/core/interfaces/UsersInterfaces";
import { user } from "@nextui-org/react";
import { usersAll } from "./thunks";

export interface initialStateUser {
  isLoading: boolean;
  users: Users[];
  count: number;
  page?: number;
}
export const initialStateUsers: initialStateUser = {
  isLoading: true,
  users: [],
  count: 0,
};
export const UserSlice = createSlice({
  name: "users",
  initialState: initialStateUsers,
  reducers: {
    setUser(state, { payload }) {
      state.users = payload;
      state.isLoading = false;
    },
    setLoading(state) {
      state.isLoading = false;
    },
    setError(state, action) {
      state.isLoading = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(usersAll.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(usersAll.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.users = payload.results;
        state.count = payload.count;
      });
  },
});
export const {} = UserSlice.actions;
