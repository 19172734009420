import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from '@nextui-org/react';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

export const schemaManufacturer = yup.object({
    name: yup
        .string()
        .min(3, 'El nombre debe tener al menos 3 caracteres')
        .max(20, 'El nombre no puede tener más de 20 caracteres')
        .required('Este campo es requerido'),
    description: yup
        .string()
        .min(5, 'La descripción debe tener al menos 5 caracteres')
        .max(50, 'La descripción no puede tener más de 50 caracteres')
        .required('Este campo es requerido'),
});
type Props = {
    data?: any | {};
    setData?: (data: any) => void;
    onSubmit: (data: any) => void; 
    ref?: React.MutableRefObject<(() => void) | null>;
};



export const FormManufacturer = React.forwardRef(({data, onSubmit}: Props, ref) => {
   
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: yupResolver(schemaManufacturer),
        
    });
    // Usamos useImperativeHandle para exponer el handleSubmit a través del ref.
    React.useImperativeHandle(ref, () => handleSubmit(onSubmit));
    // Efecto para setear los valores del formulario cuando `props.data` cambia
    React.useEffect(() => {
        if (data) {
            setValue("name", data.name || "");
            setValue("description",data.description || "");
        }
    }, [data, setValue]);
    return (
        <form className="flex flex-col my-4">
            <div className="my-4">
                <Controller
                    control={control}
                    name="name"
                    render={({ field }) => (
                        <>
                            <Input
                                {...field}
                                isInvalid={!!errors.name}
                                type="text"
                                autoFocus
                                placeholder="Nombre"
                                variant="bordered"
                            />
                            {errors.name && (
                                <span style={{ color: 'red', fontSize: '12px' }}>
                                    {errors.name.message}
                                </span>
                            )}
                        </>
                    )}
                />
            </div>
            <div className="my-4">
                <Controller
                    control={control}
                    name="description"
                    render={({ field }) => (
                        <>
                            <Input
                                {...field}
                                isInvalid={!!errors.description}
                                type="text"
                                placeholder="Descripción"
                                variant="bordered"
                            />
                            {errors.description && (
                                <span style={{ color: 'red', fontSize: '12px' }}>
                                    {errors.description.message}
                                </span>
                            )}
                        </>
                    )}
                />
            </div>
           
        </form>
    );
});
