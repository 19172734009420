import { createSlice } from "@reduxjs/toolkit";
import { SectorsThunks } from "./thunks";

export interface initialStateSector {
    isLoading: boolean;
    sector:any[];
    count:number 
    page?:number
  }
  
  export const initialStateSector: initialStateSector = {
    isLoading: false,
    count: 0,
    sector:[]
  };
  
  export const SectorSlice = createSlice({
    name: "Sector",
    initialState: initialStateSector,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(SectorsThunks.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(SectorsThunks.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          state.sector = payload.results  || payload
          state.count = payload.count
        });
    },
  });
  
  export const {} = SectorSlice.actions;