import React from 'react';
import { useStoreCreateInvoice } from '../../../../../../../../../store/finances/useStoreCreateInvoice';
import { FaTrash } from 'react-icons/fa';
import { MdFormatListBulletedAdd } from 'react-icons/md';
import { log } from 'console';
import { createInvoiceThunks } from '../../../../../../../../../store/finances/Thunks';
import { ErrorToast, SuccessToast } from '../../../../../../../../../libs/Notifications';
import { useNavigate } from 'react-router';

export const ItemsTotal = () => {
    const { formData, removeFormData, formDataCompany,resetForm,resetFormCompany } = useStoreCreateInvoice(); // Accede a formData y removeFormData desde el store
    const navigate = useNavigate();

    // Calcular subtotal, total de IVA y total general
    const subtotal = formData.reduce((acc, item) => acc + (item.price * item.cant), 0);
    const totalIVA = formData.reduce((acc, item) => acc + (item.exentoIVA ? 0 : item.iva), 0);
    const total = subtotal + totalIVA;

    const createInvoice = () => {


        const devices = formData.filter((d: any) => d.type === 1);

        // Mapea los dispositivos filtrados al formato deseado
        const invoice_items_devices = devices.map((device) => ({
            device: Number(device.id), // Asigna el id del dispositivo
            details: device.description || "EQUIPO", // Usa la descripción o asigna "EQUIPO" por defecto
            amount: device.price, // Asigna el precio como el monto
            count: Number(device.cant), // Asigna la cantidad
            tax: 1 // Asigna el valor de IVA
        }));

        const services = formData.filter((s) => s.type === 2);

        // Mapea los dispositivos filtrados al formato deseado
        const invoice_items_services = services.map((service) => ({
            service: Number(service.id), // Asigna el id del dispositivo
            details: service.description || "SERVICIO", // Usa la descripción o asigna "Servicio" por defecto
            amount: service.price, // Asigna el precio como el monto
            count: Number(service.cant), // Asigna la cantidad
            tax: service.iva > 0 ? 1 : null // Asigna el valor de IVA
        }));

        const invoice = {
            ...formDataCompany,
            invoice_items_devices: [...invoice_items_devices],
            invoice_items_services: [...invoice_items_services],
        }

        createInvoiceThunks(invoice).then(() => {
            resetForm();
            resetFormCompany();
          SuccessToast("Factura Creada con exito.");
          navigate(`/finance/invoices/`);
        }).catch(({ response }) => {
            if (response?.status === 400) {
              const errorMessage =
                Object.keys(response.data || {})
                  .join(", ")
                  .concat(": " + Object.values(response.data || {}).join(", ")) ||
                Object.values(response.data || {}).join(", ") ||
                "Error desconocido";
              return ErrorToast(errorMessage);
            }
          });

      
    }

    return (
        <div className="space-y-4 rounded-lg border border-gray-100 bg-gray-50 p-6 dark:border-gray-700 dark:bg-gray-800">
            <h3 className="text-lg font-bold">Detalles de la Factura</h3>
            {formData.length > 0 && (
                <div className="overflow-x-auto">                    
                <table className="min-w-full divide-y divide-gray-200" >
                        <thead className="bg-primary">
                            <tr>
                                <th className="px-4 py-2 text-left text-sm font-medium text-white">Item</th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-white">Descripción</th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-white">Cantidad</th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-white">Exento IVA</th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-white">IVA</th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-white">Total</th>
                                <th className="px-4 py-2 text-left text-sm font-medium text-white">Acciones</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {formData.map((item, index) => (
                                <tr key={index}>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">{item.name}</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">{item.description}</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">{item.cant}</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">{item.exentoIVA ? 'Sí' : 'No'}</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">${item.exentoIVA ? 0 : item.iva}</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">${(item.price * item.cant) + (item.exentoIVA ? 0 : item.iva)}</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-900">
                                        <button
                                            onClick={() => removeFormData(index)}
                                            className="text-red-600 hover:text-red-800"
                                            aria-label="Eliminar item"
                                        >
                                            <FaTrash color='danger' />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {/* Mostrar Subtotal, IVA total y Total */}
            <div className="space-y-2 mt-4">
                <dl className="flex items-center justify-between gap-4">
                    <dt className="text-base font-normal text-gray-500 dark:text-gray-400">Subtotal</dt>
                    <dd className="text-base font-medium text-gray-900 dark:text-white">${subtotal.toFixed(2)}</dd>
                </dl>
                <dl className="flex items-center justify-between gap-4">
                    <dt className="text-base font-normal text-gray-500 dark:text-gray-400">IVA</dt>
                    <dd className="text-base font-medium text-gray-900">${totalIVA.toFixed(2)}</dd>
                </dl>
                <dl className="flex items-center justify-between gap-4">
                    <dt className="text-base font-normal text-gray-500 dark:text-gray-400">Total</dt>
                    <dd className="text-base font-medium text-green-900 dark:text-white">${total.toFixed(2)}</dd>
                </dl>
            </div>
            {formData.length > 0 && (
        <div className="flex justify-end mt-4">
                    <button onClick={createInvoice} className=" h-12 bg-primary  text-white flex items-center p-2 rounded-xl hover:bg-secondary transition">
                        <MdFormatListBulletedAdd className="text-2xl text-white mr-2" />
                        Crear Factura
                    </button>
                </div>
            )}

        </div>
    );
}
