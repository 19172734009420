import React, { useState } from 'react'
import { useAllParams } from '../../../../../hooks/useAllParams';
import { AddRetention } from './components/AddRetention';
import { TableRetentions } from './components/TableRetentions';
import { usePermissions } from '../../../../../context/PermissionContext';
import { Button, Input } from '@nextui-org/react';
import { MdFilterAltOff } from 'react-icons/md';
import { Controller, useForm } from 'react-hook-form';
import { BiSearch } from 'react-icons/bi';

export const RetentionsCore = () => {
    const { params, deleteParams, setSearchParams } = useAllParams();
    const [key, setKey] = useState(0); // Estado clave para forzar el re-render
    const { permissions } = usePermissions();
    const actionPermissionAdd = permissions && permissions.includes("action_add_retention");
    const {
        control,
        handleSubmit,
        formState: { errors },
      } = useForm({
        defaultValues: {
          search: params.search || "",
        },
      });
    const handleConsultation = () => {
        // const param = new RatesParams();
        // if (params.page) param.page = Number(params.page);
        // if (params.search) param.search = params.search;
        // param.service = filterService;
        // queryClient.fetchQuery(
        //   queryOptions({
        //     queryKey: ["rates"],
        //     queryFn: () =>
        //       getRates(param).then((d) => {
        //         setRates(d.data.results, d.data.count);
        //       }),
        //     retry: false,
        //   })
        // );
      };

      const onSubmit = ({ search }: any) => {
        if (!search) {
          deleteParams(["search"]);
        } else {
          const { page, ...rest } = params;
          setSearchParams({ ...rest, search });
        }
      };
    function handleReset(e: any): void {
        throw new Error('Function not implemented.');
    }

  return (
    <div
    className="animate-fade-up  bg-white px-4 py-5 dark:bg-primaryDark rounded-lg sm:px-6 my-4"
    key={key}
  >
    <div className="space-y-7">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 items-center">
      <form onSubmit={handleSubmit(onSubmit)}   className=" flex items-center space-x-2">
              <Controller
                control={control}
                name="search"
                render={({ field }) => (
                  <div className="flex items-center">
                    <Input
                      {...field}
                      variant="bordered"
                      type="text"
                      size="lg"
                      placeholder="Introduce para buscar"
                      className="w-full lg:w-[500px] sm:w-full"
                    />
                    <Button
                      isIconOnly
                      color="primary"
                      aria-label="Like"
                      type="submit"
                      className="ml-2"
                    >
                      <BiSearch className="h-6 w-6 text-white" />
                    </Button>
                  </div>
                )}
              />
            </form>
      </div>
    </div>
    <div className="flex space-x-3 justify-end">
      {/* {actionPermissionAdd && (
        <AddRates e={undefined} reload={handleConsultation} />
      )} */}
              <AddRetention e={undefined} reload={handleConsultation} />

    </div>
    <div className="animate-fade-up">
      <TableRetentions reload={handleConsultation} />
    </div>
  </div>
  )
}
