/* eslint-disable react-hooks/rules-of-hooks */
import { useSelector } from "react-redux";
import { TableLayout, valueType } from "../../../../layout/TableLayout";
import { PaginatorPayments } from "../../../finances/payments/components/table/PaginatorPayments";
import { RootState } from "../../../../../../store/store";
import { ModalComponent } from "../../../../components/Modal/ModalComponent";
import { LuFileEdit } from "react-icons/lu";
import { Button, Chip, useDisclosure } from "@nextui-org/react";
import { useState } from "react";
import React from "react";
import { FormUser } from "./FormUser";
import { LoadingToast } from "../../../../../../libs/Notifications";
import { userPatch } from "../../../../../../store/settings/Users/thunks";
import { usePermissions } from "../../../../../../context/PermissionContext";
import { useStoreUsers } from "../../../../../../store/accounts/users/useStoreUsers";
import { ActionStatus, ActionUsers } from "./ActionUsers";
import { UsersAll } from "../../../../interfaces/UsersInterfaces";

type Props = {
  reload: () => void;
};

export const TableUsuarios = ({ reload }: Props) => {
  const { permissions } = usePermissions();
  const { users, count } = useStoreUsers((state) => state);

  const actionPermissionEdit =
    permissions && permissions.includes("action_edit_users");
  const actionPermissionDelete =
    permissions && permissions.includes("action_delete_users");
  const actionPermissionStatus =
    permissions && permissions.includes("action_change_users_status");

  const dataTable: valueType[] = [
    { name: "Nro", value: "id" },
    { name: "Identificación", value: "identification" },
    {
      name: "Nombres",
      value: (item: UsersAll) => {
        return (
          <span>
            {item.name}, {item.lastname}
          </span>
        );
      },
    },
    { name: "Correo electrónico", value: "email" },
    { name: "Teléfono", value: "phone" },
    { name: "Compañia", value: "company_name" },
    {
      name: "Roles",
      value: (item: UsersAll) => {
        return (
          <div className="flex flex-col gap-2">
            {item.groups.length > 0 && item.groups.map((group) => (
              <Chip  size="sm" variant="bordered">
                {group}
              </Chip>
            ))}
          </div>
        );
      },
    },
    {
      name: "Estado",
      value: (item: UsersAll) => {
        return (
          <Chip
            color={item.is_active ? "primary" : "danger"}
            size="sm"
            variant="solid"
          >
            {item.is_active ? "Activo" : "Inactivo"}
          </Chip>
        );
      },
    },
    {
      name: "Acción",
      value: (item: UsersAll) => (
        <>
          {actionPermissionEdit && <ActionUsers data={item} reload={reload} />}
          {actionPermissionStatus && (
            <ActionStatus data={item} reload={reload} />
          )}
        </>
      ),
    },
  ];
  return (
    <div className="space-y-7">
      <div>
        <TableLayout
          isLoading={false}
          data={users}
          columns={dataTable}
          Paginator={<PaginatorPayments count={count} />}
        />
      </div>
    </div>
  );
};
