// @flow
import * as React from "react";
import { schemaSector } from "./Validators";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Input, Select, SelectItem, Switch, Textarea } from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";
import { OccupationsThunks } from "../../../../store/options/Ocupation/thunks";
import { PaymentParams } from "../../params/payment/payments/paymentParams";
import { AppDispatch, RootState } from "../../../../store/store";
import { StatesThunks } from "../../../../store/location/States/thunks";
import { queryOptions, useQueryClient } from "@tanstack/react-query";
import { LocationParams } from "../../params/locations/LocationParams";
import { GetParishThunks } from "../../../../store/location/Parish/thunks";
import { ParishAll } from "../../interfaces/LocationsAll";
import { ErrorToast } from "../../../../libs/Notifications";
type Props = {
  onSubmit: (data: any) => void;
  ref?: React.MutableRefObject<(() => void) | null>;
  data?: any;
};
export const FormSector = React.forwardRef(({ data, onSubmit }: Props, ref) => {
  const dispatch = useDispatch<AppDispatch>();
  const { states } = useSelector((state: RootState) => state.states);
  const queryClient = useQueryClient();

  const {
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaSector),
  });
  React.useImperativeHandle(ref, () => handleSubmit(onSubmit));
const [ parish, setParish] = React.useState<ParishAll[]>([])
  const handleConsultation = () => {
    const param: PaymentParams = new PaymentParams();
    param.remove_pagination = true;
    dispatch(StatesThunks(param));
  };

  const handleParish = (value: any) => {
    if (value) {
      const param: LocationParams = new LocationParams();
      param.remove_pagination = true;
      param.state = value;
      queryClient.fetchQuery(
        queryOptions({
          queryKey: ["parishAll"],
          queryFn: () =>
            GetParishThunks(param)
              .then((d) => {
                setParish(d.data);
              })
              .catch(({ response }) => {
                if (response?.status === 400) {
                  const errorMessage =
                    Object.keys(response.data || {})
                      .join(", ")
                      .concat(
                        ": " + Object.values(response.data || {}).join(", ")
                      ) ||
                    Object.values(response.data || {}).join(", ") ||
                    "Error desconocido";
                  return ErrorToast(errorMessage);
                }
              }),
          retry: false,
        })
      );
    }
  };
  React.useEffect(() => {
    if (data) {
      handleParish(data?.estado)
      setValue("name", data?.name);
      setValue("state", data?.estado);
      setValue("parroquia", data?.parroquia);
     setValue("description", data?.description)
    }
  }, [data]);

  React.useEffect(() => {
    dispatch(handleConsultation);
  }, []);
  return (
    <div>
      <form className="grid gap-3">
        <Controller
          name="state"
          control={control}
          render={({ field }) => (
            <>
              <Select
                {...field}
                variant="bordered"
                items={states}
                placeholder="Estado"
                selectedKeys={field.value ? new Set([field.value]) : new Set()}
                isInvalid={!!errors.state}
                errorMessage={errors.state?.message}
                onChange={(e)=>{field.onChange(e); handleParish(e.target.value);}}
              >
                {(type) => (
                  <SelectItem key={type.id} value={type.id}>
                    {type.name}
                  </SelectItem>
                )}
              </Select>
            </>
          )}
        />
        <Controller
          name="parroquia"
          control={control}
          render={({ field }) => (
            <>
              <Select
                {...field}
                variant="bordered"
                items={parish}
                placeholder="Parroquia"
                selectedKeys={field.value ? new Set([field.value]) : new Set()}
                isInvalid={!!errors.parroquia}
                errorMessage={errors.parroquia?.message}
              >
                {(type) => (
                  <SelectItem key={type.id} value={type.id}>
                    {type.name}
                  </SelectItem>
                )}
              </Select>
            </>
          )}
        />
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <Input
              {...field}
              isInvalid={!!errors.name}
              errorMessage={errors.name?.message}
              type="text"
              autoFocus
              placeholder="Nombre del sector"
              variant="bordered"
            />
          )}
        />
        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <Textarea
              {...field}
              isInvalid={!!errors.description}
              errorMessage={errors.description?.message}
              type="text"
              autoFocus
              placeholder="Agregue una breve descripción"
              variant="bordered"
            />
          )}
        />
      </form>
    </div>
  );
});
