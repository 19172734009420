import { useDisclosure, Button } from "@nextui-org/react";
import { useRef } from "react";
import { FaRegTrashCan } from "react-icons/fa6";
import { LuFileEdit } from "react-icons/lu";

import { ErrorToast, SuccessToast } from "../../../../../../libs/Notifications";
import { ModalComponent } from "../../../../components/Modal/ModalComponent";
import { PopoverConfirm } from "../../../../components/Popover/PopoverConfirm";
import { FormModelDevice } from "./FormModelDevice";
import {
  DeleteModelThunks,
  PatchModelThunks,
} from "../../../../../../store/managements/model/thunks";

type Props = {
  data?: any;
  reload: () => void;
};

export const Action = ({ data, reload }: Props) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const formRef = useRef<() => void | null>(null);
  const handleFormSubmit = (formData: any) => {
    PatchModelThunks(formData, data.id)
      .then((result) => {
        SuccessToast("Modelo actualizado correctamente");
        onClose();
        reload();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          const { name, description } = response?.data;
          return ErrorToast(description || name);
        }
        ErrorToast("Hubo un error al actualizar el Modelo");
        return;
      });
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current();
    }
  };
  return (
    <>
      <Button
        isIconOnly
        variant="light"
        color="primary"
        size="sm"
        onPress={onOpen}
      >
        <LuFileEdit  className={`font-semibold h-6 w-6 `} />
      </Button>
      <ModalComponent
        header={`Editar datos del modelo ${data.name}`}
        body={
          <FormModelDevice
            data={data}
            onSubmit={handleFormSubmit}
            ref={formRef}
          />
        }
        handleSubmitClick={handleSubmitClick}
        onClose={onClose}
        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        title={""}
      />
    </>
  );
};

export const ActionDelete = ({ data, reload }: Props) => {
  const handleDelete = () => {
  
    DeleteModelThunks(data.id)
      .then((result) => {
        SuccessToast("Modelo eliminado correctamente");
        reload();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          // Verificar si hay un mensaje de error en el cuerpo de la respuesta
          const errorMessage =  Object.values(response.data || {}).join(', ') || "Error desconocido";
          return ErrorToast(errorMessage);
        }
  
        ErrorToast("Hubo un error al eliminar el Modelo");
      });
  };
  

  return (
    <PopoverConfirm
      title="Confirmar Eliminación"
      message="¿Estás seguro de que deseas eliminar este elemento?"
      confirm={handleDelete} // Esta función se ejecutará al confirmar
      item={undefined}
    >
      <Button isIconOnly variant="light" color="danger" size="sm">
        <FaRegTrashCan className="font-semibold h-6 w-6" />
      </Button>
    </PopoverConfirm>
  );
};
