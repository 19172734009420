import { createAsyncThunk } from "@reduxjs/toolkit";
import { movilPayAPI } from "../../../api/movilPayAPI";

export const SectorCompanyThunks = createAsyncThunk(
    "sectorEconomic",
    async (params?: any) => {
      return movilPayAPI
        .get("/companies/sector/", { params })
        .then((result) => {
          return result.data;
        })
        .catch((err) => {
          return [];
        });
    }
  );

  export const SectorCompanyIDThunks = createAsyncThunk(
    "sectorEconomicID",
    async (args: { id?: any; params?: any }) => {
      const { id, params } = args;
      try {
        const result = await movilPayAPI.get(`/companies/sector/${id}/`, {
          params,
        });
        return result.data;
      } catch (err) {
        console.error(err); // Manejo de errores
        return [];
      }
    }
  );
  
  export const PostSectorCompanyThunks = (body: any) => {
    return movilPayAPI.post(`/companies/sector/`, body);
  };
  export const PatchSectorCompanyThunks = (body: any, id: number) => {
    return movilPayAPI.patch(`/companies/sector/${id}/`, body);
  };
  
  export const DeleteSectorCompanyThunks = (id: any) => {
    return movilPayAPI.delete(`/companies/sector/${id}`);
  };
  