import { Divider, Image, Switch } from "@nextui-org/react";
import { data } from "../../../../../payments/components/ChartPiePayments";
import { useEffect, useState } from "react";
import { getInvoiceDetailsThunks } from "../../../../../../../../../store/finances/PaymentInvoiceThunsks";
import { DeatilsInvoice } from "../../../../../../../interfaces/DetailsInvoiceInterfaces";

type Props = {
    data?: any | {};
};

export const FormDetailInvoice = ({ data }: Props) => {
    const [invoice, setInvoice] = useState<DeatilsInvoice>();
    const [showBs, setShowBs] = useState(false); // Nuevo estado para el toggle

    useEffect(() => {
        const fetchInvoiceDetail = async () => {
            await getInvoiceDetail(Number(data.id));
        };
        fetchInvoiceDetail();
    }, []);

    const getInvoiceDetail = async (invoiceId: number) => {
        const res = await getInvoiceDetailsThunks(invoiceId);
        setInvoice(res.data);
    };

    return (
        <div className="px-2 py-2 bg-white rounded-2xl flex flex-col gap-8">
            <div className="flex flex-col md:flex-row justify-between items-center w-full">
                <div className="flex flex-col items-start gap-2 md:gap-4 px-6">
                    <div className="relative mb-4 w-20 h-14 md:w-28 md:h-20">
                        <Image
                            src={require("../../../../../../../../../assets/img/logotipo_movil play_23x14cm.png")}
                        />
                    </div>
                </div>
                <div className="flex flex-col md:flex-row justify-between items-center gap-4 md:gap-10">
                    <div className="flex items-center gap-2 md:gap-4">
                        <span className="text-gray-700 text-lg md:text-xl font-medium">Estado:</span>
                        <span className="text-gray-700 text-lg md:text-xl font-normal">{invoice?.status_name}</span>
                    </div>
                    <div className="flex items-center gap-2 md:gap-4">
                        <span className="text-gray-700 text-lg md:text-xl font-medium">Nro Control:</span>
                        <span className="text-gray-700 text-lg md:text-xl font-normal">{invoice?.nro_control_tfhk}</span>
                    </div>
                </div>
                <div className="flex flex-col items-start gap-2">
                    <span className="text-gray-700 text-lg md:text-xl font-normal">Detalle de factura</span>
                    <span className="text-green-500 text-2xl md:text-3xl font-medium">#{data.id}</span>
                </div>
            </div>

            <div className="flex flex-col items-start gap-3 w-full">
                <div className="w-full">
                    <span className="mb-4 text-gray-700 text-xl md:text-xl font-semibold">Datos del cliente</span>
                </div>
                <Divider />
                <div className="flex flex-col md:flex-row justify-between w-full text-lg md:text-lg text-gray-700">
                    <span>{invoice?.company_name}</span>
                    <span>{invoice?.company_identification}</span>
                    <span>{invoice?.company_email}</span>
                </div>
            </div>

            <div className="flex flex-col items-start gap-4 w-full">
                <div className="w-full">
                    <span className="text-gray-700 text-xl md:text-xl font-semibold">Información de Facturacion</span>
                </div>
                <Divider />

                <div className="flex flex-col md:flex-row justify-between items-start gap-4 w-full">
                    <div className="flex flex-col items-start">
                        <span className="text-gray-700 text-lg md:text-lg font-medium">Creada por:</span>
                        <span className="text-gray-700 text-lg md:text-lg font-normal">{invoice?.created_by_name}</span>
                    </div>
                    <div className="flex flex-col items-start">
                        <span className="text-gray-700 text-lg md:text-lg font-medium">Fecha de Creación:</span>
                        <span className="text-gray-700 text-lg md:text-lg font-normal">{invoice?.date_emission}</span>
                    </div>
                    <div className="flex flex-col items-start">
                        <span className="text-gray-700 text-lg md:text-lg font-medium">Fecha de Emisión:</span>
                        <span className="text-gray-700 text-lg md:text-lg font-normal">{invoice?.date_emission_tfhk}</span>
                    </div>
                    <div className="flex flex-col items-start">
                        <span className="text-gray-700 text-lg md:text-lg font-medium">Fecha de pago:</span>
                        <span className="text-gray-700 text-lg md:text-lg font-normal">{invoice?.date_payment}</span>
                    </div>
                </div>
            </div>

            <div className="flex-col items-start gap-3 w-full grid grid-cols-2">
                <div className="w-full">
                    <span className="text-gray-700 text-xl md:text-xl font-semibold">Servicios</span>
                    <Divider className="my-2"></Divider>
                    <ul className="space-y-4">
                        {invoice?.invoice_items_services.map((service) => (
                            <li key={service.id} className="flex items-start space-x-3">
                                <span className="text-[#51ae32] font-bold text-lg">•</span>
                                <span className="text-lg">{service.count} {service.details} ${service.amount}</span>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="w-full">
                    <span className="text-gray-700 text-xl md:text-xl font-semibold">Dispositivos</span>
                    <Divider className="my-2"></Divider>
                    <ul className="space-y-4">
                        {invoice?.invoice_items_devices.map((device) => (
                            <li key={device.id} className="flex items-start space-x-3">
                                <span className="text-[#51ae32] font-bold text-lg">•</span>
                                <span className="text-lg">{device.count} {device.details} ${device.amount}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="flex flex-col items-start gap-3 w-full">
                <div className="w-full">
                    <span className="text-gray-700 text-xl md:text-xl font-semibold">Información de Pago</span>
                </div>
                <Divider></Divider>

                <div className="flex flex-col md:flex-row justify-between items-start gap-4 w-full">
                    <div className="flex flex-col items-start gap-3">
                        <span className="text-gray-700 text-xl md:text-lg font-semibold">Método de pago:</span>
                        {invoice?.payments_invoices.map((payment) => (
                            <>
                                <div className="text-green-500 text-xl md:text-lg font-medium">
                                    <span className="text-[#51ae32] font-bold text-lg">•</span>
                                    {payment.payment.method_name}</div>
                                <div className="text-gray-500 text-xl md:text-lg font-medium">
                                    Monto:{showBs ? `Bs ${payment.payment.amount_bs}` : `$ ${payment.payment.amount}`}
                                </div>
                                <div className="text-gray-500 text-xl md:text-lg font-medium">
                                    pagado por: {payment.created_by_name}
                                </div>
                                <Divider></Divider>


                            </>
                        ))}
                    </div>
                    <div className="flex flex-col items-center gap-2">
                        <span className="text-gray-700 text-xl md:text-2xl font-semibold">Monto Total: </span>
                        <span className="text-green-700 text-3xl md:text-5xl font-medium">
                            {showBs ? ` Bs ${invoice?.amount_invoice_bs.total}` : ` $ ${invoice?.amount_invoice_usd.total}`}
                        </span>
                        <span className="text-gray-700 text-lg font-medium">Tasa: {invoice?.dollar_rate} Bs</span>
                    </div>
                    <div className="flex flex-col items-start gap-3">
                        <div className="flex justify-between w-full">
                            <span className="text-gray-700 text-lg font-semibold">Subtotal:</span>
                            <span className="text-gray-700 text-lg ml-2">
                                {showBs ? `Bs ${invoice?.amount_invoice_bs.subtotal}` : `$ ${invoice?.amount_invoice_usd.subtotal}`}
                            </span>
                        </div>
                        <div className="flex justify-between w-full">
                            <span className="text-gray-700 text-lg font-semibold">IVA:</span>
                            <span className="text-gray-700 text-lg">
                                {showBs ? `Bs ${invoice?.amount_invoice_bs.iva}` : `$ ${invoice?.amount_invoice_usd.iva}`}
                            </span>
                        </div>
                        <Divider />
                        <div className="flex justify-between w-full">
                            <span className="text-gray-700 text-lg font-semibold">Total:</span>
                            <span className="text-green-500 text-lg">
                                {showBs ? `Bs ${invoice?.amount_invoice_bs.total}` : `$ ${invoice?.amount_invoice_usd.total}`}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {invoice?.detail_canceled ? (
                  <div className="flex flex-col items-start gap-3 w-full">
                  <div className="w-full">
                      <span className="text-gray-700 text-xl md:text-xl font-semibold">Detalles de Anulacion</span>
                  </div>
                  <Divider></Divider>
                  <span>
                      {invoice?.detail_canceled}
                  </span>
              </div>
            ) :''}
          

            {/* Toggle para mostrar montos en bolívares o dólares */}
            <div className="flex justify-end gap-3 mt-4">
                <span className="text-gray-700 text-lg">Ver montos en Bs:</span>
                <Switch checked={showBs} onChange={() => setShowBs(!showBs)} />
            </div>
        </div>
    );
};
