import React, { useState } from 'react';
import { Button } from '@nextui-org/react'; // Asegúrate de tener este componente o utilizar tu propio componente de botón
import { FaDesktop, FaCog } from 'react-icons/fa'; // Puedes cambiar los íconos según lo que necesites
import FormItemDevices from './FormItemDevices';
import FormItemServices from './FormItemServices';


interface Props {
    idCompany: any;
  }

export const FormItems = ({idCompany}:Props) => {
    const [selectedOption, setSelectedOption] = useState('dispositivos'); // Estado para manejar la opción seleccionada

    const handleSelect = (option: string) => {
        setSelectedOption(option);
    };

    return (
        <div className="flex flex-col items-center space-y-4">
            <div className="flex space-x-4">
                <Button
                    onClick={() => handleSelect('dispositivos')}
                    color={selectedOption === 'dispositivos' ? 'primary' : 'default'} // Cambia el color basado en la selección
                    variant="solid"
                >
                    <FaDesktop className="mr-2" color='#fff' /> {/* Icono para Dispositivos */}
                    <span className='text-white'>Dispositivos</span>
                </Button>
                <Button
                    onClick={() => handleSelect('servicio')}
                    color={selectedOption === 'servicio' ? 'primary' : 'default'} // Cambia el color basado en la selección
                    variant="solid"
                >
                    <FaCog className="mr-2" color='#fff' /> {/* Icono para Servicio */}
                    <span className='text-white'>Servicios</span>
                </Button>
            </div>

            {/* Cargar componente correspondiente basado en la opción seleccionada */}
            {selectedOption === 'dispositivos' ? (
                <div className="border p-4 rounded-lg w-full text-center">
                    
                    <FormItemDevices idCompany={idCompany}/>

                </div>
            ) : (
                <div className="border p-4 rounded-lg w-full text-center">
                    {/* Componente o contenido para Servicio */}

                    <FormItemServices idCompany={idCompany}/>


                </div>
            )}
        </div>
    );
};
