import * as Yup from 'yup';

export const EditInvoiceSchema = Yup.object().shape({
  identification: Yup.string(),
  // Fecha de emisión
  date_emission: Yup.string(),
  // Fecha de vencimiento
  date_expirated: Yup.string()
    .required('La fecha de vencimiento es requerida.')
    .typeError('La fecha de vencimiento no es válida.'),
    // .min(Yup.ref('date_emission'), 'La fecha de vencimiento no puede ser anterior a la fecha de emisión.'),
  // Mes a facturar
  month: Yup.string()
    .required('El mes a facturar es requerido.')
    .min(1, 'El mes debe ser mayor o igual a 1.')
    .max(12, 'El mes no puede ser mayor a 12.')
    .typeError('El mes debe ser un número.'),
  // Contrato
  contract: Yup.string(),
  // Cliente
  client: Yup.string(),

});