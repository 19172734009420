import { FaWarehouse } from "react-icons/fa";
import { FcDepartment, FcMultipleDevices, FcMultipleSmartphones } from "react-icons/fc";
import { MdOutlinePrecisionManufacturing } from "react-icons/md";

export const DeviceManagementRouters =  [
    {
      name: "Dispositivos",
      path: "management/devices/",
      icon: FcMultipleDevices ,
      superAdmin: true,
      show: true,
      children: null,
    },
    {
      name: "Almacén",
      path: "management/warehouse/",
      icon: FaWarehouse,
      superAdmin: true,
      show: true,
      children: null,
    },
    {
      name: "Fabricantes",
      path: "management/manufacturers/",
      icon: MdOutlinePrecisionManufacturing ,
      superAdmin: true,
      show: true,
      children: null,
    },
    {
      name: "Modelos de Dispositivos",
      path: "management/models/",
      icon: FcMultipleSmartphones  ,
      superAdmin: true,
      show: true,
      children: null,
    },
  ]
