// @flow 
import * as React from 'react';
import { usePermissions } from '../../../../../../../context/PermissionContext';
import { TableLayout, valueType } from '../../../../../layout/TableLayout';
import { ParishAll } from '../../../../../interfaces/LocationsAll';
import { useStoreParishCore } from '../../../../../../../store/location/Parish/useStoreParish';
import { PaginatorPayments } from '../../../../finances/payments/components/table/PaginatorPayments';
import { EditParish } from '../ActionTable';
type Props = {
    reload: () => void;
  };
  ;
export const TableParish = ({reload }: Props) => {
    const { parish, count } = useStoreParishCore((state) => state);

    const { permissions } = usePermissions();
    const dataTable: valueType[] = [
        { name: "Nro", value: "id" },
        { name: "Parroquia", value: "name" },
        { name: "Descripción", value: "description" },
        { name: "Estado", value: "state_name" },
        {
          name: "Acción",
          value: (item: ParishAll) => (
            <>
              <EditParish reload={reload} data={item}/>
            </>
          ),
        },
      ]; 
    return (
        <div className="space-y-7">
        <div>
          <TableLayout
            isLoading={false}
            data={parish}
            columns={dataTable}
            Paginator={<PaginatorPayments count={count} />}
          />
        </div>
      </div>
    );
};