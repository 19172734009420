// @flow 
import { useDisclosure, Button } from '@nextui-org/react';
import * as React from 'react';
import { IoIosAdd } from 'react-icons/io';
import { ModalComponent } from '../../../../components/Modal/ModalComponent';
import { ErrorToast, LoadingToast, SuccessToast } from '../../../../../../libs/Notifications';
import { FormBankAccount } from './FormBankAccount';
import { PostBanksAccounts } from '../../../../../../store/banks-accounts/Thunks';



type Props = {
  e?: any
  reload: () => void
};

export const AddToBankAccount = ({ e,reload }: Props) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [data, setData] = React.useState(e);

  const formRef = React.useRef<() => void | null>(null);

  const handleFormSubmit = (formData: any) => {
    console.log("Submitted data", formData);
    const body ={
      "bank": formData.bank,
      "identification": formData.identification,
      "nro_cta": formData.accountNumber,
      "tlf": formData.phone
    }
    PostBanksAccounts(body)
    .then((result) => {
      SuccessToast("Cuenta Agregada Correctamente.");
      onClose();
      reload();
    })
    .catch(({ response }) => {
        if (response?.status === 400) {
          // Verificar si hay un mensaje de error en el cuerpo de la respuesta
          const errorMessage =  Object.values(response.data || {}).join(', ') || "Error desconocido";
          return ErrorToast(errorMessage);
        }
    
      return;
    });
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current()
    }
  };

  return (
    <>
      <Button
        size="md"
        color="primary"
        className="shadow-lg"
        endContent={<IoIosAdd className="h-7 w-7" />}
        onPress={onOpen}
      >
        Agregar Cuenta
      </Button>

      <ModalComponent
        header={"Agregar nueva cuenta Bancaria"}
        body={<FormBankAccount  setData={setData} onSubmit={handleFormSubmit} ref={formRef} />} 
        handleSubmitClick={handleSubmitClick}
        onClose={onClose}        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        title={""}
      />
    </>
  );
};

