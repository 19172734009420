// Importar las dependencias necesarias
import { TableLayout, valueType } from "../../../../layout/TableLayout";
import { PaginatorTable } from "../../../../components/table/PaginatorTable";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";
import { Action, ActionDelete } from "./ActionsModels";
import { usePermissions } from "../../../../../../context/PermissionContext";

type Props = {
  reload: () => void;
};

export const TableModelDevices = ({ reload }: Props) => {
  const { permissions, loading } = usePermissions();
  const actionPermissionEdit =permissions && permissions.includes('action_edit_device_model');
  const actionPermissionDelete =permissions && permissions.includes('action_delete_device_model');
  const dataTable: valueType[] = [
    { name: "ID", value: "id" },
    { name: "Nombre", value: "name" },
    { name: "Descripción", value: "detail" },
    // { name: "Estatus", value: "status" },
    {
      name: "Acción",
      value: (data: any) => (
        <>
         {actionPermissionEdit && <Action data={data} reload={reload} />}
         {actionPermissionDelete && <ActionDelete data={data} reload={reload} />}
        </>
      ),
    },
  ];

  const { model, count } = useSelector(
    (state: RootState) => state.model
  );
  return (
    <div className="space-y-7">
      <div>
        {/* {count > 0 && ( */}
          <TableLayout
            isLoading={false}
            data={model} // Usar datos de dispositivos
            columns={dataTable}
            Paginator={<PaginatorTable count={count} />}
          />
        {/* )} */}
      </div>
    </div>
  );
};
