// @flow 
import { CustomerUser } from '../../../pages/core/interfaces/CustomerUser';
import { createSlice } from '@reduxjs/toolkit';
import { CustomerUserService } from './thunk';

export interface initialStateCustomerGroup{
    customerGroups: CustomerUser[],
    isLoading: boolean,
    count: number,
    page?: number,
}

export const initialStateCustomerGroups: initialStateCustomerGroup = {
    isLoading: true,
    customerGroups: [],
    count: 0,
}
export const CustomerGroupslice = createSlice({
    name: "customer-groups",
    initialState: initialStateCustomerGroups,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(CustomerUserService.pending, (state) => {
            state.isLoading = true;
        }).addCase(CustomerUserService.fulfilled, (state, {payload})=>{
            state.isLoading = false;
            state.customerGroups = payload.results;
            state.count = payload.count;
        })
    },
})
export const {} = CustomerGroupslice.actions