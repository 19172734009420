import React, { useEffect } from 'react';
import { TotalInvoice } from './components/TotalInvoice';
import useStorePaymentInvoice from '../../../../../../../store/finances/useStorePaymentInvoice';
import { getInvoiceDetailsThunks, getPendingInvoicesThunks } from '../../../../../../../store/finances/PaymentInvoiceThunsks';
import { queryOptions, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { getKeyValue, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react';
import PaymentMethods from './methods/PaymentMethods';
import { TableMethods } from './components/TableMethods';
import { TablePendingInvoices } from './components/TablePendingInvoices';
import { TablePaymentInvoice } from './components/TablePaymentInvoice';
import { InvoicesParams } from '../../../../../params/finances/InvoicesParams';



export const PaymentInvoiceCore = () => {
    const { invoices, addInvoice,addInvoicePending, setBalance,setBalanceBs,clearInvoices } = useStorePaymentInvoice();
    const { idInvoice } = useParams();
    const params = new InvoicesParams();
 

    useEffect(() => {
        const fetchInvoiceDetail = async () => {
          if (idInvoice) {
            await getInvoiceDetail(Number(idInvoice));
            await pendingInvoices(Number(idInvoice));
          }
        };
        fetchInvoiceDetail(); // Call the function asynchronously
      }, [idInvoice]); // Add `id` as a dependency

    const getInvoiceDetail =  async (invoiceId:number) => {
          clearInvoices()
        const res = await getInvoiceDetailsThunks(invoiceId)
        const inv = res.data;
        const totals = res.data.amount_invoice_usd;
        const balances = res.data.balance_company
        const invoice = {
            id: inv.id,
            amount: totals.total - inv.charged,
            iva: totals.iva,
            subtotal: totals.subtotal,
            charged: inv.charged,
            rate: inv.dollar_rate
        };
        params.invoice_exclude = idInvoice;
        params.company = inv.company
        params.status = 3
        params.annulled= false
        setBalance(balances.balance_usd);
        setBalanceBs(balances.balance_bs);
        addInvoice(invoice);



    };

    const pendingInvoices = async (invoiceId:number) => {
       const {data} = await getPendingInvoicesThunks(params); 
       data.results.map((resp:any)=>{
            const totals = resp.amount_invoice_usd;
            const invoice = {
                id: resp.id,
                amount: totals.total - resp.charged,
                iva: totals.iva,
                subtotal: totals.subtotal,
                charged: resp.charged,
                rate: resp.dollar_rate
            };
    
            addInvoicePending(invoice);
       })
    }

    return (
        <div className="bg-white p-10 rounded-lg grid grid-cols-1 gap-4 lg:grid-cols-2">
            <div>
                <div className='grid grid-cols-1 gap-4'>
                    <TablePendingInvoices />
                    <TablePaymentInvoice />
                </div>
                <div>
                    <PaymentMethods />
                </div>
            </div>
            
            <div>
                <TableMethods />
                <TotalInvoice />
            
            </div>
        </div>
    );
};
