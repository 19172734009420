import { useDisclosure, Button } from "@nextui-org/react";
import React, { useState } from "react";
import { ErrorToast, LoadingToast } from "../../../../../../libs/Notifications";
import { userPatch } from "../../../../../../store/settings/Users/thunks";
import { ModalComponent } from "../../../../components/Modal/ModalComponent";
import { FormUser } from "./FormUser";
import { UsersAll } from "../../../../interfaces/UsersInterfaces";
import { PatchUsersThunks } from "../../../../../../store/accounts/users/thunks";
import { PopoverConfirm } from "../../../../components/Popover/PopoverConfirm";
import { FcCancel, FcOk } from "react-icons/fc";
import { LuFileEdit } from "react-icons/lu";

type Props = {
  data?: UsersAll;
  reload: () => void;
};

// };
export const ActionUsers = ({ data, reload }: Props) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const formRef = React.useRef<() => void | null>(null);
  const handleFormSubmit = (formData: any) => {
    const { success, error } = LoadingToast();
    const body = {
      ...formData,
      phone: formData.code.concat(formData.phone),
      identification: formData.type.concat(formData.identification),
      groups: [Number(formData.groups)]

    };
    PatchUsersThunks(Number(data?.id), body)
      .then((results) => {
        onClose();
        success("Datos actualizados correctamente");
        reload();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          const errorMessage =
            Object.keys(response.data || {})
              .join(", ")
              .concat(": " + Object.values(response.data || {}).join(", ")) ||
            Object.values(response.data || {}).join(", ") ||
            "Error desconocido";
          return ErrorToast(errorMessage);
        }
      });
  };
  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current();
    }
  };
  return (
    <>
      <Button
        isIconOnly
        variant="light"
        color="primary"
        size="sm"
        onPress={onOpen}
      >
        <LuFileEdit  className={`font-semibold h-6 w-6 `} />
      </Button>
      <ModalComponent
        header={"Editar datos del usuario"}
        body={
          <FormUser data={data} onSubmit={handleFormSubmit} ref={formRef} />
        }
        handleSubmitClick={handleSubmitClick}
        onClose={onClose}
        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        title={""}
      />
    </>
  );
};

export const ActionStatus = ({ data, reload }: Props) => {
  const { onClose } = useDisclosure();
  const handleDelete = (formData: any) => {
    const { success, error } = LoadingToast();
    const body = {
      is_active: !data?.is_active,
    };
    PatchUsersThunks(Number(data?.id), body)
      .then((results) => {
        onClose();
        success("Estado actualizado correctamente");
        reload();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          const errorMessage =
            Object.keys(response.data || {})
              .join(", ")
              .concat(": " + Object.values(response.data || {}).join(", ")) ||
            Object.values(response.data || {}).join(", ") ||
            "Error desconocido";
          return ErrorToast(errorMessage);
        }
      });
  };
  return (
    <>
      <PopoverConfirm
        title="Confirmar cambio de Estado"
        message={`¿Estás seguro que deseas ${
          data?.is_active ? "inactivar" : "activar"
        } este usuario?`}
        confirm={handleDelete} // Esta función se ejecutará al confirmar
        item={undefined}
      >
        <Button
          size="sm"
          className="shadow-lg"
          radius="full"
          variant="light"
          isIconOnly
          color={data?.is_active ? "danger" : "success"}
        >
          {data?.is_active ? (
            <FcCancel className=" h-6 w-6" />
          ) : (
            <FcOk className=" h-6 w-6" />
          )}
        </Button>
      </PopoverConfirm>
    </>
  );
};
