import { Button, useDisclosure } from "@nextui-org/react";
import { LoadingToast } from "../../../../../../libs/Notifications";
import { CreateCustomerUserService } from "../../../../../../store/settings/CustomerGroups/thunk";
import { ModalComponent } from "../../../../components/Modal/ModalComponent";
import { IoIosAdd } from "react-icons/io";
import { useRef, useState } from "react";
import { FormPermissions } from "./FormPermissions";

export const AddPermission = (e: any) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [data, setData] = useState(e);
  const formRef = useRef<() => void | null>(null);
  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current();
    }
  };
  const handleFormSubmit = (data: any) => {
    const { success, error } = LoadingToast();
    const body = {
      ...data,
      permissions: data.permissions.split(","),
    };
    console.log(body);
    CreateCustomerUserService(body)
      .then((result) => {
        success("Registro éxitoso");
        onClose();
      })
      .catch(({ response }) => {
        if (response.status === 400) {
          const { email, identification, name, lastname } = response.data;
          return error(identification || email || name || lastname);
        }
        error("Hubo un error al registrar el Grupo");
        return;
      });
  };

  return (
    <>
      <Button
        size="md"
        color="primary"
        className="shadow-lg"
        endContent={<IoIosAdd className="h-7 w-7" />}
        onPress={onOpen}
      >
        Crear Grupo
      </Button>

      <ModalComponent
        header={"Crear grupo de permisos"}
        body={
          <FormPermissions
            data={data}
            onSubmit={handleFormSubmit}
            ref={formRef}
          />
        }
        handleSubmitClick={handleSubmitClick}
        onClose={onClose}
        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        title={""}
      />
    </>
  );
};
