import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { IoIosRefresh } from "react-icons/io";
import { Button, Input } from "@nextui-org/react";
import { FcSearch } from "react-icons/fc";
import { AddDevices } from "./components/AddDevices";
import { TableDevices } from "./components/table/TableDevices";
import { queryOptions, useQueryClient } from "@tanstack/react-query";
import { useStoreDeviceCore } from "../../../../../store/managements/devices/useStoreDeviceCore";
import { DevicesParams } from "../../../params/management/devicesParams";
import { getDevicesRequest } from "../../../../../store/managements/devices/thunks";
import { AppDispatch } from "../../../../../store/store";
import { useAllParams } from "../../../../../hooks/useAllParams";
import { usePermissions } from "../../../../../context/PermissionContext";
import { BiSearch } from "react-icons/bi";

export const DevicesCore = () => {
  const dispatch = useDispatch<AppDispatch>();
  const queryClient = useQueryClient();
  const { params, deleteParams, setSearchParams } = useAllParams();
  const { setDevices } = useStoreDeviceCore((state) => state);
  const { permissions, loading } = usePermissions();
  const actionPermission =
    permissions && permissions.includes("action_create_device");
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      search: params.search || "",
    },
  });

  const handleConsultation = () => {
    const param = new DevicesParams();
    if (params.page) param.page = Number(params.page);
    if (params.search) param.search = params.search;

    queryClient.fetchQuery(
      queryOptions({
        queryKey: ["devices"],
        queryFn: () =>
          getDevicesRequest(param).then((d) => {
            setDevices(d.data.results, d.data.count);
          }),
        retry: false,
      })
    );
  };

  useEffect(() => {
    handleConsultation();
  }, [params]);

  const onSubmit = ({ search }: any) => {
    if (!search) {
      deleteParams(["search"]);
    } else {
      const { page, ...rest } = params;
      setSearchParams({ ...rest, search });
    }
  };

  return (
    <div className="animate-fade-up">
      <div className="space-y-7">
        <div className="lg:flex lg:justify-between lg:items-center ">
          <div className="mb-7 lg:mb-0">
            <form onSubmit={handleSubmit(onSubmit)}   className=" flex items-center space-x-2">
              <Controller
                control={control}
                name="search"
                render={({ field }) => (
                  <div className="flex items-center">
                    <Input
                      {...field}
                      variant="bordered"
                      type="text"
                      size="lg"
                      placeholder="Introduce para buscar"
                      className="w-full lg:w-[500px] sm:w-full"
                    />
                    <Button
                      isIconOnly
                      color="primary"
                      aria-label="Like"
                      type="submit"
                      className="ml-2"
                    >
                      <BiSearch className="h-6 w-6 text-white" />
                    </Button>
                  </div>
                )}
              />
            </form>
          </div>
          <div className="flex space-x-3 justify-between">
            {actionPermission && (
              <AddDevices e={undefined} reload={handleConsultation} />
            )}
          </div>
        </div>
        <div className="animate-fade-up">
          <TableDevices reload={handleConsultation} />
        </div>
      </div>
    </div>
  );
};
