// @flow
import * as React from "react";
import { PaymentMethodsCompany } from "../../../../../interfaces/CompaniesInterfaces";
import { schemaMethodZelle, schemaSNP } from "./Validators/shemaSNP";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient } from "@tanstack/react-query";
import { Autocomplete, AutocompleteItem, Input } from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../../../store/store";
type Props = {
  data?: PaymentMethodsCompany | any;
  setData?: (data: any) => void;
  onSubmit: (data: any) => void;
  ref?: React.MutableRefObject<(() => void) | null>;
};
export const FormMethodUSD = React.forwardRef(
  ({ onSubmit, data }: Props, ref) => {
    const {
      control,
      handleSubmit,
      formState: { errors, isValid },
      setValue,
    } = useForm({
      resolver: yupResolver(schemaMethodZelle),
    });
    React.useImperativeHandle(ref, () => handleSubmit(onSubmit));
    React.useEffect(() => {
      setValue("bank", data?.bank.toString());
      setValue("account_holder_name", data?.account_holder_name);
      setValue("email", data?.email);
    }, [data]);
    return (
      <div className="w-full flex flex-col my-4 space-y-2 ">
        {data && (
            <>
              <div className="flex justify-center border-2 border-primary  py-2 rounded-lg   items-center">
                {data?.payment_method_name}
              </div>{" "}
            </>
          ) }
        <Controller
          name="bank"
          control={control}
          render={({ field }) => (
            <>
              <div className="flex items-center">
                <Autocomplete
                  {...field} // Pasa las propiedades del Controller
                  className="max-w"
                  variant="bordered"
                  defaultItems={banks}
                  placeholder="Seleccione el banco a afiliar"
                  isInvalid={!!errors.bank}
                  errorMessage={errors.bank?.message}
                  selectedKey={field.value}
                  onSelectionChange={(e) => field.onChange(e)}
                >
                  {(item) => (
                    <AutocompleteItem key={item.id}>
                      {item.name}
                    </AutocompleteItem>
                  )}
                </Autocomplete>
              </div>
            </>
          )}
        />
        <Controller
          name="account_holder_name"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <Input
                type="text"
                maxLength={30}
                onChange={onChange}
                variant="bordered"
                isInvalid={!!errors.account_holder_name}
                errorMessage={errors.account_holder_name?.message}
                onBlur={onBlur}
                value={value}
                placeholder="Colocar el nombre del Titular "
              />
              <span></span>
            </>
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <Input
                maxLength={30}
                onChange={onChange}
                variant="bordered"
                isInvalid={!!errors.email}
                errorMessage={errors.email?.message}
                onBlur={onBlur}
                value={value}
                placeholder="Colocar el correo"
              />
              <span></span>
            </>
          )}
        />
      </div>
    );
  }
);
let banks = [
  {
    id: 6,
    name: "ZELLE",
  },
  {
    id: 7,
    name: "CHASE",
  },
];
