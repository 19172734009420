import { create } from "zustand";
import { AccountAfiliate } from "../../../pages/core/interfaces/CompaniesInterfaces";

interface AccountAfiliateState {
    accountAfiliate: AccountAfiliate[];
    count: number;
    setAccountAfiliate: (accountAfiliate: AccountAfiliate[], count: number) => void;
    reset: () => void;
  }
  export const useStoreAccountAfiliate = create<AccountAfiliateState>((set) => ({
    accountAfiliate: [],
    count: 0,
    setAccountAfiliate: (accountAfiliate: AccountAfiliate[], count: number) => {
      set({ accountAfiliate, count });
    },
    reset: () => {
      set({ accountAfiliate: [], count: 0 });
    },
  }));