import { createAsyncThunk } from "@reduxjs/toolkit";
import { CompaniesParams } from "../../../pages/core/params/companies/companiesParams";
import { movilPayAPI } from "../../../api/movilPayAPI";
import { ServicesConfigParams } from "../../../pages/core/params/services-configs/ServicesConfigParams";

export const PaymentMethodCompaniesThunks = createAsyncThunk(
  "payment_methods_companies",
  async (args: { id: any; params?: ServicesConfigParams }) => {
    const { id, params } = args;
    try {
      const result = await movilPayAPI.get(
        `/companies/company_service/${id}/payment_methods/`,
        { params }
      );
      return result.data;
    } catch (err) {
      console.error(err); // Manejo de errores
      return [];
    }
  }
);

export const PostPaymentMethodCompaniesIDThunks = (body: any, id: any) => {
  return movilPayAPI.post(
    `/companies/company_service/${id}/payment_methods/`,
    body
  );
};
export const GetPaymentMethodCompaniesThunks = (
  params?: ServicesConfigParams,
  id?: number
) => {
  return movilPayAPI.get(`/companies/company_service/${id}/payment_methods/`, {params});
};
export const GetIdPaymentMethodCompaniesThunks = (id: number) => {
  return movilPayAPI.get(`/companies/company_service/payment_methods/${id}/`);
};
export const PatchPaymentMethodCompaniesThunks = (body: any, id: any) => {
  return movilPayAPI.patch(
    `/companies/company_service/payment_methods/${id}/`,
    body
  );
};

export const DeletePaymentMethodCompaniesThunks = (id: any) => {
  return movilPayAPI.delete(
    `/companies/company_service/payment_methods/${id}/`
  );
};
