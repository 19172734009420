import { createSlice } from "@reduxjs/toolkit";
import { PaymentMethodCompaniesThunks } from "./thunks";
import { ServiceConfigurationCompany } from "../../../pages/core/interfaces/ServicesCompany";
import { MethodPaymentCompany } from "../../../pages/core/interfaces/PaymentInterfaces";

export interface initialStateService {
  isLoading: boolean;
  paymentmethodCompany:MethodPaymentCompany[];
  page?:number;
  count: number; // total number of items in the list, obtained from the API response
}

export const initialStateServices: initialStateService = {
  isLoading: false,
  count: 0,  // total number of items in the list, obtained from the API response
  paymentmethodCompany:[] 
};

export const MethodPaymentsCompanySlice = createSlice({
  name: "paymentmethodCompany",
  initialState: initialStateServices,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(PaymentMethodCompaniesThunks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(PaymentMethodCompaniesThunks.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.paymentmethodCompany = payload;
        state.count = payload.length;
      });
  },
});

export const {} = MethodPaymentsCompanySlice.actions;
