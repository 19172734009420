import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";

import { FaCircle } from "react-icons/fa";
import { CardInfoPayments } from "../components/CardInfoPayments";
import { CardInforCompany } from "./CardInforCompany";
import { FormEditCompany } from "./FormEditCompany";
import { Button } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { LuFileEdit } from "react-icons/lu";

import { MdEditOff } from "react-icons/md";
import { EditCompany } from "./EditCompany";
import { usePermissions } from "../../../../../../context/PermissionContext";

export const InfoCompany = () => {
  const { companies } = useSelector((d: RootState) => d.companyID);
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = () => {
    setIsOpen(!isOpen);
  };
  const { permissions, loading } = usePermissions();
  const actionPermissionEdit =permissions && permissions.includes('action_edit_company_information');
  // const canDelete = permissions.includes('delete');

  useEffect(() => {
    // handleChange();
  }, [companies]);
  return (
    <>
      <section aria-labelledby="applicant-information-title">
        <div className="bg-white overflow-hidden shadow rounded-lg border  my-4 dark:bg-primaryDark dark:text-textDark">
          <div className="relative px-4 py-5 sm:px-6">
           {actionPermissionEdit && <Button
              size="sm"
              color="primary"
              className="absolute top-2 right-4 shadow-lg"
              radius="full"
              isIconOnly
              onPress={handleChange}
            >
              {isOpen ? (
                <MdEditOff className=" p-1 h-7 w-7" />
              ) : (
                <LuFileEdit  className=" p-1 h-7 w-7" />
              )}
            </Button>}
          </div>
          {isOpen ? (
            <EditCompany company={companies} onClose={handleChange} />
          ) : (
            <CardInforCompany />
          )}
        </div>
      </section>
    </>
  );
};
