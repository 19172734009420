// @flow
import * as React from "react";
import { TableLayout, valueType } from "../../../../../../layout/TableLayout";
import { count } from "console";
import { PaginatorPayments } from "../../../../../finances/payments/components/table/PaginatorPayments";
import { useStoreDevicesServiceCompany } from "../../../../../../../../store/companies/DevicesCompany/useStoreDevicesServiceCompany";
import { Chip } from "@nextui-org/react";
import moment from "moment";
import { DevicesServiceConfig } from "../../../../../../interfaces/DevicesServicesConfig";
import {
  MerchantDetail,
  FinishDetail,
  EditDataDevice,
  UnlinkDevice,
} from "../OptionsTable";
import { usePermissions } from "../../../../../../../../context/PermissionContext";
type Props = {
  reload: () => void;
};
export const TableSCDevice = ({ reload }: Props) => {
  const { devicesServiceCompany, count } = useStoreDevicesServiceCompany(
    (state) => state
  );
  const { permissions, loading } = usePermissions();
  const actionPermissionEdit =
    permissions && permissions.includes("action_edit_device_to_company");
  const actionPermissionFinish =
    permissions && permissions.includes("action_end_the_company_device");

  const dataTable: valueType[] = [
    // { name: "Dispositivo", value: "device" },
    {
      name: "Serial",
      value: "device_serial",
    },
    {
      name: "MAC",
      value: "device_mac",
    },
    {
      name: "Merchant",
      value: (item: any) => {
        return (
          <>
            <MerchantDetail services={item} />
          </>
        );
      },
    },
    { name: "Dirección", value: "address" },
    { name: "Creado por ", value: "created_by__name" },
    {
      name: "Creado",
      value: (item: any) => {
        return moment(item.created_at).format("DD-MM-YYYY, hh:mm a");
      },
    },
    {
      name: "Finalizado",
      value: (item: DevicesServiceConfig) => {
        return (
          <>
            {item.date_end != null ? (
              <FinishDetail services={item} />
            ) : (
              "Sin Finalizar"
            )}
          </>
        );
      },
    },

    {
      name: "Estado",
      value: (item: any) => {
        return (
          <>
            <Chip color={item.status ? "primary" : "danger"}>
              {item.status ? "Activo" : "Inactivo"}
            </Chip>
          </>
        );
      },
    },

    {
      name: "Acción",
      value: (e: any) => {
        return (
          <div className="inline-flex gap-x-2 items-center">
            {e.date_end == null && actionPermissionEdit && (
              <EditDataDevice services={e} reload={reload} />
            )}
            {e.date_end == null && actionPermissionFinish && (
              <UnlinkDevice services={e} reload={reload} />
            )}
          </div>
        );
      },
    },
  ];
  return (
    <>
      <TableLayout
        columns={dataTable}
        data={devicesServiceCompany}
        isLoading={false}
        Paginator={<PaginatorPayments count={count} />}
      />
    </>
  );
};
