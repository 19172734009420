// @flow
import { useDisclosure, Button } from "@nextui-org/react";
import * as React from "react";
import { IoIosAdd } from "react-icons/io";
import { ModalComponent } from "../../../../components/Modal/ModalComponent";
import { ErrorToast, SuccessToast } from "../../../../../../libs/Notifications";
import { FormModelDevice } from "./FormModelDevice";
import { PostModelsThunks } from "../../../../../../store/managements/model/thunks";

type Props = {
  e?: any;
  reload: () => void;
};

export const AddModelDevice = ({ e, reload }: Props) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [data, setData] = React.useState(e);

  const formRef = React.useRef<() => void | null>(null);
  const handleFormSubmit = (formData: any) => {
    PostModelsThunks(formData)
      .then((result) => {
        SuccessToast("Modelo creado correctamente");

        onClose();
        reload();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          const { name, detail } = response?.data;
          return ErrorToast(detail || name);
        }
        ErrorToast("Hubo un error al registrar el Modelo");
        return;
      });
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current();
    }
  };

  return (
    <>
      <Button
        size="md"
        color="primary"
        className="shadow-lg"
        endContent={<IoIosAdd className="h-7 w-7" />}
        onPress={onOpen}
      >
        Agregar Modelo
      </Button>

      <ModalComponent
        header={"Registrar datos del Modelo"}
        body={
          <FormModelDevice
            setData={setData}
            onSubmit={handleFormSubmit}
            ref={formRef}
          />
        }
        handleSubmitClick={handleSubmitClick}
        onClose={onClose}
        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        title={""}
      />
    </>
  );
};
