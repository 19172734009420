import React from 'react'
import { FcConferenceCall, FcDonate, FcFinePrint } from 'react-icons/fc'

export const supportsRouters = [
    {
      name: "Ticket",
      path: "supports/tickets/",
      icon: FcFinePrint,
      show: true,
      children: null,
    },
    
  ]