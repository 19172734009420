export class WarehouseParams{
    
    page?:number
    since?:string
    search?:string
    remove_pagination?:string
    device_type__service?:number |string
    device_type?:number |string
    status?:boolean|string
    used?:string |string
    exclude?:string
    service?:string
    company?:number |string

}