import { movilPayAPI } from "../../api/movilPayAPI";
import { DollarParams } from "../../pages/core/params/dollar/DollarParams";
import { InvoicesParams } from "../../pages/core/params/finances/InvoicesParams";
import { PaymentMethodsParams } from "../../pages/core/params/finances/PaymentMethodsParams";

export const getPaymentMethodsThunks = (params?: PaymentMethodsParams) => {
  return movilPayAPI.get(`/payments/payment_methods/`, { params });
};

export const getInvoiceDetailsThunks = (id?: number) => {
  return movilPayAPI.get(`/invoices/${id}/`);
};

export const paymentValidateThunks = (validate: any) => {
  return movilPayAPI.post(`/payments/validate/`, validate);
};

export const getDollar = (params?: DollarParams) => {
      return  movilPayAPI.get("/base/dollar/", { params });
}

export const getPendingInvoicesThunks = (params?:InvoicesParams) => {
  return movilPayAPI.get(`/invoices/`, {params});
};

export const paymentInvoicesThunks = (payment: any) => {
  return movilPayAPI.post(`/payments/register/`, payment);
};
  
