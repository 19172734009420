// @flow
import * as React from "react";
import { useParams } from "react-router-dom";
import { ServicesConfigParams } from "../../../../../params/services-configs/ServicesConfigParams";
import { IoBanOutline } from "react-icons/io5";
import { GetPaymentMethodCompaniesThunks } from "../../../../../../../store/companies/PaymentMethodCompany/thunks";
import { TableServiceCompanysnp } from "./table/TableSCsnp";
import { useStoreSCPaymentMethodCore } from "../../../../../../../store/companies/PaymentMethodCompany/useStoreSCPaymentMethod";
import { useAllParams } from "../../../../../../../hooks/useAllParams";
import { Controller, useForm } from "react-hook-form";
import { queryOptions, useQueryClient } from "@tanstack/react-query";
import { Button, Input } from "@nextui-org/react";
import { FcSearch } from "react-icons/fc";
import { AddSCPaymentCompany } from "./AddSCPaymentCompany";
import { usePermissions } from "../../../../../../../context/PermissionContext";
import { BiSearch } from "react-icons/bi";
import { ErrorToast } from "../../../../../../../libs/Notifications";
type Props = {
  e: any;
  reload?: () => void;
};
export const ConfigServiceTangible = () => {
  const queryClient = useQueryClient();
  const { item } = useParams();

  const { count, setscpaymentMethod } = useStoreSCPaymentMethodCore(
    (state) => state
  );
  const { params, deleteParams, setSearchParams } = useAllParams();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      search: params.search || "",
    },
  });
  const { permissions, loading } = usePermissions();
  const actionPermissionAdd =
    permissions && permissions.includes("action_add_payment_method");

  const handleConsultation = async () => {
    const param = new ServicesConfigParams();
    params.search && (param.search = params.search);
    params.is_active && (param.is_active = params.is_active);
    params.finish && (param.finish = params.finish);
    try {
      await queryClient.fetchQuery(
        queryOptions({
          queryKey: ["scpaymentAll"],
          queryFn: () =>
            GetPaymentMethodCompaniesThunks(param, Number(item))
              .then((d) => {
                setscpaymentMethod(d.data, d.data.length);
              })
              .catch(({ response }) => {
                if (response?.status === 400) {
                  const errorMessage =
                    Object.keys(response.data || {})
                      .join(", ")
                      .concat(
                        ": " + Object.values(response.data || {}).join(", ")
                      ) ||
                    Object.values(response.data || {}).join(", ") ||
                    "Error desconocido";
                  return ErrorToast(errorMessage);
                }
              }),
          retry: false,
        })
      );
    } catch (error) {
      console.error(error, "snp");
    }
  };
  React.useEffect(() => {
    handleConsultation();
  }, [item, params]);

  const onSubmit = ({ search }: any) => {
    if (!search) {
      deleteParams(["search"]);
    } else {
      const { page, ...rest } = params;
      setSearchParams({ ...rest, search });
    }
  };

  return (
    <>
      <div>
        <div className="my-4 flex justify-between ">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex w-full  gap-x-2"
          >
            <Controller
              control={control}
              name="search"
              render={({ field }) => (
                <div className="flex w-full  items-center">
                  <Input
                    {...field}
                    type="text"
                    variant="bordered"
                    placeholder="Introduce para buscar"
                    className="max-w-lg"
                  />
                  <Button
                    isIconOnly
                    color="primary"
                    type="submit"
                    className="ml-2"
                    size="sm"
                  >
                    <BiSearch className="h-6 w-6 text-white" />
                  </Button>
                </div>
              )}
            />
          </form>
          {actionPermissionAdd && (
            <AddSCPaymentCompany reload={handleConsultation} />
          )}
        </div>
        {count > 0 ? (
          <>
            <TableServiceCompanysnp reload={handleConsultation} />
          </>
        ) : (
          <div className="text-center">
            <IoBanOutline className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-xl font-semibold text-gray-500">
              No hay resultado
            </h3>
          </div>
        )}
      </div>
    </>
  );
};
