import { create } from "zustand";
import { MethodPaymentCompany } from "../../../pages/core/interfaces/PaymentInterfaces";

interface SCPaymentMethodState {
  scpaymentMethod: MethodPaymentCompany[];
  count: number;
  setscpaymentMethod: (
    serviceCompany: MethodPaymentCompany[],
    count: number
  ) => void;
  reset: () => void;
}

export const useStoreSCPaymentMethodCore = create<SCPaymentMethodState>(
  (set) => ({
    scpaymentMethod: [],
    count: 0,
    setscpaymentMethod: (
      scpaymentMethod: MethodPaymentCompany[],
      count: number
    ) => set(() => ({ scpaymentMethod, count })),
    reset: () => set(() => ({ scpaymentMethod: [], count: 0 })),
  })
);
