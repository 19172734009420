import React, { useEffect } from "react";
import { Progress } from "@nextui-org/react";
import { PLayouts } from "../../../layout/PLayouts";
import { steps } from "./components/steps";
import { useAllParams } from "../../../../../hooks/useAllParams";
import { useParams } from "react-router-dom";

export interface Steps {
  id: string;
  title: string;
  active: string;
  component: JSX.Element | any;
}
type StepsProps = Steps[];

export const CreateCompanies = () => {
  const { id } = useParams();
  const [companyCreate, setCompanyCreate] = React.useState({});
  const stepsItem: StepsProps = steps;
  return (
    <>
      <div className="w-1/2 my-4  pb-4">
        <div className="flex items-center pb-3 ">
          {stepsItem.map((item, index) => {
            return (
              <>
                <div className="flex items-center pb-3 justify-center" key={index}>
                  <div className="flex flex-shrink gap-2  items-center justify-center pb-4">
                    <div
                      className={` w-6 h-6 rounded-full text-base text-white flex items-center ${
                        id === item.id ? "bg-primary" : "bg-gray-600"
                      }`}
                    >
                      <span className=" text-center w-full font-bold">
                        {item.id}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col mx-4 space-y-1 w-1/4 text-center">
                  <Progress value={id === item.id ? 100 : 0} color="primary" />
                  <PLayouts message={item.title} />
                </div>
              </>
            );
          })}
        </div>
      </div>
      <div className="relative flex flex-col max-w-6xl mx-auto">
        {stepsItem.map((item) => {
          return item.id === id ? (
            <>
              <item.component
                key={item.id}
                setCompanyCreate={setCompanyCreate}
                companyCreate={companyCreate}
              />
            </>
          ) : null;
        })}
      </div>
    </>
  );
};
