import { useDisclosure } from "@nextui-org/react";
import { useRef } from "react";
import { ModalComponent } from "../../../../../components/Modal/ModalComponent";
import { annulmentInvoiceThunks, editInvoiceThunks } from "../../../../../../../store/finances/Thunks";
import { ErrorToast, SuccessToast } from "../../../../../../../libs/Notifications";
import { FormEditInvoice } from "../forms-invoices/components/edit/FormEditInvoice";

type Props = {
    data?: any;
    reload: () => void;
  };
  
  
  export const EditInvoice = ({ data, reload }: Props) => {
  
    const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  
  
    const formRef = useRef<() => void | null>(null);
  
  
  
  
  
    const handleFormSubmit = (formData: any) => {
        console.log("update data", formData);
        const date = formData.month
        const body =
            {
                date_expiration: formData.date_expirated,
                month: parseInt(date.slice(-2)),
                year: parseInt(date.slice(0, 4)),
            }
        
        editInvoiceThunks( data.id,body)
            .then((result) => {
              SuccessToast("Factura actualizada correctamente.");
                onClose();
                reload();
  
            })
            .catch(({ response }) => {
              if (response?.status === 400) {
                // Verificar si hay un mensaje de error en el cuerpo de la respuesta
                const errorMessage =  Object.values(response.data || {}).join(', ') || "Error desconocido";
                return ErrorToast(errorMessage);
              }
              ErrorToast("Hubo un error al actualizar la factura.");
                return;
            });
    };
  
    const handleSubmitClick = () => {
        if (formRef.current) {
            formRef.current();
        }
    };
  
    return (
        <>
            <span
                style={{
                    cursor: 'pointer',
                }}
                onClick={onOpen} // Abrir el modal al presionar el texto
            >
                Modificar Factura
            </span>
            <ModalComponent
                header={`Modificar Factura ${data.id}`}
                body={<FormEditInvoice data={data} onSubmit={handleFormSubmit} ref={formRef} />}
                handleSubmitClick={handleSubmitClick}
                onClose={onClose}
                isOpen={isOpen}
                onOpen={onOpen}
                size="5xl"
                onOpenChange={onOpenChange}
                title={""}
            />
        </>
    );
  }