import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserParams } from "../../../pages/core/params/settings/userParams";
import { movilPayAPI } from "../../../api/movilPayAPI";

export const CustomerUserService = createAsyncThunk(
  "custom-groups",
  async (params?: UserParams) => {
    return await movilPayAPI
      .get("/users/custom-groups/", { params })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return [];
      }); 
  }
);

export const GetCustomerUserService = (params?: UserParams) => {
  return movilPayAPI.get("/users/custom-groups/", {params});
};

export const CreateCustomerUserService = (body: any) => {
  return movilPayAPI.post("/users/custom-groups/", body);
};
export const UpdateCustomerUserService = (body: any, id:number) => {
  return movilPayAPI.patch(`/users/custom-groups/${id}/`, body);
}
export const DeleteCustomerUserService = (id:number) => {
  return movilPayAPI.delete(`/users/custom-groups/${id}/`);
}
