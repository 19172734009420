// @flow
import * as React from "react";
import { Company } from "../../../../interfaces/CompaniesInterfaces";
import { FormEditCompany } from "./FormEditCompany";
import { ErrorToast, LoadingToast } from "../../../../../../libs/Notifications";
import {
  PatchCompaniesThunks,
  CompaniesIDThunks,
} from "../../../../../../store/companies/thunks";
import { Button } from "@nextui-org/react";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch } from "../../../../../../store/store";
import { useDispatch } from "react-redux";

type Props = {
  company: Company | null;
  onClose: () => void;
  reload?: () => void;
};

export const EditCompany = ({ company, reload, onClose }: Props) => {
  const formRef = React.useRef<() => void | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const handleFormSubmit = (data: any) => {
    const { success, error } = LoadingToast();
    const newDate = new Date().toISOString().split("T")[0];
    let body = {
      ...data,
      phone: "+" + data.code.concat(data.phone),
      whatsapp_phone: "+" + data.codeWhats.concat(data.whatsapp_phone),
      start_date_work: company?.start_date_work,
      date_incorporation: newDate,
      payment_methods_companies: [],
      company_options: [],  
    };
    PatchCompaniesThunks(body, company?.id)
      .then((results) => {
        success("Datos de la compañia actualizados exitosamente!");
        onClose()
        dispatch(CompaniesIDThunks({  id:company?.id }))
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          const errorMessage =
            Object.keys(response.data || {})
              .join(", ")
              .concat(
                ": " + Object.values(response.data || {}).join(", ")
              ) ||
            Object.values(response.data || {}).join(", ") ||
            "Error desconocido";
          return ErrorToast(errorMessage);
        }
      })
  };
  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current();
    }
  };
  
  return (
    <div className="my-4">
      <FormEditCompany
        data={company}
        onSubmit={handleFormSubmit}
        ref={formRef}
      />
      <div className=" col-span-full  flex justify-center gap-x-2 my-6">
        <Button
          color="primary"
          size="md"
          type="submit"
          onClick={handleSubmitClick}
          className=" text-white text-base shadow-lg font-semibold  rounded-lg active:scale-95 transition-transform transform"
        >
          Guardar
        </Button>
      </div>
    </div>
  );
};
