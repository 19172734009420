

export const LocationRouter = [
{
    name: "Parroquias",
    path: "parish/",
    value: () => {},
    permission: 'action_view_parish'

  },
  {
    name: "Sectores",
    path: "sector/",
     value: () => {},
     permission: 'action_view_sector'
  },
 ];
