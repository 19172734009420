import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Input, Button } from '@nextui-org/react';
import { yupResolver } from '@hookform/resolvers/yup';
import InvoiceSchema from './validators/InvoiceSchema';
import { useAllParams } from '../../../../../../../hooks/useAllParams';
import { SearchCompanies } from '../../../../companies/all/components/SearchCompanies';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../../../store/store';
import { CompaniesParams } from '../../../../../params/companies/companiesParams';
import { CompaniesThunks } from '../../../../../../../store/companies/thunks';
import { ItemsTotal } from './components/create/ItemsTotal';
import { FormItems } from './components/create/FormItems';
import { useStoreCreateInvoice } from '../../../../../../../store/finances/useStoreCreateInvoice';

export const FormInvoice = () => {
    const {
        control,
        getValues,
        trigger,
        formState: { errors, isValid }, // Acceder a isValid
        setValue,
    } = useForm({
        resolver: yupResolver(InvoiceSchema),
        mode: 'onChange', // Establecer el modo de validación
    });
    const { resetForm, setFormDataCompany } = useStoreCreateInvoice(); // Accede a formData y removeFormData desde el store

    const { params, setSearchParams } = useAllParams();
    const dispatch = useDispatch<AppDispatch>();

    const { companies } = useSelector((state: RootState) => state.companies);
    const [suggestions, setSuggestions] = useState<any[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [idCompany, setIdCompany] = useState();

    // Ejecutar consulta cuando searchTerm cambie
    useEffect(() => {
        if (searchTerm) {
            handleConsultation();
        } else {
            setSuggestions([]); // Limpiar sugerencias si searchTerm está vacío
        }
    }, [searchTerm]);

    const handleConsultation = async () => {
        const param = new CompaniesParams();
        params.status && (param.status = params.status);
        params.page && (param.page = Number(params.page));
        param.search = searchTerm; // Asigna el término de búsqueda actual
        await dispatch(CompaniesThunks(param));
        setSuggestions(companies);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
        const { value, key }: any = event.target;

        // Si se presiona "Enter", llama a handleConsultation
        if (event.type === 'keydown' && key === 'Enter') {
            setSearchTerm(value);
            setSearchParams({ search: value });
        } else {
            setSearchTerm(value);
            setSearchParams({ search: value });
        }
    };

    const handleSelectClient = async (client: any) => {
        setValue('client', client.name);
        setValue('identification', client.rif);
        setValue('contract', client.id);
        setIdCompany(client.id);
        // Reiniciar solo los campos específicos
        setValue('date_emission', ''); // Reinicia a cadena vacía
        setValue('date_expirated', ''); // Reinicia a cadena vacía
        setValue('month', ''); // Reinicia a cadena vacía
        resetForm();
        await trigger(); // Esto activará la validación de todos los campos

        setSearchTerm(''); // Limpiar el término de búsqueda
        setSuggestions([]); // Limpiar las sugerencias
    };
    // Watch for form validity and automatically set form data for company in Zustand
    useEffect(() => {

      setCompanyData();
    }, [isValid]);

    const setCompanyData = () => {

        if (isValid){
        const fecha = getValues('month')


            const companyData = {
                company: parseInt(getValues('contract')),
                date_emission: getValues('date_emission'),
                date_expiration: getValues('date_expirated'),
                month: parseInt(fecha.slice(-2)),
                year: parseInt(fecha.slice(0, 4)),
            };
            setFormDataCompany(companyData);
        }
    }

    return (
        <div className="bg-white p-10 rounded-lg grid grid-cols-1 gap-4 lg:grid-cols-2">
            <div>
                <div className="my-4 relative">
                    <Input
                        value={searchTerm}
                        onChange={handleSearch}
                        placeholder="Buscar compañías"
                    />
                    {suggestions.length > 0 && (
                        <div className="bg-white border border-gray-300 rounded-lg mt-1 w-full">
                            {suggestions.map((client, index) => (
                                <div
                                    key={index}
                                    onClick={() => handleSelectClient(client)}
                                    className="cursor-pointer p-2 hover:bg-gray-200"
                                >
                                    {client.name}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                <form className="flex flex-col my-4 ">
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                        <div className="col-span-1">
                            <Controller
                                control={control}
                                name="identification"
                                render={({ field }) => (
                                    <>
                                        <Input
                                            {...field}
                                            isInvalid={!!errors.identification}
                                            type="text"
                                            placeholder="Ej:J00000000"
                                            variant="bordered"
                                        />
                                        {errors.identification && (
                                            <span style={{ color: 'red', fontSize: '12px' }}>
                                                {errors.identification.message}
                                            </span>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                        <div className="col-span-1">
                            <Controller
                                control={control}
                                name="client"
                                render={({ field }) => (
                                    <>
                                        <Input
                                            {...field}
                                            isInvalid={!!errors.client}
                                            type="text"
                                            placeholder="Nombre completo del cliente"
                                            variant="bordered"
                                        />
                                        {errors.client && (
                                            <span style={{ color: 'red', fontSize: '12px' }}>
                                                {errors.client.message}
                                            </span>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                        <div className="col-span-1">
                            <Controller
                                control={control}
                                name="contract"
                                render={({ field }) => (
                                    <>
                                        <Input
                                            {...field}
                                            isInvalid={!!errors.contract}
                                            type="text"
                                            placeholder="Código del contrato"
                                            variant="bordered"
                                        />
                                        {errors.contract && (
                                            <span style={{ color: 'red', fontSize: '12px' }}>
                                                {errors.contract.message}
                                            </span>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                        <div className="col-span-1">
                            <Controller
                                control={control}
                                name="date_emission"
                                render={({ field }) => (
                                    <>
                                        <Input
                                            {...field}
                                            isInvalid={!!errors.date_emission}
                                            type="date"
                                            label="Fecha Emisión"
                                            placeholder="Elija fecha Emisión"
                                            variant="bordered"
                                        />
                                        {errors.date_emission && (
                                            <span style={{ color: 'red', fontSize: '12px' }}>
                                                {errors.date_emission.message}
                                            </span>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                        <div className="col-span-1">
                            <Controller
                                control={control}
                                name="date_expirated"
                                render={({ field }) => (
                                    <>
                                        <Input
                                            {...field}
                                            isInvalid={!!errors.date_expirated}
                                            type="date"
                                            label="Fecha Vencimiento"
                                            placeholder="Elija fecha Vencimiento"
                                            variant="bordered"
                                        />
                                        {errors.date_expirated && (
                                            <span style={{ color: 'red', fontSize: '12px' }}>
                                                {errors.date_expirated.message}
                                            </span>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                        <div className="col-span-1">
                            <Controller
                                control={control}
                                name="month"
                                render={({ field }) => (
                                    <>
                                        <Input
                                            {...field}
                                            isInvalid={!!errors.month}
                                            type="month"
                                            label="Año y mes a facturar"
                                            variant="bordered"
                                        />
                                        {errors.month && (
                                            <span style={{ color: 'red', fontSize: '12px' }}>
                                                {errors.month.message}
                                            </span>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                </form>
                {!isValid && (
                    <span style={{ color: 'red', fontSize: '13px', marginTop: '10' }}>*Todos los Campos son Obligatorios para agregar Item a la factura.</span>
                )}
                {isValid && ( // Mostrar el botón solo si el formulario es válido
                    <div className="my-8">
                        <FormItems idCompany={idCompany} />
                    </div>
                )}
            </div>
            <div className="my-4">
                <ItemsTotal />
            </div>

        </div>
    );
};
