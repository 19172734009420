import { createAsyncThunk } from "@reduxjs/toolkit";
import { ServicesConfigParams } from "../../../pages/core/params/services-configs/ServicesConfigParams";
import { movilPayAPI } from "../../../api/movilPayAPI";


export const ServicesConfigurationThunks = createAsyncThunk(
  "servicesConfiguration",
  async (params?: ServicesConfigParams) => {
    return movilPayAPI
      .get("/companies/service/configuration", { params })
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        return [];
      });
  }
);

export const ServicesConfigurationIDThunks = createAsyncThunk(
  'servicesConfigurationID',
  async (args: {id?:any , params?: any}) => {
    const { id, params } = args;
    try {
      const result = await movilPayAPI.get(`/companies/service/configuration/${id}/`, { params });
      return result.data;
    } catch (err) {
      console.error(err); // Manejo de errores
      return [];
    }
  }
);


export const PostServicesCompaniesThunks = (body: any) => {
  return movilPayAPI.post(`/companies/service/configuration/`, body);
};
