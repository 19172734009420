// Importar las dependencias necesarias
import { TableLayout, valueType } from "../../../../layout/TableLayout";
import { useStoreDeviceCore } from "../../../../../../store/managements/devices/useStoreDeviceCore";
import { PaginatorTable } from "../../../../components/table/PaginatorTable";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";
import { Action, ActionDelete } from "./ActionsManufacturers";
import { usePermissions } from "../../../../../../context/PermissionContext";

type Props = {
  reload: () => void;
};

export const TableManufacturers = ({ reload }: Props) => {
  const { permissions, loading } = usePermissions();
  const actionPermissionEdit =
    permissions && permissions.includes("action_edit_manufacturer");
  const actionPermissionDelete =
    permissions && permissions.includes("action_remove_manufacturer");
  const dataTable: valueType[] = [
    { name: "ID", value: "id" },
    { name: "Nombre", value: "name" },
    { name: "Descripción", value: "description" },
    {
      name: "Acción",
      value: (data: any) => (
        <>
          {actionPermissionEdit && <Action data={data} reload={reload} />}
          {actionPermissionDelete && (
            <ActionDelete data={data} reload={reload} />
          )}
        </>
      ),
    },
  ];

  const { manufacturer, count } = useSelector(
    (state: RootState) => state.manufacturer
  );

  return (
    <div className="space-y-7">
      <div>
        <TableLayout
          isLoading={false}
          data={manufacturer} // Usar datos de dispositivos
          columns={dataTable}
          Paginator={<PaginatorTable count={count} />}
        />
      </div>
    </div>
  );
};
