import { createAsyncThunk } from "@reduxjs/toolkit";
import { movilPayAPI } from "../../api/movilPayAPI";
import { PaymentParams } from "../../pages/core/params/payment/payments/paymentParams";

export const ServicesThunks = createAsyncThunk(
  "services",
  async (params?: PaymentParams) => {
    return movilPayAPI
      .get("/services/", { params })
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        return [];
      });
  }
);

export const CreateService = (body: any) => {
  return movilPayAPI.post('/services/', body)
} 
export const UpdateServicesThunks = (body: any, id:number) => {
  return movilPayAPI.patch(`/services/${id}/`, body);
}
export const DeleteServicesThunks = (id:number) => {
  return movilPayAPI.delete(`/services/${id}/`);
}

export const ServicesTypeThunks = createAsyncThunk(
  "servicesTypes",
  async (params?: PaymentParams) => {
    return movilPayAPI
      .get("/services/service-types", { params })
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        return [];
      });
  }
);


export const getServicesRequest = (params?: PaymentParams) => {
  return movilPayAPI.get(`/services/`, { params });
};
