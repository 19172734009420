// @flow
import {
  Dropdown,
  DropdownTrigger,
  Button,
  DropdownMenu,
  DropdownItem,
  Avatar,
  AvatarIcon,
  User,
} from "@nextui-org/react";
import * as React from "react";
import { FaCircleUser } from "react-icons/fa6";
import { FcConferenceCall, FcFactory, FcSettings } from "react-icons/fc";
import { Link } from "react-router-dom";
import { BsBuildingFillGear } from "react-icons/bs";
import { useUserMovilPay } from "../../../hooks/useUserMovilPay";
import { FiUser } from "react-icons/fi";

type Props = {};
export const ActionMenuAccount = (props: Props) => {
  const iconClasses =
    "text-xl text-default-500 pointer-events-none flex-shrink-0";
  const { name, lastname } = useUserMovilPay();
  const username = `${name} ${lastname}`;

  return (
    <Dropdown>
      <DropdownTrigger>
        
        <Button  variant="light" size="md"  startContent={<FiUser radius="full" className="bg-primary rounded-full p-1 w-8 h-8 text-white/80 " />} >
        <span className="text-base font-semibold text-nowrap text-neutral-600 hidden sm:block ">{username}</span></Button>
      </DropdownTrigger>
      <DropdownMenu color="primary" aria-label="Static Actions">
        <DropdownItem
          key="profile"
          startContent={<FcConferenceCall className={iconClasses} />}
        >
          <Link
            className="text-base leading-6 font-semibold"
            to={`/accounts/profile/`}
          >
            Mi Perfil
          </Link>
        </DropdownItem>
        <DropdownItem
          key="my_company"
          startContent={<FcFactory className={iconClasses} />}
        >
          <Link
            className="text-base leading-6 font-semibold"
            to={`/accounts/my_company/`}
          >
            Mi Compañia
          </Link>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
