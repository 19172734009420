// @flow 
import { useDisclosure, Button } from '@nextui-org/react';
import * as React from 'react';
import { IoIosAdd } from 'react-icons/io';
import { ModalComponent } from '../../../../components/Modal/ModalComponent';
import { ErrorToast,  SuccessToast } from '../../../../../../libs/Notifications';
import { FormRates } from './FormRates';
import { PostRates } from '../../../../../../store/rates/Thunks';



type Props = {
  e?: any
  reload: () => void
};

export const AddRates = ({ e,reload }: Props) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [data, setData] = React.useState(e);

  const formRef = React.useRef<() => void | null>(null);

  const handleFormSubmit = (formData: any) => {
    console.log("Submitted data", formData);

    PostRates(formData)
    .then((result) => {
      SuccessToast("Cuenta Agregada Correctamente.");
      onClose();
      reload();
    })
    .catch(({ response }) => {
        if (response?.status === 400) {
          // Verificar si hay un mensaje de error en el cuerpo de la respuesta
          const errorMessage =  Object.values(response.data || {}).join(', ') || "Error desconocido";
          return ErrorToast(errorMessage);
        }
    
      return;
    });
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current()
    }
  };

  return (
    <>
      <Button
        size="md"
        color="primary"
        className="shadow-lg"
        endContent={<IoIosAdd className="h-7 w-7" />}
        onPress={onOpen}
      >
        Agregar Tarifa
      </Button>

      <ModalComponent
        header={"Agregar nueva Tarifa"}
        body={<FormRates  setData={setData} onSubmit={handleFormSubmit} ref={formRef} />} 
        handleSubmitClick={handleSubmitClick}
        onClose={onClose}        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        title={""}
      />
    </>
  );
};

