import { Outlet } from "react-router-dom";


export const ServicesCompanyCore = () => {
  return (
    <>
      <div className="animate-fade-up  bg-white px-4 py-5 dark:bg-primaryDark rounded-lg sm:px-6 my-4">
        <Outlet />
      </div>
    </>
  );
};

