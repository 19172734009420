import { Input, Button, user } from "@nextui-org/react";
import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { FcSearch } from "react-icons/fc";
import { IoIosRefresh } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import { useAllParams } from "../../../../../hooks/useAllParams";
import { usersAll } from "../../../../../store/settings/Users/thunks";
import { AppDispatch, RootState } from "../../../../../store/store";
import { UserParams } from "../../../params/settings/userParams";
import { TableUsuarios } from "./components/TableUsuarios";
import { AddUser } from "./components/AddUsers";
import { usePermissions } from "../../../../../context/PermissionContext";
import { useStoreUsers } from "../../../../../store/accounts/users/useStoreUsers";
import { queryOptions, useQueryClient } from "@tanstack/react-query";
import { GetUsersThunks } from "../../../../../store/accounts/users/thunks";
import { ErrorToast } from "../../../../../libs/Notifications";
import { BiSearch } from "react-icons/bi";

export const UsuariosCore = () => {
  const queryClient = useQueryClient();
  const { count, setUsers } = useStoreUsers((state) => state);
  const { permissions, loading } = usePermissions();
  const actionPermissionAdd =
    permissions && permissions.includes("action_create_users");
  const dispatch = useDispatch<AppDispatch>();
  const { params, deleteParams, setSearchParams } = useAllParams();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      search: params.search || "",
    },
  });
  const handleConsultation = () => {
    const param = new UserParams();
    params.page && (param.page = Number(params.page));
    params.search && (param.search = params.search);
    queryClient.fetchQuery(
      queryOptions({
        queryKey: ["usersAll"],
        queryFn: () =>
          GetUsersThunks(param)
            .then((d) => {
              setUsers(d.data.results, d.data.count);
            })
            .catch(({ response }) => {
              if (response?.status === 400) {
                const errorMessage =
                  Object.keys(response.data || {})
                    .join(", ")
                    .concat(
                      ": " + Object.values(response.data || {}).join(", ")
                    ) ||
                  Object.values(response.data || {}).join(", ") ||
                  "Error desconocido";
                return ErrorToast(errorMessage);
              }
            }),
        retry: false,
      })
    );
  };
  const handleSearch = ({ search }: any) => {
    if (typeof search == "undefined" || search.length == 0) {
      deleteParams(["search"]);
      setSearchParams({});
      return;
    }
    setSearchParams({ search });
    return;
  };

  useEffect(() => {
    handleConsultation();
  }, [params]);

  return (
    <div className="animate-fade-up">
      <div className="space-y-7">
        <div className="lg:flex lg:justify-between lg:items-center ">
          <div className="mb-7 lg:mb-0">
            <form onSubmit={handleSubmit(handleSearch)}>
              <Controller
                control={control}
                name="search"
                render={({ field }) => (
                  <div className="flex w-full  items-center">
                    <Input
                      {...field}
                      type="text"
                      variant="bordered"
                      placeholder="Introduce para buscar"
                      className="w-full lg:w-[500px] sm:w-full"
                      size="lg"
                    />
                    <Button
                      isIconOnly
                      color="primary"
                      type="submit"
                      className="ml-2"
                    >
                      <BiSearch className="h-6 w-6 text-white" />
                    </Button>
                  </div>
                )}
              />
            </form>
          </div>
          <div className="flex space-x-3 justify-between">
            {actionPermissionAdd && <AddUser reload={handleConsultation} />}
            <Button
              size="md"
              variant="ghost"
              color="default"
              className="hover:text-white shadow-lg"
              endContent={<IoIosRefresh className="h-5 w-5" />}
              onClick={handleConsultation}
            >
              Actualizar
            </Button>
          </div>
        </div>
        <div className=" animate-fade-up">
          {count > 0 && <TableUsuarios reload={handleConsultation} />}
        </div>
      </div>
    </div>
  );
};
