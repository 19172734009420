import { GrValidate } from "react-icons/gr";
import { VscDebugRestart } from "react-icons/vsc";
import { MdOutlineCancel } from "react-icons/md";
import { formatBS, formatDollar } from "../../../../../../libs/FormatAmount";
import { TbDatabaseDollar } from "react-icons/tb";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";
import { useMemo } from "react";


export const CardInfoPayments = () => {
  const { dashboard } = useSelector((d: RootState) => d.DashboardPaymentSlice);
  

  const getAmount: any = useMemo(() => {
    // calcular total de procesados
    const totalProcessing = dashboard.reduce(
      (acumulador: any, elemento: any) => {
        return acumulador + elemento.quantity;
      },
      0
    );

    // calcular total de Validadas
    const totalValidated = dashboard.reduce(
      (acumulador: any, elemento: any) => {
        return acumulador + elemento.quantity_validated_transactions;
      },
      0
    );

    // calcular total de No asociadas
    const totalNotAsociated = dashboard.reduce(
      (acumulador: any, elemento: any) => {
        return acumulador + elemento.quantity_unassociated_transactions;
      },
      0
    );

    // calcular total acumulado
    const totalAcumulateDolar = dashboard.reduce(
      (acumulador: any, elemento: any) => {
        return acumulador + elemento.total_in_usd;
      },
      0
    );

    // calcular total acumulado
    const totalAcumulateBs = dashboard.reduce(
      (acumulador: any, elemento: any) => {
        return acumulador + elemento.total_in_bs;
      },
      0
    );

    return {
      totalProcessing,
      totalValidated,
      totalNotAsociated,
      totalAcumulateDolar,
      totalAcumulateBs,
    };
  }, [dashboard]);

  

  return (
    <div className="grid lg:grid-cols-4 sm:grid-cols-1  gap-2 border-y-1  border-slate-200">
      {/* Card 1*/} 
    
      <div className=" p-4  justify-start items-center gap-2 inline-flex  border-r-small my-4 ">
        <div
          className={`rounded-full flex justify-center overflow-hidden duration-300 h-14 w-14 shrink-0 bg-primary items-center`}
        >
          <VscDebugRestart className="h-8 w-8 text-gray-50 text-center dark:text-white" />
        </div>
        <div className="flex-col justify-center items-center gap-2 flex">
          <div className="text-zinc-700 text-2xl dark:text-titleDark  font-medium flex items-center ">
          Procesadas
          </div>
          <div className="text-lime-600 text-2xl font-medium ">
          {getAmount["totalProcessing"]}
          </div>
        </div>
      </div>
      {/* Card 2 */}
      <div className=" p-4  justify-start items-center gap-2 inline-flex  border-r-small my-4 ">
        <div
          className={`rounded-full flex justify-center overflow-hidden duration-300 h-14 w-14 shrink-0 bg-primary items-center`}
        >
          <GrValidate className="h-8 w-8 text-gray-50 text-center dark:text-white" />
        </div>
        <div className="flex-col justify-center items-center gap-2 flex">
          <div className="text-zinc-700 text-2xl dark:text-titleDark  font-medium flex items-center ">
          Validadas
          </div>
          <div className="text-lime-600 text-2xl font-medium ">
          {getAmount["totalValidated"]}
          </div>
        </div>
      </div>


      {/* Card 3 */}
     
      <div className=" p-4  justify-start items-center gap-2 inline-flex  border-r-small my-4 ">
        <div
          className={`rounded-full flex justify-center overflow-hidden duration-300 h-14 w-14 shrink-0 bg-primary items-center`}
        >
          <MdOutlineCancel className="h-8 w-8 text-gray-50 text-center dark:text-white" />
        </div>
        <div className="flex-col justify-center items-center gap-2 flex">
          <div className="text-zinc-700 text-2xl dark:text-titleDark  font-medium flex items-center ">
          No asociadas
          </div>
          <div className="text-lime-600 text-2xl font-medium ">
        {getAmount["totalNotAsociated"]}
          </div>
        </div>
      </div>
      {/* Card 4 */}
      
     
      <div className=" p-4  justify-start items-center gap-4 inline-flex  my-4">
       
       <div
       className={`rounded-full flex justify-center overflow-hidden duration-300 h-16 w-16 shrink-0 bg-primary items-center`}
     >
       <TbDatabaseDollar className="h-8 w-8 text-gray-50 text-center dark:text-white" />
     </div>
     <div className="flex-col justify-center items-center gap-2 flex">
       <div className="text-zinc-700 text-2xl dark:text-titleDark  font-medium flex items-center ">
       Acumulado
       </div>
       <div className="text-lime-600 text-xl font-medium ">
       {formatBS(getAmount["totalAcumulateBs"])}
       </div>
       <div className=" w-3/4 border-1"></div>
       <div className="text-gray-500 text-xl font-medium ">

       {formatDollar(getAmount["totalAcumulateDolar"])}
       </div>
     </div>
     </div>
    </div>
  );
};
