// Importar las dependencias necesarias
import { TableLayout, valueType } from "../../../../../layout/TableLayout";
import { Action, ActionDelete } from "../ActionsDevices";
import { PaginatorDevices } from "./PaginatorDevices";
import { useStoreDeviceCore } from "../../../../../../../store/managements/devices/useStoreDeviceCore";
import { usePermissions } from "../../../../../../../context/PermissionContext";

type Props = {
  reload: () => void
};


export const TableDevices = ({reload }: Props) => {
  const { permissions, loading } = usePermissions();
  const actionPermissionEdit =permissions && permissions.includes('action_edit_device');
  const actionPermissionDelete =permissions && permissions.includes('action_delete_device');
  const dataTable: valueType[] = [
    { name: "ID", value: "id" },
    { name: "Nombre", value: "name" },
    { name: "Descripción", value: "detail" },
    { name: "Ocupados", value: "quantity_occuped" },
    { name: "Disponibles", value: "quantity_available" },
    { name: "Costo", value: "cost" },
    { name: "Acción", value: (data: any) => <>{actionPermissionEdit && <Action data={data} reload={reload}/>} {actionPermissionDelete && <ActionDelete data={data} reload={reload}/>}</> },
  ];  
 
  const { devices, count } = useStoreDeviceCore(
    (state: any) => state
  );
  
 
  
  return (
    <div className="space-y-7">
      <div>
        <TableLayout
          isLoading={false}
          data={devices} // Usar datos de dispositivos
          columns={dataTable}
          Paginator={<PaginatorDevices count={count} />}
        />
      </div>
    </div>
  );
};
