// @flow
import * as React from "react";
import { Parish } from "../../../../components/location/ActionsLocation";
import { LocationParams } from "../../../../params/locations/LocationParams";
import { GetParishThunks } from "../../../../../../store/location/Parish/thunks";
import { queryOptions, useQueryClient } from "@tanstack/react-query";
import { useStoreParishCore } from "../../../../../../store/location/Parish/useStoreParish";
import { ErrorToast } from "../../../../../../libs/Notifications";
import { data } from "../../../finances/payments/components/ChartPiePayments";
import { useAllParams } from "../../../../../../hooks/useAllParams";
import { Controller, useForm } from "react-hook-form";
import { Button, Input, Select, SelectItem } from "@nextui-org/react";
import { AppDispatch, RootState } from "../../../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { StatesThunks } from "../../../../../../store/location/States/thunks";
import { BiSearch } from "react-icons/bi";
import { TableParish } from "./table/tableParish";
type Props = {};
export const ParishCore = (props: Props) => {
  const queryClient = useQueryClient();
  const { params, deleteParams, setSearchParams } = useAllParams();
  const { states } = useSelector((state: RootState) => state.states);
  const dispatch = useDispatch<AppDispatch>();

  const { count, setParish } = useStoreParishCore((state) => state);
  const { control, handleSubmit } = useForm({
    defaultValues: {
      search: params.search || "",
      state: params.state || "",
    },
  });
  const getParish = () => {
    const param: LocationParams = new LocationParams();
    params.page && (param.page = Number(params.page));
    params.search && (param.search = params.search);
    params.state && (param.state = params.state);
    queryClient.fetchQuery(
      queryOptions({
        queryKey: ["parishAll"],
        queryFn: () =>
          GetParishThunks(param)
            .then((d) => {
              setParish(d.data.results, d.data.count);
            })
            .catch(({ response }) => {
              if (response?.status === 400) {
                const errorMessage =
                  Object.keys(response.data || {})
                    .join(", ")
                    .concat(
                      ": " + Object.values(response.data || {}).join(", ")
                    ) ||
                  Object.values(response.data || {}).join(", ") ||
                  "Error desconocido";
                return ErrorToast(errorMessage);
              }
            }),
        retry: false,
      })
    );
  };
  const handleConsultation = () => {
    const param: LocationParams = new LocationParams();
    param.remove_pagination = true;
    dispatch(StatesThunks(param));
  };
  const handleState = ({ target: { value } }: any) => {
    if (value) {
      return setSearchParams({ state: value });
    }
    return setSearchParams({});
  };
  const handleSearch = ({ search }: any) => {
    if (typeof search == "undefined" || search.length == 0) {
      deleteParams(["search"]);
      setSearchParams({});
      return;
    }
    setSearchParams({ search });
    return;
  };
  React.useEffect(() => {
    handleConsultation();
  }, []);
  React.useEffect(() => {
    getParish();
  }, [params]);
  return (
    <div className="animate-fade-up w-full">
      <div className="space-y-7">
        <div className="lg:flex lg:justify-between lg:items-center ">
          <div className="smb-7 lg:mb-0">
            <form
              onSubmit={handleSubmit(handleSearch)}
              className="flex p-0 m-0"

            >
              <Controller
                control={control}
                name="search"
                render={({ field }) => (
                  <div className="flex w-full justify-center items-center">
                    <Input
                      {...field}
                      type="text"
                      variant="bordered"
                      placeholder="Introduce para buscar"
                       className="w-full lg:w-[350px] sm:w-full"
                    />
                    <Button
                      isIconOnly
                      color="primary"
                      type="submit"
                      className="ml-2"
                      size="sm"
                    >
                      <BiSearch className="h-6 w-6 text-white" />
                    </Button>
                  </div>
                )}
              />
              <Controller
                control={control}
                name="state"
                render={({ field }) => (
                  <Select
                    {...field}
                    items={states}
                    variant="bordered"
                    placeholder="Filtrar por estados"
                   className="w-full lg:w-[350px] sm:w-full"
                    key={field.value}
                    onChange={handleState}
                  >
                    {(finish) => (
                      <SelectItem key={finish.id}>{finish.name}</SelectItem>
                    )}
                  </Select>
                )}
              />
            </form>
          </div>
          <div className="flex space-x-3 justify-between">
            <Parish reload={getParish} />
          </div>
        </div>
        <div className=" animate-fade-up">
          {count > 0 && <TableParish reload={handleConsultation} />}
        </div>
      </div>
    </div>
  );
};
