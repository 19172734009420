// @flow
import { Button, useDisclosure } from "@nextui-org/react";
import * as React from "react";
import { IoIosAdd } from "react-icons/io";
import { ModalComponent } from "../../../../../components/Modal/ModalComponent";
import { FormServicesConfig } from "./FormServicesConfig";
import {
  ErrorToast,
  SuccessToast,
} from "../../../../../../../libs/Notifications";
import { useParams } from "react-router-dom";
import { DevicesCreatServiceCompanyThunks } from "../../../../../../../store/companies/DevicesCompany/thunks";
import { useStoreServiceCompanyCore } from "../../../../../../../store/companies/servicesCompany/useStoreServiceCompanyCore";
import { useUserMovilPay } from "../../../../../../../hooks/useUserMovilPay";
type Props = {
  reload?: () => void;
};
export const AddSCDevice = ({ reload }: Props) => {

  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { id  } = useParams();
  const { company} = useUserMovilPay();
  const option = id ? id : company
  const formRef = React.useRef<() => void | null>(null);
  const { serviceCompany } = useStoreServiceCompanyCore(
    (state) => state
  );

  const handleFormSubmit = (formData: any) => {
    const service =  serviceCompany.filter((sc)=> sc.id === Number(formData.service))[0]?.service
    const body = {
      ...formData,
      service: service,
      company: option,
      date_end: null,
      date_end_detail: null,
    };
    DevicesCreatServiceCompanyThunks(body)
      .then(() => {
        SuccessToast("Dispositivo creado correctamente");
        reload && reload();
        onClose();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          const errorMessage =
            Object.keys(response.data || {})
              .join(", ")
              .concat(": " + Object.values(response.data || {}).join(", ")) ||
            Object.values(response.data || {}).join(", ") ||
            "Error desconocido";
          return ErrorToast(errorMessage);
        }
      });
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current();
    }
  };
  return (
    <>
      <div className="flex justify-end mx-2">
        <Button
          size="md"
          color="primary"
          className="shadow-lg"
          endContent={<IoIosAdd className="h-7 w-7" />}
          onPress={onOpen}
        >
          Agregar dispositivo
        </Button>
        <ModalComponent
          header={"Agregar el dispositivo"}
          body={
            <FormServicesConfig onSubmit={handleFormSubmit} ref={formRef} />
          }
          handleSubmitClick={handleSubmitClick}
          onClose={onClose}
          isOpen={isOpen}
          onOpen={onOpen}
          onOpenChange={onOpenChange}
          title={""}
        />
      </div>
    </>
  );
};
