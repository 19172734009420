import { Button, useDisclosure } from "@nextui-org/react";
import { IoIosAdd } from "react-icons/io";

import * as yup from "yup";
import { ErrorToast, LoadingToast } from "../../../../../../libs/Notifications";
import { userCreate } from "../../../../../../store/settings/Users/thunks";
import { useState } from "react";
import { ModalComponent } from "../../../../components/Modal/ModalComponent";
import { FormUser } from "./FormUser";
import React from "react";
import { PostUsersThunks } from "../../../../../../store/accounts/users/thunks";

export const AddUser = (e: any) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [data, setData] = useState(e);
  const formRef = React.useRef<() => void | null>(null);

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current();
    }
  };
  const handleFormSubmit = (formData: any) => {
    const { success } = LoadingToast();
    const body = {
      ...formData,
      phone: formData.code.concat(formData.phone),
      identification: formData.type.concat(formData.identification),
      groups: [Number(formData.groups)]
    };
    PostUsersThunks(body)
      .then((result) => {
        success("Registro éxitoso");
        onClose();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          const errorMessage =
            Object.keys(response.data || {})
              .join(", ")
              .concat(": " + Object.values(response.data || {}).join(", ")) ||
            Object.values(response.data || {}).join(", ") ||
            "Error desconocido";
          return ErrorToast(errorMessage);
        }
      });
  };

  return (
    <>
      <Button
        size="md"
        color="primary"
        className="shadow-lg"
        endContent={<IoIosAdd className="h-7 w-7" />}
        onPress={onOpen}
      >
        Crear Usuario
      </Button>

      <ModalComponent
        header={"Registrar datos"}
        body={
          <FormUser onSubmit={handleFormSubmit} ref={formRef} />
        }
        handleSubmitClick={handleSubmitClick}
        onClose={onClose}
        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        title={""}
      />
    </>
  );
};
