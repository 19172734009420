import React from "react";
import { Avatar, Button, Tooltip } from "@nextui-org/react";
import { FaInfo } from "react-icons/fa";
import { Company } from "../../../../interfaces/CompaniesInterfaces";
import { FaBuildingUser } from "react-icons/fa6";


type Props = {
  company: Company | any; // Replace with actual type
  handleButton: (id: number) => void;
};
export const CardCompanies = ({ company, handleButton }: Props) => {
  return (
    <div
      onClick={() => handleButton(company.id)}
      className="inline-block  justify-between items-center gap-2 py-2.5 px-4 dark:text-white hover:bg-neutral-200 border-2 border-neutral-200 transition-all duration-300 cursor-pointer  hover:text-neutral-900 rounded-xl "
    >
      <div className="inline-flex justify-between  items-center  gap-x-4 px-2">
        <Avatar
          icon={
            <FaBuildingUser className="h-12 w-12 rounded-full p-1 flex-none text-primaryDark  bg-gray-200" />
          }
        />
        <div className="flex flex-col  text-wrap">
          <span className="font-medium text-lg">{company.name}</span>
          <span className="font-medium">{company.rif}</span>
          <span  className="text-sm ">{company.email}</span>
        </div>
      </div>
    </div>
  );
};
