// @flow 
import { yupResolver } from '@hookform/resolvers/yup';
import { Textarea } from '@nextui-org/react';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

type Props = {
    data: any;
    setData?: (data: any) => void;
    onSubmit: (data: any) => void;
    ref?: React.MutableRefObject<(() => void) | null>;
};
let schema= yup.object({
    date_end_detail: yup.string().required("Este campo es requerido"),
  });
  
export const FormFinishService = React.forwardRef(
    ({ data, onSubmit }: Props, ref) => {
      const {
        control,
        handleSubmit,
        formState: { errors },
      } = useForm({
        resolver: yupResolver(schema),
      });
      
      React.useImperativeHandle(ref, () => handleSubmit(onSubmit));
  
      return (
        <form className="flex flex-col w-full">
          <Controller
            control={control}
            name="date_end_detail"
            render={({ field }) => (
              <Textarea
                {...field}
                isInvalid={!!errors.date_end_detail}
                errorMessage={errors.date_end_detail?.message}
                type="text"
                autoFocus
                label="Motivo de finalización"
                placeholder="Agregue el motivo por el cual finalizará el servicio."
                variant="bordered"
              />
            )}
          />
        </form>
      );
    }
  );