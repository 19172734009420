import { queryOptions, useQueryClient } from "@tanstack/react-query";
import { useAllParams } from "../../../../../hooks/useAllParams";
import { AddRates } from "./components/AddRates";
import { useStoreRates } from "../../../../../store/rates/useStoreRates";
import { getRates } from "../../../../../store/rates/Thunks";
import { useEffect, useState } from "react";
import { TableRates } from "./components/TableRates";
import { RatesParams } from "../../../params/rates/ratesParams";
import { PaymentParams } from "../../../params/payment/payments/paymentParams";
import { getServicesRequest } from "../../../../../store/services/thunks";
import { Button, Select, SelectItem, Tooltip } from "@nextui-org/react";
import { FcClearFilters } from "react-icons/fc";
import { usePermissions } from "../../../../../context/PermissionContext";
import { MdFilterAltOff, MdOutlineFilterAltOff, MdOutlineFilterListOff } from "react-icons/md";

export const RatesCore = () => {
  const { params, deleteParams, setSearchParams } = useAllParams();
  const { setRates, count } = useStoreRates((state) => state);
  const queryClient = useQueryClient();
  const [services, setServices] = useState([]);
  const [filterService, setFilterService] = useState<string>();
  const [key, setKey] = useState(0); // Estado clave para forzar el re-render
  const { permissions, loading } = usePermissions();
  const actionPermissionAdd =
    permissions && permissions.includes("action_add_rates");
  const handleConsultation = () => {
    const param = new RatesParams();
    if (params.page) param.page = Number(params.page);
    if (params.search) param.search = params.search;
    param.service = filterService;
    queryClient.fetchQuery(
      queryOptions({
        queryKey: ["rates"],
        queryFn: () =>
          getRates(param).then((d) => {
            setRates(d.data.results, d.data.count);
          }),
        retry: false,
      })
    );
  };

  const handleConsultationServices = () => {
    const param = new PaymentParams();
    param.remove_pagination = true;

    queryClient.fetchQuery(
      queryOptions({
        queryKey: ["services"],
        queryFn: () =>
          getServicesRequest(param).then((d) => {
            setServices(d.data);
          }),
        retry: false,
      })
    );
  };

  useEffect(() => {
    handleConsultation();
  }, [params]); // Add filterUsed to the dependencies array

  useEffect(() => {
    handleConsultationServices();
  }, []);

  const handleFilterChangeService = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value;
    await setFilterService(value);

    handleConsultation(); // Trigger consultation with new filter value
  };

  const handleReset = async () => {
    setFilterService("");

    setKey((prevKey) => prevKey + 1); // Actualiza la clave para forzar el re-render
  };

  return (
    <div
      className="animate-fade-up  bg-white px-4 py-5 dark:bg-primaryDark rounded-lg sm:px-6 my-4"
      key={key}
    >
      <div className="space-y-7">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 items-center">
          <div>
            <Select
              placeholder="Filtrar por Servicio"
              onChange={handleFilterChangeService}
               className="w-full lg:w-[500px] sm:w-full"
                      size="lg"
              variant="bordered"
            >
              {services.map((service: any) => (
                <SelectItem key={service.id} value={service.id}>
                  {service.name}
                </SelectItem>
              ))}
            </Select>
          </div>
          <div>
            <Tooltip content="Borrar filtros">
              <Button
                isIconOnly
                variant="solid"
                color="primary"
                
                onPress={handleReset}
              >
                <MdFilterAltOff  
                  className={`font-semibold h-6 w-6 `}
                />
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="flex space-x-3 justify-end">
        {actionPermissionAdd && (
          <AddRates e={undefined} reload={handleConsultation} />
        )}
      </div>
      <div className="animate-fade-up">
        <TableRates reload={handleConsultation} />
      </div>
    </div>
  );
};
