// src/stores/useInvoiceStore.ts
import { create } from 'zustand';

// Definimos la interfaz para una factura
interface Invoice {
  id: number;
  amount: number;
  iva: number;
  subtotal: number;
  charged: number;
  rate: number;
}

// Definimos el estado del store
interface InvoiceState {
  invoiceId: number | null;
  balance: number; // balance agregado aquí
  balanceBs: number;
  invoices: Invoice[];
  invoicesPending: Invoice[];
  total: number;
  totalBs: number;
  subtotal: number;
  iva: number;
  setInvoiceId: (id: number) => void;
  setBalance: (balance: number) => void;
  setBalanceBs: (balanceBs: number) => void;
  addInvoice: (invoice: Invoice) => void;
  addInvoicePending: (invoice: Invoice) => void;
  clearInvoices: () => void;
  removeInvoiceById: (id: number) => void;
}

const usePaymentInvoiceStore = create<InvoiceState>((set) => ({
  invoiceId: null,
  balance: 0, // valor inicial para balance
  balanceBs: 0,
  invoices: [],
  invoicesPending: [],
  total: 0,
  totalBs: 0,
  subtotal: 0,
  iva: 0,

  setInvoiceId: (id) => set({ invoiceId: id }),
  setBalance: (balance) => set({ balance: balance }),
  setBalanceBs: (balanceBs) => set({ balanceBs: balanceBs }),

  addInvoice: (invoice) =>
    set((state) => {
      // Verifica si la factura ya existe en invoices
      const exists = state.invoices.some((inv) => inv.id === invoice.id);
      if (exists) {
        console.warn(`La factura con ID ${invoice.id} ya existe en invoices.`);
        return state; // No se modifica el estado si ya existe
      }

      return {
        invoices: [...state.invoices, invoice],
        total: state.total + ((invoice.amount) - state.balance),
        totalBs: state.totalBs + (((invoice.amount) * invoice.rate)-state.balanceBs),
        iva: state.iva + invoice.iva,
        subtotal: state.subtotal + invoice.subtotal,
      };
    }),

  addInvoicePending: (invoice) =>
    set((state) => {
      // Verifica si la factura ya existe en invoices
      const alreadyAdded = state.invoices.some((inv) => inv.id === invoice.id);
      if (alreadyAdded) {
        console.warn(`La factura con ID ${invoice.id} ya está en invoices, no se agregará a invoicesPending.`);
        return state; // No se modifica el estado si ya existe en invoices
      }

      // Si no está en invoices, agrega la factura a invoicesPending
      return {
        invoicesPending: [...state.invoicesPending, invoice],
      };
    }),

  clearInvoices: () => set({ invoices: [], total: 0, totalBs: 0, iva: 0, subtotal: 0, invoicesPending: [], balance:0, balanceBs:0 }),

  removeInvoiceById: (id) =>
    set((state) => {
      const updatedInvoices = state.invoices.filter((invoice) => invoice.id !== id);
      const updatedTotal = updatedInvoices.reduce((acc, invoice) => acc + (invoice.amount - invoice.charged), 0);
      const updatedTotalBs = updatedInvoices.reduce((acc, invoice) => acc + (invoice.amount - invoice.charged) * invoice.rate, 0);
      const updatedIva = updatedInvoices.reduce((acc, invoice) => acc + invoice.iva, 0);
      const updatedSubtotal = updatedInvoices.reduce((acc, invoice) => acc + invoice.subtotal, 0);

      return {
        invoices: updatedInvoices,
        total: updatedTotal,
        totalBs: updatedTotalBs,
        iva: updatedIva,
        subtotal: updatedSubtotal,
      };
    }),
}));

export default usePaymentInvoiceStore;
