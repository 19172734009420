import { Outlet } from "react-router-dom";

const CompaniesCore = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default CompaniesCore;
