import { FormCreateCompany } from "./FormCreateCompany";
import { ServicesCompany } from "./ServicesCompany";


export const steps = [
    {
        id: '1', title: 'Datos de la Compañia', active: 'bg-green-700', component:FormCreateCompany
    },
    {
        id: '2', title: 'Servicios', active: 'bg-green-700' , component: ServicesCompany
    },
]
