import React from 'react'
import { FcConferenceCall, FcDonate, FcFactory, FcInfo, FcMoneyTransfer,  } from 'react-icons/fc'
import { MdOutlineManageAccounts } from 'react-icons/md'
import { GoPasskeyFill } from "react-icons/go";
import { AiOutlineBank } from 'react-icons/ai';

export const accountRouters = [
    {
      name: "Perfil",
      path: "accounts/profile/",
      icon: FcConferenceCall,
      show: true,
      children: null,
    },
    {
      name: "Mi compañia",
      path: "accounts/my_company/",
      icon: FcFactory,
      show: true,
      children: null,
    },
  ]