import { create } from "zustand";
import { CustomerGroup } from "../../../pages/core/params/permissions/CustomerGroup";

interface CustomerGroupsState {
  customerGroup: CustomerGroup[];
  count: number;
  setCustomerGroup: (customerGroup: CustomerGroup[], count:number) => void;
  resetCustomerGroup: () => void;
}
export const useStoreCustomerGroup = create<CustomerGroupsState>((set) => ({
  customerGroup: [],
  count: 0,
  setCustomerGroup: (customerGroup: CustomerGroup[], count: number) => {
    set({ customerGroup, count });
  },
  resetCustomerGroup: () => {
    set({ customerGroup: [], count: 0 });
  },
}));
