import { createAsyncThunk } from "@reduxjs/toolkit";
import { movilPayAPI } from "../../../api/movilPayAPI";
import { LocationParams } from "../../../pages/core/params/locations/LocationParams";

export const SectorsThunks = createAsyncThunk(
  "Sectors",
  async (params?: any) => {
    return movilPayAPI
      .get("/locations/sector/", { params })
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        return [];
      });
  }
);
export const SectorIDThunks = createAsyncThunk(
  "SectorID",
  async (args: { id?: any; params?: any }) => {
    const { id, params } = args;
    try {
      const result = await movilPayAPI.get(`/locations/sector/${id}/`, {
        params,
      });
      return result.data;
    } catch (err) {
      console.error(err); // Manejo de errores
      return [];
    }
  }
);

export const PostSectorThunks = (body: any) => {
  return movilPayAPI.post(`/locations/sector/`, body);
};
export const PatchSectorThunks = (body: any, id: number) => {
  return movilPayAPI.patch(`/locations/sector/${id}/`, body);
};

export const DeleteSectorThunks = (id: any) => {
  return movilPayAPI.delete(`/locations/sector/${id}`);
};

export const GetSectorThunks = (params?: LocationParams) => {
  return movilPayAPI.get(`/locations/sector/`, {params: params});
};
