import * as yup from "yup";

export const schemaState = yup.object({
    name: yup.string().required("Este campo es requerido"),
    description: yup.string().required("Este campo es requerido"),
  });
  
export const schemaParish = yup.object({
  name: yup.string().required("Este campo es requerido"),
  description: yup.string().required("Este campo es requerido"),
  state: yup.string().required("Este campo es requerido"),
});

export const schemaSector = yup.object({
    name: yup.string().required("Este campo es requerido"),
    description: yup.string().required("Este campo es requerido"),
    parroquia: yup.string().required("Este campo es requerido"),
    state: yup.string().required("Este campo es requerido"),
  });