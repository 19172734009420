// @flow
import * as React from "react";
import { AppDispatch, RootState } from "../../../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Divider,
  Input,
  Textarea,
  Select,
  SelectItem,
  DateValue,
  Button,
  Switch,
  Autocomplete,
  AutocompleteItem,
  SelectSection,
} from "@nextui-org/react";
import { useForm, Controller } from "react-hook-form";
import { getLocalTimeZone, today } from "@internationalized/date";
import { type } from "../../../../components/methodPayments/typeIdentification";
import { Company } from "../../../../interfaces/CompaniesInterfaces";
import { keyFilterNumber } from "../../../../../../helpers/KeyFIlterMovilPay";
import { schemaCompany } from "./Validators";
import {
  Parish,
  Sector,
  States,
} from "../../../../components/location/ActionsLocation";
import {
  EconomicSector,
  OcupationCompany,
  TypeCompanyModal,
} from "../../../../components/company/CompanyOptions";
import { OccupationsThunks } from "../../../../../../store/options/Ocupation/thunks";
import { PaymentParams } from "../../../../params/payment/payments/paymentParams";
import { TypeCompanyThunks } from "../../../../../../store/options/TypeCompany/thunks";
import { SectorCompanyThunks } from "../../../../../../store/options/SectorEconomic/thunks";
import { StatesThunks } from "../../../../../../store/location/States/thunks";
import { ParishThunks } from "../../../../../../store/location/Parish/thunks";
import { SectorsThunks } from "../../../../../../store/location/Sector/thunks";
import { useEffect } from "react";
import { useStoreCodePhones } from "../../../../../../store/companies/useStoreCodePhone";
import { queryOptions, useQueryClient } from "@tanstack/react-query";
import {
  CompaniesThunks,
  GetCodePhoneThunks,
  getCompaniesRequest,
} from "../../../../../../store/companies/thunks";
import { ErrorToast } from "../../../../../../libs/Notifications";
import { LocationParams } from "../../../../params/locations/LocationParams";
import { CompaniesParams } from "../../../../params/companies/companiesParams";
import { useStoreCompanieAll } from "../../../../../../store/companies/CompaniesAllStore";

type Props = {
  data?: Company | null;
  setData?: (data: any) => void;
  onSubmit: (data: any) => void;
  ref?: React.MutableRefObject<(() => void) | null>;

  onClose?: () => void;
};
export const FormEditCompany = React.forwardRef(
  ({ onSubmit, data }: Props, ref) => {
    const {
      control,
      handleSubmit,
      setValue,
      watch,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(schemaCompany),
      defaultValues: {
        code: "58",
        codeWhats: "58",
        withholding_agent: "false",
      },
    });
    const { codePhone, setCodePhone } = useStoreCodePhones((state) => state);

    const dispatch = useDispatch<AppDispatch>();

    React.useImperativeHandle(ref, () => handleSubmit(onSubmit));

    const [withholdingAgent, setWithholdingAgent] = React.useState<
      boolean | any
    >(false);
    const [companyMasterType, setCompanyMasterType] = React.useState<
      boolean | any
    >(false);
    const [files, setFiles] = React.useState<FileList>();
    const { SectorCompany } = useSelector(
      (state: RootState) => state.sectorEconomic
    );
    const { TypeCompany } = useSelector(
      (state: RootState) => state.typeCompany
    );
    const queryClient = useQueryClient();

    const { occupations } = useSelector((state: RootState) => state.ocupations);
    const { states } = useSelector((state: RootState) => state.states);
    const { parish } = useSelector((state: RootState) => state.parish);
    const { sector } = useSelector((state: RootState) => state.sector);
    const { companieAll, setCompanieAll } = useStoreCompanieAll(
      (state) => state
    );

    const handleConsultationCompany = () => {
      const param = new CompaniesParams();
      param.remove_pagination = "true";
      queryClient.fetchQuery(
        queryOptions({
          queryKey: ["codePhone"],
          queryFn: () =>
            GetCodePhoneThunks()
              .then((d) => {
                setCodePhone(d.data);
              })
              .catch(({ response }) => {
                if (response?.status === 400) {
                  const errorMessage =
                    Object.keys(response.data || {})
                      .join(", ")
                      .concat(
                        ": " + Object.values(response.data || {}).join(", ")
                      ) ||
                    Object.values(response.data || {}).join(", ") ||
                    "Error desconocido";
                  return ErrorToast(errorMessage);
                }
              }),
          retry: false,
        })
      );
    };
    const handleCodePhone = () => {
      queryClient.fetchQuery(
        queryOptions({
          queryKey: ["companieAll"],
          queryFn: () =>
            getCompaniesRequest()
              .then((d) => {
                setCompanieAll(d.data.results, d.data.count);
              })
              .catch(({ response }) => {
                if (response?.status === 400) {
                  const errorMessage =
                    Object.keys(response.data || {})
                      .join(", ")
                      .concat(
                        ": " + Object.values(response.data || {}).join(", ")
                      ) ||
                    Object.values(response.data || {}).join(", ") ||
                    "Error desconocido";
                  return ErrorToast(errorMessage);
                }
              }),
          retry: false,
        })
      );
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const val = event.target;
      setFiles((event.target.files as FileList) || null);
      console.log(event.target.files as FileList, val.files);
    };
    const getOccuptions = () => {
      const param: PaymentParams = new PaymentParams();
      param.remove_pagination = true;
      dispatch(OccupationsThunks(param));
    };
    const getTypeCompany = () => {
      const param: PaymentParams = new PaymentParams();
      param.remove_pagination = true;
      dispatch(TypeCompanyThunks(param));
    };
    const getSectorCompany = () => {
      const param: PaymentParams = new PaymentParams();
      param.remove_pagination = true;
      dispatch(SectorCompanyThunks(param));
    };
    const getStates = () => {
      const param: LocationParams = new LocationParams();
      param.remove_pagination = true;
      dispatch(StatesThunks(param));
    };
    const getParish = (value: any) => {
      const param: LocationParams = new LocationParams();
      if (value) {
        param.remove_pagination = true;
        param.state = value;
        dispatch(ParishThunks(param));
      }
    };
    const getSectors = (value: any) => {
      const param: LocationParams = new LocationParams();
      if (value) {
        param.remove_pagination = true;
        param.parroquia = value;
        dispatch(SectorsThunks(param));
      }
    };
    React.useEffect(() => {
      getOccuptions();
      getTypeCompany();
      getSectorCompany();
      getStates();
      handleCodePhone();
      handleConsultationCompany();
    }, []);

    useEffect(() => {
      getParish(data?.location_state);
      getSectors(data?.location_parroquia);
      if (data) {
        setValue("name", data?.name);
        setValue("legal_name", data?.legal_name);
        setValue("type", data?.type);
        setValue("economic_sector", data?.economic_sector);
        setValue("ocupation", data?.ocupation);
        setValue("description", data?.description);
        setValue("email", data?.email);
        setValue("phone", data?.phone?.slice(3));
        setValue("location_state", Number(data?.location_state));
        setValue("location_parroquia", Number(data?.location_parroquia));
        setValue("location_sector", Number(data?.location_sector));
        setValue("billing_address", data?.billing_address);
        setValue("whatsapp_phone", data?.whatsapp_phone?.slice(3));
        setValue("withholding_agent", data?.withholding_agent.toString());
        setValue("company_master", data?.company_master.toString());
        setWithholdingAgent(data?.withholding_agent);
        setCompanyMasterType(data?.company_master !=null  ? true: false)
      }
    }, [data]);

    return (
      <>
        <div className="grid xl:grid-cols-3 gap-6 p-6">
          <div className="grid grid-cols-3 items-center justify-center col-span-full  mt-4">
            <Divider />
            <span className="flex justify-center font-thin text-2xl">
              Datos de la Compañia
            </span>
            <Divider content="Ubicación" />
          </div>
          <div className="col-span-full">
            <div className="flex gap-x-4 items-center justify-center ">
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <Input
                    {...field}
                    isInvalid={!!errors.name}
                    errorMessage={errors.name?.message}
                    type="text"
                    autoFocus
                    labelPlacement="outside"
                    label="Nombre Comercial"
                    placeholder="Introduce el nombre comercial"
                    variant="bordered"
                  />
                )}
              />
              <Controller
                control={control}
                name="legal_name"
                render={({ field }) => (
                  <Input
                    {...field}
                    isInvalid={!!errors.legal_name}
                    errorMessage={errors.legal_name?.message}
                    type="text"
                    autoFocus
                    label="Razón social"
                    labelPlacement="outside"
                    placeholder="Introduce la razón social"
                    variant="bordered"
                  />
                )}
              />
            </div>
          </div>
          <div className="col-span-full flex gap-x-4 items-center justify-center ">
            <div className="flex w-full items-center gap-2">
              <Controller
                name="type"
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      variant="bordered"
                      items={TypeCompany}
                      label="Tipo de empresa"
                      selectedKeys={new Set([field.value])}
                      isInvalid={!!errors.type}
                      labelPlacement="outside"
                      errorMessage={errors.type?.message}
                      // endContent={<TypeCompanyModal reload={getTypeCompany} />}
                    >
                      {(type) => (
                        <SelectItem key={type.id} value={type.id}>
                          {type.name}
                        </SelectItem>
                      )}
                    </Select>
                  </>
                )}
              />
            </div>
            <div className="flex w-full items-center gap-2">
              <Controller
                name="economic_sector"
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      variant="bordered"
                      items={SectorCompany}
                      label="Sector economico"
                      selectedKeys={new Set([field.value])}
                      isInvalid={!!errors.economic_sector}
                      labelPlacement="outside"
                      errorMessage={errors.economic_sector?.message}
                      // endContent={<EconomicSector reload={getSectorCompany} />}
                    >
                      {(type) => (
                        <SelectItem key={type.id} value={type.id}>
                          {type.name}
                        </SelectItem>
                      )}
                    </Select>
                  </>
                )}
              />
            </div>
            <div className="flex w-full items-center gap-2">
              <Controller
                name="ocupation"
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      variant="bordered"
                      className="flex items-center"
                      items={occupations}
                      label="Ocupación"
                      selectedKeys={new Set([field.value])}
                      isInvalid={!!errors.ocupation}
                      labelPlacement="outside"
                      errorMessage={errors.ocupation?.message}
                      // endContent={<OcupationCompany reload={getOccuptions} />}
                    >
                      {(type) => (
                        <SelectItem key={type.id} value={type.id}>
                          {type.name}
                        </SelectItem>
                      )}
                    </Select>
                  </>
                )}
              />
            </div>
          </div>
          <div className="col-span-full">
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <Textarea
                  {...field}
                  isInvalid={!!errors.description}
                  errorMessage={errors.description?.message}
                  type="text"
                  autoFocus
                  labelPlacement="outside"
                  label="Descripción"
                  placeholder="Agregar una breve descripción de la compañía"
                  variant="bordered"
                />
              )}
            />
          </div>
          <Controller
            control={control}
            name="withholding_agent"
            render={({ field }) => (
              <>
                <div className="grid">
                  <Switch
                    {...field}
                    isSelected={withholdingAgent}
                    onValueChange={(e) => {
                      setWithholdingAgent(e);
                      field.onChange(e.toString());
                    }}
                  >
                    ¿Es un agente de retención?
                  </Switch>
                  {errors.withholding_agent && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors.withholding_agent.message}
                    </span>
                  )}
                </div>
              </>
            )}
          />
          <Controller
            control={control}
            name="type_master"
            render={({ field }) => (
              <>
                <div className="grid">
                  <Switch
                    {...field}
                    isSelected={companyMasterType}
                    onValueChange={(e) => {
                      setCompanyMasterType(e);
                      field.onChange(e.toString());
                      setValue('company_master','');
                    }}
                  >
                    ¿Es una sucursal?
                  </Switch>
                  {errors.type_master && (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {errors.type_master.message}
                    </span>
                  )}
                </div>
              </>
            )}
          />
          {companyMasterType && (
            <Controller
              name="company_master"
              control={control}
              render={({ field }) => (
                <>
                  <div className="flex items-center">
                    <Autocomplete
                      {...field} // Pasa las propiedades del Controller
                      className="max-w"
                      variant="bordered"
                      defaultItems={companieAll}
                      placeholder="Buscar la compañia a la que pertenece"
                      isInvalid={!!errors.company_master}
                      errorMessage={errors.company_master?.message}
                      selectedKey={field.value}
                      onSelectionChange={(e) => field.onChange(e)}
                    >
                      {(item) => (
                        <AutocompleteItem key={item.id}>
                          {item.name}
                        </AutocompleteItem>
                      )}
                    </Autocomplete>
                  </div>
                </>
              )}
            />
          )}
          <div className="grid grid-cols-3 items-center justify-center col-span-full  mt-4">
            <Divider />
            <span className="flex justify-center font-thin text-2xl">
              Información de contacto
            </span>
            <Divider content="Ubicación" />
          </div>
          <div className="col-span-full flex gap-x-4  items-center justify-center ">
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <Input
                  {...field}
                  isInvalid={!!errors.email}
                  errorMessage={errors.email?.message}
                  type="text"
                  autoFocus
                  labelPlacement="outside"
                  label="Correo electrónico"
                  placeholder="Introduce el correo electrónico de la compañía"
                  variant="bordered"
                />
              )}
            />
            <div className="flex flex-col  w-full  gap-2">
              <label htmlFor="code" className="text-sm">
                Telefono de contacto
              </label>
              <div className="flex items-center  w-full  gap-2">
                <Controller
                  name="code"
                  control={control}
                  render={({ field }) => (
                    <>
                      <div className="flex items-center">
                        <Autocomplete
                          {...field} // Pasa las propiedades del Controller
                          className="max-w"
                          variant="bordered"
                          defaultItems={codePhone}
                          placeholder="58"
                          isInvalid={!!errors.code}
                          errorMessage={errors.code?.message}
                          selectedKey={field.value}
                          onSelectionChange={(e) => field.onChange(e)}
                        >
                          {(item) => (
                            <AutocompleteItem key={item.phone_code}>
                              {item.phone_code + "/" + item.iso3_code}
                            </AutocompleteItem>
                          )}
                        </Autocomplete>
                      </div>
                    </>
                  )}
                />
                <Controller
                  control={control}
                  name="phone"
                  render={({ field: { onChange, value, onBlur } }) => (
                    <Input
                      isInvalid={!!errors.phone}
                      errorMessage={errors.phone?.message}
                      maxLength={10}
                      onChange={(e) => {
                        keyFilterNumber({
                          value: e,
                          onChange,
                        });
                      }}
                      onBlur={onBlur}
                      value={value}
                      autoFocus
                      placeholder="XXX XXXXXX"
                      variant="bordered"
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex flex-col  w-full  gap-2">
              <label htmlFor="codeWhats" className="text-sm">
                Telefono de contacto
              </label>
              <div className="flex items-center  w-full  gap-2">
                <Controller
                  name="codeWhats"
                  control={control}
                  render={({ field }) => (
                    <>
                      <div className="flex items-center">
                        <Autocomplete
                          {...field} // Pasa las propiedades del Controller
                          className="max-w"
                          variant="bordered"
                          defaultItems={codePhone}
                          placeholder="58"
                          isInvalid={!!errors.code}
                          errorMessage={errors.code?.message}
                          selectedKey={field.value}
                          onSelectionChange={(e) => field.onChange(e)}
                        >
                          {(item) => (
                            <AutocompleteItem key={item.phone_code}>
                              {item.phone_code + "/" + item.iso3_code}
                            </AutocompleteItem>
                          )}
                        </Autocomplete>
                      </div>
                    </>
                  )}
                />
                <Controller
                  control={control}
                  name="whatsapp_phone"
                  render={({ field: { onChange, value, onBlur } }) => (
                    <Input
                      isInvalid={!!errors.whatsapp_phone}
                      errorMessage={errors.whatsapp_phone?.message}
                      maxLength={10}
                      onChange={(e) => {
                        keyFilterNumber({
                          value: e,
                          onChange,
                        });
                      }}
                      onBlur={onBlur}
                      value={value}
                      autoFocus
                      placeholder="XXX XXXXXX"
                      variant="bordered"
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="col-span-full flex gap-3 items-center justify-center ">
            <div className="flex w-full items-center gap-2">
              <Controller
                name="location_state"
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      variant="bordered"
                      items={states}
                      labelPlacement="outside"
                      label="Estado"
                      selectedKeys={
                        field.value ? new Set([field.value]) : new Set()
                      }
                      isInvalid={!!errors.location_state}
                      errorMessage={errors.location_state?.message}
                      onChange={(e) => {
                        getParish(e.target.value);
                        field.onChange(e);
                      }}

                      // endContent={ <States reload={getStates} />}
                    >
                      {(type) => (
                        <SelectItem key={type.id} value={type.id}>
                          {type.name}
                        </SelectItem>
                      )}
                    </Select>
                  </>
                )}
              />
            </div>
            <div className="flex w-full items-center gap-2">
              <Controller
                name="location_parroquia"
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      variant="bordered"
                      items={parish}
                      labelPlacement="outside"
                      label="Parroquia"
                      selectedKeys={
                        field.value ? new Set([field.value]) : new Set()
                      }
                      isInvalid={!!errors.location_parroquia}
                      errorMessage={errors.location_parroquia?.message}
                      onChange={(e) => {
                        getSectors(e.target.value);
                        field.onChange(e);
                      }}
                      // endContent={ <Parish reload={getParish} />}
                    >
                      {(type) => (
                        <SelectItem key={type.id} value={type.id}>
                          {type.name}
                        </SelectItem>
                      )}
                    </Select>
                  </>
                )}
              />
            </div>
            <div className="flex w-full items-center gap-2">
              <Controller
                name="location_sector"
                control={control}
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      variant="bordered"
                      items={sector}
                      labelPlacement="outside"
                      label="Sectores"
                      selectedKeys={
                        field.value ? new Set([field.value]) : new Set()
                      } // Asegura que el servicio seleccionado aparezca
                      isInvalid={!!errors.location_sector}
                      errorMessage={errors.location_sector?.message}
                    >
                      {(type) => (
                        <SelectItem key={type.id} value={type.id}>
                          {type.name}
                        </SelectItem>
                      )}
                    </Select>
                  </>
                )}
              />
              {/* <Sector reload={getSectors} /> */}
            </div>
          </div>
          <div className="col-span-full">
            <Controller
              control={control}
              name="billing_address"
              render={({ field }) => (
                <Textarea
                  {...field}
                  isInvalid={!!errors.billing_address}
                  errorMessage={errors.billing_address?.message}
                  type="text"
                  labelPlacement="outside"
                  autoFocus
                  label="Dirección de facturación"
                  placeholder="Agregar la dirección de facturación"
                  variant="bordered"
                />
              )}
            />
          </div>
        </div>
      </>
    );
  }
);
