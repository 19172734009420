export class DevicesParams{
    // bank_destiny?:string
    // bank_origin?:string
    // company?:string |number
    // company_id?:string |number
    // method?:number
    page?:number
    since?:string
    search?:string
    remove_pagination?:boolean
    // status?:boolean|string
    // until?:string
}


export class DeviceServiceConfigParams{
    page?:number
    status?:boolean
    device?:number
    ordering?:string
    payment_method?:number
    service_config?:number
    remove_pagination?:boolean

}