import { useDispatch, useSelector } from "react-redux";
import { CardServices } from "./cardServices/CardServices";
import { AppDispatch, RootState } from "../../../../../store/store";
import { useEffect } from "react";
import { Loading } from "../../../../../components/Loading";
import { ServicesThunks } from "../../../../../store/services/thunks";
import { CreateServices } from "./createServices/CreateServices";
import { Button } from "@nextui-org/react";
import { IoMdRefresh } from "react-icons/io";
import { usePermissions } from "../../../../../context/PermissionContext";

export const ServicesCore = () => {
  const { services, count, isLoading } = useSelector(
    (state: RootState) => state.services
  );
  const { permissions } = usePermissions();
  const actionPermissionAdd =
    permissions && permissions.includes("action_create_services");
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(ServicesThunks());
  }, []);
  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center self-center mx-auto min-h-screen">
          <Loading />
        </div>
      ) : (
        <>
          <div className="animate-fade-up">
            <p>Aquí encontrarás información sobre nuestros servicios</p>

            <div className="flex justify-end items-center  space-x-3 ">
              {actionPermissionAdd && <CreateServices />}
              <Button
                color="primary"
                variant="bordered"
                onClick={() => dispatch(ServicesThunks())}
                size="md"
                className="shadow-lg"
                endContent={<IoMdRefresh className="h-7 w-7" />}
              >
                Actualizar
              </Button>
            </div>
            <div className="flex  justify-center items-center">
              <div className="grid grid-cols-1 xl:grid-cols-4 m-4 gap-6 ">
                {services && services.length > 0
                  ? services.map((service, index) => (
                      <CardServices {...service} key={index} />
                    ))
                  : null}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
