import { useDisclosure } from "@nextui-org/react";
import { useRef } from "react";
import { ModalComponent } from "../../../../../components/Modal/ModalComponent";
import { FormAnnulmentInvoice } from "../forms-invoices/components/annulment/FormAnnulmentInvoice";
import { annulmentInvoiceThunks } from "../../../../../../../store/finances/Thunks";
import { ErrorToast, SuccessToast } from "../../../../../../../libs/Notifications";

type Props = {
    data?: any;
    reload: () => void;
  };
  
  
  export const AnnulmentInvoice = ({ data, reload }: Props) => {
  
    const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  
  
    const formRef = useRef<() => void | null>(null);
  
  
  
  
  
    const handleFormSubmit = (formData: any) => {
        console.log("update data", formData);
  
        annulmentInvoiceThunks( data.id,formData)
            .then((result) => {
              SuccessToast("Factura Anulada correctamente");
                onClose();
                reload();
  
            })
            .catch(({ response }) => {
              if (response?.status === 400) {
                // Verificar si hay un mensaje de error en el cuerpo de la respuesta
                const errorMessage =  Object.values(response.data || {}).join(', ') || "Error desconocido";
                return ErrorToast(errorMessage);
              }
              ErrorToast("Hubo un error al anular la factura.");
                return;
            });
    };
  
    const handleSubmitClick = () => {
        if (formRef.current) {
            formRef.current();
        }
    };
  
    return (
        <>
            <span
                style={{
                    cursor: 'pointer',
                }}
                onClick={onOpen} // Abrir el modal al presionar el texto
            >
                Anular Factura
            </span>
            <ModalComponent
                header={`Anular Factura ${data.id}`}
                body={<FormAnnulmentInvoice data={data} onSubmit={handleFormSubmit} ref={formRef} />}
                handleSubmitClick={handleSubmitClick}
                onClose={onClose}
                isOpen={isOpen}
                onOpen={onOpen}
                onOpenChange={onOpenChange}
                title={""}
            />
        </>
    );
  }