import React from 'react';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure, Button } from '@nextui-org/react';
import { FaInfo } from 'react-icons/fa';
import { deleteInvoiceThunks } from '../../../../../../../store/finances/Thunks';
import { SuccessToast, ErrorToast } from '../../../../../../../libs/Notifications';

type Props = {
    data?: any;
    reload: () => void;
};

export const ActionDelete = ({ data, reload }: Props) => {
    const { isOpen, onOpen, onClose } = useDisclosure(); // Usar el hook useDisclosure

    const handleDelete = () => {
        deleteInvoiceThunks(data.id)
        .then((result) => {
          SuccessToast("Factura eliminada correctamente");
          reload();
          onClose();
        })
        .catch(({ response }) => {
          if (response?.status === 400) {
            // Verificar si hay un mensaje de error en el cuerpo de la respuesta
            const errorMessage =  Object.values(response.data || {}).join(', ') || "Error desconocido";
            return ErrorToast(errorMessage);
          }
    
          ErrorToast("Hubo un error al eliminar la Factura.");
        });
    };

    return (
        <>
            <span
                style={{
                    cursor: 'pointer',
                }}
                onClick={onOpen} // Abrir el modal al presionar el texto
            >
                Eliminar Factura
            </span>

            <Modal isOpen={isOpen} onOpenChange={onClose}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-row gap-4">
                                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <FaInfo
                                        className="h-6 w-6 text-orange-600"
                                        aria-hidden="true"
                                    />
                                </div>Confirmar Eliminación</ModalHeader>
                            <ModalBody>
                                <h2>{`¿Estás seguro de que deseas eliminar la Factura Nro ${data.id}?`}</h2>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary"  onPress={handleDelete}>
                                    Confirmar
                                </Button>
                                <Button color='danger' onPress={onClose}>
                                    Cancelar
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
};
