// store/deviceStore.ts
import { create } from "zustand";
import { Warehouse } from "../../../pages/core/interfaces/WarehouseInterfaces";

interface WarehouseState {
  warehouse: Warehouse[];
  count: number;
  setWarehouse: (warehouse: Warehouse[], count: number) => void;
  reset: () => void;
}

export const useStoreWarehouseCore = create<WarehouseState>((set) => ({
    warehouse: [],
  count: 0,
  setWarehouse: (warehouse: Warehouse[], count: number) =>
    set(() => ({ warehouse, count })),
  reset: () => set(() => ({ warehouse: [], count: 0 })),
}));
