// @flow
import * as React from "react";
import { usePermissions } from "../../../../../../../context/PermissionContext";
import { Button } from "@nextui-org/react";
import { HiArrowPath } from "react-icons/hi2";
import { HiDownload } from "react-icons/hi";
import { useAllParams } from "../../../../../../../hooks/useAllParams";
import { SearchCompanies } from "../../../../companies/all/components/SearchCompanies";
import { queryOptions, useQueryClient } from "@tanstack/react-query";
import { getPaymentReportRequest } from "../../../../../../../store/payment/thunks";
import { saveAs } from "file-saver";
import { useParams } from "react-router-dom";
import { useUserMovilPay } from "../../../../../../../hooks/useUserMovilPay";
import { ErrorToast } from "../../../../../../../libs/Notifications";
type Props = {
  report?: () => void;
};
export const ButtonReportPayments = () => {
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { company } = useUserMovilPay();
  const { permissions } = usePermissions();
  const { params } = useAllParams();
  const actionPermissionAdd =
    permissions && permissions.includes("action_download_report");
  const handleDownloadExcel = async () => {
    try {
      const option = id ? id : company;
      const { page, ...restParams } = params;
      const payload = { ...restParams, report: "true", company: option };
      const response = await queryClient.fetchQuery(
        queryOptions({
          queryKey: ["reportPayments"],
          queryFn: () => getPaymentReportRequest(payload),
          retry: false,
        })
      );
      const blob = await response.data;
      // Usar FileSaver para descargar el archivo
      saveAs(blob, "CierreOficina.xlsx");
    } catch (error) {
      ErrorToast("Error al descargar el archivo");
    }
  };

  return (
    <div>
      <Button
        isDisabled={!(params.since && params.until)}
        variant="bordered"
        endContent={<HiDownload className="h-6 w-6" />}
        onClick={handleDownloadExcel}
        color="primary"
      >
        Reporte
      </Button>
    </div>
  );
};
