import { Button, menu } from "@nextui-org/react";
import { AddPermission } from "./components/AddPermissions";
import { TablePermissions } from "./components/TablePermissions";
import { IoIosRefresh } from "react-icons/io";
import { AppDispatch, RootState } from "../../../../../store/store";
import { useDispatch, useSelector, useStore } from "react-redux";
import {
  CustomerUserService,
  GetCustomerUserService,
} from "../../../../../store/settings/CustomerGroups/thunk";
import { useEffect } from "react";
import { useAllParams } from "../../../../../hooks/useAllParams";
import { UserParams } from "../../../params/settings/userParams";
import { usePermissions } from "../../../../../context/PermissionContext";
import { useQueryClient, queryOptions } from "@tanstack/react-query";
import { GetServicesCompaniesThunks } from "../../../../../store/companies/servicesCompany/thunks";
import { useStoreCustomerGroup } from "../../../../../store/settings/CustomerGroups/useStoreCustomerGroups";
import { IoBanOutline } from "react-icons/io5";
import { GetMenuThunks } from "../../../../../store/menu/tunks";
import { useStoreMenu } from "../../../../../store/menu/useStoreMenu";

export const PermissionsCore = () => {
  const { params, deleteParams, setSearchParams } = useAllParams();
  const { permissions, loading } = usePermissions();
  const actionPermissionAdd =
    permissions && permissions.includes("action_create_group");
  const { setCustomerGroup, resetCustomerGroup, count } = useStoreCustomerGroup(
    (state) => state
  );
  const {setMenuItem, menuItem} = useStoreMenu((state) => state);
  const queryClient = useQueryClient();
  const handleConsultation = () => {
    const param = new UserParams();
    params.page && (param.page = Number(params.page));
    params.search && (param.search = params.search);

    queryClient.fetchQuery(
      queryOptions({
        queryKey: ["serviceCompany"],
        queryFn: () =>
          GetCustomerUserService(param).then((d) => {
            setCustomerGroup(d.data.results, d.data.count);
          }),
        retry: false,
      })
    );
  };
  const handleGetMenu = () => {
    const param = new UserParams();
    param.remove_pagination = true;
    queryClient.fetchQuery(
      queryOptions({
        queryKey: ["serviceCompany"],
        queryFn: () =>
          GetMenuThunks(param).then((d) => {
            setMenuItem(d.data.results);
          }),
        retry: false,
      })
    );
  };
  useEffect(() => {
    handleGetMenu()
    handleConsultation();
  }, []);

  return (
    <>
      <div className="space-y-7 animate-fade-up">
        <div className="lg:flex lg:justify-between lg:items-center">
          <div>
            <h2 className="text-2xl font-bold">Permisos de usuarios</h2>
            <p>Gestiona los permisos de tus usuarios.</p>
          </div>
          <div className="flex space-x-3 justify-between">
           {actionPermissionAdd && <AddPermission />}
            <Button
              size="md"
              variant="ghost"
              color="default"
              className="hover:text-white shadow-lg"
              endContent={<IoIosRefresh className="h-5 w-5" />}
              onClick={handleConsultation}
            >
              Actualizar
            </Button>
          </div>
        </div>
        {count > 0 ? (
          <TablePermissions />
        ) : (
          <div className="text-center">
            <IoBanOutline className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-xl font-semibold text-gray-500">
              No hay resultado
            </h3>
          </div>
        )}
      </div>
    </>
  );
};
