import React from 'react';
import { MdOutlinePayments } from 'react-icons/md';
import useStorePaymentInvoice from '../../../../../../../../store/finances/useStorePaymentInvoice';
import usePaymentMethodStore from '../../../../../../../../store/finances/useStorePaymentMethods';
import { useNavigate } from 'react-router-dom';
import { paymentInvoicesThunks } from '../../../../../../../../store/finances/PaymentInvoiceThunsks';
import { ErrorToast, SuccessToast } from '../../../../../../../../libs/Notifications';

export const TotalInvoice = () => {
    const { total, iva, subtotal, totalBs, invoices, clearInvoices, balance } = useStorePaymentInvoice();
    const { totalM, totalMBs, paymentMethods,clearPaymentMethods } = usePaymentMethodStore();
    const navigate = useNavigate();
    const path =   localStorage.getItem("lastPath");

  
    const paymentInvoice = async () => {
        const payments = paymentMethods.map((method) => ({
            date:method.date, // formato YYYY-MM-DD
            method: method.method, // asumir que el método de pago está representado por 'id'
            amount: method.amount.toString(),
            payment_company: method.id != 0 ? method.id : null, 
        }));

        const payments_invoices = invoices.map((invoice) => ({
            invoice: invoice.id,
        }));

        const data = {
            payments,
            payments_invoices,
        };

        paymentInvoicesThunks(data).then(() => {
             clearInvoices();
             clearPaymentMethods();
            SuccessToast("Factura(s) pagada(s) con exito.");
            navigate(-1);
          }).catch(({ response }) => {
            if (response?.status === 400) {
              const errorMessage =
                Object.keys(response.data || {})
                  .join(", ")
                  .concat(": " + Object.values(response.data || {}).join(", ")) ||
                Object.values(response.data || {}).join(", ") ||
                "Error desconocido";
              return ErrorToast(errorMessage);
            }
          });
    };

    return (
        <div className="space-y-4 rounded-lg border border-gray-100 bg-gray-50 p-6 dark:border-gray-700 dark:bg-gray-800">
            <h3 className="text-lg font-bold">Detalles del pago</h3>

            <div className="space-y-2 mt-4">
                <dl className="flex items-center justify-between gap-4">
                    <dt className="text-base font-normal text-gray-500 dark:text-gray-400">USD Abonados</dt>
                    <dd className="text-base font-medium text-green-900 dark:text-white">${totalM.toFixed(2)}</dd>
                </dl>
                <dl className="flex items-center justify-between gap-4">
                    <dt className="text-base font-normal text-gray-500 dark:text-gray-400">BS Abonados</dt>
                    <dd className="text-base font-medium text-green-900 dark:text-white">Bs.{totalMBs.toFixed(2)}</dd>
                </dl>
                <dl className="flex items-center justify-between gap-4">
                    <dt className="text-base font-normal text-gray-500 dark:text-gray-400">Saldo a Favor</dt>
                    <dd className="text-base font-medium text-green-900 dark:text-white">${balance.toFixed(2)}</dd>
                </dl>
                <dl className="flex items-center justify-between gap-4">
                    <dt className="text-base font-normal text-red-900 dark:text-gray-400">USD Restantes</dt>
                    <dd className="text-base font-medium text-red-900 dark:text-white">${(total - totalM).toFixed(2)}</dd>
                </dl>
                <dl className="flex items-center justify-between gap-4">
                    <dt className="text-base font-normal text-red-900 dark:text-gray-400">BS Restantes</dt>
                    <dd className="text-base font-medium text-red-900 dark:text-white">Bs.{(totalBs - totalMBs).toFixed(2)}</dd>
                </dl>
            </div>
            <hr className="my-4 border-t border-gray-300 dark:border-gray-600" />

            <div className="space-y-2 mt-4">
                <dl className="flex items-center justify-between gap-4">
                    <dt className="text-base font-normal text-gray-500 dark:text-gray-400">Subtotal</dt>
                    <dd className="text-base font-medium text-gray-900 dark:text-white">${subtotal.toFixed(2)}</dd>
                </dl>
                <dl className="flex items-center justify-between gap-4">
                    <dt className="text-base font-normal text-gray-500 dark:text-gray-400">IVA</dt>
                    <dd className="text-base font-medium text-gray-900">${iva.toFixed(2)}</dd>
                </dl>
                <hr className="my-4 border-t border-gray-300 dark:border-gray-600" />

                <dl className="flex items-center justify-between gap-4">
                    <dt className="text-base font-normal text-gray-500 dark:text-gray-400">Total USD</dt>
                    <dd className="text-base font-medium text-green-900 dark:text-white">${total.toFixed(2)}</dd>
                </dl>
                <dl className="flex items-center justify-between gap-4">
                    <dt className="text-base font-normal text-gray-500 dark:text-gray-400">Total BS</dt>
                    <dd className="text-base font-medium text-green-900 dark:text-white">Bs.{totalBs.toFixed(2)}</dd>
                </dl>
            </div>

            {paymentMethods.length > 0 && (
                <div className="flex justify-end mt-4">
                    <button
                        onClick={paymentInvoice}
                        className="h-12 bg-primary text-white flex items-center p-2 rounded-xl hover:bg-secondary transition"
                    >
                        <MdOutlinePayments className="text-2xl text-white mr-2" />
                        Pagar Factura(s)
                    </button>
                </div>
            )}
        </div>
    );
};
