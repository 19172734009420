import { createSlice } from "@reduxjs/toolkit";
import { OccupationsThunks } from "./thunks";


export interface initialStateOccupations {
  isLoading: boolean;
  occupations:any[];
  count:number 
  page?:number
}

export const initialStateOccupations: initialStateOccupations = {
  isLoading: false,
  count: 0,
  occupations:[]
};

export const OcupationSlice = createSlice({
  name: "occupations",
  initialState: initialStateOccupations,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(OccupationsThunks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(OccupationsThunks.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.occupations = payload.results  || payload
        state.count = payload.count
      });
  },
});

export const {} = OcupationSlice.actions;