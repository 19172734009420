// @flow 

import * as React from "react";
import { Outlet } from "react-router-dom";
type Props = {};
const AccountsCore = (props: Props) => {
  return (
    <>
      <Outlet />
    </>
  );
};
export default AccountsCore;
