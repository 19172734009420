import { create } from "zustand";
import { Company } from "../../pages/core/interfaces/CompaniesInterfaces";

interface CompaniesAllState {
    companieAll: Company[];
    count: number;
    setCompanieAll: (companieAll: Company[], count: number) => void;
    resetCompanieAll: () => void;
  }
  export const useStoreCompanieAll = create<CompaniesAllState>((set) => ({
    companieAll: [],
    count: 0,
    setCompanieAll: (companieAll: Company[], count: number) => {
      set({ companieAll, count });
    },
    resetCompanieAll: () => {
      set({ companieAll: [], count: 0 });
    },
  }));