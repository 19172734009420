import { createSlice } from "@reduxjs/toolkit";
import { ServicesCompaniesIDThunks, ServicesCompaniesThunks } from "./thunks";
import { ServicesCompany } from "../../../pages/core/interfaces/ServicesCompany";
import { count } from "console";

export interface initialStateService {
  isLoading: boolean;
  servicesCompany:ServicesCompany[];
  page?:number
  count: number; // total number of items in the list, obtained from the API response
}

export const initialStateServices: initialStateService = {
  isLoading: false,
  servicesCompany:[],
  count: 0  // total number of items in the list, obtained from the API response
};

export const ServicesCompanySlice = createSlice({
  name: "servicesCompany",
  initialState: initialStateServices,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ServicesCompaniesIDThunks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ServicesCompaniesIDThunks.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.servicesCompany = payload
        state.count = payload.length;
      });
  },
});

export const {} = ServicesCompanySlice.actions;
