// @flow
import { createSlice } from "@reduxjs/toolkit";
import { dollarServices } from "./thunks";
export interface Dollar {
  date: string;
  currency: number;
  amount: string;
  status: boolean;
}
export interface initialStateDollar {
  dollar: Dollar[];
  isLoading: boolean;
}
export const InitialStateDollar: initialStateDollar = {
  dollar: [],
  isLoading: false,
};

export const DollarSlice = createSlice({
  name: "dollar",
  initialState: InitialStateDollar,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(dollarServices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(dollarServices.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.dollar = payload;
      });
  },
});

export const {} = DollarSlice.actions
