import { useDisclosure, Button } from "@nextui-org/react";
import { useRef } from "react";
import {
  MdOutlineAppSettingsAlt,
  MdOutlineDisabledVisible,
} from "react-icons/md";
import { LuFileEdit } from "react-icons/lu";

import { ModalComponent } from "../../../../components/Modal/ModalComponent";
import { FormWarehouse } from "./forms/FormWarehouse";
import { ErrorToast, SuccessToast } from "../../../../../../libs/Notifications";
import {
  DeleteWarehouseThunks,
  UnpairDeviceWarehouseThunks,
  UpdateWarehouseThunks,
} from "../../../../../../store/managements/warehouse/thunks";
import { PopoverConfirm } from "../../../../components/Popover/PopoverConfirm";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FcTimeline } from "react-icons/fc";
import TimeLineWarehouse from "./TimeLine";
import { FormConfigDevice } from "./config/FormConfigDevice";
import { formatDate } from "../../../../../../utils/formatDate";
import { FaRegTrashCan } from "react-icons/fa6";
import { BiTimer } from "react-icons/bi";

type Props = {
  data?: any;
  reload: () => void;
};

export const Action = ({ data, reload }: Props) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  const formRef = useRef<() => void | null>(null);

  
  const handleFormSubmit = (formData: any) => {
    let body;
    if (formData.bank && formData.bank!=0) {
      body= formData
    } else{
      body = { ...formData, bank: null }
    }
    UpdateWarehouseThunks(body, data.id)
      .then((result) => {
        SuccessToast("Dispositivo actualizado correctamente");
        onClose();
        reload();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          // Verificar si hay un mensaje de error en el cuerpo de la respuesta
          const errorMessage =  Object.values(response.data || {}).join(', ') || "Error desconocido";
          return ErrorToast(errorMessage);
        }
      });
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current();
    }
  };

  return (
    <>
      <Button
        isIconOnly
        variant="light"
        color="primary"
        size="sm"
        onPress={onOpen}
      >
        <LuFileEdit  className={`font-semibold h-6 w-6 `} />
      </Button>
      <ModalComponent
        header={`Editar datos del dispositivo ${data.device_type_name}`}
        body={
          <FormWarehouse
            data={data}
            onSubmit={handleFormSubmit}
            ref={formRef}
          />
        }
        handleSubmitClick={handleSubmitClick}
        onClose={onClose}
        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        title={""}
      />
    </>
  );
};


export const ActionDelete = ({ data, reload }: Props) => {

  const handleDelete = () => {

    DeleteWarehouseThunks(data.id)
      .then((result) => {
        SuccessToast("Dispositivo eliminado correctamente");
        reload();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          // Verificar si hay un mensaje de error en el cuerpo de la respuesta
          const errorMessage =  Object.values(response.data || {}).join(', ') || "Error desconocido";
          return ErrorToast(errorMessage);
        }
      });
  };

  return (
    <PopoverConfirm
      title="Confirmar Eliminación"
      message="¿Estás seguro de que deseas eliminar este elemento?"
      confirm={handleDelete} // Esta función se ejecutará al confirmar
      item={undefined}
    >
      <Button isIconOnly variant="light" color="danger" size="sm">
        <FaRegTrashCan className="font-semibold h-6 w-6" />
      </Button>
    </PopoverConfirm>
  );
};

export const ActionEnable = ({ data, reload }: Props) => {
  // Función para manejar el cambio de estado
  const handleToggleStatus = () => {

    const body = { status: !data.status };

    UpdateWarehouseThunks(body, data.id)
      .then((result) => {
        SuccessToast("Dispositivo actualizado correctamente");
        reload();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          // Verificar si hay un mensaje de error en el cuerpo de la respuesta
          const errorMessage =  Object.values(response.data || {}).join(', ') || "Error desconocido";
          return ErrorToast(errorMessage);
        }
      });
  };

  return (
    <PopoverConfirm
      title="Confirmar Cambio de Estatus"
      message="¿Estás seguro de que deseas cambiar el estatus?"
      confirm={handleToggleStatus} // Cambiar el estado según el estado actual
      item={undefined}
    >
      {data.status ? (
        <Button isIconOnly variant="light" color="danger" size="sm">
          <MdOutlineDisabledVisible className="font-semibold h-6 w-6" />
        </Button>
      ) : (
        <Button isIconOnly variant="light" color="success" size="sm">
          <IoMdCheckmarkCircleOutline className="font-semibold h-6 w-6" />
        </Button>
      )}
    </PopoverConfirm>
  );
};

export const ActionConfig = ({ data, reload }: Props) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const formRef = useRef<() => void | null>(null);

  const handleConfig = (formData: any) => {
    const body = {
      status: false,
      date_end: formatDate(new Date()),
      date_end_detail: formData.detail,
    };

    UnpairDeviceWarehouseThunks(body, data.available_device_service_id)
      .then((result) => {
        SuccessToast("Dispositivo desvinculado correctamente");
        onClose();
        reload();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          // Verificar si hay un mensaje de error en el cuerpo de la respuesta
          const errorMessage =  Object.values(response.data || {}).join(', ') || "Error desconocido";
          return ErrorToast(errorMessage);
        }
      });
  };

  const handleConfigClick = () => {
    if (formRef.current) {
      formRef.current();
    }
  };

  return (
    <>
      <Button
        isIconOnly
        variant="light"
        color="default"
        size="sm"
        onPress={onOpen}
      >
        <MdOutlineAppSettingsAlt className="font-semibold h-6 w-6" />
      </Button>
      <ModalComponent
        header={`Desvincular Equipo`}
        body={
          <FormConfigDevice data={data} onSubmit={handleConfig} ref={formRef} />
        }
        handleSubmitClick={handleConfigClick}
        onClose={onClose}        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        title={""}
      />
    </>
  );
};

export const TimeLine = (data: any) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  return (
    <>
      <Button isIconOnly variant="light" size="sm" color="primary" onPress={onOpen}>
        <BiTimer className="font-semibold h-7 w-7" />
      </Button>
      <ModalComponent
        header={`Linea de Tiempo`}
        body={<TimeLineWarehouse item={data} />}
        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        title={""}
      />
    </>
  );
};
