// Importar las dependencias necesarias
import { useSelector } from "react-redux";
import { TableLayout, valueType } from "../../../../layout/TableLayout";
import { useStoreRates } from "../../../../../../store/rates/useStoreRates";
import { PaginatorTable } from "../../../../components/table/PaginatorTable";
import { useEffect, useState } from "react";
import { queryOptions, useQueryClient } from "@tanstack/react-query";
import { RatesParams } from "../../../../params/rates/ratesParams";
import { getRates } from "../../../../../../store/rates/Thunks";
import { useAllParams } from "../../../../../../hooks/useAllParams";

type Props = {
    data: any;
  };


export const TableServicesRates = ({data}:Props) => {
    const [rates, setRates] = useState([])
    const [count, setcount] = useState([])
    const { params, deleteParams, setSearchParams } = useAllParams();
    const queryClient = useQueryClient();

    const handleConsultation = () => {
        const param = new RatesParams();
        if (params.page) param.page = Number(params.page);
        param.service = data.id
        queryClient.fetchQuery(
            queryOptions({
                queryKey: ["rates"],
                queryFn: () =>
                    getRates(param).then((d) => {
                        setRates(d.data.results);
                        setcount( d.data.count);
                    }),
                retry: false,
            })
        );
    };
    
    useEffect(() => {
        handleConsultation();
    }, [params]); // Add filterUsed to the dependencies array

  const dataTable: valueType[] = [
    { name: "ID", value: "id" },
    { name: "servicio", value: "service_name" },
    { name: "min", value: "min" },
    { name: "maximo", value: "max" },
    { name: "precio", value: "price" },
  ];

  return (
    <div className="space-y-7">
      <div>
        {/* {count > 0 && ( */}
          <TableLayout
            isLoading={false}
            data={rates} // Usar datos de dispositivos
            columns={dataTable}
            Paginator={<PaginatorTable count={rates.length} />}
          />
        {/* )} */}
      </div>
    </div>
  );
};
