import React from "react";
import { FcConferenceCall, FcDonate, FcMoneyTransfer } from "react-icons/fc";
import { MdOutlineManageAccounts } from "react-icons/md";
import { GoPasskeyFill } from "react-icons/go";
import { AiOutlineBank } from "react-icons/ai";
import { HiOutlineReceiptTax } from "react-icons/hi";

export const settingRouters = [
  {
    name: "Servicios",
    path: "setting/services/",
    icon: FcDonate,
    show: true,
    children: null,
  },
  {
    name: "Usuarios",
    path: "setting/users/",
    icon: FcConferenceCall,
    show: true,
    children: null,
  },
  {
    name: "Cuentas Bancarias",
    path: "setting/banks/",
    icon: AiOutlineBank,
    show: true,
    children: null,
  },
  {
    name: "Cuenta",
    path: "setting/account/",
    icon: MdOutlineManageAccounts,
    show: true,
    children: null,
  },
  {
    name: "Gestion de Permisos",
    path: "setting/permissions/",
    icon: GoPasskeyFill,
    show: true,
    children: null,
  },
  {
    name: "Tarifas",
    path: "setting/rates/",
    icon: FcMoneyTransfer,
    show: true,
    children: null,
  },
  {
    name: "Retenciones",
    path: "setting/retentions/",
    icon: HiOutlineReceiptTax,
  },
  {
    name: "Ubicaciones",
    path: "setting/ubications/",
    icon: FcMoneyTransfer,
    show: true,
    children: null,
  },
];
