import { Button, useDisclosure } from "@nextui-org/react";
import * as React from "react";
import { LuFileEdit } from "react-icons/lu";
import { ModalComponent } from "../../../../components/Modal/ModalComponent";
import { data } from "../../../finances/payments/components/ChartPiePayments";
import { FormUser } from "../../usuarios/components/FormUser";
import { ErrorToast, LoadingToast } from "../../../../../../libs/Notifications";
import { PatchParishThunks } from "../../../../../../store/location/Parish/thunks";
import { FormParish } from "../../../../components/location/FormParish";
type Props = {
  reload: () => void;
  data?: any;
};
export const EditParish = ({ data, reload }: Props) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const formRef = React.useRef<() => void | null>(null);
  const handleFormSubmit = (formData: any) => {
    const { success, error } = LoadingToast();
    const body = {
      ...formData,
    };
    PatchParishThunks(body, Number(data?.id))
      .then((results) => {
        onClose();
        success("Datos actualizados correctamente");
        reload();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          const errorMessage =
            Object.keys(response.data || {})
              .join(", ")
              .concat(": " + Object.values(response.data || {}).join(", ")) ||
            Object.values(response.data || {}).join(", ") ||
            "Error desconocido";
          return ErrorToast(errorMessage);
        }
      });
  };
  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current();
    }
  };
  return (
    <>
      <Button
        isIconOnly
        variant="light"
        color="primary"
        size="sm"
        onPress={onOpen}
      >
        <LuFileEdit className={`font-semibold h-6 w-6 `} />
      </Button>
      <ModalComponent
        header={"Editar parroquia"}
        body={
          <FormParish data={data} onSubmit={handleFormSubmit} ref={formRef} />
        }
        handleSubmitClick={handleSubmitClick}
        onClose={onClose}
        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        title={""}
      />
    </>
  );
};
export const DeleteParish = (props: Props) => {
  return <div></div>;
};
