import { create } from "zustand";
import { MenuItem } from "../../pages/core/params/permissions/Menu";

interface MenuState {
  menuItem: MenuItem[];
  setMenuItem: (menuItem: MenuItem[]) => void;
  resetMenuItem: () => void;
}
export const useStoreMenu = create<MenuState>((set) => ({
  menuItem: [],
  setMenuItem: (menuItem: MenuItem[]) => {
    set({ menuItem });
  },
  resetMenuItem: () => {
    set({ menuItem: [] });
  },
}));
