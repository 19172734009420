// @flow
import * as React from "react";
import { PaymentMethodsCompany } from "../../../../interfaces/CompaniesInterfaces";
import { useDisclosure, Button } from "@nextui-org/react";
import { ModalComponent } from "../../../../components/Modal/ModalComponent";
import { FaRegTrashCan } from "react-icons/fa6";
import {
  FormMovilPayComponents,
  FormTransferComponents,
} from "../../../../components/methodPayments";
import { FormZelleComponents } from "../../../../components/methodPayments/FormZelleComponents";
import { FormInmediateDebit } from "../../../../components/methodPayments/FormInmediateDebit";
import { ErrorToast, SuccessToast } from "../../../../../../libs/Notifications";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../store/store";
import { FormMovilPay } from "./FomsAccountAffiliate/FormMovilPay";
import { FormZelle } from "./FomsAccountAffiliate/FormZelle";
import { FormTransference } from "./FomsAccountAffiliate/FormTransference";
import {
  PatchAccountAfiliateThunks,
  DeleteIdAccountAfiliateThunks,
} from "../../../../../../store/companies/AccountAfiliate/thunks";
import { PopoverConfirm } from "../../../../components/Popover/PopoverConfirm";
import { LuFileEdit } from "react-icons/lu";
import { FaRegTrashAlt } from "react-icons/fa";
type Props = {
  methods: PaymentMethodsCompany | any;
  reload?: () => void;
};

export interface PaymentMethod {
  id: number;
  component: JSX.Element | any;
}
let paymentsMethods: PaymentMethod[] = [
  {
    id: 1,
    component: FormMovilPay,
  },
  {
    id: 2,
    component: FormTransference,
  },
  {
    id: 3,
    component: FormZelle,
  },
];
export const EditPaymentsMethods = ({ methods, reload }: Props) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const assignIcon = paymentsMethods.filter((p) => p.id === methods.method)[0];

  const formRef = React.useRef<() => void | null>(null);

  const handleFormSubmit = (formData: any) => {
    console.log(methods.method)
    const body = {
      ...methods,
      ...formData,
      sender: methods.method == 1 ? formData.code.concat(formData.sender): formData.sender,

    };
    console.log(body);
    PatchAccountAfiliateThunks(body, methods.id)
      .then((result) => {
        SuccessToast("Actuliazado correctamente");
        reload && reload();
        onClose();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          const errorMessage =
            Object.keys(response.data || {})
              .join(", ")
              .concat(": " + Object.values(response.data || {}).join(", ")) ||
            Object.values(response.data || {}).join(", ") ||
            "Error desconocido";
          return ErrorToast(errorMessage);
        }
      });
    return;
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current();
    }
  };
  return (
    <>
      <Button
        size="sm"
        color="primary"
        radius="full"
        isIconOnly
        variant="light"
        onPress={onOpen}
      >
        <LuFileEdit  className=" p-1 h-7 w-7" />
      </Button>
      <ModalComponent
        header={"Editar cuenta"}
        body={
          <>
            <assignIcon.component
              onSubmit={handleFormSubmit}
              ref={formRef}
              data={methods}
            />
          </>
        }
        handleSubmitClick={handleSubmitClick}
        onClose={onClose}
        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        title={""}
      />
    </>
  );
};

export const DeleteAccountAfiliate = ({ methods, reload }: Props) => {
  const handleDelete = () => {
    DeleteIdAccountAfiliateThunks(methods.id)
      .then((results) => {
        SuccessToast("Cuenta eliminada correctamente");
        reload && reload();
      })
      .catch(({response}) => {
        if (response?.status === 400) {
          const errorMessage =
            Object.keys(response.data || {})
              .join(", ")
              .concat(": " + Object.values(response.data || {}).join(", ")) ||
            Object.values(response.data || {}).join(", ") ||
            "Error desconocido";
          return ErrorToast(errorMessage);
        }
      });
  };
  return (
    <PopoverConfirm
      title="Confirmar  de eliminación"
      message={`¿Estás seguro que deseas ELIMINAR esta cuenta?`}
      confirm={handleDelete} // Esta función se ejecutará al confirmar
      item={undefined}
    >
      <Button
        size="sm"
        color="danger"
        radius="full"
        isIconOnly
        variant="light"
        
      >
        <FaRegTrashAlt   className="font-semibold h-6 w-6" />
      </Button>
    </PopoverConfirm>
  )
};
