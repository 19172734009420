// @flow
import * as React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  Divider,
  Tooltip,
} from "@nextui-org/react";

import { FormFinishDevice } from "./FormFinishDevice";
import {
  ErrorToast,
  SuccessToast,
} from "../../../../../../../libs/Notifications";
import { AiOutlineHistory } from "react-icons/ai";
import { FaUnlink } from "react-icons/fa";
import { ModalComponent } from "../../../../../components/Modal/ModalComponent";
import { FormServicesConfig } from "./FormServicesConfig";
import { Link, useParams } from "react-router-dom";
import { DevicesServiceConfig } from "../../../../../interfaces/DevicesServicesConfig";
import { GetIdPaymentMethodCompaniesThunks } from "../../../../../../../store/companies/PaymentMethodCompany/thunks";
import { MethodPaymentCompany } from "../../../../../interfaces/PaymentInterfaces";
import { DevicesUpdatServiceCompanyThunks } from "../../../../../../../store/companies/DevicesCompany/thunks";
import { useStoreServiceCompanyCore } from "../../../../../../../store/companies/servicesCompany/useStoreServiceCompanyCore";
import { LuFileEdit } from "react-icons/lu";
import { GrAction } from "react-icons/gr";

type Props = {
  services: DevicesServiceConfig;
  reload?: () => void;
};

export const UnlinkDevice = ({ services, reload }: Props) => {
  const { isOpen, onOpenChange, onClose, onOpen } = useDisclosure();
  const formRef = React.useRef<() => void | null>(null);
  const handleFormSubmit = (formData: any) => {
    const newDate = new Date().toISOString().split("T")[0];
    const body = {
      ...formData,
      date_end: newDate,
      status: false,
    };
    DevicesUpdatServiceCompanyThunks(body, services.id)
      .then((result) => {
        onClose();
        SuccessToast("Dispositivo finalizado correctamente");
        reload && reload();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          const errorMessage =
            Object.keys(response.data || {})
              .join(", ")
              .concat(": " + Object.values(response.data || {}).join(", ")) ||
            Object.values(response.data || {}).join(", ") ||
            "Error desconocido";
          return ErrorToast(errorMessage);
        }
      });
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current();
    }
  };
  return (
    <Popover
    className="z-1"
      backdrop="opaque"
      showArrow
      placement="bottom"
      crossOffset={1}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <PopoverTrigger>
        <Button
          variant="light"
          color="danger"
          radius="full"
          isIconOnly
          onPress={onOpen}
        >
          <GrAction className=" h-6 w-6" />{" "}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-3">
        <Card shadow="none" className=" border-none bg-transparent">
          <CardHeader className="flex w-full justify-between ">
            <h4 className="font-bold text-large">Finalizar el dispositivo</h4>
          </CardHeader>
          <Divider />

          <CardBody className="w-full">
            <FormFinishDevice
              devices={services}
              onSubmit={handleFormSubmit}
              ref={formRef}
            />
          </CardBody>
          <CardFooter>
            <div className="flex  gap-x-2">
              <Button
                type="submit"
                onClick={handleSubmitClick}
                color="primary"
                className="mt-3 inline-flex w-full justify-center px-3 py-2 text-sm font-semibold sm:mt-0 sm:w-auto"
              >
                Guardar
              </Button>
            </div>
          </CardFooter>
        </Card>
      </PopoverContent>
    </Popover>
  );
};

export const HistoryDataServices = ({ services }: Props) => {
  const { isOpen, onOpenChange, onClose } = useDisclosure();
  const { id, item } = useParams();

  return (
    <>
      <Popover
        backdrop="blur"
        showArrow
        placement="bottom"
        offset={10}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <PopoverTrigger>
          <Button
            color="primary"
            className="shadow-lg"
            radius="full"
            isIconOnly
          >
            <Link to={`../history/${item}/${services.id}`}>
              <AiOutlineHistory className=" p-1 h-7 w-7" />{" "}
            </Link>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-3">
          <div></div>
        </PopoverContent>
      </Popover>
    </>
  );
};
export const EditDataDevice = ({ services, reload }: Props) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const formRef = React.useRef<() => void | null>(null);
  const { serviceCompany } = useStoreServiceCompanyCore((state) => state);
  const handleFormSubmit = (formData: any) => {
   const service = serviceCompany.filter(
      (sc) => sc.id === Number(formData.service)
    )[0]?.service;
    // console.log(formData, 'formData')
    const body = {
      ...formData,

    };
    DevicesUpdatServiceCompanyThunks(body, services.id)
      .then((result) => {
        reload && reload();
        SuccessToast("Actualizado correctamente");
        onClose();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          const errorMessage =
            Object.keys(response.data || {})
              .join(", ")
              .concat(": " + Object.values(response.data || {}).join(", ")) ||
            Object.values(response.data || {}).join(", ") ||
            "Error desconocido";
          return ErrorToast(errorMessage);
        }
      });
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current();
    }
  };

  return (
    <>
      <Tooltip showArrow={true} content="Editar el dispositivo">
        <Button
          color="primary"
          variant="light"
          radius="full"
          isIconOnly
          onPress={onOpen}
        >
          <LuFileEdit className="  h-7 w-7" />{" "}
        </Button>
      </Tooltip>
      <ModalComponent
        header={"Editar  el servicio"}
        body={
          <FormServicesConfig
            onSubmit={handleFormSubmit}
            data={services}
            ref={formRef}
          />
        }
        handleSubmitClick={handleSubmitClick}
        onClose={onClose}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        title={""}
        isOpen={isOpen}
      />
    </>
  );
};

export const FinishDetail = ({ services }: Props) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  return (
    <Popover
      backdrop="blur"
      showArrow
      radius="lg"
      placement="bottom"
      offset={10}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <PopoverTrigger>
        <Button color="danger" size="sm" className="shadow-lg">
          {services.date_end}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-3">
        <div className="px-1 py-2 gap-4 space-y-2">
          <div className="text-small font-bold">Detalles de Finalización</div>
          <div className="text-tiny">{services.date_end_detail}</div>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export const MerchantDetail = ({ services }: Props) => {
  const { isOpen, onOpenChange } = useDisclosure();
  const [serviceMP, setServiceMP] = React.useState<MethodPaymentCompany>();
  const handleConsultation = () => {
    GetIdPaymentMethodCompaniesThunks(services.payment_method_cs)
      .then((data) => {
        setServiceMP(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  React.useEffect(() => {
    isOpen && handleConsultation();
  }, [onOpenChange]);
  return (
    <Popover
      backdrop="blur"
      showArrow
      placement="bottom"
      offset={10}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <PopoverTrigger>
        <Button
          size="sm"
          color="primary"
          className="shadow-lg font-semibold"
          radius="lg"
        >
          {services.device_bank_name}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-3">
        <Card
          shadow="none"
          className="max-w-[300px] border-none bg-transparent"
        >
          <CardHeader className="justify-between">
            <div className="flex gap-3">
              <div className="flex flex-col items-center justify-center">
                <h4 className="text-small font-semibold leading-none text-default-600">
                  {serviceMP?.payment_method_name}
                </h4>
              </div>
            </div>
            <Button
              className={
                serviceMP?.status == 2
                  ? "bg-transparent text-foreground border-default-200 uppercase"
                  : "uppercase"
              }
              color="primary"
              radius="full"
              variant={serviceMP?.status == 2 ? "bordered" : "solid"}
            >
              {serviceMP?.status_name}
            </Button>
          </CardHeader>
          <CardBody className="px-3 py-0 flex flex-col justify-center items-center space-y-1">
            <p className="text-small pl-px text-default-500">
              {serviceMP?.account_number}
            </p>
            <p className="text-small pl-px text-default-500">
              {serviceMP?.account_holder_name}
            </p>
            <p className="text-small pl-px text-default-500">
              {serviceMP?.identification}
            </p>
            <p className="text-small pl-px text-default-500">
              {serviceMP?.phone}
            </p>
            <p className="text-small pl-px text-default-500">
              {serviceMP?.email}
            </p>
          </CardBody>
        </Card>
      </PopoverContent>
    </Popover>
  );
};
