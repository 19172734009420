// @flow
import { useDisclosure, Button } from "@nextui-org/react";
import * as React from "react";
import { IoIosAdd } from "react-icons/io";
import { ModalComponent } from "../../../../components/Modal/ModalComponent";
import { FormDevices } from "./FormDevices";
import {
  PostDevice,
} from "../../../../../../store/managements/devices/thunks";
import { ErrorToast, SuccessToast } from "../../../../../../libs/Notifications";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../../store/store";

type Props = {
  e?: any;
  reload: () => void;
};

export const AddDevices = ({ e, reload }: Props) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [data, setData] = React.useState(e);
  const dispatch: AppDispatch = useDispatch();

  const formRef = React.useRef<() => void | null>(null);

  const handleFormSubmit = (formData: any) => {
    console.log("Submitted data", formData);
    const body = {
      name: formData.name,
      detail: formData.description,
      service: formData.service,
      cost:formData.price,
    };
    PostDevice(body)
      .then((result) => {
        SuccessToast("Dispositivo creado correctamente");

        onClose();
        reload();
      })
      
      .catch(({ response }) => {
        if (response?.status === 400) {
          const { name, description } = response?.data;
          return ErrorToast(description || name);
        }
        ErrorToast("Hubo un error al registrar el Dispositivo");
        return;
      });
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current();
    }
  };

  return (
    <>
      <Button
        size="md"
        color="primary"
        className="shadow-lg"
        endContent={<IoIosAdd className="h-7 w-7" />}
        onPress={onOpen}
      >
        Agregar Dispositivo
      </Button>

      <ModalComponent
        header={"Registrar datos del dispositivo"}
        body={
          <FormDevices
            setData={setData}
            onSubmit={handleFormSubmit}
            ref={formRef}
          />
        }
        handleSubmitClick={handleSubmitClick}
        onClose={onClose}
        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        title={""}
      />
    </>
  );
};


