import { yupResolver } from "@hookform/resolvers/yup";
import { Autocomplete, AutocompleteItem, Input } from "@nextui-org/react";
import { Controller, useForm } from "react-hook-form";
import { schemaModelDevice } from "./validators/ModelDeviceSchema";
import React, { useState, useEffect } from "react";
import { AppDispatch, RootState } from "../../../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { DevicesParams } from "../../../../params/management/devicesParams";
import { ManufacturerThunks } from "../../../../../../store/managements/manufacturers/thunks";

type Props = {
  data?: any | {};
  setData?: (data: any) => void;
  onSubmit: (data: any) => void;
  ref?: React.MutableRefObject<(() => void) | null>;
};

export const FormModelDevice = React.forwardRef(
  ({ data, onSubmit }: Props, ref) => {
    const {
      control,
      handleSubmit,
      formState: { errors },
      setValue,
    } = useForm({
      resolver: yupResolver(schemaModelDevice),
    });
    const dispacth = useDispatch<AppDispatch>();
    const { manufacturer } = useSelector(
      (state: RootState) => state.manufacturer
    );

    const handleConsultation = () => {
      const param = new DevicesParams();
      param.remove_pagination = true;
      dispacth(ManufacturerThunks(param));
    };

    // Usamos useImperativeHandle para exponer el handleSubmit a través del ref.
    React.useImperativeHandle(ref, () => handleSubmit(onSubmit));
    React.useEffect(() => {
      if (data) {
        setValue("name", data.name || "");
        setValue("detail", data.detail || "");
        const getManufacturer= manufacturer.filter((m) => m.id === data.manufacturer)[0]
        setValue("manufacturer", getManufacturer?.id.toString() || ''); 
      }
    }, [data, setValue]);
    useEffect(() => {
      handleConsultation();
    }, []);


    return (
      <form className="flex flex-col my-4">
        <div className="my-4">
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  isInvalid={!!errors.name}
                  type="text"
                  autoFocus
                  placeholder="Nombres"
                  variant="bordered"
                />
                {errors.name && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors.name.message}
                  </span>
                )}
              </>
            )}
          />
        </div>

        <div className="my-4">
          <Controller
            control={control}
            name="detail"
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  isInvalid={!!errors.detail}
                  type="text"
                  placeholder="Descripción"
                  variant="bordered"
                />
                {errors.detail && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errors.detail.message}
                  </span>
                )}
              </>
            )}
          />
        </div>

        {/* Campo de búsqueda personalizado */}
        <div className="my-4 relative">
          <Controller
            control={control}
            name="manufacturer" // Controla el nombre del fabricante en el formulario
            render={({ field }) => (
              <div className="flex items-center">
                <Autocomplete
                  {...field} // Pasa las propiedades del Controller
                  placeholder="Buscar Fabricante"
                  className="max-w"
                  variant="bordered"
                  defaultItems={manufacturer}
                  selectedKey={field.value}
                  onSelectionChange={(e) => field.onChange(e)}
                >
                  {(item) => (
                    <AutocompleteItem key={item.id}>
                      {item.name}
                    </AutocompleteItem>
                  )}
                </Autocomplete>
              </div>
            )}
          />
          {errors.manufacturer && (
            <span style={{ color: "red", fontSize: "12px" }}>
              {errors.manufacturer.message}
            </span>
          )}
        </div>
      </form>
    );
  }
);
