import { useDisclosure, Button } from "@nextui-org/react";
import { useRef } from "react";
import { ErrorToast, LoadingToast, SuccessToast } from "../../../../../../libs/Notifications";
import { DeleteRates, PatchRates } from "../../../../../../store/rates/Thunks";
import { PopoverConfirm } from "../../../../components/Popover/PopoverConfirm";
import { ModalComponent } from "../../../../components/Modal/ModalComponent";
import { FormPatchRates } from "./FormPatchRates";
import { LuFileEdit } from "react-icons/lu";
import { FaRegTrashCan } from "react-icons/fa6";


type Props = {
  data?: any;
  reload: () => void;
};


export const ActionPatch = ({ data, reload }: Props) => {

  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();


  const formRef = useRef<() => void | null>(null);





  const handleFormSubmit = (formData: any) => {
      // console.log("update data", formData);

      PatchRates(formData, data.id)
          .then((result) => {
            SuccessToast("Tarifa actualizada correctamente");
              onClose();
              reload();

          })
          .catch(({ response }) => {
            if (response?.status === 400) {
              // Verificar si hay un mensaje de error en el cuerpo de la respuesta
              const errorMessage =  Object.values(response.data || {}).join(', ') || "Error desconocido";
              return ErrorToast(errorMessage);
            }
            ErrorToast("Hubo un error al actualizar el Dispositivo");
              return;
          });
  };

  const handleSubmitClick = () => {
      if (formRef.current) {
          formRef.current();
      }
  };

  return (
      <>
          <Button
              isIconOnly
              variant="light"
              color="primary"
              size="sm"
              onPress={onOpen}
          >
              <LuFileEdit  className={`font-semibold h-6 w-6 `} />
          </Button>
          <ModalComponent
              header={`Editar Precio de la Tarifa`}
              body={<FormPatchRates data={data} onSubmit={handleFormSubmit} ref={formRef} />}
              handleSubmitClick={handleSubmitClick}
              onClose={onClose}
              isOpen={isOpen}
              onOpen={onOpen}
              onOpenChange={onOpenChange}
              title={""}
          />
      </>
  );
}

export const ActionDelete = ({ data, reload }: Props) => {
  const handleDelete = () => {
  
    DeleteRates(data.id)
      .then((result) => {
        SuccessToast("Tarifa eliminada correctamente");
        reload();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          // Verificar si hay un mensaje de error en el cuerpo de la respuesta
          const errorMessage =  Object.values(response.data || {}).join(', ') || "Error desconocido";
          return ErrorToast(errorMessage);
        }
  
        ErrorToast("Hubo un error al eliminar la Tarifa.");
      });
  };
  

  return (
    <PopoverConfirm
      title="Confirmar Eliminación"
      message="¿Estás seguro de que deseas eliminar este elemento?"
      confirm={handleDelete} // Esta función se ejecutará al confirmar
      item={undefined}
    >
      <Button isIconOnly variant="light" color="danger" size="sm">
        <FaRegTrashCan className="font-semibold h-6 w-6" />
      </Button>
    </PopoverConfirm>
  );
};
