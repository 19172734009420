// @flow
import { Link, useParams } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { ConfigServicesDevices } from "./devices/ConfigServicesDevices";
import { ConfigServiceTangible } from "./snp/ConfigServiceIntangible";
type Props = {
  path?: string;
};
export const ServicesCompanyID = ({ path }: Props) => {

  return (
    <>
      <div className="flex flex-col gap-4">
        <Link to={`../`} className="flex items-end space-x-4">
          <FaArrowLeft className="w-6 h-6" />{" "}
        </Link>
        <h2 className="gap-4 text-2xl font-medium text-neutral-700 dark:text-titleDark">
          Servicio 
        </h2>
      </div>
      <div>
        <ConfigServiceTangible />
      </div>
    </>
  );
};
