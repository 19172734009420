import { createAsyncThunk } from "@reduxjs/toolkit";
import { movilPayAPI } from "../../../api/movilPayAPI";

export const OccupationsThunks = createAsyncThunk(
  "ocupation",
  async (params?: any) => {
    return movilPayAPI
      .get("/companies/ocupation/", { params })
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        return [];
      });
  }
);
export const OccupationDThunks = createAsyncThunk(
  "ocupationID",
  async (args: { id?: any; params?: any }) => {
    const { id, params } = args;
    try {
      const result = await movilPayAPI.get(`/companies/ocupation/${id}/`, {
        params,
      });
      return result.data;
    } catch (err) {
      console.error(err); // Manejo de errores
      return [];
    }
  }
);

export const PostOccupationThunks = (body: any) => {
  return movilPayAPI.post(`/companies/ocupation/`, body);
};
export const PatchOccupationThunks = (body: any, id: number) => {
  return movilPayAPI.patch(`/companies/ocupation/${id}/`, body);
};

export const DeleteOccupationThunks = (id: any) => {
  return movilPayAPI.delete(`/companies/ocupation/${id}`);
};
