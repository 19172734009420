import { useThemeMovilPay } from "../../../../../../hooks/useTheme";
import {
  Avatar,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from "@nextui-org/react";
import { Services } from "../../../../interfaces/ServicesInterfaces";
import { useState } from "react";
import { DeleteService, ServicesModal } from "../createServices/UpdateServices";
import { SlOptions } from "react-icons/sl";
import { usePermissions } from "../../../../../../context/PermissionContext";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { LuFileEdit } from "react-icons/lu";

type ServicesProps = Services;

export const CardServices = (data: ServicesProps) => {
  const { id, name, description, price } = data;
  const { darkMode } = useThemeMovilPay();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // Estado para controlar el menú
  const [type, setType] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar el modal
  const { permissions, loading } = usePermissions();
  const actionPermissionEdit =
    permissions && permissions.includes("action_edit_services");
  const actionPermissionDelete =
    permissions && permissions.includes("action_delete_services");
  const actionPermissionRates =
    permissions && permissions.includes("action_view_service_rates");
  const img = darkMode ? "isotipo_movil play" : "isotipo_movil play";

  function handleDropdownAction(key: any): void {
    switch (key) {
      case "edit":
        setType(1);
        setIsModalOpen(true);
        setIsDropdownOpen(false); // Cierra el menú después de seleccionar
        break;
      case "show":
        setType(2);
        setIsModalOpen(true);
        setIsDropdownOpen(false); // Cierra el menú después de seleccionar
        break;
      case "delete":
        setType(3);
        setIsModalOpen(true);
        // Mantiene el menú abierto para la opción delete
        setIsDropdownOpen(false);
        break;
      default:
        setIsDropdownOpen(false); // Cierra el menú por defecto
    }
  }

  return (
    <>
      <div
        className={`font-roboto flex  w-full flex-col  gap-y-2 rounded-2xl bg-white px-8 py-6 leading-[normal] tracking-[0px] dark:bg-primaryDark text-[#374151] dark:text-white shadow-xl`}
      >
        <div className="flex justify-between gap-x-3 self-stretch w-full">
          <Avatar
            size="lg"
            isBordered
            color="primary"
            src={require(`../../../../../../assets/img/${img}.png`)}
            className="p-2 bg-primary-50 "
          />

          <div className="flex items-center justify-between gap-1">
            {actionPermissionEdit && (
              <Dropdown
                isOpen={isDropdownOpen}
                onOpenChange={setIsDropdownOpen}
              >
                <DropdownTrigger>
                  <Button
                    variant="light"
                    isIconOnly
                    radius="full"
                    className="bg-white"
                  >
                    <SlOptions className="w-7 text-primary" />
                  </Button>
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="Static Actions"
                  onAction={handleDropdownAction}

                  color="primary"
                >
                  <DropdownItem
                    key="edit"
                    startContent={<LuFileEdit className="w-5 h-5" />}
                  >
                    Editar Servicio
                  </DropdownItem>
                  <DropdownItem
                    key="show"
                    startContent={<FaMoneyBillTrendUp className="w-5 h-5" />}
                  >
                    Ver Tarifas
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            )}
          </div>
        </div>

        <div className="flex flex-col items-center self-stretch pt-[9px] w-full">
          <div className="text-2xl font-medium leading-[normal]">{name}</div>
        </div>
        <div className="leading-[normal]">{description}</div>
        <div className="leading-[normal]">USD {price}$</div>
        <div className="flex gap-3 justify-end">
          <div className="">
            {actionPermissionDelete && <DeleteService serviceId={id} />}
          </div>
        </div>
      </div>

      {/* Modal */}
      <ServicesModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        e={data}
        type={type}
      />
    </>
  );
};
