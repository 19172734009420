import { Accordion, AccordionItem } from "@nextui-org/react";
import { Link, NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { useUserMovilPay } from "../../../hooks/useUserMovilPay";
import { FaUser } from "react-icons/fa";

import React from "react";
import { CardDolar } from "./dollars/CardDolar";
import { IoGrid } from "react-icons/io5";
import Icons from "./icons";
import { useStorePermissions } from "../../../store/permission/useStorePermission";
import { queryOptions, useQueryClient } from "@tanstack/react-query";
import { GetPermissionsThunks } from "../../../store/permission/thunks";
import { ErrorToast } from "../../../libs/Notifications";
import { UserParams } from "../params/settings/userParams";
import { FcSettings } from "react-icons/fc";
import { ActionMenuAccount } from "./ActionMenuAccount";
import { MenuComponent } from "./Menu/MenuComponent";
import { Img } from "react-image";
import { useThemeMovilPay } from "../../../hooks/useTheme";

type Props = {
  item: any;
  isExpand: boolean;
};

export const SidebarDesktop = ({
  setExpand,
}: {
  setExpand: (e: any) => void;
}) => {
  const { menus } = useUserMovilPay();

  const [isExpand, setIsExpand] = useState(true);
  const handleOpened = (status: any) => {
    setIsExpand(status);
    setExpand(status);
  };
  const { darkMode } = useThemeMovilPay();
  const img = darkMode
    ? "logotipo_letras_blancas_movil_play_23x14cm"
    : "logotipo_movil play_30x17cm";
    const imgResp = darkMode
    ? "isotipo movil play todo blanco"
    : "isotipo_movil play";
  // const [userGrop, setUserGrop] = useState([""]);

  // const getGroup = () => {
  //   setUserGrop(groups.filter((g: string) => g === "CLIENTE"));
  // };

  return (
    <div className="hidden lg:fixed lg:inset-y-0 lg:z-1  lg:flex h-full lg:w-72 lg:flex-col z-10 ">
      {/* Sidebar component, swap this element with another sidebar if you like */}

      <div className="flex grow flex-col gap-y-5 overflow-y-auto  px-6 pb-4 ">
        <nav
          role="navigation"
          className={[
            "dark:bg-transparent  dark:border-r-transparent bg-white   shadow-md absolute inset-y-0 left-0",
            "transition-all duration-300 ease-in-out md:fixed",
            `${isExpand ? "w-60" : "w-20"}`,
          ].join(" ")}
        >
          {/* <button
            className="absolute z-50 top-16 -right-6 bg-white dark:bg-primaryDark  hover:bg-slate-100 text-primary p-0.5 rounded-full "
            onClick={() => {
              handleOpened(!isExpand);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`${
                isExpand ? "rotate-0" : "rotate-180"
              } transform transition duration-500 h-7 w-7`}
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </button> */}
          <div
            className={` absolute h-full block overflow-y-auto  scrollbar-none`}
          >
            <div className="mb-0 list-none text-slate-500">
            
              <div
                className={`my-3 flex flex-col justify-center items-center overflow-x-hidden duration-300 ${
                  isExpand ? "px-3" : "px-5"
                }`}
              >
                <div
                  className={`flex justify-center items-center rounded-lg w-full h-20 duration-300 `}
                >
                  <Img
                    src={require(`../../../assets/img/${isExpand ? img: imgResp}.png`)}
                    className="w-1/2 mb-2 ml-3"
                  />
                </div>
              </div>
              <div className="relative">
                {
                  menus && 
                <MenuComponent menus={menus} isExpand={isExpand} />
                }
              </div>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};
