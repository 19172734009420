import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { FinancesServicesParams } from '../../../../../../../params/finances/FinancesServicesParams';
import { addItemDevicesThunks, getInvoiceCompanyDevicesThunks } from '../../../../../../../../../store/finances/Thunks';
import DeviceSchema from '../../validators/deviceSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Input, Select, SelectItem } from '@nextui-org/react';
import { MdFormatListBulletedAdd } from 'react-icons/md';
import { useStoreCreateInvoice } from '../../../../../../../../../store/finances/useStoreCreateInvoice';
import { ErrorToast, SuccessToast } from '../../../../../../../../../libs/Notifications';


interface Props {
    invoiceData: any;
    reload: () => void;
    show: () => void;

}

const FormAddItemDevice = ({ invoiceData,reload,show }: Props) => {
    const [devices, setDevices] = useState<any>([]);
    const queryClient = useQueryClient();
    const { control, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(DeviceSchema),
    });
    const selectedDevice = watch('device');

    const getDevices = async () => {
        const param = new FinancesServicesParams();
        param.company = invoiceData.company;

        // Fetch data from API and store result in servicesData state
        const data = await queryClient.fetchQuery({
            queryKey: ["services", invoiceData.company],
            queryFn: () => getInvoiceCompanyDevicesThunks(param),
            retry: false,
        });

        // Log and set the API response
        console.log('API Response:', data.data);
        setDevices(data?.data?.results || []); // Update state with the results if available
    };

    useEffect(() => {
        if (invoiceData.company) {
            getDevices();
        }
    }, [invoiceData.company]);

    useEffect(() => {
        if (selectedDevice) {
            const device = devices.find((s: any) => s.device === Number(selectedDevice));
            if (device) {
                setValue('price', device.device_cost || 0);
                setValue('device_serial', device.device_serial || '');
                setValue('cant', '1');
                setValue('description', `Pago de mensualidad dispositivo ${device.device_serial}`);
            }
        }
    }, [selectedDevice, setValue]);

    const onSubmit = (data: any) => {
        const iva = (data.price * data.cant) * 0.16;

        const item = {

            "device": parseInt(data.device),
            "details": data.description,
            "amount": data.price,
            "count": data.cant,
            "tax": 1,
            "invoice": parseInt(invoiceData.id)
        }


        addItemDevicesThunks(item)
        .then((result) => {
          SuccessToast("Dispositivo agregado a Factura.");
            show();
            reload();

        })
        .catch(({ response }) => {
            if (response?.status === 400) {
              const errorMessage =
                Object.keys(response.data || {})
                  .join(", ")
                  .concat(": " + Object.values(response.data || {}).join(", ")) ||
                Object.values(response.data || {}).join(", ") ||
                "Error desconocido";
              return ErrorToast(errorMessage);
            }
          });



        // Add your item logic here
        // useStoreCreateInvoice.getState().setFormData(item); // Store form data in Zustand
        // reset(); // Reset the form
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-1 lg:grid-cols-3  gap-4">
            <div className="col-span-1">
                    <Controller
                        control={control}
                        name="device"
                        render={({ field }) => (
                            <Select
                                {...field}
                                onChange={(e) => field.onChange(e)}
                                isInvalid={!!errors.device}
                                placeholder="Selecciona el dispositivo"
                                variant="bordered"
                            >
                                {devices.map((device: any) => (
                                    <SelectItem key={device.device} value={device.device}>
                                        {device.device_serial}
                                    </SelectItem>
                                ))}
                            </Select>
                        )}
                    />
                    {errors.device && (
                        <span style={{ color: 'red', fontSize: '12px' }}>
                            {errors.device.message}
                        </span>
                    )}
                </div>

                <div className="col-span-1">
                    <Controller
                        name="cant"
                        control={control}
                        render={({ field }) => (
                            <Input
                                {...field}
                                placeholder="Cantidad"
                                type="number"
                                variant="bordered"
                                isInvalid={!!errors.cant}
                                readOnly={true}
                                value={field.value ?? 1}
                            />
                        )}
                    />
                    {errors.cant && (
                        <span style={{ color: 'red', fontSize: '12px' }}>
                            {errors.cant.message}
                        </span>
                    )}
                </div>
                <div className="col-span-1">
                    <Controller
                        name="price"
                        control={control}
                        render={({ field }) => (
                            <Input
                                {...field}
                                placeholder="Precio"
                                type="text"
                                variant="bordered"
                                value={field.value ?? ""}
                                isInvalid={!!errors.price}
                                readOnly={true}
                            />
                        )}
                    />
                    {errors.price && (
                        <span style={{ color: 'red', fontSize: '12px' }}>
                            {errors.price.message}
                        </span>
                    )}
                </div>
                <button
                    type="submit"
                    className=" w-40 h-14 bg-primary  text-white flex items-center p-2 rounded-xl hover:bg-secondary transition"
                >
                    <MdFormatListBulletedAdd className="text-2xl text-white mr-2" />
                    Agregar Item
                </button>
            </form>
        </div>
    )
}

export default FormAddItemDevice