import { createAsyncThunk } from "@reduxjs/toolkit";
import { movilPayAPI } from "../../../api/movilPayAPI";
import { DevicesParams } from "../../../pages/core/params/management/devicesParams";


export const ManufacturerThunks = createAsyncThunk(
  "manufacturer",
  async (params?: DevicesParams) => {
    console.log('====================================');
    console.log(params);
    console.log('====================================');
    return movilPayAPI
      .get("/devices/manufacturer/", { params })
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        return [];
      });
  }
);

export const ManufacturerThunksIDThunks = createAsyncThunk(
  'manufacturerID',
  async (args: {id?:any , params?: any}) => {
    const { id, params } = args;
    try {
      const result = await movilPayAPI.get(`/devices/manufacturer/${id}/`, { params });
      return result.data;
    } catch (err) {
      console.error(err); // Manejo de errores
      return [];
    }
  }
);


export const PostManufacturerThunks = (body: any) => {
  return movilPayAPI.post(`/devices/manufacturer/`, body);
};
export const PatchManufacturerThunks = (body: any, id:number) => {
  return movilPayAPI.patch(`/devices/manufacturer/${id}/`, body);
};

export const DeleteManufacturerThunks = (id:number) => {
  return movilPayAPI.delete(`/devices/manufacturer/${id}/`);
};


