import { create } from "zustand";

interface SectorLocationState {
  sector: any[];
  count: number;
  setSector: (sector: any[], count: number) => void;
  resetSector: () => void;
}

export const useStoreSectorCore = create<SectorLocationState>((set) => ({
    sector: [],
  count: 0,
  setSector: (sector: any[], count: number) =>
    set(() => ({ sector, count })),
  resetSector: () => set(() => ({ sector: [], count: 0 })),
}));
