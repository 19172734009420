import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { Input } from "@nextui-org/react";
import { MdAttachMoney } from "react-icons/md";
import { getDollar, paymentValidateThunks } from "../../../../../../../../store/finances/PaymentInvoiceThunsks";
import { ErrorToast, SuccessToast } from "../../../../../../../../libs/Notifications";
import { DollarParams } from "../../../../../../params/dollar/DollarParams";
import usePaymentMethodStore from "../../../../../../../../store/finances/useStorePaymentMethods";
import useStorePaymentInvoice from "../../../../../../../../store/finances/useStorePaymentInvoice";

const ItemSchema = yup.object().shape({

    monto: yup
        .number()
        .typeError("Debe ser un número")
        .required("Monto es requerido")
        .positive("El monto debe ser un número positivo"),
    fecha: yup
        .string()
        .required("Fecha del pago requerida"),
});

export const UsdCurrencyForm = () => {
    const { addPaymentMethod, totalMBs } = usePaymentMethodStore();
    const { totalBs } = useStorePaymentInvoice();


    const { control, handleSubmit, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(ItemSchema),
    });
    const param = new DollarParams()

    const onSubmit = (data: any) => {
        // Lógica para procesar el formulario
        console.log(data);

            param.date = data.fecha;

            getDollar(param).then((dollar) => {
               
                const rate = Number(dollar.data[0].amount);
                const paymentMethod= {
                    id: 0,
                    amount:data.monto,
                    name: `USD`,
                    amountBs:parseFloat((Number(data.monto) * rate).toFixed(2)),
                    rate:rate,
                    date: data.fecha,
                    method:'7'

                }
                addPaymentMethod(paymentMethod);
                SuccessToast("Pago Cargado.");
                setValue('monto',0)
                
            }).catch(({ response }) => {
                if (response?.status === 400) {
                    const errorMessage =
                        Object.keys(response.data || {})
                            .join(", ")
                            .concat(": " + Object.values(response.data || {}).join(", ")) ||
                        Object.values(response.data || {}).join(", ") ||
                        "Error desconocido";
                    return ErrorToast(errorMessage);
                }
            });
    };

    return (
        <div className="bg-white p-10 rounded-lg">
            <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-1 lg:grid-cols-3  gap-4">


                {/* Campo Monto */}
                <div className="col-span-1">
                    <Controller
                        name="monto"
                        control={control}
                        render={({ field }) => (
                            <Input
                                {...field}
                                label="Monto"
                                type="number"
                                placeholder="Ingrese el monto"
                                variant="bordered"
                                isInvalid={!!errors.monto}
                                value={String(field.value)} // Convertir el valor a string

                            />
                        )}
                    />
                    {errors.monto && (
                        <span style={{ color: 'red', fontSize: '12px' }}>
                            {errors.monto.message}
                        </span>
                    )}
                </div>

                {/* Campo Monto */}
                <div className="col-span-1">
                    <Controller
                        name="fecha"
                        control={control}
                        render={({ field }) => (
                            <Input
                                {...field}
                                label="Fecha del pago"
                                type="date"
                                placeholder="Ingrese la fecha del pago"
                                variant="bordered"
                                isInvalid={!!errors.fecha}

                            />
                        )}
                    />
                    {errors.fecha && (
                        <span style={{ color: 'red', fontSize: '12px' }}>
                            {errors.fecha.message}
                        </span>
                    )}
                </div>
                <div>
                    { totalMBs < totalBs && (
                          <button
                          type="submit"
                          className="h-14 col-span-2 bg-primary text-white flex items-center justify-center p-2 rounded-xl hover:bg-secondary transition"
                      >
                          <MdAttachMoney className="text-2xl text-white " />
                          Cargar Pago
                      </button>
                    ) }
                  
                </div>


            </form>
        </div>
    );
};

export default UsdCurrencyForm;
