// @flow
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Divider,
  Link,
  Image,
  Button,
} from "@nextui-org/react";
import * as React from "react";
import { Company } from "../../../../interfaces/CompaniesInterfaces";
import { MdFactory } from "react-icons/md";

type Props = {
  companys: Company;
};
export const CardCompany = ({ companys }: Props) => {
  return (
    <Card className="max-w-full">
      <CardHeader className="justify-between">
        <div className="flex gap-4">
          <MdFactory className="mx-2" size={40} />
          {/* <Avatar isBordered radius="full" size="md" src="https://nextui.org/avatars/avatar-1.png" /> */}
          <div className="flex flex-col gap-1 items-start justify-center">
            <h4 className="text-small font-semibold leading-none text-default-600">
              {companys.name}
            </h4>
            <h5 className="text-small tracking-tight text-default-400">
              {companys.rif}
            </h5>
            <h5 className="text-small tracking-tight text-default-400">
              {companys.email}
            </h5>
          </div>
        </div>
        <Button
          className={
            companys.status === 1
              ? "mx-4"
              : "bg-transparent text-foreground border-default-200 mx-4"
          }
          color="primary"
          radius="full"
          size="sm"
          variant={companys.status === 1 ? "solid" : "bordered"}
        >
          <span className="uppercase">{companys.status_name}</span>
        </Button>
      </CardHeader>
      <CardBody className="px-3 py-0 text-small text-center text-wrap text-default-400">
        <p>{companys.description}</p>
      </CardBody>
      <CardFooter className="gap-3 flex justify-evenly">
        {/* <div className="flex  gap-1">
          <p className="font-semibold text-default-400 text-small">
            {companys.start_date_work}
          </p>
          <p className=" text-default-400 text-small">(F. Servicio)</p>
        </div> */}
        <div className="flex flex-col gap-1">
          <p className="font-semibold text-default-400 text-small">
            <Link
              className=""
              isExternal={false}
              showAnchorIcon
              href={`/companies/company/${companys.id}`}
            >
              Ir al perfil
            </Link>
          </p>
        </div>
      </CardFooter>
    </Card>
  );
};
