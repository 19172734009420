// @flow
import { useDisclosure, Button } from "@nextui-org/react";
import * as React from "react";
import { ModalComponent } from "../../../../components/Modal/ModalComponent";
import { FormServices } from "./FormServices";
import { ErrorToast, SuccessToast } from "../../../../../../libs/Notifications";
import {
  DeleteServicesThunks,
  ServicesThunks,
  UpdateServicesThunks,
} from "../../../../../../store/services/thunks";
import { Services } from "../../../../interfaces/ServicesInterfaces";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../../store/store";
import { PopoverConfirm } from "../../../../components/Popover/PopoverConfirm";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@nextui-org/react";
import { TableServicesRates } from "./TableServicesRates";
import { FaRegTrashCan } from "react-icons/fa6";

type Props = {
  e: Services;
  isOpen?: boolean;
  onClose: () => void;
  type: number;
};
type DeleteServiceProps = {
  serviceId: number;
};

export const ServicesModal = ({ isOpen, onClose, e, type }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const [data, setData] = React.useState(e);
  const formRef = React.useRef<() => void | null>(null);

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current();
    }
  };
  const handleFormSubmit = (formData: any) => {
    UpdateServicesThunks(formData, data.id)
      .then((result) => {
        SuccessToast("Servicio actualizado correctamente");
        dispatch(ServicesThunks());
        onClose();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          // Verificar si hay un mensaje de error en el cuerpo de la respuesta
          const errorMessage =
            Object.values(response.data || {}).join(", ") ||
            "Error desconocido";
          return ErrorToast(errorMessage);
        }
        ErrorToast("Hubo un error al actualizar el Servicio");
        return;
      });
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader>
          {type == 1 ? `Editar Servicio` : `Tarifas del Servicio`}
        </ModalHeader>
        <ModalBody>
          {type == 1 ? (
            <FormServices data={e} onSubmit={handleFormSubmit} ref={formRef} />
          ) : (
            <TableServicesRates data={e} />
          )}
        </ModalBody>
        <ModalFooter>
          
          <div className="flex flex-col justify-end gap-y-2 w-full">
            <Button
              size="md"
              type="submit"
              onPress={handleSubmitClick}
              color="primary"
              className="p-3 rounded-full  font-semibold bg-primary"
            >
              Guardar
            </Button>
            <Button
              size="md"
              type="button"
              onPress={onClose}
              variant="bordered"
              color="primary"
              className="p-3 rounded-full font-semibold "
            >
              Cancelar
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export const DeleteService = ({ serviceId }: DeleteServiceProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleDelete = () => {
    DeleteServicesThunks(serviceId)
      .then(() => {
        SuccessToast("Servicio eliminado correctamente");
        dispatch(ServicesThunks());
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          const { name, description } = response?.data;
          return ErrorToast(description || name);
        }
        ErrorToast("Hubo un error al eliminar el Servicio");
      });
  };

  return (
    <PopoverConfirm
      title="Confirmar Eliminación"
      message="¿Estás seguro de que deseas eliminar este servicio?"
      confirm={handleDelete}
    >
      <Button isIconOnly variant="light" color="danger" size="sm">
        <FaRegTrashCan className="font-semibold h-6 w-6" />
      </Button>
    </PopoverConfirm>
  );
};
