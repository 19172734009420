// @flow
import * as React from "react";
import { useUserMovilPay } from "../../../../../hooks/useUserMovilPay";
import { schemaUser, schemaUserShort } from "./validators/Validators";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorToast, LoadingToast } from "../../../../../libs/Notifications";
import { Select, SelectItem, Input, Button } from "@nextui-org/react";
import { FcSearch } from "react-icons/fc";
import { type } from "../../../components/methodPayments/typeIdentification";
import { FormIdentification } from "./FormIdentification";
import { FormProfile } from "./FormProfile";
import { configTaiwind } from "../../../../../utils/configTaiwind";
import { MdSave } from "react-icons/md";
import {
  PatchUsersThunks,
  PostUsersThunks,
} from "../../../../../store/accounts/users/thunks";
import { setCookie } from "../../../../../config/cookies";
import { useNavigate } from "react-router-dom";

export const Profile = () => {
  const userData = useUserMovilPay();
  const navigate = useNavigate();
  const formRef = React.useRef<() => void | null>(null);
  const formRefUser = React.useRef<() => void | null>(null);
  const [identification, setIDentification] = React.useState();
  const onSubmit = (data: any) => {
    const { success, error } = LoadingToast();
    let body = {
      ...data,
      phone: data.code.concat(data.phone),
      identification: identification,
    };
    PatchUsersThunks(Number(userData.id), body)
      .then((results) => {
        const user = { ...userData, ...results.data };
        setCookie("user", user);
        success("Datos actualizados correctamente");
        window.location.reload();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          const errorMessage =
            Object.keys(response.data || {})
              .join(", ")
              .concat(": " + Object.values(response.data || {}).join(", ")) ||
            Object.values(response.data || {}).join(", ") ||
            "Error desconocido";
          return ErrorToast(errorMessage);
        }
      });
  };
  const onSubmitSuperUser = (data: any) => {
    const string = data.type.concat(data.identification);
    setIDentification(string);
  };
  const handleSubmitClick = () => {
    if (formRefUser.current) {
      formRefUser.current();
    }
    if (formRef.current) {
      formRef.current();
    }
  };
  return (
    <div className="flex items-center justify-center self-center mx-auto ">
      <div className="mx-auto  max-w-full space-y-3  flex-col  bg-white px-4 py-5 shadow-md dark:bg-primaryDark rounded-lg sm:px-6 ">
        <div className="mb-4">
          <h2 className="text-2xl font-bold">Actualizar datos</h2>
          <p>
            Aqui podras actualizar tus datos, asimismo cambiar tu contraseña
          </p>
        </div>
        {userData.is_superuser && (
          <FormIdentification
            data={userData}
            onSubmit={onSubmitSuperUser}
            ref={formRefUser}
          />
        )}
        <FormProfile data={userData} onSubmit={onSubmit} ref={formRef} />
        <div className="flex justify-center p-4">
          <Button
            size="md"
            type="submit"
            color="primary"
            className="shadow-lg"
            endContent={<MdSave className="h-7 w-7" />}
            onPress={handleSubmitClick}
          >
            Guardar cambios
          </Button>
        </div>
      </div>
    </div>
  );
};
