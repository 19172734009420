import { create } from "zustand";

interface PermissionsState {
  permissions: any[];
  setPermissions: (permissions: any[]) => void;
  resetPermissions: () => void;
}
export const useStorePermissions = create<PermissionsState>((set) => ({
    permissions: [],
    setPermissions: (permissions:any[]) => {
    set({ permissions });
  },
  resetPermissions: () => {
    set({ permissions: [] });
  },
}));
