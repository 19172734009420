import { createAsyncThunk } from "@reduxjs/toolkit";
import { movilPayAPI } from "../../../api/movilPayAPI";
import { DevicesServicesConfigParams } from "../../../pages/core/params/services-configs/DevicesServicesConfig";

export const DevicesServicesConfigThunks = createAsyncThunk(
    "devices-services-config",
    async (params?: DevicesServicesConfigParams) => {
      return movilPayAPI
        .get("/devices/company_services/", { params })
        .then((result) => {
          return result.data;
        })
        .catch((err) => {
          return [];
        });
    }
  );
  export const GetDevicesServiceCompanyThunks = (params?: DevicesServicesConfigParams, id?:any) => {
    return movilPayAPI.get('/companies/device_service/',{params})
  } 
  export const DevicesCreatServiceCompanyThunks = (body: any) => {
    return movilPayAPI.post('/companies/device_service/', body)
  } 
  export const DevicesUpdatServiceCompanyThunks = (body: any, id:number) => {
    return movilPayAPI.patch(`/companies/device_service/${id}/`, body);
  }
  export const DevicesDeletServiceCompanyThunks = (id:number) => {
    return movilPayAPI.delete(`/companies/device_service/${id}/`);
  }
  
  
  