import { valueType, TableLayout } from "../../../../../layout/TableLayout";
import {
  Action,
  ActionConfig,
  ActionDelete,
  ActionEnable,
  TimeLine,
} from "../ActionsWarehouse";
import { useStoreWarehouseCore } from "../../../../../../../store/managements/warehouse/useStoreWarehouseCore";
import { PaginatorWarehouse } from "./PaginatorWareHouse";
import { usePermissions } from "../../../../../../../context/PermissionContext";

type Props = {
  reload: () => void;
};

export const TableWarehouse = ({ reload }: Props) => {
  const { warehouse, count } = useStoreWarehouseCore((state: any) => state);

  const { permissions, loading } = usePermissions();
  const actionPermissionTimeline =
    permissions && permissions.includes("action_view_device_timeline");
  const actionPermissionUnlink =
    permissions && permissions.includes("action_unlink_device_from_company");
  const actionPermissionStatus =
    permissions &&
    permissions.includes("action_change_device_status_in_warehouse");
  const actionPermissionRemove =
    permissions && permissions.includes("action_remove_device_from_storage");
  const actionPermissionEdit =
    permissions && permissions.includes("action_edit_device_in_warehouse");

  const dataTable: valueType[] = [
    { name: "ID", value: "id" },
    { name: "Dispositivo", value: "device_type_name" },
    { name: "Serial", value: "serial" },
    {
      name: "Precio",
      value: (item: any) => (
        <span className="text-right">USD {item.price}</span>
      ),
    },
    { name: "MAC del Equipo", value: "mac" },
    {
      name: "Banco",
      value: (item: any) => (
        <span className="text-right">
          {item.bank_name != null ? item.bank_name : "MOVILPAY"}
        </span>
      ),
    },
    {
      name: "Compañia",
      value: (item: any) => (
        <span className="text-right">
          {item.company_name != null ? item.company_name : "N/A"}
        </span>
      ),
    },

    {
      name: "Estatus",
      value: (item: any) => (
        <div className="flex items-center space-x-2">
          <span className={item.status ? "text-green-500" : "text-red-500"}>
            {item.status ? "Activo" : "Inactivo"}
          </span>
        </div>
      ),
    },
    {
      name: "Acción",
      value: (data: any) => (
        <>

          {
            data.available_company_device_service_count == 0 ? (
              <>
                {actionPermissionEdit && <Action data={data} reload={reload} />}
                {actionPermissionRemove && (
                  <ActionDelete data={data} reload={reload} />
                )}
                {actionPermissionStatus && (
                  <ActionEnable data={data} reload={reload} />
                )}
              </>
            ) : (
              ""
            )
            // <ActionConfig data={data} reload={reload} />
          }
          {actionPermissionTimeline && <TimeLine data={data} />}
        </>
        // <>
        //   <TimeLine data={data} />

        //   {
        //     data.available_company_device_service_count == 0 ? (
        //       <>
        //         <ActionEnable data={data} reload={reload} />
        //         <Action data={data} reload={reload} />
        //         <ActionDelete data={data} reload={reload} />
        //       </>
        //     ) : (
        //       ""
        //     )
        //     // <ActionConfig data={data} reload={reload} />
        //   }
        // </>
      ),
    },
  ];

  return (
    <div className="space-y-7">
      <TableLayout
        isLoading={false}
        data={warehouse}
        columns={dataTable}
        Paginator={<PaginatorWarehouse count={count} />}
      />
    </div>
  );
};
