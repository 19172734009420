export class PaymentParams{
    bank_destiny?:string
    bank_origin?:string
    company?:string |number
    company_id?:string |number
    method?:string
    page?:number
    since?:string
    search?:string
    status?:boolean|string
    until?:string
    remove_pagination?: boolean
    device?:string
    payment_method_cs?:string
    work?:boolean|string
    show?:boolean|string
    has_rate?:boolean|string
    report?: string

}