import React from 'react';
import usePaymentMethodStore from '../../../../../../../../store/finances/useStorePaymentMethods';
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, getKeyValue } from '@nextui-org/react';
import { FaRegTrashCan } from 'react-icons/fa6';

// Define tus columnas de la tabla
const columns = [
    {
        key: "name",
        label: "Metodo",
    },

    {
        key: "rate",
        label: "Tasa",
    },
  
    {
        key: "amountBs",
        label: "Monto Bs",
    },
    {
        key: "amount",
        label: "Monto USD",
    },
    {
        key: "accion",
        label: "Acción",
    },
];

export const TableMethods = () => {
    const { paymentMethods, removePaymentMethodById } = usePaymentMethodStore();

    return (
        <>
            {paymentMethods.length > 0 && (
                <div className="bg-white mb-8 rounded-lg grid grid-cols-1 gap-4 lg:grid-cols-1">
                    <Table aria-label="Tabla de métodos de pago">
                        <TableHeader columns={columns}>
                            {(column) => (
                                <TableColumn key={column.key} className="bg-primary text-white">
                                    {column.label}
                                </TableColumn>
                            )}
                        </TableHeader>
                        <TableBody items={paymentMethods}>
                            {(methods) => (
                                <TableRow key={methods.id}>
                                    {(columnKey) => {
                                        if (columnKey === "accion") {
                                            // Botón de acción para eliminar, con ícono
                                            return (
                                                <TableCell>
                                                    <button 
                                                        onClick={() => removePaymentMethodById(methods.id)}
                                                        className="text-red-500 hover:text-red-700"
                                                        aria-label={`Eliminar método de pago ${methods.name}`}
                                                    >
                                                        <FaRegTrashCan  className="h-5 w-5" />
                                                    </button>
                                                </TableCell>
                                            );
                                        }
                                        return <TableCell>{getKeyValue(methods, columnKey)}</TableCell>;
                                    }}
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
            )}
        </>
    );
};
