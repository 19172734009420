// @flow
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  AutocompleteItem,
  Input,
  Select,
  SelectItem,
  Textarea,
} from "@nextui-org/react";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { schemaPermissions } from "../validators/useShemaPermission";
import { useStoreMenu } from "../../../../../../store/menu/useStoreMenu";
import { error } from "console";

type Props = {
  data?: any | {};
  onSubmit: (data: any) => void;
  ref?: React.MutableRefObject<(() => void) | null>;
};

export const FormPermissions = React.forwardRef(
  ({ data, onSubmit }: Props, ref) => {
    const [permissionsArr, setPermissions] = React.useState<Set<string>>(
      new Set()
    );
    const { setMenuItem, menuItem } = useStoreMenu((state) => state);
    const {
      control,
      setValue,
      watch,
      handleSubmit,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(schemaPermissions),
    });
    React.useImperativeHandle(ref, () => handleSubmit(onSubmit));
    React.useEffect(() => {
      console.log(errors);
    }, [errors]);
    console.log(menuItem);
    return (
      <>
        <form className="flex flex-col my-2 space-y-2">
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <Input
                {...field}
                isInvalid={!!errors.name}
                errorMessage={errors.name?.message}
                type="text"
                autoFocus
                label="Nombre del Grupo"
                placeholder="Nombre del grupo"
                variant="bordered"
              />
            )}
          />
          <Controller
            name="menu"
            control={control}
            render={({ field }) => (
              <>
                <div className="flex items-center">
                  <Autocomplete
                    {...field} // Pasa las propiedades del Controller
                    className="max-w"
                    variant="bordered"
                    defaultItems={menuItem}
                    label="Menú"
                    placeholder="Seleccione el menú"
                    isInvalid={!!errors.menu}
                    errorMessage={errors.menu?.message}
                    onSelectionChange={(e) => field.onChange(e)}
                  >
                    {(item) => (
                      <AutocompleteItem key={item.id.toString()}>
                        {item.name +
                          `${
                            item.parent_name !== "null" &&
                            " / " + item.parent_name
                          }`}
                      </AutocompleteItem>
                    )}
                  </Autocomplete>
                </div>
              </>
            )}
          />
          <Controller
            control={control}
            name="permissions"
            render={({ field }) => (
              <>
                <Select
                  {...field}
                  label="Permisos"
                  placeholder="Seleccione los permisos"
                  selectionMode="multiple"
                  selectedKeys={[...permissionsArr]}
                  onSelectionChange={(selected) => {
                    // Asegúrate de que `selected` sea un conjunto de strings
                    const selectedSet = new Set<string>(
                      [...selected].map(String)
                    ); // Convierte a Set<string>
                    console.log(selectedSet, "Selected")
                    setPermissions(selectedSet); // Actualiza el estado con los seleccionados
                    field.onChange([...selectedSet]); // Informa a React Hook Form sobre el cambio
                  }}
                  variant="bordered"
                >
                  {animals.map((animal) => (
                    <SelectItem key={animal.key}>{animal.label}</SelectItem>
                  ))}
                </Select>
                <p className="text-small text-default-500">
                  Selected: {permissionsArr}
                </p>
              </>
            )}
          />
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <Textarea
                {...field}
                isInvalid={!!errors.description}
                errorMessage={errors.description?.message}
                type="text"
                label="Descripción del Grupo"
                placeholder="Descripción del Grupo"
                variant="bordered"
              />
            )}
          />
          {/* <Controller
          control={control}
          name="permissions"
          rules={{ required: false }}
          render={({ field }) => (
            <Select
            {...field}
            isInvalid={!!errors.permissions}
            errorMessage={errors.permissions?.message}
            selectionMode="multiple"
            className="max-w-xs"
            labelPlacement="outside"
            label="Identificación"
            placeholder="Ej. V123456789"
            variant="bordered"
            scrollShadowProps={{
                isEnabled: false,
              }}
          >
            {permissionsArr.map((p, i) => (
              <SelectItem key={i}>
                {p}
              </SelectItem>
            ))}
          </Select>
          )}
        /> */}
        </form>
      </>
    );
  }
);

export const animals = [
  { key: "cat", label: "Cat" },
  { key: "dog", label: "Dog" },
  { key: "elephant", label: "Elephant" },
  { key: "lion", label: "Lion" },
  { key: "tiger", label: "Tiger" },
  { key: "giraffe", label: "Giraffe" },
  { key: "dolphin", label: "Dolphin" },
  { key: "penguin", label: "Penguin" },
  { key: "zebra", label: "Zebra" },
  { key: "shark", label: "Shark" },
  { key: "whale", label: "Whale" },
  { key: "otter", label: "Otter" },
  { key: "crocodile", label: "Crocodile" },
];
