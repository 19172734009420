import React, { useState, useEffect } from "react";
import { EditInvoiceSchema } from "../../validators/editInvoiceSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { Input } from "@nextui-org/react";
import { deleteItemDevicesThunks, deleteItemServicesThunks, editItemDevicesThunks, editItemServicesThunks, getInvoiceDetailThunks } from "../../../../../../../../../store/finances/Thunks";
import { queryOptions, useQueryClient } from "@tanstack/react-query";
import { FaEdit, FaSave } from "react-icons/fa";
import { ErrorToast, SuccessToast } from "../../../../../../../../../libs/Notifications";
import FormAddItems from "./FormAddItems";
import { FaRegTrashCan } from "react-icons/fa6";

type Props = {
    data?: any | {};
    setData?: (data: any) => void;
    onSubmit: (data: any) => void;
    ref?: React.MutableRefObject<(() => void) | null>;
};

export const FormEditInvoice = React.forwardRef(({ data, onSubmit }: Props, ref) => {
    const queryClient = useQueryClient();
    const [items, setItems] = useState<any>([]);
    const [editMode, setEditMode] = useState<number | null>(null);
    const [modifiedItems, setModifiedItems] = useState<{ [key: number]: any }>({}); // To track modified items
    const [showFormAddItems, setShowFormAddItems] = useState(false);

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: yupResolver(EditInvoiceSchema),
    });

    React.useImperativeHandle(ref, () => handleSubmit(onSubmit));

    const handleConsultation = async () => {
        const result = await queryClient.fetchQuery(
            queryOptions({
                queryKey: ["invoices/detail"],
                queryFn: () => getInvoiceDetailThunks(data.id).then((d) => {
                    if (d && d.data) {
                        const month = `${d.data.year}-${d.data.month}`;
                        setValue("date_expirated", d.data.date_emission);
                        setValue("month", month);

                        // Combine device and service items
                        const combinedItems = [
                            ...d.data.invoice_items_devices.map((item: any) => ({
                                ...item,
                                type: 'device', // Indicate item type
                            })),
                            ...d.data.invoice_items_services.map((item: any) => ({
                                ...item,
                                type: 'service', // Indicate item type
                            })),
                        ];

                        setItems(combinedItems); // Set items directly here
                        return d.data;
                    } else {
                        return { results: [], count: 0 };
                    }
                }).catch(({ response }) => {
                    if (response?.status === 400) {
                      const errorMessage =
                        Object.keys(response.data || {})
                          .join(", ")
                          .concat(": " + Object.values(response.data || {}).join(", ")) ||
                        Object.values(response.data || {}).join(", ") ||
                        "Error desconocido";
                      return ErrorToast(errorMessage);
                    }
                  }),
                retry: false,
            })
        );
        return result;
    };

    useEffect(() => {
        if (data) {
            handleConsultation();
        }
    }, [data]);

    const handleDelete = async (itemId: number, type: string) => {
        console.log(`Delete item with ID: ${itemId}`);
        if (type == 'device') {
            deleteItemDevicesThunks(itemId)
                .then((result) => {
                    SuccessToast("Item eliminado correctamente");
                    handleConsultation()
                })
                .catch(({ response }) => {
                    if (response?.status === 400) {
                        // Verificar si hay un mensaje de error en el cuerpo de la respuesta
                        const errorMessage = Object.values(response.data || {}).join(', ') || "Error desconocido";
                        return ErrorToast(errorMessage);
                    }

                    ErrorToast("Hubo un error al eliminar el item.");
                });
        } else {
            deleteItemServicesThunks(itemId)
                .then((result) => {
                    SuccessToast("Item eliminada correctamente");
                    handleConsultation()
                })
                .catch(({ response }) => {
                    if (response?.status === 400) {
                        // Verificar si hay un mensaje de error en el cuerpo de la respuesta
                        const errorMessage = Object.values(response.data || {}).join(', ') || "Error desconocido";
                        return ErrorToast(errorMessage);
                    }

                    ErrorToast("Hubo un error al eliminar el Item.");
                });
        }
    };

    const handleEdit = (itemId: number) => {
        setEditMode(itemId);
    };

    const handleSave = async (itemId: number, type: string) => {
        // Get the modified item data
        const modifiedItem = modifiedItems[itemId];

        if (modifiedItem) {
            try {

                if (type == 'device') {
                    editItemDevicesThunks(itemId, modifiedItem)
                        .then((result) => {
                            SuccessToast("Item actualizado correctamente");
                            handleConsultation()
                        })
                        .catch(({ response }) => {
                            if (response?.status === 400) {
                                // Verificar si hay un mensaje de error en el cuerpo de la respuesta
                                const errorMessage = Object.values(response.data || {}).join(', ') || "Error desconocido";
                                return ErrorToast(errorMessage);
                            }

                            ErrorToast("Hubo un error al actualizar el item.");
                        });
                } else {
                    editItemServicesThunks(itemId, modifiedItem)
                        .then((result) => {
                            SuccessToast("Item actualizado correctamente");
                            handleConsultation()
                        })
                        .catch(({ response }) => {
                            if (response?.status === 400) {
                                // Verificar si hay un mensaje de error en el cuerpo de la respuesta
                                const errorMessage = Object.values(response.data || {}).join(', ') || "Error desconocido";
                                return ErrorToast(errorMessage);
                            }

                            ErrorToast("Hubo un error al actualizar el item.");
                        });
                }
                console.log(`Saved changes for item with ID: ${itemId}`);
                setEditMode(null); // Exit edit mode
                setModifiedItems((prev) => ({ ...prev, [itemId]: undefined })); // Clear modified item
                await handleConsultation(); // Refresh the data
            } catch (error) {
                console.error("Error saving item:", error);
            }
        } else {
            setEditMode(null); // Exit edit mode
            setModifiedItems((prev) => ({ ...prev, [itemId]: undefined })); // Clear modified item
        }
    };

    const handleChange = (itemId: number, field: string, value: any) => {
        setModifiedItems((prev) => ({
            ...prev,
            [itemId]: {
                ...prev[itemId],
                [field]: value,
            },
        }));
    };

    const toggleFormAddItems = () => setShowFormAddItems(!showFormAddItems);


    return (
        <div className="bg-white rounded-lg grid grid-cols-1 gap-4">

            <div>
                <form className="flex flex-col">
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                        <div className="col-span-1">
                            <Controller
                                control={control}
                                name="date_expirated"
                                render={({ field }) => (
                                    <>
                                        <Input
                                            {...field}
                                            isInvalid={!!errors.date_expirated}
                                            type="date"
                                            label="Fecha Vencimiento"
                                            placeholder="Elija fecha Vencimiento"
                                            variant="bordered"
                                        />
                                        {errors.date_expirated && (
                                            <span style={{ color: 'red', fontSize: '12px' }}>
                                                {errors.date_expirated.message}
                                            </span>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                        <div className="col-span-1">
                            <Controller
                                control={control}
                                name="month"
                                render={({ field }) => (
                                    <>
                                        <Input
                                            {...field}
                                            isInvalid={!!errors.month}
                                            type="month"
                                            label="Año y mes a facturar"
                                            variant="bordered"
                                        />
                                        {errors.month && (
                                            <span style={{ color: 'red', fontSize: '12px' }}>
                                                {errors.month.message}
                                            </span>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                </form>

                <div className="my-8">
                    <div className="flex justify-end mb-2 ">
                        <button
                            className="w-50  bg-gray-500 text-white py-2 px-4 rounded mb-4"
                            onClick={toggleFormAddItems}
                        >
                            Agregar Item
                        </button>
                    </div>
                    <div className="overflow-x-auto">                    
         
                    <table className="min-w-full border-collapse border border-gray-300">
                        <thead className="bg-primary text-white">
                            <tr>
                                <th className="border border-gray-300 p-2">ID</th>
                                <th className="border border-gray-300 p-2">Tipo</th>
                                <th className="border border-gray-300 p-2">Detalle</th>
                                <th className="border border-gray-300 p-2">Monto</th>
                                <th className="border border-gray-300 p-2">Cantidad</th>
                                <th className="border border-gray-300 p-2">Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.map((item: any) => (
                                <tr key={item.id} className="border-b border-gray-300">
                                    <td className="border border-gray-300 p-2">{item.id}</td>
                                    <td className="border border-gray-300 p-2">{item.type === 'device' ? item.device_type_name : item.service_name}</td>
                                    <td className="border border-gray-300 p-2">
                                        {editMode === item.id ? (
                                            <Input
                                                value={modifiedItems[item.id]?.details || item.details}
                                                onChange={(e) => handleChange(item.id, 'details', e.target.value)}
                                            />
                                        ) : (
                                            `${item.details}`
                                        )}
                                    </td>
                                    <td className="border border-gray-300 p-2">
                                        {editMode === item.id ? (
                                            <Input
                                                value={modifiedItems[item.id]?.amount || item.amount}
                                                type="number"
                                                onChange={(e) => handleChange(item.id, 'amount', e.target.value)}
                                            />
                                        ) : (
                                            item.amount
                                        )}
                                    </td>
                                    <td className="border border-gray-300 p-2">
                                        {editMode === item.id ? (
                                            <Input
                                                value={modifiedItems[item.id]?.count || item.count}
                                                type="number"
                                                onChange={(e) => handleChange(item.id, 'count', e.target.value)}
                                            />
                                        ) : (
                                            item.count
                                        )}
                                    </td>
                                    <td className="border border-gray-300 p-2">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {editMode === item.id ? (
                                                <FaSave
                                                    onClick={() => handleSave(item.id, item.type)}
                                                    style={{ cursor: 'pointer', marginRight: '10px', color: 'green' }}
                                                />
                                            ) : (
                                                <FaEdit
                                                    onClick={() => handleEdit(item.id)}
                                                    style={{ cursor: 'pointer', marginRight: '10px' }}
                                                />
                                            )}
                                            <FaRegTrashCan 
                                                onClick={() => handleDelete(item.id, item.type)}
                                                style={{ cursor: 'pointer', color: 'red' }}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
            {showFormAddItems && (
                <div className="my-8">
                    <FormAddItems reload={handleConsultation} data={data} show={toggleFormAddItems} />
                </div>
            )}
        </div>

    );
});
