import { useMemo } from "react";
import { getCookie } from "../config/cookies";


export interface Users {
  id: number
  name: string
  lastname: string
  identification: string
  phone: any
  email: string
  company: number
  company_name: string
  is_active: boolean
  is_superuser: boolean
  permissions: any| string[]
  groups: any |string[]
  menus: Menu[]
}

export interface Menu {
  id: number
  name: string
  path: string
  icon: string
  order: number
  parent: any
  children?: Children[]
}

export interface Children {
  id: number
  name: string
  path: string
  icon: string
  order: number
  parent: number
}

export const useUserMovilPay = (): Users => {
  const data = useMemo(() => {
    const local =getCookie("user") ?? "{}";
    return local;
  }, []);

  return data;
};


export const usePermissionsMovilPay = (): string[] => {
  const data = useMemo(() => {
    const local =getCookie("permissions") ?? "[]";
    return local;
  }, []);

  return data;
};

export const usePermissionsMenuMovilPay = (): string[] => {
  const data = useMemo(() => {
    const local =getCookie("menuPermissions") ?? "[]";
    return local;
  }, []);

  return data;
};
