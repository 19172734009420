import { createAsyncThunk } from "@reduxjs/toolkit";
import { DevicesParams } from "../../../pages/core/params/management/devicesParams";
import { movilPayAPI } from "../../../api/movilPayAPI";

export const ModelsThunks = createAsyncThunk(
  "models",
  async (params?: DevicesParams) => {
    return movilPayAPI
      .get("/devices/model/", { params })
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        return [];
      });
  }
);

export const ModelIDThunks = createAsyncThunk(
  "models",
  async (args: { id?: any; params?: any }) => {
    const { id, params } = args;
    try {
      const result = await movilPayAPI.get(`/devices/model/${id}/`, {
        params,
      });
      return result.data;
    } catch (err) {
      console.error(err); // Manejo de errores
      return [];
    }
  }
);

export const PostModelsThunks = (body: any) => {
  return movilPayAPI.post(`/devices/model/`, body);
};
export const PatchModelThunks = (body: any, id: number) => {
  return movilPayAPI.patch(`/devices/model/${id}/`, body);
};

export const DeleteModelThunks = (id: any) => {
  return movilPayAPI.delete(`/devices/model/${id}`);
};
