import { create } from "zustand";
import { ParishAll } from "../../../pages/core/interfaces/LocationsAll";

interface ParishState {
  parish: ParishAll[];
  count: number;
  setParish: (parish: ParishAll[], count: number) => void;
  resetParish: () => void;
}

export const useStoreParishCore = create<ParishState>((set) => ({
    parish: [],
  count: 0,
  setParish: (parish: ParishAll[], count: number) =>
    set(() => ({ parish, count })),
  resetParish: () => set(() => ({ parish: [], count: 0 })),
}));
