import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Checkbox, Input, Select, SelectItem } from '@nextui-org/react';
import { useStoreCreateInvoice } from '../../../../../../../../../store/finances/useStoreCreateInvoice';
import { MdFormatListBulletedAdd } from 'react-icons/md';
import ItemSchema from '../../validators/itemSchema';
import { FinancesServicesParams } from '../../../../../../../params/finances/FinancesServicesParams';
import { addItemServicesThunks, getInvoiceCompanyServicesThunks } from '../../../../../../../../../store/finances/Thunks';
import { useQueryClient } from '@tanstack/react-query';
import { ErrorToast, SuccessToast } from '../../../../../../../../../libs/Notifications';



interface Props {
    invoiceData: any;
    reload: () => void;
    show: () => void;

}

const FormAddItemService = ({ invoiceData, reload, show }: any) => {
    const [services, setServices] = useState<any>([]);
    const queryClient = useQueryClient();
    const { control, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(ItemSchema),
        defaultValues: {
            service: '',
            service_name: '',
            cant: '1',
            description: '',
            price: '',
            type: '',
            serial: '',
            service_type: '',
            exentoIVA: false, // Default value for Exento de IVA
        },
    });

    const selectedService = watch('service');
    const selectedType = watch('type');
    const exentoIVA = watch('exentoIVA'); // Watching the new field

    useEffect(() => {
        if (selectedService) {
            const service = services.find((s: any) => s.id === Number(selectedService));
            if (service) {
                setValue('price', service.price || '');
                setValue('service_name', service.name || '');
                setValue('service_type', service.price != null ? '1' : '2');
            }
        }
    }, [selectedService, setValue]);

    const selectedServiceType = watch('service_type');

    useEffect(() => {
        if (selectedServiceType === '2') {
            setValue('cant', '1');
            if (selectedService === '12') { // Ventas
                setValue('type', 'masivas');
            }
        }
    }, [selectedServiceType, selectedService, setValue]);

    const getServices = async () => {
        const param = new FinancesServicesParams();
        param.company = invoiceData.company;

        // Fetch data from API and store result in servicesData state
        const data = await queryClient.fetchQuery({
            queryKey: ["services", invoiceData.company],
            queryFn: () => getInvoiceCompanyServicesThunks(param),
            retry: false,
        });

        // Log and set the API response
        console.log('API Response:', data.data);
        setServices(data?.data?.results || []); // Update state with the results if available
    };

    useEffect(() => {
        if (invoiceData.company) {
            getServices();
        }
    }, [invoiceData.company]);


    const onSubmit = (data: any) => {

        const iva = (data.price * data.cant) * 0.16;



        const item = {
            "service": data.service,
            "details": data.serial!='' ?`${data.description} serial: ${data.serial}` : data.description,
            "amount": data.price,
            "count": data.cant,
            "tax": data.exentoIVA ? null : 1,
            "invoice": parseInt(invoiceData.id)
        }

        addItemServicesThunks(item)
            .then((result) => {
                SuccessToast("Servicio agregado a Factura.");
                reset()
                reload();
                show();

            })
            .catch(({ response }) => {
                if (response?.status === 400) {
                    // Verificar si hay un mensaje de error en el cuerpo de la respuesta
                    const errorMessage = Object.values(response.data || {}).join(', ') || "Error desconocido";
                    return ErrorToast(errorMessage);
                }
                ErrorToast("Hubo un error al agregar Item.");
                return;
            });

    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-1 lg:grid-cols-3  gap-4">
            <div className="col-span-1">
                    <Controller
                        control={control}
                        name="service"
                        render={({ field }) => (
                            <Select
                                {...field}
                                onChange={(e) => field.onChange(e)}
                                isInvalid={!!errors.service}
                                placeholder="Selecciona el servicio"
                                variant="bordered"
                            >
                                {services.map((service: any) => (
                                    <SelectItem key={service.id} value={service.id}>
                                        {service.name}
                                    </SelectItem>
                                ))}
                            </Select>
                        )}
                    />
                    {errors.service && (
                        <span style={{ color: 'red', fontSize: '12px' }}>
                            {errors.service.message}
                        </span>
                    )}
                </div>

                {selectedServiceType === '2' && selectedService === '11' && (
                    <div className="col-span-1">
                        <Controller
                            name="type"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    placeholder="Selecciona tipo"
                                    variant="bordered"
                                    onChange={(e) => setValue('type', e.target.value)}
                                >
                                    <SelectItem value="masivas" key={'masivas'}>Masivas</SelectItem>
                                    <SelectItem value="unica" key={'unica'}>Unica</SelectItem>
                                </Select>
                            )}
                        />
                    </div>
                )}

                {(selectedType === 'unica' || selectedService === '13') && (
                    <div className="col-span-1">
                        <Controller
                            name="serial"
                            control={control}
                            render={({ field }) => (
                                <Input
                                    {...field}
                                    placeholder="Serial del dispositivo"
                                    variant="bordered"
                                    isInvalid={!!errors.serial}
                                />
                            )}
                        />
                    </div>
                )}

                <div className="col-span-1">
                    <Controller
                        name="description"
                        control={control}
                        render={({ field }) => (
                            <Input
                                {...field}
                                placeholder="Descripcion"
                                variant="bordered"
                                isInvalid={!!errors.description}
                            />
                        )}
                    />
                    {errors.description && (
                        <span style={{ color: 'red', fontSize: '12px' }}>
                            {errors.description.message}
                        </span>
                    )}
                </div>



                <div className="col-span-1">
                    <Controller
                        name="cant"
                        control={control}
                        render={({ field }) => (
                            <Input
                                {...field}
                                placeholder="Cantidad"
                                type="number"
                                variant="bordered"
                                isInvalid={!!errors.cant}
                                readOnly={(selectedService === '11' && selectedType === 'unica') || selectedService === '13'}
                                value={field.value ?? 1}
                            />
                        )}
                    />
                    {errors.cant && (
                        <span style={{ color: 'red', fontSize: '12px' }}>
                            {errors.cant.message}
                        </span>
                    )}
                </div>

                <div className="col-span-1">
                    <Controller
                        name="price"
                        control={control}
                        render={({ field }) => (
                            <Input
                                {...field}
                                placeholder="Precio"
                                type="text"
                                variant="bordered"
                                value={field.value ?? ""}
                                isInvalid={!!errors.price}
                            />
                        )}
                    />
                    {errors.price && (
                        <span style={{ color: 'red', fontSize: '12px' }}>
                            {errors.price.message}
                        </span>
                    )}
                </div>

                {selectedServiceType === '2' && (
                    <div className="col-span-1 mt-4">
                        <Controller
                            name="exentoIVA"
                            control={control}
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Checkbox
                                    checked={value}
                                    onChange={(e) => {
                                        const checked = e.target.checked;
                                        onChange(checked);
                                    }}
                                >
                                    Exento de IVA
                                </Checkbox>
                            )}
                        />
                    </div>
                )}



                <button
                    type="submit"
                    className=" w-40 h-14 bg-primary  text-white flex items-center p-2 rounded-xl hover:bg-secondary transition"
                >
                    <MdFormatListBulletedAdd className="text-2xl text-white mr-2" />
                    Agregar Item
                </button>

            </form>
        </div>
    );
};

export default FormAddItemService;
