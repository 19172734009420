import { createSlice } from "@reduxjs/toolkit";
import { TypeCompanyThunks } from "./thunks";


export interface initialStateTypeCompany {
  isLoading: boolean;
  TypeCompany:any[];
  count:number 
  page?:number
}

export const initialStateTypeCompany: initialStateTypeCompany = {
  isLoading: false,
  count: 0,
  TypeCompany:[]
};

export const TypeCpmpanySlice = createSlice({
  name: "typecompany",
  initialState: initialStateTypeCompany,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(TypeCompanyThunks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(TypeCompanyThunks.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.TypeCompany = payload.results || payload
        state.count = payload.count
      });
  },
});

export const {} = TypeCpmpanySlice.actions;