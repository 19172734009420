import { SelectItem, Select } from "@nextui-org/react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../../store/store";
import { useEffect, useState } from "react";
import {
  BanksThunks,
  GetBanksThunks,
} from "../../../../../../../store/banks/thunks";
import { useUserMovilPay } from "../../../../../../../hooks/useUserMovilPay";
import { useAllParams } from "../../../../../../../hooks/useAllParams";
import { useThemeMovilPay } from "../../../../../../../hooks/useTheme";
import { useStoreBank } from "../../../../../../../store/banks/useStoreBank";
import { Banks } from "../../../../../interfaces/BanksInterfaces";
import { queryOptions, useQueryClient } from "@tanstack/react-query";
import { PaymentParams } from "../../../../../params/payment/payments/paymentParams";
import { ErrorToast } from "../../../../../../../libs/Notifications";

export const BanksDestinyOriginPayments = () => {
  const { params, setSearchParams } = useAllParams();
  const { darkMode } = useThemeMovilPay();
  const { id } = useUserMovilPay();
  const dispatch = useDispatch<AppDispatch>();
  const queryClient = useQueryClient();
  const [bankOrigin, setBankOrigin] = useState<Banks[]>([]);
  const [bankDestiny, setBankDestiny] = useState<Banks[]>([]);

  const handleBankOrigin = () => {
    const param: PaymentParams = new PaymentParams();
    param.company = id;
    param.show = true;

    queryClient.fetchQuery(
      queryOptions({
        queryKey: ["bankorigin"],
        queryFn: () =>
          GetBanksThunks(param)
            .then((d) => {
              setBankOrigin(d.data);
            })
            .catch(({ response }) => {
              if (response?.status === 400) {
                const errorMessage =
                  Object.keys(response.data || {})
                    .join(", ")
                    .concat(
                      ": " + Object.values(response.data || {}).join(", ")
                    ) ||
                  Object.values(response.data || {}).join(", ") ||
                  "Error desconocido";
                return ErrorToast(errorMessage);
              }
            }),
        retry: false,
      })
    );
  };
  const handleBankDestiny = () => {
    const param: PaymentParams = new PaymentParams();
    param.company = id;
    param.work = true;

    queryClient.fetchQuery(
      queryOptions({
        queryKey: ["bankdestiny"],
        queryFn: () =>
          GetBanksThunks(param)
            .then((d) => {
              setBankDestiny(d.data);
            })
            .catch(({ response }) => {
              if (response?.status === 400) {
                const errorMessage =
                  Object.keys(response.data || {})
                    .join(", ")
                    .concat(
                      ": " + Object.values(response.data || {}).join(", ")
                    ) ||
                  Object.values(response.data || {}).join(", ") ||
                  "Error desconocido";
                return ErrorToast(errorMessage);
              }
            }),
        retry: false,
      })
    );
  };
  const destinyOptions = bankDestiny?.map((bank) => (
    <SelectItem key={bank.id} value={bank.id}>
      {bank.name}
    </SelectItem>
  ));

  const originOptions = bankOrigin?.map((bank) => (
    <SelectItem key={bank.id} value={bank.id}>
      {bank.name}
    </SelectItem>
  ));

  const origin = ({ target: { value } }: any) => {
    const { page, search, bank_origin, bank_destiny, ...restParams } = params;

    if (!value) {
      const payload = { ...restParams };
      return setSearchParams(payload);
    }

    const payload = { ...restParams, bank_origin: value };
    setSearchParams(payload);
  };
  const destiny = ({ target: { value } }: any) => {
    const { page, search, bank_destiny, ...restParams } = params;
    if (!value) {
      const payload = { ...restParams };
      return setSearchParams(payload);
    }

    const payload = { ...restParams, bank_destiny: value };

    setSearchParams(payload);
  };
  useEffect(() => {
    handleBankDestiny();
    handleBankOrigin();
    // dispatch(BanksThunks({ company: id }));
  }, []);
  return (
    <div className="space-y-3 flex flex-col">
      <div>
      <Select
          className="w-full text-white"
          variant="bordered"
          label="Banco de origen"
          labelPlacement="outside"
          onChange={origin}
          placeholder="Selecciona el banco origen"
          scrollShadowProps={{
            isEnabled: false,
          }}
        >
          {originOptions}
        </Select>
      </div>
      <div>
       
        <Select
          className="w-full "
          variant="bordered"
           label="Banco de destino"
          labelPlacement="outside"
          onChange={destiny}
          placeholder="Selecciona el banco destino"
          scrollShadowProps={{
            isEnabled: false,
          }}
        >
          {destinyOptions}
        </Select>
      </div>
    </div>
  );
};
