// store/deviceStore.ts
import { create } from "zustand";
import { ServicesCompany } from "../../../pages/core/interfaces/ServicesCompany";

interface ServiceCompanyState {
  serviceCompany: ServicesCompany[];
  count: number;
  setServiceCompany: (serviceCompany: ServicesCompany[], count: number) => void;
  reset: () => void;
}

export const useStoreServiceCompanyCore = create<ServiceCompanyState>((set) => ({
  serviceCompany: [],
  count: 0,
  setServiceCompany: (serviceCompany: ServicesCompany[], count: number) =>
    set(() => ({ serviceCompany, count })),
  reset: () => set(() => ({ serviceCompany: [], count: 0 })),
}));
