import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import bankAccountSchema from "../validators/BankAccountSchema";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../store/store";
import { BanksThunks } from "../../../../../../store/banks/thunks";
import { Input, Select, SelectItem } from "@nextui-org/react";
import { BanksAccountsParams } from "../../../../params/banks/BanksAccountsParams";
import { PaymentParams } from "../../../../params/payment/payments/paymentParams";

type Props = {
  data?: any | {};
  setData?: (data: any) => void;
  onSubmit: (data: any) => void;
  ref?: React.MutableRefObject<(() => void) | null>;
};

export const FormBankAccount = React.forwardRef(
  ({ data, onSubmit }: Props, ref) => {
    const param = new PaymentParams();

    const {
      control,
      handleSubmit,
      formState: { errors },
      setValue,
    } = useForm({
      resolver: yupResolver(bankAccountSchema),
    });

    const dispatch = useDispatch<AppDispatch>();

    // Usamos useImperativeHandle para exponer el handleSubmit a través del ref.
    React.useImperativeHandle(ref, () => handleSubmit(onSubmit));
    const { banks } = useSelector((state: RootState) => state.banks);

    const handleConsultation = () => {
      param.show = true;
      dispatch(BanksThunks(param));
    };

    React.useEffect(() => {
      handleConsultation();
    }, []);

    // Efecto para setear los valores del formulario cuando `props.data` cambia
    React.useEffect(() => {
      if (data) {
        setValue("bank", data.bank || "");
        setValue("identification", data.identification || "");
        setValue("accountNumber", data.nro_cta || "");
        setValue("phone", data.tlf || "");
        // Asegúrate de que este valor coincida con el value de los SelectItem
      }
    }, [data, setValue]);

    return (
      <form className="flex flex-col space-y-2">
        <div className="relative">
          <Controller
            control={control}
            name="bank"
            render={({ field }) => (
              <Select
                {...field}
                selectedKeys={field.value ? new Set([field.value]) : new Set()} // Asegura que el servicio seleccionado aparezca
                placeholder="Selecciona la cuenta a asociar "
                className="w-full"
                variant="bordered"
                isInvalid={!!errors.bank}
                errorMessage={errors.bank?.message}
              >
                {banks.map((bank: any) => (
                  <SelectItem key={bank.id} value={bank.id}>
                    {bank.name}
                  </SelectItem>
                ))}
              </Select>
            )}
          />
        </div>
        <div className="">
          <Controller
            control={control}
            name="identification"
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  type="text"
                  placeholder="Identificacion"
                  variant="bordered"
                  isInvalid={!!errors.identification}
                  errorMessage={errors.identification?.message}
                />
              </>
            )}
          />
        </div>
        <div className="">
          <Controller
            control={control}
            name="accountNumber"
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  type="text"
                  placeholder="N de Cuenta"
                  variant="bordered"
                  isInvalid={!!errors.accountNumber}
                  errorMessage={errors.accountNumber?.message}
                />
              </>
            )}
          />
        </div>
        <div className="">
          <Controller
            control={control}
            name="phone"
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  type="text"
                  placeholder="Telefono asociado al banco"
                  variant="bordered"
                  isInvalid={!!errors.phone}
                  errorMessage={errors.phone?.message}
                />
              </>
            )}
          />
        </div>
      </form>
    );
  }
);
