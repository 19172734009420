import { Pagination } from "@nextui-org/react";
import { useAllParams } from "../../../../../../../hooks/useAllParams";
import { PLayouts } from "../../../../../layout/PLayouts";
interface Props{
  count: number; // type: number, total items in the API call to get payments. 10 items per page. 10000 items in total. 10000 / 10 = 1000 pages. 1000 pages * 10 items per page = 10000 items. 10000 items / 10 items per page = 1000 pages. 1000 pages * 10 items per page = 10000 items. 10000 items / 10 items per page = 1000 pages. 1000 pages * 10 items per page = 10000 items. 10000 items / 10 items per page = 1000 pages. 1000 pages * 10 items per page =
}

export const PaginatorPayments = ({count}: Props) => {
  const {
    addParams,
    deleteParams,
    params: { page },
  } = useAllParams();

  // const { count, payments } = useStorePaymentCore((state: any) => state);

  const pages = Math.ceil(count / 10);
  const handlePages = (pageFunction: number) => {
    if (pageFunction == 1) {
      deleteParams(["page"]);
      return;
    }
    addParams({ page: pageFunction });
  };

  return (
    <div className="flex w-[100%] lg:justify-between justify-end items-end">
      <div className="lg:flex hidden pt-4">
        <PLayouts message={`Total de items ${count}`} />
      </div>
      {count > 0 && (
        <Pagination
          isCompact
          showControls
          showShadow
          color="primary"
          size="lg"
          radius="full"
          page={!!page ? Number(page) : 1}
          total={pages}
          onChange={handlePages}
        />
      )}
    </div>
  );
};
