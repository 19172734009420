// @flow
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "@nextui-org/react";
import * as React from "react";
import { isMobile } from "react-device-detect";
type Props = {
  header?: JSX.Element | React.ReactNode;
  body?: JSX.Element | React.ReactNode;
  titleButton?: string;
  title: string;
  isOpen: boolean;
  onOpenChange: () => void;
  onOpen?: () => void;
  handleSubmitClick?: () => void;
  onClose?: () => void;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "full";
  scrollBehavior?: "inside" | "outside" | "normal";
  placement?: "top" | "bottom" | "center" | "auto";
};
export const ModalComponent = ({
  body,
  isOpen,
  onOpen,
  onOpenChange,
  header,
  handleSubmitClick,
  onClose,
  size ="xl",
  scrollBehavior ="normal",
  placement ="auto",
}: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      size={isMobile ? "md" : size}
      scrollBehavior={scrollBehavior}
      placement={placement}
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex text-2xl ">{header}</ModalHeader>
            <ModalBody className="overflow-y-auto  scrollbar-none">{body}</ModalBody>
            <ModalFooter>
              {handleSubmitClick &&
                FooterModalComponent(handleSubmitClick, onClose)}
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

const FooterModalComponent = (
  onSubmit: () => void,
  onClose: () => void
): JSX.Element | React.ReactNode => {
  return (
    <div className="flex flex-col justify-end gap-y-2 w-full">
      <Button
        size="md"
        type="submit"
        onPress={onSubmit}
        color="primary"
        className="p-3 rounded-full  font-semibold bg-primary"
      >
        Guardar
      </Button>
      <Button
        size="md"
        type="button"
        onPress={onClose}
        variant="bordered"
        color="primary"
        className="p-3 rounded-full font-semibold "
      >
        Cancelar
      </Button>
    </div>
  );
};
