import { createAsyncThunk } from "@reduxjs/toolkit";
import { ServicesConfigParams } from "../../../pages/core/params/services-configs/ServicesConfigParams";
import { movilPayAPI } from "../../../api/movilPayAPI";

export const ServicesCompaniesThunks = createAsyncThunk(
  "servicesCompanies",
  async (params?: ServicesConfigParams) => {
    return movilPayAPI
      .get("/companies/service/", { params })
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        return [];
      });
  }
);

export const ServicesCompaniesIDThunks = createAsyncThunk(
  "servicesCompaniesID",
  async (args: { id?: any; params?: any }) => {
    const { id, params } = args;
    try {
      const result = await movilPayAPI.get(`/companies/${id}/service/`, {
        params,
      });
      return result.data;
    } catch (err) {
      console.error(err); // Manejo de errores
      return [];
    }
  }
);

export const GetServicesCompaniesThunks = (
  params?: ServicesConfigParams,
  id?: any
) => {
  return movilPayAPI.get(`/companies/${id}/service/`, { params });
};

export const PostServicesCompaniesThunks = (body: any, id: any) => {
  return movilPayAPI.post(`/companies/${id}/service/`, body);
};

export const PatchServicesCompaniesThunks = (body: any, id: any) => {
  return movilPayAPI.patch(`/companies/service/${id}/`, body);
};
