import { useDisclosure, Button } from "@nextui-org/react";
import { useRef } from "react";
import { ErrorToast,  SuccessToast } from "../../../../../../libs/Notifications";
import { ModalComponent } from "../../../../components/Modal/ModalComponent";
import { PopoverConfirm } from "../../../../components/Popover/PopoverConfirm";
import { FormBankAccount } from "./FormBankAccount";
import { DeleteBanksAccounts, UpdateBanksAccounts } from "../../../../../../store/banks-accounts/Thunks";
import { LuFileEdit } from "react-icons/lu";
import { FaRegTrashCan } from "react-icons/fa6";

type Props = {
  data?: any;
  reload: () => void;
};

export const Action = ({ data, reload }: Props) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const formRef = useRef<() => void | null>(null);
  const handleFormSubmit = (formData: any) => {
    const body ={
        "bank": formData.bank,
        "identification": formData.identification,
        "nro_cta": formData.accountNumber,
        "tlf": formData.phone
      }
    UpdateBanksAccounts(body, data.id)
      .then((result) => {
        SuccessToast("Cuenta actualizada correctamente");
        onClose();
        reload();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
            // Verificar si hay un mensaje de error en el cuerpo de la respuesta
            const errorMessage =  Object.values(response.data || {}).join(', ') || "Error desconocido";
            return ErrorToast(errorMessage);
          }
      });
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current();
    }
  };
  return (
    <>
      <Button
        isIconOnly
        variant="light"
        color="primary"
        size="sm"
        onPress={onOpen}
      >
        <LuFileEdit  className={`font-semibold h-6 w-6 `} />
      </Button>
      <ModalComponent
        header={`Editar datos de la cuenta`}
        body={
          <FormBankAccount
            data={data}
            onSubmit={handleFormSubmit}
            ref={formRef}
          />
        }
        handleSubmitClick={handleSubmitClick}
        onClose={onClose}
        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        title={""}
      />
    </>
  );
};

export const ActionDelete = ({ data, reload }: Props) => {
  const handleDelete = () => {
  
    DeleteBanksAccounts(data.id)
      .then((result) => {
        SuccessToast("Cuenta eliminada correctamente");
        reload();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          // Verificar si hay un mensaje de error en el cuerpo de la respuesta
          const errorMessage =  Object.values(response.data || {}).join(', ') || "Error desconocido";
          return ErrorToast(errorMessage);
        }
  
        ErrorToast("Hubo un error al eliminar la Cuenta.");
      });
  };
  

  return (
    <PopoverConfirm
      title="Confirmar Eliminación"
      message="¿Estás seguro de que deseas eliminar este elemento?"
      confirm={handleDelete} // Esta función se ejecutará al confirmar
      item={undefined}
    >
      <Button isIconOnly variant="light" color="danger" size="sm">
        <FaRegTrashCan className="font-semibold h-6 w-6" />
      </Button>
    </PopoverConfirm>
  );
};
