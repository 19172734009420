import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { SidebarCore } from "../components/SidebarCore";
import { Loading } from "../../../components/Loading";
import {
  Statistics,
  Setting,
  Report,
  Companies,
  Management,
} from "../data/ItemRouter";
import { AllCompanies, CreateCompanies } from "../views/companies";
import { CompanyCore } from "../views/companies/company/CompanyCore";
import { BreadCrumbCore } from "../components/BreadCrumb/BreadCrumbCore";
import FinancesCore from "../views/finances/FinancesCore";
import PaymentCore from "../views/finances/payments/PaymentCore";
import { ColletionsCore } from "../views/finances/collections/ColletionsCore";
import { UsuariosCore } from "../views/setting/usuarios/UsuariosCore";
import { ServicesCore } from "../views/setting/services/ServicesCore";
import { TracingTabs } from "../views/companies/company/FallowUp/TracingTabs";

import { InvoicesComponent } from "../views/companies/company/invoices/InvoicesComponents";
import { ServicesCompanyCore } from "../views/companies/company/services/ServicesCompanyCore";
import { MethodPaymentsCompany } from "../views/companies/company/methodPayments/MethodPaymentsCompany";
import { InfoCompany } from "../views/companies/company/InfoCompany/InfoCompany";
import SupportsCore from "../views/supports/SupportsCore";
import TicketsCore from "../views/supports/tickets/TicketsCore";
import { PermissionsCore } from "../views/setting/permission/PermissionsCore";
import { DevicesCore } from "../views/deviceManagement/devices/DevicesCore";
import { WarehouseCore } from "../views/deviceManagement/warehouse/WarehouseCore";
import { DashBoardCompanyID } from "../views/companies/company/dashboardCompany/DashBoardCompanyID";
import { ServicesCompanyID } from "../views/companies/company/services/ServicesCompanyID";
import { ServicesCompanies } from "../views/companies/company/services/ServicesCompanies";
import { ManufacturersCore } from "../views/deviceManagement/manufacturers/ManufacturersCore";
import { ModelDevicesCore } from "../views/deviceManagement/model-devices/ModelDevicesCore";
import { HistoryDataServices } from "../views/companies/company/services/devices/OptionsTable";
import { HistoryServicesTransactions } from "../views/companies/company/services/HistoryServicesTransactions";
import { AsociatedBanksComponent } from "../views/companies/company/associatedBanks/AsociatedBanksComponent";
import { BanksCore } from "../views/setting/banks/BanksCore";
import { RatesCore } from "../views/setting/rates/RatesCore";
import { ConfigServiceTangible } from "../views/companies/company/services/snp/ConfigServiceIntangible";
import { ConfigServicesDevices } from "../views/companies/company/services/devices/ConfigServicesDevices";
import { CreateCompanyCore } from "../views/companies/create/CreateCompanyCore";
import InvoicesCore from "../views/finances/invoices/InvoicesCore";
import { FormInvoice } from "../views/finances/invoices/components/forms-invoices/FormInvoice";
import { PaymentInvoiceCore } from "../views/finances/invoices/components/payment-invoices/PaymentInvoiceCore";
import AccountsCore from "../views/accounts/AccountsCore";
import { Profile } from "../views/accounts/profile/Profile";
import { MyCompany } from "../views/accounts/myCompany/MyCompany";
import {PaymentsCompanyCore} from "../views/companies/company/paymentsCompany/PaymentsCompany";
import { RetentionsCore } from "../views/setting/retentions/RetentionsCore";
import { LocationsCore } from "../views/setting/locations/LocationsCore";
import { ParishCore } from "../views/setting/locations/Parish/ParishCore";
import { SectorCore } from "../views/setting/locations/Sector/SectorCore";

export const CoreRouters = () => {
  return (
    <>
      <SidebarCore>
        <Suspense
          fallback={
            <div className="flex justify-center items-center">
              <Loading />
            </div>
          }
        >
          <>
            {/* {getTitle} */}
            {/* <BreadCrumbCore /> */}
            <Routes>
              <Route path="/" element={<Navigate to="dashboard/" />} />
              <Route path="dashboard/" element={<Statistics />} />
              <Route path="accounts/" element={<AccountsCore />}>
                <Route index element={<Navigate to="profile/" />} />
                <Route path="profile/" element={<Profile />} />
                <Route path="my_company/" element={<MyCompany />}>
                  <Route index element={<Navigate to="info_company/" />} />
                  <Route path="dashboard/" element={<DashBoardCompanyID />} />
                  <Route path="fallow_up/" element={<TracingTabs />} />
                  <Route path="info_company/" element={<InfoCompany />} />
                  <Route path="invoices/" element={<InvoicesCore />} />
                  <Route path="payments/" element={<PaymentsCompanyCore />} />
                  <Route path="invoices/payment/:idInvoice/" element={<PaymentInvoiceCore />} />

                  {/* <Route
                    path="associated_banks/"
                    element={<AsociatedBanksComponent />}
                  /> */}
              <Route path="affiliate_accounts/" element={<MethodPaymentsCompany />} />

                  <Route path="services/" element={<ServicesCompanyCore />}>
                    <Route index element={<ServicesCompanies />} />
                    <Route path=":item/" element={<ServicesCompanyID />} />
                    <Route
                      path=":item/history/:serviceId/"
                      element={<HistoryServicesTransactions />}
                    />
                  </Route>
                  <Route path="devices/" element={<ServicesCompanyCore />}>
                    <Route index element={<ConfigServicesDevices />} />
                  </Route>
                  <Route path="*" element={<Navigate to="./" />} />
                </Route>
              </Route>
              <Route path="setting/" element={<Setting />}>
                <Route path="users/" element={<UsuariosCore />} />
                <Route path="services/" element={<ServicesCore />} />
                <Route path="permissions/" element={<PermissionsCore />} />
                <Route path="rates/" element={<RatesCore />} />
                <Route path="retentions/" element={<RetentionsCore />} />
                <Route path="banks/" element={<BanksCore />} />
                <Route path="ubications/" element={<LocationsCore />} >
                <Route index element={<Navigate to="parish/" />} />
                <Route  path="parish" element={<ParishCore />} />
                <Route  path="sector" element={<SectorCore />} />
                </Route>
              </Route>
              <Route path="reports/" element={<Report />} />
              <Route path="finance/" element={<FinancesCore />}>
                <Route index element={<Navigate to="payments/" />} />
                <Route path="payments/" element={<PaymentCore />} />
                <Route path="collections/" element={<ColletionsCore />} />
                <Route path="invoices/" element={<InvoicesCore />} />
                <Route path="invoices/create/" element={<FormInvoice />} />
                <Route path="invoices/payment/:idInvoice/" element={<PaymentInvoiceCore />} />
                <Route path="*" element={<Navigate to="./" />} />
              </Route>
              <Route path="companies/" element={<Companies />}>
                <Route index element={<AllCompanies />} />
                {/* Rutas del create */}
                <Route path="create/" element={<CreateCompanyCore />}>
                  <Route index element={<Navigate to="paso/1" />} />
                  <Route path="paso/:id" element={<CreateCompanies />} />
                </Route>
                <Route path="company/:id/" element={<CompanyCore />}>
                <Route index element={<Navigate to="info_company/" />} />
                  <Route path="dashboard/" element={<DashBoardCompanyID />} />
                  <Route path="fallow_up/" element={<TracingTabs />} />
                  <Route path="info_company/" element={<InfoCompany />} />
                  <Route path="invoices/" element={<InvoicesCore />} />
                  <Route path="payments/" element={<PaymentsCompanyCore />} />
                    <Route path="invoices/payment/:idInvoice/" element={<PaymentInvoiceCore />} />
                  <Route path="affiliate_accounts/" element={<MethodPaymentsCompany />} />
                  {/* <Route
                    path="associated_banks/"
                    element={<AsociatedBanksComponent />}
                  /> */}
                  <Route path="services/" element={<ServicesCompanyCore />}>
                    <Route index element={<ServicesCompanies />} />
                    <Route path=":item/" element={<ServicesCompanyID />} />
                    <Route
                      path=":item/history/:serviceId/"
                      element={<HistoryServicesTransactions />}
                    />
                  </Route>
                  <Route path="devices/" element={<ServicesCompanyCore />}>
                    <Route index element={<ConfigServicesDevices />} />
                  </Route>
                </Route>
                <Route path="*" element={<Navigate to="./" />} />
              </Route>
              <Route path="collections/" element={<Companies />} />
              <Route path="*" element={<Navigate to="/" />} />
              <Route path="supports/" element={<SupportsCore />}>
                <Route path="tickets/" element={<TicketsCore />}></Route>
              </Route>
              <Route path="management/" element={<Management />}>
                <Route path="devices/" element={<DevicesCore />}></Route>
                <Route path="warehouse/" element={<WarehouseCore />}></Route>
                <Route
                  path="manufacturers/"
                  element={<ManufacturersCore />}
                ></Route>
                <Route path="models/" element={<ModelDevicesCore />}></Route>
              </Route>
            </Routes>
          </>
        </Suspense>
      </SidebarCore>
    </>
  );
};
