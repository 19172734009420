import { Card, CardBody, Tooltip } from "@nextui-org/react";
import { FcCurrencyExchange } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { useEffect } from "react";
import { dollarServices } from "../../../../store/dollar/thunks";
import { useAllParams } from "../../../../hooks/useAllParams";
import { DollarParams } from "../../params/dollar/DollarParams";
import { log } from "console";
import { today } from "@internationalized/date";
import { formaterTimeInternalized } from "../../../../libs/GetTimeInternalized";
import { PiCurrencyCircleDollarLight } from "react-icons/pi";
import React from "react";
import moment from "moment";
import { AiFillDollarCircle } from "react-icons/ai";

interface Responsive {
  isMobile?: boolean;
}
export const CardDolar = ({ isMobile }: Responsive) => {
  const dispatch = useDispatch<AppDispatch>();
  const { dollar, isLoading } = useSelector((state: RootState) => state.dollar);
  const { params, setSearchParams } = useAllParams();
  const date = moment().format("DD/MM/YYYY");

  const handleDateDollar = () => {
    const param = new DollarParams();
    param.date = moment().format("YYYY-MM-DD");
    dispatch(dollarServices(param));
  };
  React.useEffect(() => {
    handleDateDollar();
  }, []);
  return (
    <>
      <div
        className="cursor-pointer sm:grid justify-center hidden "
        onClick={handleDateDollar}
      >
        <div className="flex justify-center items-center space-x-1">
          <span className="font-semibold text-sm  text-gray-700">
            Tasa BCV, hoy {date}:
          </span>
        </div>
        <div className="inline-flex items-center justify-center space-x-2">
          <span className="text-primary font-semibold text-xl">
            {dollar && dollar[0]?.amount} Bs
          </span>
        </div>
      </div>

      <div className="sm:hidden"> 
        <Tooltip content={`Tasa BCV hoy ${date}:`}>
          <div
            className="flex items-center justify-center w-full bg-neutral-200 rounded-full px-2  space-x-1"
            onClick={handleDateDollar}
          >
            <AiFillDollarCircle className="w-6 h-6 ml-2" />
            <span className="text-primary text-xl font-semibold">
              {dollar && dollar[0]?.amount} Bs.
            </span>
          </div>
        </Tooltip>
      </div>
    </>
  );
};

// {dollar.length > 0 && dollar[0].monto}
