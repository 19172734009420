// @flow 
import { yupResolver } from '@hookform/resolvers/yup';
import { Select, SelectItem, Input } from '@nextui-org/react';
import * as React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { schemaUserShort } from './validators/Validators';
import { type } from '../../../components/methodPayments/typeIdentification';
type Props = {
    data: any;
    onSubmit: (data: any) => void;
    ref?: React.MutableRefObject<(() => void) | null>;
  };
export const FormIdentification =React.forwardRef(
    ({ onSubmit, data }: Props, ref) => {
      
      const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
      } = useForm({
        resolver: yupResolver(schemaUserShort), 
      });
      const typeItems = type;
      React.useImperativeHandle(ref, () => handleSubmit(onSubmit));
      React.useEffect(() => {
        if (data) {
          setValue("type", data?.identification.charAt(0));
          setValue("identification", data?.identification.slice(1));
        }
        }, [data]);
          return (
            <form>
              <div className="col-span-full flex items-center justify-center">
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        label="Tipo"
                        variant="bordered"
                        items={typeItems}
                        selectedKeys={new Set([field.value])}
                        className="w-28"
                        isInvalid={!!errors.type}
                        errorMessage={errors.type?.message}
                      >
                        {(type) => (
                          <SelectItem key={type.label}>{type.label}</SelectItem>
                        )}
                      </Select>
                      <span></span>
                    </>
                  )}
                />
        
                <Controller
                  control={control}
                  name="identification"
                  render={({ field }) => (
                    <Input
                      {...field}
                      label="Identificación"
                      isInvalid={!!errors.identification}
                      errorMessage={errors.identification?.message}
                      type="text"
                      autoFocus
                      placeholder="Introduce la Identificación de la compañía"
                      variant="bordered"
                    />
                  )}
                />
              </div>
            </form>
          );
});