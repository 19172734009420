// PermissionContext.tsx
import React, { createContext, useContext, useState } from "react";
import { useStorePermissions } from "../store/permission/useStorePermission";
import { getCookie, setCookie } from "../config/cookies";
import { usePermissionsMenuMovilPay } from "../hooks/useUserMovilPay";

interface PermissionContextType {
  permissions: string[];
  loading: boolean;
}

const PermissionContext = createContext<PermissionContextType | undefined>(
  undefined
);

export const usePermissions = (): PermissionContextType => {
  const context = useContext(PermissionContext);
  if (!context) {
    throw new Error("usePermissions must be used within a PermissionProvider");
  }
  return context;
};

export const PermissionProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { permissions } = useStorePermissions((state) => state);
  const [loading, setLoading] = useState<boolean>(true);
  return (
    <PermissionContext.Provider value={{ permissions, loading }}>
      {children}
    </PermissionContext.Provider>
  );
};
