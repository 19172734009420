import { createSlice } from "@reduxjs/toolkit";
import { ParishThunks } from "./thunks";

export interface initialStateParish {
    isLoading: boolean;
    parish:any[];
    count:number 
    page?:number
  }
  
  export const initialStateParish: initialStateParish = {
    isLoading: false,
    count: 0,
    parish:[]
  };
  
  export const ParishSlice = createSlice({
    name: "Options",
    initialState: initialStateParish,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(ParishThunks.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(ParishThunks.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          state.parish = payload.results  || payload
          state.count = payload.count
        });
    },
  });
  
  export const {} = ParishSlice.actions;