import * as yup from 'yup';

export const schemaModelDevice = yup.object({
    name: yup
        .string()
        .min(3, 'El nombre debe tener al menos 3 caracteres')
        .max(20, 'El nombre no puede tener más de 20 caracteres')
        .required('Este campo es requerido'),
        detail: yup
        .string()
        .min(5, 'La descripción debe tener al menos 5 caracteres')
        .max(50, 'La descripción no puede tener más de 50 caracteres')
        .required('Este campo es requerido'),
        // manufacturerName: yup
        // .string()
        // .required('Este campo es requerido'),
    manufacturer: yup
        .string()
        .required('Este campo es requerido'),
});