// @flow
import * as React from "react";
import { schemaAccountAffiliateTransf } from "../../services/snp/Validators/shemaSNP";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { PaymentMethodsCompany } from "../../../../../interfaces/CompaniesInterfaces";
import { Input } from "@nextui-org/react";
type Props = {
  data?: PaymentMethodsCompany | any;
  onSubmit: (data: any) => void;
  ref?: React.MutableRefObject<(() => void) | null>;
};
export const FormTransference = React.forwardRef(
  ({ onSubmit, data }: Props, ref) => {
    const {
      control,
      handleSubmit,
      formState: { errors },
      setValue,
    } = useForm({
      resolver: yupResolver(schemaAccountAffiliateTransf),
      defaultValues: {
        code: "58",
      },
    });

    // Usamos useImperativeHandle para exponer el handleSubmit a través del ref.
    React.useImperativeHandle(ref, () => handleSubmit(onSubmit));
    React.useEffect(() => {
      setValue("name", data?.name);
      setValue("sender", data?.sender);
    }, [data]);
    return (
        <div className="w-full flex flex-col my-4 space-y-2 ">
        <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  type="text"
                   className="w-full"
                  maxLength={30}
                  variant="bordered"
                  isInvalid={!!errors.name}
                  errorMessage={errors.name?.message}
                  placeholder="Colocar el nombre del Titular "
                />
                <span></span>
              </>
            )}
          />
          <Controller
            name="sender"
            control={control}
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  className="w-full"
                  type="text"
                  variant="bordered"
                  maxLength={24}
                  isInvalid={!!errors.sender}
                  errorMessage={errors.sender?.message}
                  placeholder="XXXX-XXXX-XXXX-XXXX-XXXX "
                />
                <span></span>
              </>
            )}
          />
        </div>
    );
  }
);
