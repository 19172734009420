import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, getKeyValue } from '@nextui-org/react';
import React from 'react';
import useStorePaymentInvoice from '../../../../../../../../store/finances/useStorePaymentInvoice';
import { FaRegTrashCan } from 'react-icons/fa6';

// Define tus columnas de la tabla
const columns = [
    {
        key: "id",
        label: "Factura a Pagar",
    },
    {
        key: "total",
        label: "Monto",
    },
    {
        key: "charged",
        label: "Monto Cargado",
    },
    {
        key: "amount",
        label: "Deuda",
    },
    {
        key: "accion",
        label: "Acción",
    },
];

export const TablePaymentInvoice = () => {
    const { invoices, removeInvoiceById } = useStorePaymentInvoice();

    return (
        <div>
            <Table aria-label="Example table with dynamic content">
                <TableHeader columns={columns}>
                    {(column) => (
                        <TableColumn key={column.key} className="bg-primary text-white">
                            {column.label}
                        </TableColumn>
                    )}
                </TableHeader>
                <TableBody>
                    {invoices.map((invoice, index) => (
                        <TableRow key={invoice.id}>
                            {columns.map((column) => {
                                      if (column.key === "total") {
                                        // Muestra el botón de acción solo si no es el primer registro
                                        return (
                                            <TableCell key={column.key}>
                                                 <span>
                                                    {invoice.amount+invoice.charged}
                                                 </span>
                                            </TableCell>
                                        );
                                    }

                                    if (column.key === "amount") {
                                        // Muestra el botón de acción solo si no es el primer registro
                                        return (
                                            <TableCell key={column.key}>
                                                 <span>
                                                    {invoice.amount.toFixed(2)}
                                                 </span>
                                            </TableCell>
                                        );
                                    }
                                if (column.key === "accion") {
                                    // Muestra el botón de acción solo si no es el primer registro
                                    return (
                                        <TableCell key={column.key}>
                                            {index !== 0 && (
                                                <button
                                                    onClick={() => removeInvoiceById(invoice.id)}
                                                    className="text-red-500 hover:text-red-700"
                                                >
                                                    <FaRegTrashCan className="h-5 w-5" />
                                                </button>
                                            )}
                                        </TableCell>
                                    );
                                }
                                return (
                                    <TableCell key={column.key}>
                                        {getKeyValue(invoice, column.key)}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};
