import { create } from "zustand";
import {ResultBanksAccounts } from "../../pages/core/interfaces/BanksAccountsInterfaces";

interface BanksAccountsState {
  banksAccounts: ResultBanksAccounts[];
  count: number;
  setBanksAccounts: (banksAccounts: ResultBanksAccounts[], count: number) => void;
  reset: () => void;
}

export const useStoreBanksAccounts = create<BanksAccountsState>((set) => ({
  banksAccounts: [],
  count: 0,
  setBanksAccounts: (banksAccounts: ResultBanksAccounts[], count: number) =>
    set(() => ({ banksAccounts, count })),
  reset: () => set(() => ({ banksAccounts: [], count: 0 })),
}));


