// @flow 
import * as React from 'react';
import { Outlet } from 'react-router-dom';
type Props = {
    
};
export const CreateCompanyCore = (props: Props) => {
    return (
        <div>
         <Outlet />   
        </div>
    );
};