import { create } from "zustand";
import { Payment } from "../../pages/core/interfaces/PaymentInterfaces";

interface PaymentCoreState {
  payments: any[];
  count: number;
  setPayments: (permissions: any[], count: number) => void;
  resetPayments: () => void;
}
export const useStorePaymentCore = create<PaymentCoreState>((set) => ({
  payments: [],
  count: 0,
  setPayments: (payments: Payment[], count: number) =>
    set(() => ({ payments, count })),
  resetPayments: () => set(() => ({ payments: [], count: 0 })),
}));
