import { create } from "zustand";

interface RolesState {
  roles: any[];
  setRoles: (roles: any[], count: number) => void;
  reset: () => void;
  count: number;
}

export const useStoreRoles = create<RolesState>((set) => ({
  roles: [],
  count: 0,
  setRoles: (roles: any[], count: number) => set(() => ({ roles, count })),
  reset: () => set(() => ({ roles: [], count: 0 })),
}));
