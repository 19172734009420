import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';

import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { DeviceServiceConfigParams } from '../../../../params/management/devicesParams';
import { getDeviceServiceConfigThunks } from '../../../../../../store/managements/warehouse/thunks';
import { queryOptions, useQueryClient } from '@tanstack/react-query';
import { MdOutlinePermDeviceInformation } from 'react-icons/md';
import { Card, Skeleton, User } from '@nextui-org/react';
import { formatDate } from '../../../../../../utils/formatDate';

type Props = {
    item?: any;
};

export default function TimeLineWarehouse({ item }: Props) {
    const [timeLine, setTimeLine] = useState<any[]>([]);
    const param = new DeviceServiceConfigParams();
    const queryClient = useQueryClient();

    const getDeviceServiceConfig = async () => {
        param.ordering = "date_end";
        param.device = item.data.id;
        param.remove_pagination = true;
        queryClient.fetchQuery(
            queryOptions({
                queryKey: ["timeline"],
                queryFn: () => getDeviceServiceConfigThunks(param).then((d) => {
                    setTimeLine(d.data);
                }),
                retry: false,
            })
        );
    };

    useEffect(() => {
        getDeviceServiceConfig();
    }, []);

  

    return (
        <>
            {timeLine.length === 0 ? (
                <Card className="w-full space-y-5 p-4" radius="lg">
                    <div className="h-24 rounded-lg bg-default-300 flex items-center justify-center">
                        <p className="text-lg font-semibold">Sin registro en Línea de tiempo</p>
                    </div>
                    {/* <Skeleton className="rounded-lg">
                     <div className="h-24 rounded-lg bg-default-300"></div>
                   </Skeleton> */}
                    <div className="space-y-3">
                        <Skeleton className="w-3/5 rounded-lg">
                            <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
                        </Skeleton>
                        <Skeleton className="w-4/5 rounded-lg">
                            <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
                        </Skeleton>
                        <Skeleton className="w-2/5 rounded-lg">
                            <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
                        </Skeleton>
                    </div>
                </Card>
            ) : (
                <Timeline position="alternate">
                    {timeLine.map((item, index) => (
                        <TimelineItem key={item.id}>
                            <TimelineOppositeContent
                                sx={{ m: 'auto 0' }}
                                align="right"
                                variant="body2"
                                color="text.secondary"
                            >
                                <Typography>Inicio: {formatDate(item.created_at)}</Typography>
                                {item.date_end !== null && (
                                    <Typography>Fin: {formatDate(item.date_end)}</Typography>
                                )}
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                {index === 0 && item.date_end == null ? (
                                    <>
                                        <TimelineConnector sx={{ backgroundColor: '#22c55e' }} />
                                        <TimelineDot sx={{ backgroundColor: '#22c55e' }}>
                                            <MdOutlinePermDeviceInformation />
                                        </TimelineDot>
                                        <TimelineConnector sx={{ backgroundColor: '#22c55e' }} />
                                    </>
                                ) : (
                                    <>
                                        <TimelineConnector sx={{ backgroundColor: '#e11d48' }} />
                                        <TimelineDot sx={{ backgroundColor: '#e11d48' }}>
                                            <MdOutlinePermDeviceInformation />
                                        </TimelineDot>
                                        <TimelineConnector sx={{ backgroundColor: '#f43f5e' }} />
                                    </>
                                )}
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                {/* <User
                                    name={item.company_name}
                                    description={item.date_end_detail}
                                    avatarProps={{
                                        src: "https://i.pravatar.cc/150?u=a04258114e29026702d"
                                    }}
                                /> */}
                                <Typography variant="h6" component="span">
                                    {item.company_name}
                                </Typography>
                                <Typography>{item.date_end_detail}</Typography>
                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>
            )}
        </>
    );
}
