// store/thunks.ts
import { createAsyncThunk } from "@reduxjs/toolkit";
import { movilPayAPI } from "../../../api/movilPayAPI";
import { WarehouseParams } from "../../../pages/core/params/management/warehouseParams";
import { DeviceServiceConfigParams } from "../../../pages/core/params/management/devicesParams";
import { ServiceConfigParams } from "../../../pages/core/params/management/serviceConfigParams";
import { CompaniesParams } from "../../../pages/core/params/companies/companiesParams";

export const fetchWarehouse = createAsyncThunk(
  "warehouse",
  async (params?: WarehouseParams) => {
    return movilPayAPI
      .get("/devices/", { params })
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        return [];
      });
  }
);

export const getWarehouseRequest = (params?: WarehouseParams) => {
  return movilPayAPI.get(`/devices/`, { params });
};
 
export const PostWarehouse = (body: any) => {
  return movilPayAPI.post(`/devices/`, body);
};

export const UpdateWarehouseThunks = (body: any, id: number) => {
  return movilPayAPI.patch(`/devices/${id}/`, body);
};

export const DeleteWarehouseThunks = (id: number) => {
  return movilPayAPI.delete(`/devices/${id}/`);
};


// Eliminar rutas

export const getDeviceServiceConfigThunks = (
  params?: DeviceServiceConfigParams
) => {
  return movilPayAPI.get(`/companies/device_service/`, { params });
};

export const getCompaniesThunks = (params?: CompaniesParams) => {
  return movilPayAPI.get("/companies/", {params});
};

export const getServiceConfigThunks = (params?: ServiceConfigParams) => {
  return movilPayAPI.get("/services/service-configs/", {params});
};

export const UnpairDeviceWarehouseThunks = (body: any, id: number) => {
  return movilPayAPI.patch(`/companies/device_service/${id}/`, body);
};
