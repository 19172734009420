import { create } from "zustand";
import { CodePhone } from "../../pages/core/interfaces/PaymentInterfaces";

interface CodePhonesState {
  codePhone: CodePhone[];
  setCodePhone: (codePhone: CodePhone[]) => void;
  reset: () => void;
}
export const useStoreCodePhones = create<CodePhonesState>((set) => ({
  codePhone: [],
  setCodePhone: (codePhone:CodePhone[]) => {
    set({ codePhone });
  },
  reset: () => {
    set({ codePhone: [] });
  },
}));
