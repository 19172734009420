import { useSelector } from "react-redux";
import { TableLayout, valueType } from "../../../../../layout/TableLayout";
import { PaginatorTable } from "../../../../../components/table/PaginatorTable";
import { useStoreInvoices } from "../../../../../../../store/finances/useStoreInvoices";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  useDisclosure,
} from "@nextui-org/react";
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineDeleteSweep } from "react-icons/md";
import { TbListDetails, TbReceiptOff } from "react-icons/tb";
import { IoOptionsOutline } from "react-icons/io5";
import { ActionDelete } from "../actions-invoices/DeleteInvoice";
import { useState } from "react";
import { AnnulmentInvoice } from "../actions-invoices/AnulmentInvoice";
import { EditInvoice } from "../actions-invoices/EditInvoice";
import { FaCashRegister } from "react-icons/fa6";
import { PaymentInvoice } from "../actions-invoices/PaymentInvoice";
import { usePermissions } from "../../../../../../../context/PermissionContext";
import { DetailInvoice } from "../actions-invoices/DetailInvoice";

type Props = {
  reload: () => void;
};

export const TableInvoices = ({ reload }: Props) => {
  const { invoices, count } = useStoreInvoices((state: any) => state);
  const [isOpen, setIsOpen] = useState(false);

  const { permissions } = usePermissions();
  const actionPermissionUpdate =
    permissions && permissions.includes("action_update_invoice");
  const actionPermissionDelete =
    permissions && permissions.includes("action_delete_invoice");
  const actionPermissionAnnular =
    permissions && permissions.includes("action_annular_invoice");
  const actionPermissionEmit =
    permissions && permissions.includes("action_emit_invoice");
  const dataTable: valueType[] = [
    { name: "ID", value: "id" },
    { name: "Cliente", value: "company_name" },
    { name: "Control", value: "nro_control_tfhk" },
    { name: "Emision", value: "date_emission" },
    { name: "Vencimiento", value: "date_expiration" },
    {
      name: "Facturada",
      value: (data: any) => (
        <span>USD {data.total_amount_device + data.total_amount_service}</span>
      ),
    },
    {
      name: "Deuda",
      value: (data: any) => (
        <span>
          USD{" "}
          {(
            data.total_amount_device +
            data.total_amount_service -
            parseFloat(data.charged)
          ).toFixed(2)}
        </span>
      ),
    },
    { name: "Cargado", value: (data: any) => <span>USD {data.charged}</span> },
    { name: "Fecha Pago", value: "date_payment" },
    {
      name: "Status",
      value: (data: any) => (
        <span>
          {data.detail_canceled === null ? data.status_name : "Anulada"}
        </span>
      ),
    },
    {
      name: "Acción",
      value: (data: any) => (
        <Dropdown closeOnSelect={isOpen}>
          <DropdownTrigger>
            <Button
              variant="light"
              isIconOnly
              radius="full"
              className="bg-white"
            >
              <IoOptionsOutline size={24} className="text-gray-800" />
            </Button>
          </DropdownTrigger>
          <DropdownMenu
            closeOnSelect={isOpen}
            onClose={()=>setIsOpen(!isOpen)}
            variant="solid"
            color="primary"
            aria-label="Dropdown menu with icons"
          >
            {actionPermissionAnnular && data && data.status === 4 && (
              <DropdownItem
                key="anullment"
                color="primary"
                startContent={<TbReceiptOff />}
              >
                <AnnulmentInvoice reload={reload} data={data} />
              </DropdownItem>
            )}
            <DropdownItem
              key="detail"
              color="primary"
              startContent={<TbListDetails />}
            >
              <DetailInvoice reload={reload} data={data} />
            </DropdownItem>
            {actionPermissionUpdate &&
              data &&
              data.status === 3 &&
              data.detail_canceled === null &&
              data.nro_control_tfhk === null && (
                <DropdownItem
                  key="mod"
                  color="primary"
                  startContent={<FaRegEdit />}
                >
                  <EditInvoice reload={reload} data={data} />
                </DropdownItem>
              )}
            {data && data.status === 3 && data.detail_canceled === null && (
              <DropdownItem
                key="payment"
                color="primary"
                startContent={<FaCashRegister />}
              >
                <PaymentInvoice reload={reload} data={data} />
              </DropdownItem>
            )}
            {actionPermissionDelete &&
              data &&
              data.status === 3 &&
              data.detail_canceled === null &&
              data.nro_control_tfhk === null && (
                <DropdownItem
                  key="delete"
                  className="text-danger"
                  color="danger"
                  startContent={<MdOutlineDeleteSweep />}
                >
                  <ActionDelete reload={reload} data={data} />
                </DropdownItem>
              )}
          </DropdownMenu>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className="space-y-7">
      <div>
        <TableLayout
          isLoading={false}
          data={invoices}
          columns={dataTable}
          Paginator={<PaginatorTable count={count} />}
        />
      </div>
    </div>
  );
};
