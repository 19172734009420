import * as Yup from 'yup';

const InvoiceSchema = Yup.object().shape({
  search: Yup.string(),
  identification: Yup.string().required('El campo banco es requerido.'),

  // Fecha de emisión
  date_emission: Yup.string()
    .required('La fecha de emisión es requerida.')
    .typeError('La fecha de emisión no es válida.'),

  // Fecha de vencimiento
  date_expirated: Yup.string()
    .required('La fecha de vencimiento es requerida.')
    .typeError('La fecha de vencimiento no es válida.'),
    // .min(Yup.ref('date_emission'), 'La fecha de vencimiento no puede ser anterior a la fecha de emisión.'),

  // Mes a facturar
  month: Yup.string()
    .required('El mes a facturar es requerido.')
    .min(1, 'El mes debe ser mayor o igual a 1.')
    .max(12, 'El mes no puede ser mayor a 12.')
    .typeError('El mes debe ser un número.'),

  // Contrato
  contract: Yup.string()
    .required('El campo contrato es requerido.'),

  // Cliente
  client: Yup.string()
    .required('El campo cliente es requerido.')
    .min(3, 'El nombre del cliente debe tener al menos 3 caracteres.'),

  // Factura (invoices)
  // invoice: Yup.object().required('El campo factura es requerido.'),
});

export default InvoiceSchema;
