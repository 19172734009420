import React, { useEffect, useRef, useState } from "react";
import { AddInvoice } from "./components/actions-invoices/AddInvoice";
import { TableInvoices } from "./components/table-invoices/TableInvoices";
import { InvoicesParams } from "../../../params/finances/InvoicesParams";
import { useStoreInvoices } from "../../../../../store/finances/useStoreInvoices";
import { queryOptions, useQueryClient } from "@tanstack/react-query";
import { useAllParams } from "../../../../../hooks/useAllParams";
import {
  getInvoicesThunks,
  getStatusThunks,
} from "../../../../../store/finances/Thunks";
import { useParams } from "react-router-dom";
import { useUserMovilPay } from "../../../../../hooks/useUserMovilPay";
import { usePermissions } from "../../../../../context/PermissionContext";
import {
  Button,
  DateRangePicker,
  DateValue,
  Input,
  RangeValue,
  Select,
  SelectItem,
  Switch,
  Tooltip,
} from "@nextui-org/react";
import { Controller, useForm } from "react-hook-form";
import { FcClearFilters, FcSearch } from "react-icons/fc";
import {
  todayInternalized,
  DateInternalized,
  formaterTimeInternalizedRange,
} from "../../../../../libs/GetTimeInternalized";
import { BiSearch } from "react-icons/bi";
import { MdFilterAltOff } from "react-icons/md";
import { ErrorToast } from "../../../../../libs/Notifications";

const InvoicesCore = () => {
  const { params, deleteParams, setSearchParams } = useAllParams();
  const { setInvoices, count } = useStoreInvoices((state) => state);
  const queryClient = useQueryClient();
  const { id } = useParams();
  const path = localStorage.getItem("lastPath");
  const { company, is_superuser } = useUserMovilPay();
  const { permissions } = usePermissions();
  const actionPermission =
    permissions && permissions.includes("action_create_invoice");
  const param = new InvoicesParams();
  const [key, setKey] = useState(0); // Estado clave para forzar el re-render
  const today = todayInternalized();
  const [status, setStatus] = useState<any>();
  const [filterStatus, setFilterStatus] = useState<string>();
  const [filterEmit, setFilterEmit] = useState<string>();
  const [filterAnulled, setFilterAnulled] = useState<string>();
  const [dateCreate, setDateCreate] = useState<RangeValue<DateValue> | null>(
    null
  ); // Inicializado vacío
  const [since, setSince] = useState<string>();
  const [until, setUntil] = useState<string>();

  const [datePayment, setDatePayment] = useState<RangeValue<DateValue> | null>(
    null
  ); // Segundo DateRangePicker
  const [sincePayment, setSincepayment] = useState<string>();
  const [untilPayment, setUntilPayment] = useState<string>();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      search: params.search,
      since: params.since,
      until: params.until,
      sincePayment: params.sincePayment || null,
      untilPayment: params.untilPayment ||  null,
    },
  });
  let idInvoice: string | undefined;

  if (id) {
    idInvoice = id;
  } else if (path == "/accounts/my_company/invoices/") {
    idInvoice = company.toString();
  } else {
    idInvoice = undefined;
  }

  const handleConsultation = async () => {
    param.company = idInvoice;
    if (params.page) param.page = Number(params.page);
    if (params.search) param.search = params.search;
    param.status = filterStatus; // Add the filter parameter
    param.emission_tfhk = filterEmit; // Add the filter parameter
    param.annulled = filterAnulled;
    param.date_emission_since = since;
    param.date_emission_until = until;
    param.date_payment_since = sincePayment;
    param.date_payment_until = untilPayment;

    queryClient.fetchQuery(
      queryOptions({
        queryKey: ["invoices"],
        queryFn: () =>
          getInvoicesThunks(param).then((d) => {
            if (d && d.data) {
              setInvoices(d.data.results, d.data.count);
              return d.data; // Devolver los datos en lugar de undefined
            } else {
              return { results: [], count: 0 }; // Valor predeterminado
            }
          }).catch(({ response }) => {
            if (response?.status === 400) {
              const errorMessage =
                Object.keys(response.data || {})
                  .join(", ")
                  .concat(": " + Object.values(response.data || {}).join(", ")) ||
                Object.values(response.data || {}).join(", ") ||
                "Error desconocido";
              return ErrorToast(errorMessage);
            }
          }),
        retry: false,
      })
    );
  };

  useEffect(() => {
    handleConsultation();
  }, [params]);
  useEffect(() => {
    getStatus();
  }, []);

  const onSubmit = ({ search }: any) => {
    if (!search) {
      deleteParams(["search"]);
    } else {
      const { page, ...rest } = params;
      setSearchParams({ ...rest, search });
    }
  };

  const getStatus = async () => {
    const res = await getStatusThunks();
    setStatus(res.data);
  };

  const initValue = () => {
    if (params.since) {
      return {
        start: DateInternalized(params.since),
        end: DateInternalized(params.until),
      };
    }

    return {
      start: todayInternalized(),
      end: todayInternalized(),
    };
  };

  const handleStatusChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value;
    await setFilterStatus(value);
    await deleteParams(["page"]);

    handleConsultation(); // Trigger consultation with new filter value
  };

  const handleEmitChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.checked; // `checked` es un booleano
    await setFilterEmit(value ? "true" : "");
    await deleteParams(["page"]);

    handleConsultation(); // Trigger consultation with new filter value
  };

  const handleAnulledChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.checked; // `checked` es un booleano
    await setFilterAnulled(value ? "true" : "");
    await deleteParams(["page"]);

    handleConsultation(); // Trigger consultation with new filter value
  };

  const handleDateRangeChange = async (
    newRange: RangeValue<DateValue> | null
  ) => {
    setDateCreate(newRange);
    // if (newRange) {
    //   const since = `${newRange.start.year}-${newRange.start.month}-${newRange.start.day}`;
    //   const until = `${newRange.end.year}-${newRange.end.month}-${newRange.end.day}`;
    //   await setSince(since);
    //   await setUntil(until);
    //   handleConsultation();
    // }

    
    if (newRange && newRange.start && newRange.end) {
      // Verificar que la fecha de inicio no sea posterior a la de fin
      const startDate = new Date(newRange.start.year, newRange.start.month - 1, newRange.start.day);
      const endDate = new Date(newRange.end.year, newRange.end.month - 1, newRange.end.day);
  
      if (startDate <= endDate) {
        const since = `${newRange.start.year}-${String(newRange.start.month).padStart(2, '0')}-${String(newRange.start.day).padStart(2, '0')}`;
        const until = `${newRange.end.year}-${String(newRange.end.month).padStart(2, '0')}-${String(newRange.end.day).padStart(2, '0')}`;
        
        await setSince(since);
        await setUntil(until);
        handleConsultation();
      } else {
        console.error("El rango de fechas no es válido: la fecha de inicio es posterior a la fecha de fin.");
      }
    }
  };

  const handleSecondDateRangeChange = async (
    newRange: RangeValue<DateValue> | null
  ) => {
    setDatePayment(newRange);
  
    if (newRange && newRange.start && newRange.end) {
      // Verificar que la fecha de inicio no sea posterior a la de fin
      const startDate = new Date(newRange.start.year, newRange.start.month - 1, newRange.start.day);
      const endDate = new Date(newRange.end.year, newRange.end.month - 1, newRange.end.day);
  
      if (startDate <= endDate) {
        const since = `${newRange.start.year}-${String(newRange.start.month).padStart(2, '0')}-${String(newRange.start.day).padStart(2, '0')}`;
        const until = `${newRange.end.year}-${String(newRange.end.month).padStart(2, '0')}-${String(newRange.end.day).padStart(2, '0')}`;
        
        await setSincepayment(since);
        await setUntilPayment(until);
  
        // Ejecutar solo si el rango de fechas es válido
        handleConsultation();
      } else {
        console.error("El rango de fechas no es válido: la fecha de inicio es posterior a la fecha de fin.");
      }
    }
  };
  

  const handleReset = async () => {
    setFilterStatus("");
    setFilterEmit("");
    setFilterAnulled("");
    setSince("");
    setUntil("");
    setDateCreate(null);
    setSincepayment("");
    setUntilPayment("");
    setDatePayment(null);
    deleteParams(["search", "page"]);

    reset();
    setKey((prevKey) => prevKey + 1); // Actualiza la clave para forzar el re-render
  };

  return (
    <div
      className="animate-fade-up  bg-white px-4 py-5 dark:bg-primaryDark rounded-lg sm:px-6 my-4 "
      key={key}
    >
      <div className="space-y-7">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 items-center ">
          <div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className=" flex items-center space-x-2"
            >
              <Controller
                control={control}
                name="search"
                render={({ field }) => (
                  <div className="flex w-full items-center">
                    <Input
                      {...field}
                      variant="bordered"
                      type="text"
                      size="lg"
                      placeholder="Introduce para buscar"
                      className="w-full"
                    />
                    <Button
                      isIconOnly
                      color="primary"
                      aria-label="Like"
                      type="submit"
                      className="ml-2"
                    >
                      <BiSearch className="h-6 w-6 text-white" />
                    </Button>
                  </div>
                )}
              />
            </form>
          </div>
          <div>
            <Select
              variant="bordered"
              placeholder="Filtrar por Estado"
              onChange={handleStatusChange}
              className="w-full"
              size="lg"
            >
              {status?.map((status: any) => (
                <SelectItem key={status.id} value={status.id}>
                  {status.name}
                </SelectItem>
              ))}
            </Select>
          </div>
          <div className="flex flex-row justify-start">
            <div className="flex  gap-3 mt-4">
              <span className="text-gray-700 text-lg">Emitidas:</span>
              <Switch onChange={handleEmitChange} />
            </div>
            <div className="flex justify-center gap-3 mt-4 ml-4">
              <span className="text-gray-700 text-lg">Anuladas:</span>
              <Switch onChange={handleAnulledChange} />
            </div>
          </div>

          <div>
            <DateRangePicker
              label="Fecha de Creacion"
              variant="bordered"
              labelPlacement="outside"
              value={dateCreate}
              onChange={handleDateRangeChange}
              className="max-w-full"
              size="lg"

            />
          </div>
          <div>
            <DateRangePicker
              label="Fecha de Pagos"
              labelPlacement="outside"
              variant="bordered"
              value={datePayment}
              size="lg"
              onChange={handleSecondDateRangeChange}
              className="max-w-full"

            />
          </div>

          <div className="mt-5 gap-x-2">
            <Tooltip content="Borrar filtros">
              <Button
                isIconOnly
                variant="solid"
                color="primary"
                onPress={handleReset}
              >
                <MdFilterAltOff className={`font-semibold h-6 w-6 `} />
              </Button>
            </Tooltip>
          </div>

        </div>

        <div className="flex space-x-3 justify-end">
          {actionPermission && (
            <AddInvoice e={undefined} reload={handleConsultation} />
          )}
        </div>

        <div className="animate-fade-up">
          <TableInvoices reload={handleConsultation} />
        </div>
      </div>
    </div>
  );
};

export default InvoicesCore;
