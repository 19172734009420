import { FaChartBar, FaFileInvoiceDollar, FaHandHoldingUsd } from "react-icons/fa";
import { FaBuildingUser } from "react-icons/fa6";
import { TbTimeline } from "react-icons/tb";
import { DevicesServicesCompanyRouters, ServicesCompanyRouters } from "./ServicesCompanyRouter";
import { RiBankFill } from "react-icons/ri";
import { MdImportantDevices } from "react-icons/md";
import { GrMoney } from "react-icons/gr";

export const companyIDRouters = [

  // {
  //   name: "Dashboard",
  //   path: "dashboard/",
  //   icon: FaChartBar,
  //   value: () => {},
  //   permission: 'action_view_dashboard'
  // },
  
  {
    name: "Información",
    path: "info_company/",
    icon: FaBuildingUser,
    value: () => {},
    permission: 'action_view_company_information'

  },
  {
    name: "Facturas",
    path: "invoices/",
    icon: FaFileInvoiceDollar,
     value: () => {},
     permission: 'action_view_invoices'
  },
  {
    name: "Pagos",
    path: "payments/",
    icon: GrMoney,
     value: () => {},
     permission: 'action_view_payments'
  },
  {
    name: "Servicios",
    path: "services/",
    icon: FaHandHoldingUsd,
    value: () => {},
    permission: 'action_view_services'
  },
  {
    name: "Dispositivos",
    path: "devices/",
    icon: MdImportantDevices,
    value: () => {},
    permission: 'action_view_devices'

  },
  // {
  //   name: "Cuentas asociadas",
  //   path: "associated_banks/",
  //   icon: RiBankFill,
  //   value: () => {},
  //   permission: 'action_view_associated_accounts'

  // },
  {
    name: "Cuentas afiliadas",
    path: "affiliate_accounts/",
    icon: RiBankFill,
    value: () => {},
    permission: 'action_view_affiliate'

  },
  // {
  //   name: "Seguimientos",
  //   path: "fallow_up/",
  //   icon: TbTimeline,
  //   value: () => {},
  //   permission: 'action_view_traces'

  // },
];
