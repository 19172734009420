import { Button, Input } from "@nextui-org/react";
import { Controller, useForm } from "react-hook-form";
import { FcSearch } from "react-icons/fc";
import { useAllParams } from "../../../../../hooks/useAllParams";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import { ManufacturerThunks } from "../../../../../store/managements/manufacturers/thunks";
import { useEffect } from "react";
import { DevicesParams } from "../../../params/management/devicesParams";
import { TableManufacturers } from "./components/TableManufacturers";
import { AddManufacturer } from "./components/AddManufacturer";
import { usePermissions } from "../../../../../context/PermissionContext";
import { BiSearch } from "react-icons/bi";

export const ManufacturersCore = () => {
  const { params, deleteParams, setSearchParams } = useAllParams();
  const { permissions, loading } = usePermissions();
  const actionPermissionAdd =permissions && permissions.includes('action_add_manufacturer');
  
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      search: params.search || "",
    },
  });
  const dispacth = useDispatch<AppDispatch>();


  const handleConsultation = async () => {
    const param = new DevicesParams();
    param.search = params.search;
    dispacth(ManufacturerThunks(param));
  };
  useEffect(() => {
    handleConsultation();
  }, [params]);

  useEffect(() => {
    handleConsultation();
  }, []);

  const onSubmit = ({ search }: any) => {

    if (!search) {
      deleteParams(["search"]);
    } else {
      const { page, ...rest } = params;
      setSearchParams({ ...rest, search });
    }
  };

  return (
    <div className="animate-fade-up">
      <div className="space-y-7">
        <div className="lg:flex lg:justify-between lg:items-center ">
          <div className="mb-7 lg:mb-0">
            <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
                control={control}
                name="search"
                render={({ field }) => (
                  <div className="flex items-center">
                    <Input
                      {...field}
                      variant="bordered"
                      type="text"
                      size="lg"
                      placeholder="Introduce para buscar"
                      className="w-full lg:w-[500px] sm:w-full"
                    />
                    <Button
                      isIconOnly
                      color="primary"
                      aria-label="Like"
                      type="submit"
                      className="ml-2"
                    >
                      <BiSearch className="h-6 w-6 text-white" />
                    </Button>
                  </div>
                )}
              />
            </form>
          </div>
          <div className="flex space-x-3 justify-between">
           {actionPermissionAdd && <AddManufacturer e={undefined} reload={handleConsultation} />}
          </div>
        </div>
        <div className="animate-fade-up">
          <TableManufacturers reload={handleConsultation} />
        </div>
      </div>
    </div>
  );
};
