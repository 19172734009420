// @flow
import * as React from "react";
import { useAllParams } from "../../../../../../hooks/useAllParams";
import { useThemeMovilPay } from "../../../../../../hooks/useTheme";
import { Select, SelectItem } from "@nextui-org/react";
import { useStoreDevicesServiceCompany } from "../../../../../../store/companies/DevicesCompany/useStoreDevicesServiceCompany";
type Props = {};
export const FilterForSCPaymentMethod = (props: Props) => {
  const { params, setSearchParams,addParams, deleteParams } = useAllParams();
  const { darkMode } = useThemeMovilPay();
  const { count, devicesServiceCompany } = useStoreDevicesServiceCompany(
    (state) => state
  );

  const device = ({ target: { value } }: any) => {
    if (!value) {
      return deleteParams(["device"]);
    }
    addParams({device: value} );
  };
  return (
    <div className="space-y-3">
      <div>
        <label className={`${darkMode && "text-white"} `}>Dispositivos</label>
        {
          count === 0 ? (
            <p className="text-gray-600 text-sm">
              No hay dispositivos registrados
            </p>
          ) : (
            <Select
              className="w-full text-white"
              variant="bordered"
              color="primary"
              items={devicesServiceCompany}
              onChange={device}
              defaultSelectedKeys={params.device ?? null}
              placeholder="Selecciona el dispositivo"
              scrollShadowProps={{
                isEnabled: false,
              }}
            >
              {(device) => (
                <SelectItem key={device.device}>
                  {device.device_serial +
                    " /  " +
                    device.device_mac +
                    (device.bank_name != null
                      ? " /  " + device?.bank_name
                      : " /  MOVILPAY")}
                </SelectItem>
              )}
            </Select>
          )

          // Render devices here...

          // Example:
          // devicesServiceCompany.map((device) => (
          //   <SelectItem key={device.id}>
          //     {device.device_serial +
          //       " /  " +
          //       device.device_mac +
          //       (device.bank_name!= null? " /  " + device?.bank_name : " /  MOVILPAY")}
          //   </SelectItem>
          // ))

          // End of example

          // Replace the above code with the actual rendering logic based on your data structure and API response
        }
        {/* <Select
          className="w-full text-white"
          variant="bordered"
          color="primary"
          items={devicesServiceCompany}
          onChange={device}
          defaultSelectedKeys={params.device ?? null}
          placeholder="Selecciona el dispositivo"
          scrollShadowProps={{
            isEnabled: false,
          }}
        >
          {(device) => (
              <SelectItem key={device.id}>
                {device.device_serial +
                  " /  " +
                  device.device_mac +
                  (device.bank_name != null
                    ? " /  " + device?.bank_name
                    : " /  MOVILPAY")}
              </SelectItem>
            )}
        </Select> */}
      </div>
    </div>
  );
};
