import * as yup from 'yup';

const ItemSchema = yup.object().shape({
    service: yup.string().required('Service is required'),
    service_name: yup.string(),
    cant: yup.string().min(1).required('Quantity is required'),
    description: yup.string().required('Description is required'),
    price: yup.string().nullable().required("Precio es requerido"),
    type: yup.string(),
    serial: yup.string(),
    service_type: yup.string(),
    exentoIVA: yup.boolean(), // New field for Exento de IVA
});

export default ItemSchema;
