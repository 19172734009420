import { queryOptions, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { create } from "zustand";
import { Payment } from "../../../../interfaces/PaymentInterfaces";
import { useAllParams } from "../../../../../../hooks/useAllParams";
import { AppDispatch, RootState } from "../../../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { usePermissions } from "../../../../../../context/PermissionContext";
import { useUserMovilPay } from "../../../../../../hooks/useUserMovilPay";
import { PaymentParams } from "../../../../params/payment/payments/paymentParams";
import {
  getPaymentRequest,
  PaymentsThunks,
} from "../../../../../../store/payment/thunks";
import { useEffect } from "react";
import { CardInfoPayments } from "../components/CardInfoPayments";
import { BottonsPayments } from "../../../finances/payments/components/table/BottonsPayments";
import { CheckFilterPayments } from "../../../finances/payments/components/filters/CheckFilterPayments";
import { TemplateTableLayout } from "../../../../layout/TemplateTableLayout";
import { BanksDestinyOriginPayments } from "../../../finances/payments/components/filters/BanksDestinyOriginPayments";
import { SinceUntilPayments } from "../../../finances/payments/components/filters/SinceUntilPayments";
import { TablePayment } from "../../../finances/payments/components/table/TablePayments";
import PaymentCore from "../../../finances/payments/PaymentCore";
import { useStorePaymentCore } from "../../../../../../store/payment/useStorePayment";
import { ErrorToast } from "../../../../../../libs/Notifications";
import { DevicesServicesConfigParams } from "../../../../params/services-configs/DevicesServicesConfig";
import { useStoreDevicesServiceCompany } from "../../../../../../store/companies/DevicesCompany/useStoreDevicesServiceCompany";
import { GetDevicesServiceCompanyThunks } from "../../../../../../store/companies/DevicesCompany/thunks";
import { FilterForSCPaymentMethod } from "../services/FilterForSCPaymentMethod";
import { ButtonReportPayments } from "../../../finances/payments/components/table/ButtonReportPayments";
import { saveAs } from "file-saver";

export const PaymentsCompanyCore = () => {
  const queryClient = useQueryClient();
  const { params, deleteParams, setSearchParams } = useAllParams();
  const { count, setPayments, payments, resetPayments } = useStorePaymentCore(
    (state: any) => state
  );
  const { setDevicesServiceCompany } = useStoreDevicesServiceCompany(
    (state) => state
  );
  const { permissions } = usePermissions();
  const actionPermissionFilter =
    permissions && permissions.includes("action_filter_payments");
  const actionPermissionAdd =
    permissions && permissions.includes("action_add_payments");
  const { companies } = useSelector((state: RootState) => state.companyID);

  const { id, serviceId } = useParams();
  const { company } = useUserMovilPay();
  const handleConsultation = async () => {
    const option = id ? id : company;
    const param = new PaymentParams();
    param.company = Number(option);
    param.payment_method_cs = serviceId;
    params.method && (param.method = params.method);
    params.status && (param.status = params.status);
    params.page && (param.page = Number(params.page));
    params.since && (param.since = params.since);
    params.until && (param.until = params.until);
    params.search && (param.search = params.search);
    params.bank_origin && (param.bank_origin = params.bank_origin);
    params.bank_destiny && (param.bank_destiny = params.bank_destiny);
    params.device && (param.device = params.device);
    await queryClient.fetchQuery(
      queryOptions({
        queryKey: ["paymentsAll"],
        queryFn: () =>
          getPaymentRequest(param)
            .then((d) => {
              return setPayments(d.data.results, d.data.count);
            })
            .catch(({ response }) => {
              if (response?.status === 400) {
                const errorMessage =
                  Object.keys(response.data || {})
                    .join(", ")
                    .concat(
                      ": " + Object.values(response.data || {}).join(", ")
                    ) ||
                  Object.values(response.data || {}).join(", ") ||
                  "Error desconocido";
                return ErrorToast(errorMessage);
              }
            }),
        retry: false,
      })
    );
  };

  const handleDevicesSCPM = () => {
    const param = new DevicesServicesConfigParams();
    // param.payment_method_cs = serviceId;
    if (companies?.id !== undefined && companies?.company_master !== undefined) {
      param.remove_pagination = "true";
      param.available = "true";
      param.company_in =
        (companies?.company_master !== null &&
          companies?.company_master + "," + companies?.id.toString()) ||
        companies?.id.toString();

      queryClient.fetchQuery(
        queryOptions({
          queryKey: ["scdevices"],
          queryFn: () =>
            GetDevicesServiceCompanyThunks(param)
              .then((d) => {
                setDevicesServiceCompany(d.data, d.data.length);
              })
              .catch(({ response }) => {
                if (response?.status === 400) {
                  const errorMessage =
                    Object.keys(response.data || {})
                      .join(", ")
                      .concat(
                        ": " + Object.values(response.data || {}).join(", ")
                      ) ||
                    Object.values(response.data || {}).join(", ") ||
                    "Error desconocido";
                  return ErrorToast(errorMessage);
                }
              }),
          retry: false,
        })
      );
    }
  };
  useEffect(() => {
    handleConsultation();
  }, [params]);
  useEffect(() => {
    handleDevicesSCPM();
  }, []);
  const handleSearch = ({ search }: any) => {
    if (search == undefined || search == null) return;

    if (search.length == 0) {
      deleteParams(["search"]);
      return;
    }
    const { page, ...rest } = params;
    setSearchParams({ ...rest, search });
  };

  const handleDelete = () => {
    deleteParams([
      "since",
      "until",
      "page",
      "method",
      "search",
      "bank_origin",
      "bank_destiny",
      "report",
      "device",
      "status",
    ]);
    // handleConsultation();
    return;
  };
  return (
    <div className="animate-fade-up space-y-5">
      <CardInfoPayments />
      <TemplateTableLayout
        title="Información de los pagos"
        bottons={
          actionPermissionAdd && (
            <div className="flex items-center space-x-2">
              <BottonsPayments refresh={handleConsultation} />
              <ButtonReportPayments />
            </div>
          )
        }
        search={handleSearch}
        deleteParams={handleDelete}
        filters={
          actionPermissionFilter
            ? [
                {
                  name: "",
                  component: <CheckFilterPayments />,
                  field: "status",
                },
                {
                  name: "Seleccione los dispositivos",
                  component: <FilterForSCPaymentMethod />,
                  field: "banks",
                },
                {
                  name: "Introduce un rango de fecha",
                  component: <SinceUntilPayments />,
                  field: "since",
                },
                {
                  name: "Selecciona los bancos",
                  component: <BanksDestinyOriginPayments />,
                  field: "banks",
                },
              ]
            : []
        }
      >
        <>
          {params.since && (
            <p className="font-semibold text-xl dark:text-white text-end mt-4">
              {params.since && params.since}
              {params.until && ` – ${params.until}`}
            </p>
          )}
        </>
        <TablePayment />
      </TemplateTableLayout>
    </div>
  );
};
