// @flow
import { yupResolver } from "@hookform/resolvers/yup";
import { Input, Select, SelectItem } from "@nextui-org/react";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { Services } from "../../../../interfaces/ServicesInterfaces";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../store/store";
import { ServicesTypeThunks } from "../../../../../../store/services/thunks";


type Props = {
  data?: any | {};
  onSubmit: (data: any) => void;
  ref?: React.MutableRefObject<(() => void) | null>;
};
let schema = yup.object({
  name: yup.string().required("Este campo es requerido"),
  description: yup.string().required("Este campo es requerido"),
  code: yup.string().required("Este campo es requerido"),
  price: yup.number().required("Este campo es requerido"),
});
export const FormServices = React.forwardRef(
  ({ data, onSubmit }: Props, ref) => {

    const { servicesType, count } = useSelector(
      (state: RootState) => state.servicesType
    );
    const dispatch = useDispatch<AppDispatch>();
    const {
      control,
      setValue,
      watch,
      handleSubmit,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(schema),
    });
    React.useImperativeHandle(ref, () => handleSubmit(onSubmit));

    const valuesData = () => {
      setValue("name", data.name || "");
      setValue("description", data.description || "");
      setValue("code", data.code || "");
      setValue("price", data.price || "");
    };
    const handleConsultation = () => {
      dispatch(ServicesTypeThunks());
    };

    React.useEffect(() => {
      data !== undefined && valuesData();
      handleConsultation()
    }, []);
    return (
      <div>
        <form className="gap-4 space-y-3">
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <Input
                {...field}
                isInvalid={!!errors.name}
                errorMessage={errors.name?.message}
                type="text"
                autoFocus
                placeholder="Nombre del servicio"
                variant="bordered"
              />
            )}
          />
          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <Input
                {...field}
                isInvalid={!!errors.description}
                errorMessage={errors.description?.message}
                type="text"
                autoFocus
                placeholder="Agregar una breve descripción del servicio... "
                variant="bordered"
              />
            )}
          />
          <Controller
            control={control}
            name="code"
            render={({ field }) => (
              <Input
                {...field}
                isInvalid={!!errors.code}
                errorMessage={errors.code?.message}
                type="text"
                autoFocus
                placeholder="Agregar codigo Ej. SNP"
                variant="bordered"
              />
            )}
          />

          <Controller
            control={control}
            name="price"
            render={({ field }) => (
              <Input
                {...field}
                isInvalid={!!errors.price}
                errorMessage={errors.price?.message}
                type="number"
                autoFocus
                placeholder="Precio"
                variant="bordered"
                value={field.value ? String(field.value) : ""} // Convierte el valor a string
              />
            )}
          />
        </form>
      </div>
    );
  });
