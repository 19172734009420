import * as React from "react";
import * as yup from "yup";
import { PaymentMethodsCompany } from "../../../../../interfaces/CompaniesInterfaces";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQueryClient, queryOptions } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { ErrorToast } from "../../../../../../../libs/Notifications";
import { BanksThunks } from "../../../../../../../store/banks/thunks";
import { GetCodePhoneThunks } from "../../../../../../../store/companies/thunks";
import { useStoreCodePhones } from "../../../../../../../store/companies/useStoreCodePhone";
import { AppDispatch, RootState } from "../../../../../../../store/store";
import { PaymentParams } from "../../../../../params/payment/payments/paymentParams";
import { schemaAccountAffiliateMovil, } from "../../services/snp/Validators/shemaSNP";
import { Autocomplete, AutocompleteItem, Input } from "@nextui-org/react";
type Props = {
  data?: PaymentMethodsCompany | any;
  onSubmit: (data: any) => void;
  ref?: React.MutableRefObject<(() => void) | null>;
};
export const FormMovilPay = React.forwardRef(
  ({ onSubmit, data }: Props, ref) => {
    const {
      control,
      handleSubmit,
      formState: { errors },
      setValue,
    } = useForm({
      resolver: yupResolver(schemaAccountAffiliateMovil),
      defaultValues: {
        code: "58",
      },
    });
    const queryClient = useQueryClient();
    const { codePhone, setCodePhone } = useStoreCodePhones((state) => state);
    // Usamos useImperativeHandle para exponer el handleSubmit a través del ref.
    React.useImperativeHandle(ref, () => handleSubmit(onSubmit));

    const handleCodePhone = () => {
      queryClient.fetchQuery(
        queryOptions({
          queryKey: ["codePhone"],
          queryFn: () =>
            GetCodePhoneThunks()
              .then((d) => {
                setCodePhone(d.data);
              })
              .catch(({ response }) => {
                if (response?.status === 400) {
                  const errorMessage =
                    Object.keys(response.data || {})
                      .join(", ")
                      .concat(
                        ": " + Object.values(response.data || {}).join(", ")
                      ) ||
                    Object.values(response.data || {}).join(", ") ||
                    "Error desconocido";
                  return ErrorToast(errorMessage);
                }
              }),
          retry: false,
        })
      );
    };
 
    React.useEffect(() => {
      handleCodePhone();
    }, []);
      React.useEffect(() => {
        setValue("name", data?.name);
        setValue("sender", data?.sender.slice(2));
      }, [data]);
    return (
        <div className=" w-full flex flex-col my-4 space-y-2 ">
        <Controller
            name="name"
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <Input
                  type="text"
                  maxLength={30}
                  onChange={onChange}
                  variant="bordered"
                  isInvalid={!!errors.name}
                  errorMessage={errors.name?.message}
                  onBlur={onBlur}
                  value={value}
                  placeholder="Colocar el nombre del Titular "
                />
                <span></span>
              </>
            )}
          />
          
          <div className="flex gap-2">
            <Controller
              name="code"
              control={control}
              render={({ field }) => (
                <>
                  <div className="flex items-center">
                    <Autocomplete
                      {...field} // Pasa las propiedades del Controller
                      className="max-w"
                      variant="bordered"
                      defaultItems={codePhone}
                      placeholder="58"
                      isInvalid={!!errors.code}
                      errorMessage={errors.code?.message}
                      selectedKey={field.value}
                      onSelectionChange={(e) => field.onChange(e)}
                    >
                      {(item) => (
                        <AutocompleteItem key={item.phone_code}>
                          {item.phone_code + "/" + item.iso3_code}
                        </AutocompleteItem>
                      )}
                    </Autocomplete>
                  </div>
                </>
              )}
            />
            <Controller
              name="sender"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    type="text"
                    variant="bordered"
                    maxLength={10}
                    isInvalid={!!errors.sender}
                    errorMessage={errors.sender?.message}
                    placeholder="Telefono XXX XXXXXXX"
                  />
                  <span></span>
                </>
              )}
            />
          </div>
        
         
        </div>
    );
  }
);
