import { create } from "zustand";
import { ResultInvoices } from "../../pages/core/interfaces/InvoicesInterfaces";

interface InvociesState {
  invoices: ResultInvoices[];
  count: number;
  setInvoices: (invoices: ResultInvoices[], count: number) => void;
  reset: () => void;
}

export const useStoreInvoices = create<InvociesState>((set) => ({
  invoices: [],
  count: 0,
  setInvoices: (invoices: ResultInvoices[], count: number) =>
    set(() => ({ invoices, count })),
  reset: () => set(() => ({ invoices: [], count: 0 })),
}));


