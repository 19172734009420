// store/thunks.ts
import { movilPayAPI } from "../../../api/movilPayAPI";
import { DevicesParams } from "../../../pages/core/params/management/devicesParams";




export const getDevicesRequest =  (params?: DevicesParams) => {
  return   movilPayAPI.get(`/devices/types/`, { params });
};

export const PostDevice = (body: any) => {
  return movilPayAPI.post(`/devices/types/`, body);
};

export const UpdateDeviceThunks = (body: any, id:number) => {
  return movilPayAPI.patch(`/devices/types/${id}/`, body);
}

export const DeleteDeviceThunks = (id:number) => {
  return movilPayAPI.delete(`/devices/types/${id}/`);
}
