import {
  useDisclosure,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  Chip,
  Tooltip,
} from "@nextui-org/react";
import React from "react";
import { MdModeEdit, MdOutlineImportantDevices } from "react-icons/md";
import { useDispatch } from "react-redux";
import { LoadingToast } from "../../../../../../../libs/Notifications";
import {
  DeletePaymentMethodCompaniesThunks,
  PatchPaymentMethodCompaniesThunks,
  PaymentMethodCompaniesThunks,
} from "../../../../../../../store/companies/PaymentMethodCompany/thunks";
import { AppDispatch } from "../../../../../../../store/store";
import { ModalComponent } from "../../../../../components/Modal/ModalComponent";
import { ServicesCompany } from "../../../../../interfaces/ServicesCompany";
import { FormServicesTangible } from "./FormServicesIntangible";
import { TbClockDollar } from "react-icons/tb";
import { Link, useParams } from "react-router-dom";
import { ServicesConfigParams } from "../../../../../params/services-configs/ServicesConfigParams";
import { MethodPaymentCompany } from "../../../../../interfaces/PaymentInterfaces";
import { ServicesCompaniesIDThunks } from "../../../../../../../store/companies/servicesCompany/thunks";
import { PopoverConfirm } from "../../../../../components/Popover/PopoverConfirm";
import { FcCancel, FcOk } from "react-icons/fc";
import { FaRegTrashAlt } from "react-icons/fa";
import { LuFileEdit } from "react-icons/lu";
import { BiTimer } from "react-icons/bi";
import { FormMethodUSD } from "./FormMethodUSD";

type Props = {
  data: MethodPaymentCompany | any;
  reload: () => void;
};
export const OpenEditServiceSNP = ({ data, reload }: any) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { item } = useParams();

  const formRef = React.useRef<() => void | null>(null);

  const handleFormSubmit = (formData: any) => {
    const { success, error } = LoadingToast();
    let body;
    if (data.payment_method === 3) {
      body = {
        ...formData,
      };
    } else {
      body = {
        account_holder_name: formData.account_holder_name,
        account_number: formData.account_number,
        bank: Number(formData.bank),
        email: formData.email,
        phone: "+" + formData.code.concat(formData.phone),
        company_service: Number(item),
        payment_method: formData.payment_method[0],
      };
    }
    PatchPaymentMethodCompaniesThunks(body, data.id)
      .then(() => {
        success("Actualizado correctamente");
        onClose();
        reload();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          const errorMessage =
            Object.keys(response.data || {})
              .join(", ")
              .concat(": " + Object.values(response.data || {}).join(", ")) ||
            Object.values(response.data || {}).join(", ") ||
            "Error desconocido";
          return error(errorMessage);
        }
      });
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current();
    }
  };

  return (
    <>
      <Tooltip showArrow={true} content="Editar Método">
        <Button
          isIconOnly
          variant="light"
          size="sm"
          color="primary"
          onPress={onOpen}
        >
          <LuFileEdit className="w-6 h-6" />
        </Button>
      </Tooltip>
      <ModalComponent
        header={"Editar Método de Pago"}
        body={
          data?.payment_method == 3 ? (
            <FormMethodUSD
              onSubmit={handleFormSubmit}
              ref={formRef}
              data={data}
            />
          ) : (
            <FormServicesTangible
              onSubmit={handleFormSubmit}
              ref={formRef}
              data={data}
            />
          )
        }
        handleSubmitClick={handleSubmitClick}
        onClose={onClose}
        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        title={""}
      />
    </>
  );
};

export const ActionTypeServices = ({ data }: Props) => {
  const { serviceId, item } = useParams();
  const { isOpen, onOpenChange, onOpen } = useDisclosure();
  return (
    <div className="flex gap-2">
      <Tooltip showArrow={true} content="Ver Historial">
        <Button
          isIconOnly
          variant="light"
          size="sm"
          color="primary"
          onPress={onOpen}
        >
          <Link to={`../${item}/history/${data.id}`}>
            <BiTimer className="w-7 h-7" />
          </Link>
        </Button>
      </Tooltip>
    </div>
  );
};

export const OpenStatusSNP = ({ data, reload }: Props) => {
  const { onClose } = useDisclosure();
  const dispatch = useDispatch<AppDispatch>();
  const handleConsultation = () => {
    dispatch(PaymentMethodCompaniesThunks({ id: data.company_service }));
  };
  const handleDelete = () => {
    const { success, error } = LoadingToast();
    const body = {
      status: data.status == 1 ? 2 : 1,
    };
    PatchPaymentMethodCompaniesThunks(body, data.id)
      .then(() => {
        success("Actualizado correctamente");
        onClose();
        handleConsultation();
        reload();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          const errorMessage =
            Object.keys(response.data || {})
              .join(", ")
              .concat(": " + Object.values(response.data || {}).join(", ")) ||
            Object.values(response.data || {}).join(", ") ||
            "Error desconocido";
          return error(errorMessage);
        }
      });
  };
  return (
    <>
      <PopoverConfirm
        title="Confirmar cambio de Estado"
        message={`¿Estás seguro que deseas ${
          data.status == 1 ? "inactivar" : "activar"
        } este servicio?`}
        confirm={handleDelete} // Esta función se ejecutará al confirmar
        item={undefined}
      >
        <Button
          isIconOnly
          variant="light"
          size="sm"
          color={data.status == 1 ? "danger" : "success"}
        >
          {data.status == 1 ? (
            <FcCancel className=" h-6 w-6" />
          ) : (
            <FcOk className=" h-6 w-6" />
          )}
        </Button>
      </PopoverConfirm>
    </>
  );
};

export const ActionDeleteSNP = ({ data, reload }: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const handleConsultation = () => {
    dispatch(PaymentMethodCompaniesThunks({ id: data.company_service }));
  };
  const handleDelete = () => {
    const { success, error } = LoadingToast();

    DeletePaymentMethodCompaniesThunks(data.id)
      .then(() => {
        success("Eliminado correctamente");
        handleConsultation();
        reload();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          const errorMessage =
            Object.keys(response.data || {})
              .join(", ")
              .concat(": " + Object.values(response.data || {}).join(", ")) ||
            Object.values(response.data || {}).join(", ") ||
            "Error desconocido";
          return error(errorMessage);
        }
      });
  };

  return (
    <PopoverConfirm
      title="Confirmar  de eliminación"
      message={`¿Estás seguro que deseas ELIMINAR este servicio?`}
      confirm={handleDelete} // Esta función se ejecutará al confirmar
      item={undefined}
    >
      <Button isIconOnly variant="light" size="sm" color="danger">
        <FaRegTrashAlt className="font-semibold h-6 w-6" />
      </Button>
    </PopoverConfirm>
  );
};
