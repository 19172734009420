import { createSlice } from "@reduxjs/toolkit";
import {  ServicesThunks, ServicesTypeThunks } from "./thunks";
import { Payment } from "../../pages/core/interfaces/PaymentInterfaces";
import { Services } from "../../pages/core/interfaces/ServicesInterfaces";

export interface initialStateService {
  isLoading: boolean;
  servicesType:any[];
  count:number 
  page?:number
}

export const initialStateServices: initialStateService = {
  isLoading: false,
  count: 0,
  servicesType:[]
};

export const ServicesTypeSlice = createSlice({
  name: "servicesTypes",
  initialState: initialStateServices,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(ServicesTypeThunks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ServicesTypeThunks.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.servicesType = payload.results
        state.count = payload.count
      });
  },
});

export const {} = ServicesTypeSlice.actions;
