import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  Select,
  SelectItem,
  Input,
  Table,
  TableHeader,
  getKeyValue,
  TableBody,
  TableCell,
  TableColumn,
  TableRow,
  Button,
} from "@nextui-org/react";
import { AppDispatch, RootState } from "../../../../../../store/store";
import { ServicesThunks } from "../../../../../../store/services/thunks";
import { schemaRates } from "../validators/RatesSchema";
import { schemaTarifa } from "../validators/TarifaSchema";
import { FaTrash } from "react-icons/fa6";
import { IoIosAdd } from "react-icons/io";
import { TypeCompanyThunks } from "../../../../../../store/options/TypeCompany/thunks";
import { PaymentParams } from "../../../../params/payment/payments/paymentParams";

type Props = {
  data?: any | {};
  setData?: (data: any) => void;
  onSubmit: (data: any) => void;
  ref?: React.MutableRefObject<(() => void) | null>;
};

export const FormRates = React.forwardRef((props: Props, ref) => {

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schemaRates),
    defaultValues: {
      data: [],
    },
  });

  const {
    control: controlTarifa,
    handleSubmit: handleSubmitTarifa,
    formState: { errors: errorsTarifa },
    reset: resetTarifa,
  } = useForm({
    resolver: yupResolver(schemaTarifa),
    defaultValues: {
      min: 0,
      max: 0,
      price: 0,
    },
  });

  React.useImperativeHandle(ref, () => handleSubmit(props.onSubmit));

  const [ranges, setRanges] = useState<any[]>([]);
  const [ultimoFin, setUltimoFin] = useState(0);
  const [errorTarifa, setErrorTarifa] = useState(""); // Para mostrar errores personalizados

  const { services } = useSelector((state: RootState) => state.services);
  const dispatch = useDispatch<AppDispatch>();
  const { TypeCompany } = useSelector(
    (state: RootState) => state.typeCompany
  );
  const getTypeCompany = () => {
    const param: PaymentParams = new PaymentParams();
    param.remove_pagination = true;
    dispatch(TypeCompanyThunks(param));
  };

  useEffect(() => {
    getTypeCompany()
    dispatch(ServicesThunks());
  }, [dispatch]);
  const onAddTarifa = (data: any) => {
    // Verificar que los campos no estén vacíos
    if (!data.max || !data.price) {
      setErrorTarifa("Todos los campos de la tarifa son obligatorios");
      return;
    }

    // Validación: El nuevo inicio (min) debe ser mayor al último fin (max)
    if (data.min <= ultimoFin) {
      setErrorTarifa(
        `El inicio (${data.min}) debe ser mayor al último fin registrado (${ultimoFin})`
      );
      return;
    }

    // Validación: El fin (max) debe ser mayor o igual al inicio (min)
    if (data.max <= data.min) {
      setErrorTarifa(
        `El fin (${data.max}) debe ser mayor que el inicio (${data.min})`
      );
      return;
    }

    // Si las validaciones pasan, agregamos el nuevo rango
    const nuevoRango = { min: data.min, max: data.max, price: data.price };

    // Actualizar el estado con el nuevo rango
    setRanges([...ranges, nuevoRango]);
    setUltimoFin(data.max); // Actualizar el valor de 'ultimoFin'
    setValue("data", [...ranges, nuevoRango]);
    resetTarifa(); // Resetea los campos de tarifa
    setErrorTarifa(""); // Limpia el mensaje de error
  };

  const eliminarUltimoRango = (index: number) => {
    if (ranges.length > 0 && index === ranges.length - 1) {
      const updatedRanges = ranges.slice(0, -1);
      setRanges(updatedRanges);
      setUltimoFin(
        updatedRanges.length > 0
          ? updatedRanges[updatedRanges.length - 1].fin
          : 0
      );
      setValue("data", updatedRanges);
    }
  };

  const validateBeforeSubmit = (data: any) => {
    if (ranges.length === 0) {
      setErrorTarifa("Debes agregar al menos una tarifa.");
      return;
    }

    setErrorTarifa("");
    props.onSubmit(data);
  };

  return (
    <form className="flex flex-col space-y-3">

   
  
      {/* Selección del servicio */}
      <div className="">
        <Controller
          control={control}
          name="service"
          render={({ field }) => (
            <>
              <Select
                {...field}
                selectedKeys={field.value ? new Set([field.value]): new Set()}
                isInvalid={!!errors.service}
                placeholder="Selecciona un servicio"
                variant="bordered"
                errorMessage={errors.service?.message}
              >
                {services.map((service) => (
                  <SelectItem key={service.id} value={service.id}>
                    {service.name}
                  </SelectItem>
                ))}
              </Select>
             
            </>
          )}
        />
      </div>
      <div className="">
        <Controller
          control={control}
          name="company_type"
          render={({ field }) => (
            <>
              <Select
                {...field}
                selectedKeys={field.value ? new Set([field.value]): new Set()}
                isInvalid={!!errors.company_type}
                placeholder="Selecciona un tipo de compañia"
                variant="bordered"
                errorMessage={errors.company_type?.message}

              >
                {TypeCompany.map((type) => (
                 <SelectItem key={type.id} value={type.id}>
                 {type.name}
               </SelectItem>
                ))}
              </Select>
           
            </>
          )}
        />
      </div>
      {errors.data && (
        <span style={{ color: "red", fontSize: "12px" }}>
          {errors.data.message}
        </span>
      )}
      {/* Subformulario para tarifas */}
      <div className="grid grid-cols-3 gap-4 mt-4">
        <div>
          <Controller
            control={controlTarifa}
            name="min"
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  value={String(field.value)} // Convertir el valor a string
                  label="Inicio"
                  placeholder="Inicio"
                  variant="bordered"
                  labelPlacement="outside"
                />
              </>
            )}
          />
        </div>
        <div>
          <Controller
            control={controlTarifa}
            name="max"
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  value={String(field.value)} // Convertir el valor a string
                  isInvalid={!!errorsTarifa.max}
                  label="Fin"
                  placeholder="Fin"
                  variant="bordered"
                  labelPlacement="outside"

                />
                {errorsTarifa.max && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorsTarifa.max.message}
                  </span>
                )}
              </>
            )}
          />
        </div>
        <div>
          <Controller
            control={controlTarifa}
            name="price"
            render={({ field }) => (
              <>
                <Input
                  {...field}
                  value={String(field.value)} // Convertir el valor a string
                  isInvalid={!!errorsTarifa.price}
                  label="Precio"
                  placeholder="Precio"
                  variant="bordered"
                  labelPlacement="outside"

                />
                {errorsTarifa.price && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    {errorsTarifa.price.message}
                  </span>
                )}
              </>
            )}
          />
        </div>
        
      </div>

      {/* Botones */}
      <div className="flex justify-end   space-x-2">
        <Button
          size="md"
          color="primary"
          className="shadow-lg"
          endContent={<IoIosAdd className="h-7 w-7" />}
          onPress={() => handleSubmitTarifa(onAddTarifa)()}
        >
          Agregar Tarifa
        </Button>
       
      </div>

      {/* Mensaje de error si no hay tarifas */}
      {errorTarifa && (
        <div style={{ color: "red", fontSize: "14px", marginTop: "8px" }}>
          {errorTarifa}
        </div>
      )}

      {/* Tabla de tarifas agregadas */}
      {ranges.length > 0 && (
        <>
          <Table aria-label="Example table with dynamic content">
            <TableHeader>
              <TableColumn>Inicio</TableColumn>
              <TableColumn>Fin</TableColumn>
              <TableColumn>Precio</TableColumn>
              <TableColumn>Acción</TableColumn>
            </TableHeader>
            <TableBody>
              {ranges.map((range, index) => (
                <TableRow key={index}>
                  <TableCell>{range.min}</TableCell>
                  <TableCell>{range.max}</TableCell>
                  <TableCell>{range.price}</TableCell>
                  <TableCell>
                    {index === ranges.length - 1 && (
                      <button
                        type="button"
                        className="text-red-500 p-1"
                        onClick={() => eliminarUltimoRango(index)}
                      >
                        <FaTrash size={16} />
                      </button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>

        // <table className="table-auto w-full mt-4 border-collapse border border-gray-300">
        //   <thead>
        //     <tr className="bg-gray-100">
        //       <th className="border p-2">Inicio</th>
        //       <th className="border p-2">Fin</th>
        //       <th className="border p-2">Precio</th>
        //       <th className="border p-2">Acciones</th>
        //     </tr>
        //   </thead>
        //   <tbody>
        //     {ranges.map((range, index) => (
        //       <tr key={index} className="bg-white">
        //         <td className="border p-2">{range.min}</td>
        //         <td className="border p-2">{range.max}</td>
        //         <td className="border p-2">{range.price}</td>
        //         <td className="border p-2">
        //           {index === ranges.length - 1 && (
        //             <button
        //             type="button"
        //             className="text-red-500 p-1"
        //             onClick={() => eliminarUltimoRango(index)}
        //           >
        //             <FaTrash size={16} />
        //           </button>
        //           )}
        //         </td>
        //       </tr>
        //     ))}
        //   </tbody>
        // </table>
      )}
    </form>
  );
});
