import React from "react";
import { useDispatch } from "react-redux";
import { Avatar, Card, CardBody, CardFooter, CardHeader } from "@nextui-org/react";
import { Action, ActionDelete } from "./ActionsBanksAccounts";
import { useStoreBanksAccounts } from "../../../../../../store/banks-accounts/useStoreBanksAccounts";
import { usePermissions } from "../../../../../../context/PermissionContext";
type Props = {
  reload: () => void;
};
const CardBanks = ({ reload }: Props) => {
  const dispatch = useDispatch();
  const { banksAccounts, count } = useStoreBanksAccounts((state: any) => state);
  const { permissions, loading } = usePermissions();
  const actionPermissionEdit =
    permissions && permissions.includes("action_edit_bank_accounts");
  const actionPermissionDelete =
    permissions && permissions.includes("action_delete_bank_accounts");

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
      {banksAccounts.map((bank: any) => (
        <Card className="max-w-full mb-4" key={bank.id}>
          <CardHeader className="justify-between">
            <div className="flex gap-5">
              {/* Reemplazar el Avatar con una imagen */}
              <Avatar
                size="lg"
                isBordered
                color="primary"
                src={require(`../../../../../../assets/img/isotipo_movil play.png`)}
                className="p-2 bg-primary-50 "
              />

              {/* <img
                src={require("../../../../../../assets/img/isotipo_movil play.png")}
                alt="Bank Logo"
                className=" w-16 h-16 object-cover" // Tamaño y estilo de la imagen
              /> */}
              <div className="flex flex-col gap-1 items-start justify-center">
                <h4 className="text-small font-semibold leading-none text-default-600">
                  {bank.bank_name}
                </h4>
                <h4 className="text-small font-semibold leading-none text-default-600">
                  {bank.identification}
                </h4>
              </div>
            </div>
          </CardHeader>
          <CardBody className="px-3 py-0 text-small text-default-400">
            <p className="text-small text-foreground/80 font-bold">
              Cuenta: {bank.nro_cta}
            </p>
            <p className="text-small text-foreground/80 font-bold">
              Teléfono: {bank.tlf}
            </p>
            <p className="text-small text-foreground/80 font-bold">
              Compañias Asignadas: {bank.quantity_companies}
            </p>
          </CardBody>
          <CardFooter className="gap-3 justify-end">
            <div className="flex">
             {actionPermissionEdit && <Action data={bank} reload={reload} />}
            </div>
            <div className="flex">
              {actionPermissionDelete &&<ActionDelete data={bank} reload={reload} />}
            </div>
          </CardFooter>
        </Card>
      ))}
    </div>
  );
};

export default CardBanks;
