import { movilPayAPI } from "../../../api/movilPayAPI";
import { UserParams } from "../../../pages/core/params/settings/userParams";

export const GetUsersThunks = (params?: UserParams) => {
  return movilPayAPI.get(`/users/`, { params });
};
export const PostUsersThunks = (body: any) => {
  return movilPayAPI.post(`/users/`, body);
};
export const PatchUsersThunks = (id: number, body:any) => {
  return movilPayAPI.patch(`/users/${id}/`, body);
};
