import { createAsyncThunk } from "@reduxjs/toolkit";
import { movilPayAPI } from "../../api/movilPayAPI";
import { CompaniesParams } from "../../pages/core/params/companies/companiesParams";


export const CompaniesThunks = createAsyncThunk(
  "companies",
  async (params?: CompaniesParams) => {
    return movilPayAPI
      .get("/companies/", { params })
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        return [];
      });
  }
);

export const CompaniesIDThunks = createAsyncThunk(
  'companiesID',
  async (args: {id?:any , params?: any}) => {
    const { id, params } = args;
    try {
      const result = await movilPayAPI.get(`/companies/${id}/`, { params });
      return result.data;
    } catch (err) {
      console.error(err); // Manejo de errores
      return [];
    }
  }
);

export const PatchCompaniesThunks = (body: any, id: any) => {
  return movilPayAPI.patch(`/companies/${id}/`, body);
};

export const PostCompaniesIDThunks = (body: any, id: any) => {
  return movilPayAPI.post(`/companies/${id}/`, body);
};

export const PostCompaniesThunks = (body: any) => {
  return movilPayAPI.post(`/companies/`, body);
};
export const PostServiceConfigThunks = (body: any) => {
  return movilPayAPI.post(`/services/service-configs/`, body);
};

export const GetCodePhoneThunks = () => {
  return movilPayAPI.get(`/base/phone_codes/`);
}


export const getCompaniesRequest =  (params?: CompaniesParams) => {
  return   movilPayAPI.get(`/companies/`, { params });
};