import { createAsyncThunk } from "@reduxjs/toolkit";
import { movilPayAPI } from "../../../api/movilPayAPI";

export const TypeCompanyThunks = createAsyncThunk(
  "typeCompany",
  async (params?: any) => {
    return movilPayAPI
      .get("/companies/type/", { params })
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        return [];
      });
  }
);
export const TypeCompanyIDThunks = createAsyncThunk(
  "typeCompanyID",
  async (args: { id?: any; params?: any }) => {
    const { id, params } = args;
    try {
      const result = await movilPayAPI.get(`/companies/type/${id}/`, {
        params,
      });
      return result.data;
    } catch (err) {
      console.error(err); // Manejo de errores
      return [];
    }
  }
);

export const PostTypeCompanyThunks = (body: any) => {
  return movilPayAPI.post(`/companies/type/`, body);
};
export const PatchTypeCompanyThunks = (body: any, id: number) => {
  return movilPayAPI.patch(`/companies/type/${id}/`, body);
};

export const DeleteTypeCompanyThunks = (id: any) => {
  return movilPayAPI.delete(`/companies/type/${id}`);
};
