import * as yup from "yup";
export const schemaUser = yup.object({
  name: yup.string().required("Este campo es requerido"),
  lastname: yup.string().required("Este campo es requerido"),
  email: yup
    .string()
    .required("Este campo es requerido")
    .email("debe contener el formarto de correo"),
    code: yup.string().required("Debes selecionar el codigo"),
    phone: yup
    .string()
    .required("Este campo es requerido")
    .max(10, "El teléfono tiene un máximo de 10 caracteres")
    .matches(/^[0-9]{10}$/, "Formato XXXXXXXXXXX"),
  password: yup.string(),
  confirmPassword: yup
  .string()
  .oneOf([yup.ref("password"), ""], "Las contraseñas deben coincidir"),
});
export const schemaUserShort = yup.object({
  identification: yup
    .string()
    .max(10, "Hasta 10 caracteres")
    .matches(/^[0-9]*$/, "Soló números")
    .required("Este campo es requerido"),
  type: yup.string().required("Este campo es requerido"),
});
