import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BottonsPayments } from "./components/table/BottonsPayments";
import { CheckFilterPayments } from "./components/filters/CheckFilterPayments";
import { SinceUntilPayments } from "./components/filters/SinceUntilPayments";
import { TablePayment } from "./components/table/TablePayments";
import { PaymentParams } from "../../../params/payment/payments/paymentParams";
import { AppDispatch } from "../../../../../store/store";
import { useAllParams } from "../../../../../hooks/useAllParams";
import { getPaymentRequest, PaymentsThunks } from "../../../../../store/payment/thunks";
import { TemplateTableLayout } from "../../../layout/TemplateTableLayout";
import { BanksDestinyOriginPayments } from "./components/filters/BanksDestinyOriginPayments";
import { CompaniesFilterPayments } from "./components/filters/CompaniesFilterPayments";
import { CardInfoPayments } from "../../companies/company/components/CardInfoPayments";
import { DashboardParams } from "../../../params/dashboard/DashboardParams";
import { firstDayMonth } from "../../../services/getToday";
import moment from "moment";
import { DashboardParamsThunks } from "../../../../../store/dashboard/thunks";
import { create } from "zustand";
import { Payment } from "../../../interfaces/PaymentInterfaces";
import { queryOptions, useQueryClient } from "@tanstack/react-query";
import { usePermissions } from "../../../../../context/PermissionContext";
import { useStorePaymentCore } from "../../../../../store/payment/useStorePayment";
import { ErrorToast } from "../../../../../libs/Notifications";


const PaymentCore = () => { 
  const queryClient = useQueryClient();
  const { params, deleteParams, setSearchParams } = useAllParams();
  const { setPayments, resetPayments } = useStorePaymentCore((state: any) => state);
  const { permissions } = usePermissions();
  const actionPermissionFilter =permissions && permissions.includes('action_filter_payments');
  const actionPermissionAdd =permissions && permissions.includes('action_add_payments');
  const handleConsultation = async () => {
  
    const param = new PaymentParams();
    params.status && (param.status = params.status);
    params.page && (param.page = Number(params.page));
    params.since && (param.since = params.since);
    params.until && (param.until = params.until);
    params.search && (param.search = params.search);
    params.bank_origin && (param.bank_origin = params.bank_origin);
    params.bank_destiny && (param.bank_destiny = params.bank_destiny);
    params.company && (param.company = params.company);

    await queryClient.fetchQuery(
      queryOptions({
        queryKey: ["paymentsHistory"],
        queryFn: () =>
          getPaymentRequest(param)
            .then((d) => {
              setPayments(d.data.results, d.data.count);
            })
            .catch(({ response }) => {
              if (response?.status === 400) {
                const errorMessage =
                  Object.keys(response.data || {})
                    .join(", ")
                    .concat(
                      ": " + Object.values(response.data || {}).join(", ")
                    ) ||
                  Object.values(response.data || {}).join(", ") ||
                  "Error desconocido";
                return ErrorToast(errorMessage);
              }
            }),
        retry: false,
      })
    );
  };

  useEffect(() => {
    handleConsultation();
  }, [params]);

  const handleSearch = ({ search }: any) => {
    if (search == undefined || search == null) return;

    if (search.length == 0) {
      deleteParams(["search"]);
      return;
    }
    const { page, ...rest } = params;
    setSearchParams({ ...rest, search });
  };



  return (
    <div className="animate-fade-up space-y-5">
      <CardInfoPayments />
      <TemplateTableLayout
        title="Información de los pagos"
        bottons={actionPermissionAdd && <BottonsPayments refresh={handleConsultation} />}
        search={handleSearch}
        filters={actionPermissionFilter ?[
          {
            name: "",
            component: <CheckFilterPayments />,
            field: "status",
          },
          {
            name: "Introduce un rango de fecha",
            component: <SinceUntilPayments />,
            field: "since",
          },
          {
            name: "Compañía",
            component: <CompaniesFilterPayments />,
            field: "company",
          },
          {
            name: "Selecciona los bancos",
            component: <BanksDestinyOriginPayments />,
            field: "banks",
          },
        ]:[]}
      >
        <>
          {params.since && (
            <p className="font-semibold text-xl dark:text-white text-end mt-4">
              {params.since && params.since}
              {params.until && ` – ${params.until}`}
            </p>
          )}
        </>
      </TemplateTableLayout>
      <TablePayment />
    </div>
  );
};

export default PaymentCore;
