// @flow 
import * as React from 'react';
import { PatchSectorThunks } from '../../../../../../store/location/Sector/thunks';
import { useDisclosure, Button } from '@nextui-org/react';
import { LuFileEdit } from 'react-icons/lu';
import { LoadingToast, ErrorToast } from '../../../../../../libs/Notifications';
import { FormParish } from '../../../../components/location/FormParish';
import { ModalComponent } from '../../../../components/Modal/ModalComponent';
import { data } from '../../../finances/payments/components/ChartPiePayments';
import { FormSector } from '../../../../components/location/FormSector';
type Props = {
    reload: () => void;
    data?: any;
  };
export const EditSector = ({data, reload}: Props) => {
    const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
    const formRef = React.useRef<() => void | null>(null);
    const handleFormSubmit = (formData: any) => {
      const { success, error } = LoadingToast();
      const body = {
        ...formData,
      };
      PatchSectorThunks(body, Number(data?.id))
        .then((results) => {
          onClose();
          success("Datos actualizados correctamente");
          reload();
        })
        .catch(({ response }) => {
          if (response?.status === 400) {
            const errorMessage =
              Object.keys(response.data || {})
                .join(", ")
                .concat(": " + Object.values(response.data || {}).join(", ")) ||
              Object.values(response.data || {}).join(", ") ||
              "Error desconocido";
            return ErrorToast(errorMessage);
          }
        });
    };
    const handleSubmitClick = () => {
      if (formRef.current) {
        formRef.current();
      }
    };
   
    return (
      <>
        <Button
          isIconOnly
          variant="light"
          color="primary"
          size="sm"
          onPress={onOpen}
        >
          <LuFileEdit className={`font-semibold h-6 w-6 `} />
        </Button>
        <ModalComponent
          header={"Editar Sector"}
          body={
            <FormSector data={data} onSubmit={handleFormSubmit} ref={formRef} />
          }
          handleSubmitClick={handleSubmitClick}
          onClose={onClose}
          isOpen={isOpen}
          onOpen={onOpen}
          onOpenChange={onOpenChange}
          title={""}
        />
      </>
    );
};
export const DeleteSector = (props: Props) => {
    return (
        <div>
            
        </div>
    );
};