import { Invoices } from './../../pages/core/interfaces/InovicesInterfaces';
import { createSlice } from "@reduxjs/toolkit";
import { getCookie } from "../../config/cookies";
import { InvoicesThunks } from './thunks';

export interface initialStateInvoices {
    isLoading: boolean;
    invoices:Invoices[];
    count:number 
    page?:number
  }
  export const initialStateInvoices: initialStateInvoices = {
    isLoading: false,
    count: 0,
    invoices:[]
  };

  export const InvoicesSlice = createSlice({
    name: "Invoices",
    initialState: initialStateInvoices,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(InvoicesThunks.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(InvoicesThunks.fulfilled, (state, { payload }) => {
          state.isLoading = false;
          state.invoices = payload.results
          state.count = payload.count
        });
    },
  });
  
  export const {} = InvoicesSlice.actions;
  