import { createAsyncThunk } from "@reduxjs/toolkit";
import { movilPayAPI } from "../../../api/movilPayAPI";

export const StatesThunks = createAsyncThunk(
  "states",
  async (params?: any) => {
    return movilPayAPI
      .get("/locations/state/", { params })
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        return [];
      });
  }
);
export const StateIDThunks = createAsyncThunk(
  "stateID",
  async (args: { id?: any; params?: any }) => {
    const { id, params } = args;
    try {
      const result = await movilPayAPI.get(`/locations/state/${id}/`, {
        params,
      });
      return result.data;
    } catch (err) {
      console.error(err); // Manejo de errores
      return [];
    }
  }
);

export const PostStateThunks = (body: any) => {
  return movilPayAPI.post(`/locations/state/`, body);
};
export const PatchStateThunks = (body: any, id: number) => {
  return movilPayAPI.patch(`/locations/state/${id}/`, body);
};

export const DeleteStateThunks = (id: any) => {
  return movilPayAPI.delete(`/locations/state/${id}`);
};
