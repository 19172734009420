import { FaCashRegister, FaFileInvoice, FaFileInvoiceDollar } from "react-icons/fa6";
import { FcBriefcase, FcCurrencyExchange } from "react-icons/fc";


export const financesRouters =  [
    {
      name: "Pagos",
      path: "finance/payments/",
      icon: FcCurrencyExchange,
      superAdmin: false,
      show: true,
      children: null,
    },
    {
      name: "Cobranzas",
      path: "finance/collections/",
      icon: FcBriefcase,
      superAdmin: false,
      show: true,
      children: null,
    },
    {
      name: "Facturacion",
      path: "finance/invoices/",
      icon: FaFileInvoice,
      superAdmin: false,
      show: true,
      children: null,
    },
    {
      name: "Crear Factura",
      path: "finance/invoices/create/",
      icon: FaFileInvoiceDollar ,
      superAdmin: false,
      show: true,
      children: null,
    },
    {
      name: "Pagar Factura",
      path: "finance/invoices/payment/",
      icon: FaCashRegister,
      superAdmin: false,
      show: true,
      children: null,
    },
  ]
