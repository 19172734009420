import { createAsyncThunk } from "@reduxjs/toolkit";
import { movilPayAPI } from "../../../api/movilPayAPI";
import { LocationParams } from "../../../pages/core/params/locations/LocationParams";

export const ParishThunks = createAsyncThunk(
  "parish",
  async (params?: any) => {
    return movilPayAPI
      .get("/locations/parroquia/", { params })
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        return [];
      });
  }
);
export const ParishIDThunks = createAsyncThunk(
  "ParishID",
  async (args: { id?: any; params?: any }) => {
    const { id, params } = args;
    try {
      const result = await movilPayAPI.get(`/locations/parroquia/${id}/`, {
        params,
      });
      return result.data;
    } catch (err) {
      console.error(err); // Manejo de errores
      return [];
    }
  }
);


export const PostParishThunks = (body: any) => {
  return movilPayAPI.post(`/locations/parroquia/`, body);
};
export const PatchParishThunks = (body: any, id: number) => {
  return movilPayAPI.patch(`/locations/parroquia/${id}/`, body);
};

export const DeleteParishThunks = (id: any) => {
  return movilPayAPI.delete(`/locations/parroquia/${id}`);
};
export const GetParishThunks = (params?: LocationParams) => {
  return movilPayAPI.get(`/locations/parroquia/`, {params: params});
};
