import { PaginatorPayments } from "./PaginatorPayments";
import moment from "moment";
import { TableLayout, valueType } from "../../../../../layout/TableLayout";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store/store";
import { usePermissions } from "../../../../../../../context/PermissionContext";
import { useStorePaymentCore } from "../../../../../../../store/payment/useStorePayment";

const dataTable: valueType[] = [
  { name: "Compañía", value: "company_name" },
  { name: "Banco de origen", value: "bank_origin_name" },
  { name: "Banco de destino", value: "bank_destiny_name" },
  { name: "Referencia", value: "reference" },
  { name: "Método del pago", value: "method_name" },
  {
    name: "Fecha del pago",
    value: (item: any) => {
      return moment(item.date).format("DD-MM-YYYY");
    },
  },
  {
    name: "Estado",
    value: (item: any) => {
      return (
        <span className={item.status ? "text-green-500" : "text-red-500"}>
          {item.status ? "Activo" : "Inactivo"}
        </span>
      );
    },
  },
  { name: "Monto", value: "amount" },
  {
    name: "Acción",
    value: (e: any) => {
      return <></>;
    },
  },
];

export const TablePayment = () => {
  // const { payments, count } = useSelector((d: RootState)=> d.payments)
  const { count, payments } = useStorePaymentCore(
    (state: any) => state
  );
  return (
    <>
      {count > 0 && (
        <TableLayout
          isLoading={false}
          data={payments}
          columns={dataTable}
          Paginator={<PaginatorPayments count={count} />}
        />
      )}
    </>
  );
};
