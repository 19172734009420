// @flow
import { Chip } from "@nextui-org/react";
import * as React from "react";
import { MethodPaymentCompany } from "../../../../../../interfaces/PaymentInterfaces";
import { TableLayout, valueType } from "../../../../../../layout/TableLayout";
import {
  OpenEditServiceSNP,
  ActionTypeServices,
  OpenStatusSNP,
  ActionDeleteSNP,
} from "../ActionServiceSNP";
import { PaginatorPayments } from "../../../../../finances/payments/components/table/PaginatorPayments";
import { useStoreSCPaymentMethodCore } from "../../../../../../../../store/companies/PaymentMethodCompany/useStoreSCPaymentMethod";
import { usePermissions } from "../../../../../../../../context/PermissionContext";

type Props = {
  reload: () => void;
};
export const TableServiceCompanysnp = ({ reload }: Props) => {
  const { scpaymentMethod, count } = useStoreSCPaymentMethodCore(
    (state) => state
  );
  const { permissions, loading } = usePermissions();
  const actionPermissionEdit =permissions && permissions.includes('action_edit_payment_method');
  const actionPermissioHistory =permissions && permissions.includes('action_view_service_transaction_history');
  const actionPermissionDelete =permissions && permissions.includes('action_edit_payment_method');
  const actionPermissionStatus =permissions && permissions.includes('action_change_status_payment_method');

  const dataTable: valueType[] = [
    {
      name: "Métodos",
      value: "payment_method_name",
    },
    { name: "Banco", value: "bank_achronym" },
    {
      name: "Nro.Cuenta",
      value: "account_number",
    },
    {
      name: "Teléfono",
      value: "phone",
    },
    {
      name: "Titular",
      value: "account_holder_name",
    },
    {
      name: "Identificación",
      value: "identification",
    },
    {
      name: "Correo",
      value: "email",
    },
    {
      name: "Estado",
      value: (item: any) => {
        return (
          <>
            <Chip
              color={item.status == 1 ? "primary" : "danger"}
              className="uppercase"
            >
              {item.status_name}
            </Chip>
          </>
        );
      },
    },
    {
      name: "Acción",
      value: (e: MethodPaymentCompany) => (
        <div className="flex gap-x-2 ">
         {actionPermissionEdit && <OpenEditServiceSNP data={e} reload={reload} />}
         { actionPermissioHistory && <ActionTypeServices data={e} reload={reload} />}
          {actionPermissionStatus && <OpenStatusSNP data={e} reload={reload} />}
          {actionPermissionDelete && <ActionDeleteSNP data={e} reload={reload} />}
        </div>
      ),
    },
  ];
  return (
    <>
      <TableLayout
        columns={dataTable}
        data={scpaymentMethod}
        isLoading={false}
        Paginator={<PaginatorPayments count={count} />}
      />
    </>
  );
};
