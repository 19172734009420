import React from 'react';
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure, Button } from '@nextui-org/react';
import { FaInfo } from 'react-icons/fa';
import { deleteInvoiceThunks } from '../../../../../../../store/finances/Thunks';
import { SuccessToast, ErrorToast } from '../../../../../../../libs/Notifications';
import { ModalComponent } from '../../../../../components/Modal/ModalComponent';
import { FormDetailInvoice } from '../forms-invoices/components/details/FormDetailInvoice';
import { data } from '../../../payments/components/ChartPiePayments';

type Props = {
    data?: any;
    reload: () => void;
};

export const DetailInvoice = ({ data, reload }: Props) => {
    const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
    const handleDelete = () => {
        deleteInvoiceThunks(data.id)
        .then((result) => {
          SuccessToast("Factura eliminada correctamente");
          reload();
          onClose();
        })
        .catch(({ response }) => {
          if (response?.status === 400) {
            // Verificar si hay un mensaje de error en el cuerpo de la respuesta
            const errorMessage =  Object.values(response.data || {}).join(', ') || "Error desconocido";
            return ErrorToast(errorMessage);
          }
    
          ErrorToast("Hubo un error al eliminar la Factura.");
        });
    };

    return (
        <>
            <span
                style={{
                    cursor: 'pointer',
                }}
                onClick={onOpen} // Abrir el modal al presionar el texto
            >
                Ver Detalles
            </span>

            <ModalComponent
                body={<FormDetailInvoice  data={data}/>}
                onClose={onClose}
                isOpen={isOpen}
                onOpen={onOpen}
                size="5xl"
                scrollBehavior="inside"
                placement="bottom"
                onOpenChange={onOpenChange}
                title={""}
            />
        </>
    );
};
