import { create } from "zustand";
import { UsersAll } from "../../../pages/core/interfaces/UsersInterfaces";

interface UsersState {
  users: UsersAll[];
  setUsers: (users: UsersAll[], count: number) => void;
  reset: () => void;
  count: number;
}

export const useStoreUsers = create<UsersState>((set) => ({
  users: [],
  count: 0,
  setUsers: (users: UsersAll[], count: number) => set(() => ({ users, count })),
  reset: () => set(() => ({ users: [], count: 0 })),
}));
