import { movilPayAPI } from "../../api/movilPayAPI";
import { BanksAccountsParams } from "../../pages/core/params/banks/BanksAccountsParams";

export const getBanksAccounts =  (params?: BanksAccountsParams) => {
    return   movilPayAPI.get(`/services/banks_associated/`, { params });
  };
  
  export const PostBanksAccounts = (body: any) => {
    return movilPayAPI.post(`/services/banks_associated/`, body);
  };
  
  export const UpdateBanksAccounts = (body: any, id:number) => {
    return movilPayAPI.patch(`/services/banks_associated/${id}/`, body);
  }
  
  export const DeleteBanksAccounts = (id:number) => {
    return movilPayAPI.delete(`/services/banks_associated/${id}/`);
  }
