// @flow
import {
  useDisclosure,
  Button,
  Tab,
  Tabs,
  Radio,
  RadioGroup,
  cn,
} from "@nextui-org/react";
import * as React from "react";
import { IoIosAdd } from "react-icons/io";
import { RootState } from "../../../../../../store/store";
import { useSelector } from "react-redux";
import { ErrorToast, SuccessToast } from "../../../../../../libs/Notifications";
import { ModalComponent } from "../../../../components/Modal/ModalComponent";
import { FormMovilPay } from "./FomsAccountAffiliate/FormMovilPay";
import { BiTransfer } from "react-icons/bi";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { SiZelle } from "react-icons/si";
import { FormZelle } from "./FomsAccountAffiliate/FormZelle";
import { FormTransference } from "./FomsAccountAffiliate/FormTransference";
import { PostAccountAfiliateThunks } from "../../../../../../store/companies/AccountAfiliate/thunks";
import { RenderTexture } from "@react-three/drei";
type Props = {
  reload: () => void;
};

export const AddAccountAffiliate = ({ reload }: Props) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { companies } = useSelector((state: RootState) => state.companyID);
  const formRef = React.useRef<() => void | null>(null);
  const [selected, setSelected] = React.useState<string | any>("");

  const handleFormSubmit = (formData: any) => {
    if (selected === "") {
      onClose();
      return ErrorToast("Vuela a seleccionar el método de pago");
    }

    const body = {
      ...formData,
      sender:
        selected == "1"
          ? formData.code.concat(formData.sender)
          : formData.sender,
      company: companies?.id,
      method: selected,
    };
    PostAccountAfiliateThunks(body)
      .then((result) => {
        SuccessToast("Agregado exitosamente");
        reload();
        onClose();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          const errorMessage =
            Object.keys(response.data || {})
              .join(", ")
              .concat(": " + Object.values(response.data || {}).join(", ")) ||
            Object.values(response.data || {}).join(", ") ||
            "Error desconocido";
          return ErrorToast(errorMessage);
        }
      });
    return;
  };
  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current();
    }
  };

  return (
    <div>
      <Button
        color="primary"
        className="shadow-lg"
        endContent={<IoIosAdd className="h-7 w-7" />}
        onPress={onOpen}
      >
        Afiliar cuenta
      </Button>
      <ModalComponent
        header={"Afiliar cuenta"}
        body={
          <>
            <div className="flex flex-col w-full justify-between max-w-xl">
              <RadioGroup
                orientation="horizontal"
                value={selected}
                onValueChange={setSelected}
                className="flex justify-center" 
              >
                {tabs.map((tab, i) => (
                  <>
                    <Radio
                      value={tab.id.toString()}
                      classNames={{
                        base: cn(
                          "w-full m-0 bg-content1 hover:bg-content2 items-center font-medium",
                          "flex-row-reverse cursor-pointer rounded-xl gap-2 p-2 border-2 border-transparent text-primary",
                          "data-[selected=true]:border-primary"
                        ),
                      }}
                    >
                      <div className="flex justify-center items-center space-x-2">
                        <tab.Icon className="h-5 w-5" />
                        <span>{tab.label}</span>
                      </div>
                    </Radio>
                  </>
                ))}
              </RadioGroup>
              {tabs.map(
                (tab, i) =>
                  selected == tab.id.toString() && (
                    <div className="flex flex-col items-center space-y-4 p-4  ">
                      <div className="inline-flex gap-2 p-2 items-center">
                        <tab.Icon className="h-6 w-6" />
                        <p className="text-xl">{tab.label}</p>
                      </div>
                      <tab.Content onSubmit={handleFormSubmit} ref={formRef} />
                    </div>
                  )
              )}
            </div>
          </>
        }
        handleSubmitClick={handleSubmitClick}
        onClose={onClose}
        isOpen={isOpen}
        onOpen={onOpen}
        onOpenChange={onOpenChange}
        title={""}
      />
    </div>
  );
};

// Hacer la adaptacion para que al seleccionar una opcion se envie el valor al formulario y me la devuelva para saber
// que metodo es .
let tabs = [
  {
    id: 1,
    label: "Pago móvil",
    Icon: HiOutlineDevicePhoneMobile,
    Content: FormMovilPay,
  },
  {
    id: 2,
    label: "Transferencia",
    Icon: BiTransfer,
    Content: FormTransference,
  },
  {
    id: 3,
    label: "Zelle",
    Icon: SiZelle,
    Content: FormZelle,
  },
];
