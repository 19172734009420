// @flow
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  useDisclosure,
} from "@nextui-org/react";
import * as React from "react";
import { ServicesCompany } from "../../../../interfaces/ServicesCompany";
import { FcCancel, FcOk } from "react-icons/fc";
import { Link } from "react-router-dom";
import { IoMdEye } from "react-icons/io";
import { LoadingToast } from "../../../../../../libs/Notifications";
import { PopoverConfirm } from "../../../../components/Popover/PopoverConfirm";
import { FormFinishService } from "./FormFinishService";
import { PatchServicesCompaniesThunks } from "../../../../../../store/companies/servicesCompany/thunks";
import { FaUnlink } from "react-icons/fa";
import { GrAction } from "react-icons/gr";
import { ImSwitch } from "react-icons/im";
import { HiOutlineStatusOffline, HiOutlineStatusOnline } from "react-icons/hi";

type Props = {
  e: ServicesCompany;
  reload?: () => void;
};
export const ActionServicesCompany = ({ e }: Props) => {
  return (
    <>
      <Tooltip showArrow={true} content="Ver servicios">
        <Button
          
          color="primary"
          variant="light"
          radius="full"
          isIconOnly
        >
          <Link to={`../${e.id}`}>
            <IoMdEye className="w-7 h-7" />
          </Link>
        </Button>
      </Tooltip>
    </>
  );
};

export const OpenStatusService = ({ e, reload }: Props) => {
  const { onClose, onOpen, isOpen } = useDisclosure();
  const handleDelete = () => {
    const { success, error } = LoadingToast();
    const body = {
      is_active: !e.is_active,
    };
    PatchServicesCompaniesThunks(body, e.id)
      .then(() => {
        success("Actualizado correctamente");
        onClose();
        reload && reload();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          const errorMessage =
            Object.keys(response.data || {})
              .join(", ")
              .concat(": " + Object.values(response.data || {}).join(", ")) ||
            Object.values(response.data || {}).join(", ") ||
            "Error desconocido";
          return error(errorMessage);
        }
      });
  };

  return (
    <>
      <PopoverConfirm
        title={`¡El servicio esta ${e.is_active ? "Activo" : "inactivo"}!`}
        message={`¿Estás seguro que deseas ${
          e.is_active ? "inactivar" : "activar"
        } este servicio?`}
        confirm={handleDelete} // Esta función se ejecutará al confirmar
        item={undefined}
      >
        <Button
          onPress={onOpen}
          
          variant="light"
          radius="full"
          isIconOnly
          color={e.is_active ? "danger" : "primary"}
        >
          {e.is_active ? (
            <HiOutlineStatusOffline  className="h-7 w-7" />
          ) : (
            <HiOutlineStatusOnline className="h-7 w-7" />
          )}
        </Button>
      </PopoverConfirm>
    </>
  );
};

export const FinishService = ({ e, reload }: Props) => {
  const { isOpen, onOpenChange, onClose } = useDisclosure();

  const formRef = React.useRef<() => void | null>(null);
  const handleFormSubmit = (formData: any) => {
    const { success, error } = LoadingToast();
    const body = {
      ...formData,
      date_end: new Date().toISOString().split("T")[0],
    };
    PatchServicesCompaniesThunks(body, e.id)
      .then(() => {
        onClose();
        success("Finalizado correctamente");
        reload && reload();
      })
      .catch(({ response }) => {
        onClose();
        if (response?.status === 400) {
          const errorMessage =
            Object.keys(response.data || {})
              .join(", ")
              .concat(": " + Object.values(response.data || {}).join(", ")) ||
            Object.values(response.data || {}).join(", ") ||
            "Error desconocido";
          return error(errorMessage);
        }
      });
  };

  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current();
    }
  };
  return (
    <Popover
      placement="bottom"
      showArrow
      offset={10}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <Tooltip showArrow content="Finalizar el servicio">
        <PopoverTrigger>
          <Button
            variant="light"
            color="danger"
            radius="full"
            isIconOnly
          >
            <GrAction className=" h-6 w-6" />{" "}
          </Button>
        </PopoverTrigger>
      </Tooltip>
      <PopoverContent className="w-full">
        <Card shadow="none" className=" border-none bg-transparent">
          <CardHeader className="flex w-full justify-between ">
            <h4 className="font-bold text-large">
              {"    "}Finalizar el servicio {"    "}
            </h4>
          </CardHeader>
          <Divider />
          <CardBody className="w-full">
            <FormFinishService
              data={e}
              onSubmit={handleFormSubmit}
              ref={formRef}
            />
          </CardBody>
          <CardFooter>
            <div className="w-full gap-x-2">
              <Button
                type="submit"
                onClick={handleSubmitClick}
                color="primary"
                className=" inline-flex  justify-center
                px-3 py-2 text-sm font-semibold sm:mt-0 w-full"
              >
                Finalizar
              </Button>
            </div>
          </CardFooter>
        </Card>
      </PopoverContent>
    </Popover>
  );
};

export const FinishDetailService = ({ e }: Props) => {
  const { isOpen, onOpenChange } = useDisclosure();

  return (
    <Popover
      showArrow
      placement="bottom"
      offset={10}
      isOpen={isOpen}
      onOpenChange={onOpenChange}
    >
      <PopoverTrigger>
        <Button  size="sm"  color="danger" className="shadow-lg">
          {e.date_end}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-3">
        <div className="px-1 py-2 gap-4 space-y-2">
          <div className="text-small font-bold">Detalles de Finalización</div>
          <div className="text-tiny">{e.date_end_detail}</div>
        </div>
      </PopoverContent>
    </Popover>
  );
};
