import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { FinancesServicesParams } from '../../../../../../../params/finances/FinancesServicesParams';
import { getInvoiceCompanyDevicesThunks } from '../../../../../../../../../store/finances/Thunks';
import DeviceSchema from '../../validators/deviceSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Input, Select, SelectItem } from '@nextui-org/react';
import { MdFormatListBulletedAdd } from 'react-icons/md';
import { useStoreCreateInvoice } from '../../../../../../../../../store/finances/useStoreCreateInvoice';


interface Props {
    idCompany: any;
  }

const FormItemDevices = ({idCompany}:Props) => {
    const [devices, setDevices] = useState<any>([]);
    const queryClient = useQueryClient();
    const { control, handleSubmit, watch, reset, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(DeviceSchema),
    });
    const selectedDevice = watch('device');

    const getDevices = async () => {
        const param = new FinancesServicesParams();
        param.company = idCompany;

        // Fetch data from API and store result in servicesData state
        const data = await queryClient.fetchQuery({
            queryKey: ["services", idCompany],
            queryFn: () => getInvoiceCompanyDevicesThunks(param),
            retry: false,
        });

        // Log and set the API response
        console.log('API Response:', data.data);
        setDevices(data?.data?.results || []); // Update state with the results if available
    };

    useEffect(() => {
        if (idCompany) {
            getDevices();
        }
    }, [idCompany]);

    useEffect(() => {
        if (selectedDevice) {
            const device = devices.find((s: any) => s.device === Number(selectedDevice));
            if (device) {
                setValue('price', device.device_cost || 0);
                setValue('device_serial', device.device_serial || '');
                setValue('cant','1');
                setValue('description',`Pago de mensualidad`);
            }
        }
    }, [selectedDevice, setValue]);

    const onSubmit =(data: any) =>{
        const iva = (data.price * data.cant) * 0.16;

        const item = {
            id: data.device,
            name: data.device_serial,
            cant: data.cant,
            description: data.description,
            price: data.price,
            serial: '',
            exentoIVA:false,
            iva:iva,
            type:1
        }
        // Add your item logic here
        useStoreCreateInvoice.getState().setFormData(item); // Store form data in Zustand
        reset(); // Reset the form
    }

  return (
    <div>
            <form onSubmit={handleSubmit(onSubmit)} className="grid grid-cols-1 lg:grid-cols-3  gap-4">
            <div className="col-span-1">
                    <Controller
                        control={control}
                        name="device"
                        render={({ field }) => (
                            <Select
                                {...field}
                                onChange={(e) => field.onChange(e)}
                                isInvalid={!!errors.device}
                                placeholder="Selecciona el dispositivo"
                                variant="bordered"
                            >
                                {devices.map((device: any) => (
                                    <SelectItem key={device.device} value={device.device}>
                                        {device.device_serial}
                                    </SelectItem>
                                ))}
                            </Select>
                        )}
                    />
                    {errors.device && (
                        <span style={{ color: 'red', fontSize: '12px' }}>
                            {errors.device.message}
                        </span>
                    )}
                </div>

                <div className="col-span-1">
                    <Controller
                        name="cant"
                        control={control}
                        render={({ field }) => (
                            <Input
                                {...field}
                                placeholder="Cantidad"
                                type="number"
                                variant="bordered"
                                isInvalid={!!errors.cant}
                                readOnly={true}
                                value={field.value ?? 1}
                            />
                        )}
                    />
                    {errors.cant && (
                        <span style={{ color: 'red', fontSize: '12px' }}>
                            {errors.cant.message}
                        </span>
                    )}
                </div>
                <div className="col-span-1">
                    <Controller
                        name="price"
                        control={control}
                        render={({ field }) => (
                            <Input
                                {...field}
                                placeholder="Precio"
                                type="text"
                                variant="bordered"
                                value={field.value ?? ""}
                                isInvalid={!!errors.price}
                                readOnly={true}
                            />
                        )}
                    />
                    {errors.price && (
                        <span style={{ color: 'red', fontSize: '12px' }}>
                            {errors.price.message}
                        </span>
                    )}
                </div>
                <button
                    type="submit"
                    className=" h-14 bg-primary  text-white flex items-center p-2 rounded-xl hover:bg-secondary transition"
                >
                    <MdFormatListBulletedAdd className="text-2xl text-white mr-2" />
                    Agregar Item
                </button>
                </form>
    </div>
  )
}

export default FormItemDevices