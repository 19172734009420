// store/deviceStore.ts
import { create } from "zustand";
import { DevicesServiceConfig } from "../../../pages/core/interfaces/DevicesServicesConfig";

interface DevicesServiceCompanyState {
  devicesServiceCompany: DevicesServiceConfig[];
  count: number;
  setDevicesServiceCompany: (
    devicesServiceCompany: DevicesServiceConfig[],
    count: number
  ) => void;
  reset: () => void;
}

export const useStoreDevicesServiceCompany = create<DevicesServiceCompanyState>(
  (set) => ({
    devicesServiceCompany: [],
    count: 0,
    setDevicesServiceCompany: (
      devicesServiceCompany: DevicesServiceConfig[],
      count: number
    ) => set(() => ({ devicesServiceCompany, count })),
    reset: () => set(() => ({ devicesServiceCompany: [], count: 0 })),
  })
);
