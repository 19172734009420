export class InvoicesParams{
    search?:string
    page?:number
    remove_pagination?:boolean
    invoice_exclude?:string
    company?:string
    status?:number | string
    emission_tfhk?: boolean | string
    annulled?: boolean | string
    date_emission_since?:string;
    date_emission_until?:string;
    date_payment_since?:string;
    date_payment_until?:string;
}