import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserParams } from "../../../pages/core/params/settings/userParams";
import { movilPayAPI } from "../../../api/movilPayAPI";

export const usersAll = createAsyncThunk("users", async (params?: UserParams) => {
  return await movilPayAPI
    .get("/companies/users/", {params})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return [];
    });
});
export const userCreate=(body:any)=>{
  return movilPayAPI.post(`/companies/users/`,body)
}
export const userPatch=(body:any, id:number)=>{
  return movilPayAPI.patch(`/companies/users/${id}/`,body)
}
