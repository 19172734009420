import { Pagination } from "@nextui-org/react";
import { useAllParams } from "../../../../hooks/useAllParams";
import { PLayouts } from "../../layout/PLayouts";

interface Props {
  count: number;
}

export const PaginatorTable = ({ count }: Props) => {
  const { addParams, deleteParams, params: { page } } = useAllParams();
  
  const pages = Math.ceil(count / 10);
  const handlePages = (pageFunction: number) => {
    if (pageFunction === 1) {
      deleteParams(["page"]);
    } else {
      addParams({ page: pageFunction });
    }
  };

  return (
    <div className="flex w-[100%] lg:justify-between justify-end items-end">
      <div className="lg:flex hidden pt-4">
        <PLayouts message={`Total de items ${count}`} />
      </div>
      {count > 0 && (
        <Pagination
          isCompact
          showControls
          showShadow
          color="primary"
          size="lg"
          radius="full"
          page={!!page ? Number(page) : 1}
          total={pages}
          onChange={handlePages}
        />
      )}
    </div>
  );
};
