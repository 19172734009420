import { useDisclosure, Button } from "@nextui-org/react";
import { ErrorToast, SuccessToast } from "../../../../../../libs/Notifications";
import {
  CreateService,
  ServicesThunks,
} from "../../../../../../store/services/thunks";
import { useRef, useState } from "react";
import { ModalComponent } from "../../../../components/Modal/ModalComponent";
import { FormServices } from "./FormServices";
import { IoIosAdd } from "react-icons/io";
import { AppDispatch } from "../../../../../../store/store";
import { useDispatch } from "react-redux";

export const CreateServices = (e: any) => {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [data, setData] = useState(e);
  const formRef = useRef<() => void | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const handleFormSubmit = (formData:any) => {
    const { name, description, price, code } = formData;
    const body = {
      name: name,
      description: description,
      is_active: true,
      code: code,
      price:price
    };
    CreateService(body)
      .then((result) => {
        SuccessToast("Servicio creado correctamente");
        dispatch(ServicesThunks());
        onClose();
      })
      .catch(({ response }) => {
        if (response?.status === 400) {
          // Verificar si hay un mensaje de error en el cuerpo de la respuesta
          const errorMessage =  Object.values(response.data || {}).join(', ') || "Error desconocido";
          return ErrorToast(errorMessage);
        }
      });
  };
  const handleSubmitClick = () => {
    if (formRef.current) {
      formRef.current();
    }
  };

  return (
    <>
      <>
      <Button
        size="md"
        color="primary"
        className="shadow-lg"
        endContent={<IoIosAdd className="h-7 w-7" />}
        onPress={onOpen}
      >
        Crear Servicio
      </Button>
        <ModalComponent
          header={"Agregar nuevo servicio"}
          body={<FormServices data={data} onSubmit={handleFormSubmit} ref={formRef}  />}
          handleSubmitClick={handleSubmitClick}
          onClose={onClose}          isOpen={isOpen}
          onOpen={onOpen}
          onOpenChange={onOpenChange}
          title={""}
        />
      </>
    </>
  );
};


