import { movilPayAPI } from "../../api/movilPayAPI";
import { RatesParams } from "../../pages/core/params/rates/ratesParams";

export const getRates = (params?: RatesParams) => {
  return movilPayAPI.get(`/services/rates/`, { params });
};

export const PostRates = (body: any) => {
  return movilPayAPI.post(`/services/rates/`, body);
};

export const PatchRates = (body: any, id: number) => {
  return movilPayAPI.patch(`/services/rates/${id}/`, body);
};

export const DeleteRates = (id: number) => {
  return movilPayAPI.delete(`/services/rates/${id}/`);
};
