import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../store/store";

import { ButtonModalMethodComponents } from "../../../../components/methodPayments";
import { FaMobileAlt } from "react-icons/fa";
import { TbTransfer } from "react-icons/tb";
import { CompaniesIDThunks } from "../../../../../../store/companies/thunks";
import { useParams } from "react-router-dom";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";
import { BiTransfer } from "react-icons/bi";
import { SiZelle } from "react-icons/si";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import {
  AccountAfiliate,
  PaymentMethodsCompany,
} from "../../../../interfaces/CompaniesInterfaces";
import { IconType } from "react-icons";
import {
  DeleteAccountAfiliate,
  EditPaymentsMethods,
} from "./EditPaymentsMethods";
import { AddAccountAffiliate } from "./AddAccountAffiliate";
import { UserParams } from "../../../../params/settings/userParams";
import { GetAccountAfiliateThunks } from "../../../../../../store/companies/AccountAfiliate/thunks";
import { useStoreAccountAfiliate } from "../../../../../../store/companies/AccountAfiliate/useStoreAccountAfiliate";
import { useQueryClient, queryOptions } from "@tanstack/react-query";
import { usePermissions } from "../../../../../../context/PermissionContext";
import { useAllParams } from "../../../../../../hooks/useAllParams";
import { ErrorToast } from "../../../../../../libs/Notifications";
import React from "react";
import { IoBanOutline } from "react-icons/io5";

export interface Props {
  data: AccountAfiliate; // type: Payment[]
}

export interface PropsIcons {
  IconToAssign?: IconType | undefined;
}

export interface PaymentMethod {
  label: string;
  Icon: IconType;
  info: string[];
  id: number;
}

let paymentsMethods: PaymentMethod[] = [
  {
    id: 1,
    label: "PAGOMOVIL",
    Icon: HiOutlineDevicePhoneMobile,
    info: ["sender", "name", "email"],
  },
  {
    id: 2,

    label: "TRANSFERENCIA",
    Icon: BiTransfer,
    info: ["sender", "name", "email"],
  },
  {
    id: 3,
    label: "ZELLE",
    Icon: SiZelle,
    info: ["sender", "name", "email"],
  },
];

interface PropsPayment {
  data: PaymentMethodsCompany | any;
  reload?: () => void;
}

export const InfoCard = ({ data, reload }: PropsPayment) => {
  const { permissions } = usePermissions();

  const assignIcon = paymentsMethods.filter((p) => p.id === data.method)[0];
  const actionPermissionEdit =
    permissions && permissions.includes("action_edit_affiliate_accounts");
  const actionPermissionDelete =
    permissions && permissions.includes("action_delete_affiliate_accounts");
  return (
    <>
      <span className="grid absolute right-2 top-2">
        {actionPermissionEdit && (
          <EditPaymentsMethods methods={data} reload={reload} />
        )}
        {actionPermissionDelete && (
          <DeleteAccountAfiliate methods={data} reload={reload} />
        )}
      </span>
      <IconMethod IconToAssign={assignIcon.Icon} />
      <h1 className="mt-4 text-xl font-semibold dark:text-gray-300">
        {assignIcon.label}
      </h1>

      {assignIcon.info.map((a: any) => (
        <p className="mt-2 text-gray-500 dark:text-gray-400 text-wrap">
          {data[a]}
        </p>
      ))}
    </>
  );
};

const IconMethod = ({ IconToAssign }: PropsIcons) => {
  return (
    <div className="flex items-center space-x-2 text-lime-600">
      {IconToAssign && (
        <>
          <IconToAssign className="h-20 w-20" />
        </>
      )}
    </div>
  );
};

export const MethodPaymentsCompany = () => {
  const queryClient = useQueryClient();
  const { permissions } = usePermissions();
  const { accountAfiliate, setAccountAfiliate, count } =
    useStoreAccountAfiliate((state) => state);
  const actionPermissionAdd =
    permissions && permissions.includes("action_add_affiliate_accounts");

  const { params, deleteParams, setSearchParams } = useAllParams();
  const handleConsultation = () => {
    const param = new UserParams();
    params.page && (param.page = Number(params.page));
    params.search && (param.search = params.search);
    queryClient.fetchQuery(
      queryOptions({
        queryKey: ["accountAfiliate"],
        queryFn: () =>
          GetAccountAfiliateThunks(param)
            .then((d) => {
              setAccountAfiliate(d.data.results, d.data.count);
            })
            .catch(({ response }) => {
              if (response?.status === 400) {
                const errorMessage =
                  Object.keys(response.data || {})
                    .join(", ")
                    .concat(
                      ": " + Object.values(response.data || {}).join(", ")
                    ) ||
                  Object.values(response.data || {}).join(", ") ||
                  "Error desconocido";
                return ErrorToast(errorMessage);
              }
            }),
        retry: false,
      })
    );
  };
  React.useEffect(() => {
    handleConsultation();
  }, []);
  return (
    <>
      <div className="animate-fade-up bg-white px-4 py-5 dark:bg-primaryDark rounded-lg sm:px-6 my-4 ">
        <div className="flex items-center justify-end">
          {actionPermissionAdd && (
            <AddAccountAffiliate reload={handleConsultation} />
          )}
        </div>
        {count ? (
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-2 xl:grid-cols-4 md:p-6 text-center">
            {accountAfiliate.map((res) => (
              <div
                key={res.id}
                className="relative flex flex-wrap flex-col items-center justify-center border rounded-lg shadow py-3 md:px-6"
              >
                <InfoCard data={res} reload={handleConsultation} />
              </div>
            ))}
          </div>
        ) : (
          <div className="my-10 flex justify-center items-center w-full mx-auto  space-x-4">
            <div className="flex mx-auto text-center">
              <IoBanOutline className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-xl font-semibold text-gray-500">
                No hay resultado
              </h3>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
