// @flow
import * as React from "react";
import { usePermissions } from "../../../../../../../context/PermissionContext";
import { TableLayout, valueType } from "../../../../../layout/TableLayout";
import { ParishAll } from "../../../../../interfaces/LocationsAll";
import { useStoreParishCore } from "../../../../../../../store/location/Parish/useStoreParish";
import { PaginatorPayments } from "../../../../finances/payments/components/table/PaginatorPayments";
import { useStoreSectorCore } from "../../../../../../../store/location/Sector/useStoreSector";
import { EditSector } from "../ActionTable";
type Props = {
  reload: () => void;
};
export const TableSector = ({ reload }: Props) => {
  const { sector, count } = useStoreSectorCore((state) => state);

  const { permissions } = usePermissions();
  const dataTable: valueType[] = [
    { name: "Nro", value: "id" },
    { name: "Sector", value: "name" },
    { name: "Descripción", value: "description" },
    { name: "Parroquia", value: "parroquia_name" },
    {
        name: "Acción",
        value: (item: any) => (
          <div>
            <EditSector data={item} reload={reload} />
          </div>
        ),
      },
  ];
  return (
    <div className="space-y-7">
    <div>
      <TableLayout
        isLoading={false}
        data={sector}
        columns={dataTable}
        Paginator={<PaginatorPayments count={count} />}
      />
    </div>
  </div>
);
};
