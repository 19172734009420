import { createSlice } from "@reduxjs/toolkit";
import { SectorCompanyThunks } from "./thunks";


export interface initialStateSectorCompany {
  isLoading: boolean;
  SectorCompany:any[];
  count:number 
  page?:number
}

export const initialStateSectorCompany: initialStateSectorCompany = {
  isLoading: false,
  count: 0,
  SectorCompany:[]
};

export const SectorCompanySlice = createSlice({
  name: "sectorcompany",
  initialState: initialStateSectorCompany,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SectorCompanyThunks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(SectorCompanyThunks.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.SectorCompany = payload.results  || payload
        state.count = payload.count
      });
  },
});

export const {} = SectorCompanySlice.actions;