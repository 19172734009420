// @flow
import * as React from "react";
import { Menu } from "../../../../hooks/useUserMovilPay";
import { MenuItems } from "./MenuItems";
type Props = {
  menus: Menu[];
  isExpand:boolean, 
};
export const MenuComponent = ({ menus, isExpand }: Props) => {
    return (
    <div className="mt-3 mb-10 py-2 leading-10">
      <div className="list-none text-sm font-normal px-3">
        <div className="-mx-2 space-y-4">
          {menus.map((menu) => (
            <MenuItems key={menu.id} menu={menu} isExpand={isExpand} />
          ))}
        </div>
      </div>
    </div>
  );
};
