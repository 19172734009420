// @flow 
import * as React from 'react';
import { ChartIncomeCompany } from '../components/ChartIncomeCompany';
import { InvoiceInfo } from '../components/InvoiceInfo';
type Props = {
    
};
export const DashBoardCompanyID = (props: Props) => {
    return (
        <div className="grid grid-cols-4 gap-4 animate-fade-up space-y-2 my-4 rounded-lg">
          <div className="col-span-full">
            {/* Información de la compañía */}
            <InvoiceInfo />
          </div>
          <div className="col-span-full">
            {/* Gráfica de los ingresos de la compañía */}
            <ChartIncomeCompany />
          </div>
          
        </div>
    );
}; 