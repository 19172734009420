import * as React from "react";
import { Input, Button, Select, SelectItem, Tooltip } from "@nextui-org/react";
import { useForm, Controller } from "react-hook-form";
import { FcClearFilters, FcSearch } from "react-icons/fc";
import { IoIosRefresh } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useAllParams } from "../../../../../hooks/useAllParams";
import { AppDispatch, RootState } from "../../../../../store/store";
import { AddToWarehouse } from "./components/AddToWarehouse";
import { TableWarehouse } from "./components/table/TableWarehouse";
import { WarehouseParams } from "../../../params/management/warehouseParams";
import { getWarehouseRequest } from "../../../../../store/managements/warehouse/thunks";
import { useEffect, useState } from "react";
import { queryOptions, useQueryClient } from "@tanstack/react-query";
import { useStoreWarehouseCore } from "../../../../../store/managements/warehouse/useStoreWarehouseCore";
import { BulkUploadWarehouse } from "./components/BulkUploadWarehouse";
import { DevicesParams } from "../../../params/management/devicesParams";
import { getDevicesRequest } from "../../../../../store/managements/devices/thunks";
import { CompaniesParams } from "../../../params/companies/companiesParams";
import { getCompaniesRequest } from "../../../../../store/companies/thunks";
import { usePermissions } from "../../../../../context/PermissionContext";
import { BiSearch } from "react-icons/bi";

type Props = {};

export const WarehouseCore = (props: Props) => {
  const queryClient = useQueryClient();
  const { params, deleteParams, setSearchParams } = useAllParams();
  const { setWarehouse } = useStoreWarehouseCore((state) => state);
  const param = new WarehouseParams();
  const [key, setKey] = useState(0); // Estado clave para forzar el re-render

  // New state for the filter
  const [filterUsed, setFilterUsed] = useState<string>();
  const [filterStatus, setFilterStatus] = useState<string>();
  const [filterDevice, setFilterDevice] = useState<string>();
  const [devices, setDevices] = useState<any[]>([]);
  const [filterCompany, setFilterCompany] = useState<string>();
  const [companies, setCompanies] = useState<any[]>([]);
  const { permissions, loading } = usePermissions();
  const actionPermissioAdd =
    permissions && permissions.includes("action_add_device_in_warehouse");
  const actionPermissioLoad =
    permissions && permissions.includes("action_bulk_device_loading");
  console.log(actionPermissioAdd);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      search: params.search,
      used: filterUsed,
    },
  });

  const handleConsultation = () => {
    if (params.page) param.page = Number(params.page);
    if (params.search) param.search = params.search;
    param.used = filterUsed; // Add the filter parameter
    param.device_type = filterDevice; // Add the filter parameter
    param.company = filterCompany; // Add the filter parameter
    param.status = filterStatus; // Add the filter parameter

    queryClient.fetchQuery(
      queryOptions({
        queryKey: ["warehouse"],
        queryFn: () =>
          getWarehouseRequest(param).then((d) => {
            setWarehouse(d.data.results, d.data.count);
          }),
        retry: false,
      })
    );
  };

  const handleConsultationDevices = () => {
    const param = new DevicesParams();
    param.remove_pagination = true;

    queryClient.fetchQuery(
      queryOptions({
        queryKey: ["devices"],
        queryFn: () =>
          getDevicesRequest(param).then((d) => {
            setDevices(d.data);
          }),
        retry: false,
      })
    );
  };

  const handleFilterChangeDevice = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value;
    await setFilterDevice(value);

    handleConsultation(); // Trigger consultation with new filter value
  };

  const handleConsultationCompanies = () => {
    const param = new CompaniesParams();
    param.remove_pagination = true;
    queryClient.fetchQuery(
      queryOptions({
        queryKey: ["companies"],
        queryFn: () =>
          getCompaniesRequest(param).then((d) => {
            setCompanies(d.data);
          }),
        retry: false,
      })
    );
  };

  const handleFilterChangeCompany = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value;
    await setFilterCompany(value);

    handleConsultation(); // Trigger consultation with new filter value
  };

  useEffect(() => {
    handleConsultation();
  }, [params, filterUsed]); // Add filterUsed to the dependencies array

  const onSubmit = ({ search }: any) => {
    if (!search) {
      deleteParams(["search"]);
    } else {
      const { page, ...rest } = params;
      setSearchParams({ ...rest, search });
    }
  };

  // Updated handler to work with the event
  const handleFilterChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value;
    await setFilterUsed(value);

    handleConsultation(); // Trigger consultation with new filter value
  };

  // Updated handler to work with the event
  const handleStatusChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = event.target.value;
    await setFilterStatus(value);

    handleConsultation(); // Trigger consultation with new filter value
  };

  useEffect(() => {
    handleConsultationDevices();
    handleConsultationCompanies();
  }, []);

  const handleReset = async () => {
    setFilterUsed("");
    setFilterDevice("");
    setFilterCompany("");
    setFilterStatus("");
    reset();
    setKey((prevKey) => prevKey + 1); // Actualiza la clave para forzar el re-render
  };

  return (
    <div className="animate-fade-up " key={key}>
      <div className="space-y-7 ">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                control={control}
                name="search"
                render={({ field }) => (
                  <div className="flex items-center">
                    <Input
                      {...field}
                      type="text"
                      size="lg"
                      variant="bordered"
                      placeholder="Introduce para buscar"
                      className="w-full lg:w-full sm:w-full"
                    />
                    <Button
                      isIconOnly
                      color="primary"
                      aria-label="Like"
                      type="submit"
                      className="ml-2"
                    >
                      <BiSearch className="h-6 w-6 text-white" />
                    </Button>
                  </div>
                )}
              />
            </form>
          </div>
          <div>
            <Select
              variant="bordered"
              placeholder="Filtrar por Uso"
              onChange={handleFilterChange}
              className="w-full"
              size="lg"
            >
              <SelectItem value="" key={""}>
                Todos
              </SelectItem>
              <SelectItem value="true" key={"true"}>
                Usados
              </SelectItem>
              <SelectItem value="false" key={"false"}>
                No Usados
              </SelectItem>
            </Select>
          </div>
          <div>
            <Select
              variant="bordered"
              placeholder="Filtrar por Dispositivo"
              onChange={handleFilterChangeDevice}
              className="w-full"
              size="lg"
            >
              {devices.map((device: any) => (
                <SelectItem key={device.id} value={device.id}>
                  {device.name}
                </SelectItem>
              ))}
            </Select>
          </div>
          <div>
            <Select
              placeholder="Filtrar por Compañia"
              variant="bordered"
              onChange={handleFilterChangeCompany}
              className="w-full"
              size="lg"
            >
              {companies.map((company: any) => (
                <SelectItem key={company.id} value={company.id}>
                  {company.name}
                </SelectItem>
              ))}
            </Select>
          </div>
          <div>
            <Select
              variant="bordered"
              placeholder="Filtrar por Estado"
              onChange={handleStatusChange}
              className="w-full"
              size="lg"
            >
              <SelectItem value="" key={""}>
                Todos
              </SelectItem>
              <SelectItem value="true" key={"true"}>
                Activos
              </SelectItem>
              <SelectItem value="false" key={"false"}>
                Inactivos
              </SelectItem>
            </Select>
          </div>
          <div className="flex items-center gap-x-2">
            <Button
              isIconOnly
              variant="light"
              color="primary"
              size="sm"
              title={"Borrar filtros"}
              onPress={handleReset}
            >
              <FcClearFilters className={`font-semibold h-12 w-12 `} />
            </Button>
          </div>
        </div>
        <div className="flex space-x-3 justify-end">
          {/* <BulkUploadWarehouse e={undefined} reload={handleConsultation} /> */}
          {actionPermissioAdd && (
            <AddToWarehouse e={undefined} reload={handleConsultation} />
          )}
        </div>
        <div className="animate-fade-up ">
          <TableWarehouse reload={handleConsultation} />
        </div>
      </div>
    </div>
  );
};
