import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  AutocompleteItem,
  Select,
  SelectItem,
  Textarea,
} from "@nextui-org/react";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useEffect } from "react";
import { WarehouseParams } from "../../../../../params/management/warehouseParams";
import { getWarehouseRequest } from "../../../../../../../store/managements/warehouse/thunks";
import { useParams } from "react-router-dom";
import { GetPaymentMethodCompaniesThunks } from "../../../../../../../store/companies/PaymentMethodCompany/thunks";
import {
  MethodPayment,
  MethodPaymentCompany,
} from "../../../../../interfaces/PaymentInterfaces";
import { GetServicesCompaniesThunks } from "../../../../../../../store/companies/servicesCompany/thunks";
import { DevicesServiceConfig } from "../../../../../interfaces/DevicesServicesConfig";
import { useQueryClient, queryOptions } from "@tanstack/react-query";
import { useStoreWarehouseCore } from "../../../../../../../store/managements/warehouse/useStoreWarehouseCore";
import { useStoreSCPaymentMethodCore } from "../../../../../../../store/companies/PaymentMethodCompany/useStoreSCPaymentMethod";
import { ServicesConfigParams } from "../../../../../params/services-configs/ServicesConfigParams";
import { useStoreServiceCompanyCore } from "../../../../../../../store/companies/servicesCompany/useStoreServiceCompanyCore";
import { Device } from "../../../../../interfaces/DevicesInterface";
import { PaymentMethodsCompany } from "../../../../../interfaces/CompaniesInterfaces";
import { ServicesCompany } from "../../../../../interfaces/ServicesCompany";
import { Warehouse } from "../../../../../interfaces/WarehouseInterfaces";
import { useUserMovilPay } from "../../../../../../../hooks/useUserMovilPay";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../../store/store";
import { error } from "console";

type Props = {
  setData?: (data: any) => void;
  data?: DevicesServiceConfig;
  onSubmit: (data: any) => void;
  ref?: React.MutableRefObject<(() => void) | null>;
};

let schemaDevices = yup.object({
  service: yup.string().required("Este campo es requerido"),
  device: yup.string(),
  address: yup.string(),
  payment_method_cs: yup.string().required("Este campo es requerido"),
});

export const FormServicesConfig = React.forwardRef(
  ({ onSubmit, data }: Props, ref) => {
    const {
      control,
      handleSubmit,
      formState: { errors },
      setValue,
      watch,
    } = useForm({
      resolver: yupResolver(schemaDevices),
    });
    const { companies } = useSelector((state: RootState) => state.companyID);
    
    const { id, item } = useParams();
    const { company } = useUserMovilPay();
    const queryClient = useQueryClient();
    const [warehouse, setWarehouse] = React.useState<Warehouse[]>([]);
    const [scpaymentMethod, setscpaymentMethod] = React.useState<
    MethodPaymentCompany[]
    >([]);
    const [serviceCompany, setServiceCompany] = React.useState<
      ServicesCompany[]
    >([]);
    const [filterPay, setFilterPay] = React.useState<MethodPaymentCompany[]>(
      []
    );
    React.useImperativeHandle(ref, () => handleSubmit(onSubmit));
    const option = id ? id : company;
    const deviceWatch = watch("device");
    const servicesWatch = watch("service");
    // 1. Consultar servicio  de la compañia.
    const handleSC = () => {
      const param = new ServicesConfigParams();
      param.is_active = true;
      queryClient.fetchQuery(
        queryOptions({
          queryKey: ["serviceCompanyID"],
          queryFn: () =>
            GetServicesCompaniesThunks(param, option)
          .then((d) => {
            setServiceCompany(d.data);
          })
          .catch((err) => console.error(err)),
          retry: false,
        })
      );
    };

    // 2. Consulta de dispositivos, que no sean usados y esten activos.
    const handleConsultation = () => {
      const param = new WarehouseParams();
      param.used = data ? "" : "false";
      param.status = "true";
      param.remove_pagination = "true";
      // param.company = ` ${companies?.company_master !=null ? companies?.company_master+',':''}${id} `|| '';
      queryClient.fetchQuery(
        queryOptions({
          queryKey: ["devicesSCGet"],
          queryFn: () =>
            getWarehouseRequest(param)
              .then((d) => {
                setWarehouse(d.data);
              })
              .catch((err) => console.error(err)),
          retry: false,
        })
      );
    };
    const handleSCPM = async (device?: any) => {
      // Filtrar el id del servicio de la compañia
      const optionServices = serviceCompany.filter(
        (serv) => serv.service === Number(data?.service)
      );
      //Obtener el id del servicio ya sea para crear o editar
      const idService =
        optionServices.length > 0 ? optionServices[0].id : servicesWatch;
      // Obtener el id del dispositivo ya sea para crear o editar
      const idDevice = deviceWatch || data?.device;
      //Validar el si existe el Id para seguir con las validaciones
      if (idDevice) {
        let deviceID: any[] = [];
        const param = new ServicesConfigParams();
        //Obtener el mismo dispositivo con el Id anterior.
        const deviceSelected = warehouse.filter(
          (wh) => wh.id === Number(idDevice)
        );
        if (deviceSelected.length > 0) {
          //Obtener los distintos metodos de pagos que tenga el dispositivo
          deviceSelected.forEach((devic) => {
            const countD = devic.device_payment_methods;
            deviceID = countD?.map((dev) => dev.payment_method);
          });
        }
        if (deviceID.length > 0) {
          param.payment_method_in = deviceID.join(",");
          data == null && (param.status = 1);
          try {
            if (idService) {
              const response = await queryClient.fetchQuery(
                queryOptions({
                  queryKey: ["serviceSNP"],
                  queryFn: () =>
                    GetPaymentMethodCompaniesThunks(param, Number(idService)),
                  retry: false,
                })
              );

              const fetchedMethods = response.data;
              setscpaymentMethod(fetchedMethods); // Actualiza el estado con los métodos obtenidos

              if (deviceSelected[0]?.bank !== null) {
                const filteredMethods = fetchedMethods.filter(
                  (method: { bank: number }) =>
                    method.bank === Number(deviceSelected[0].bank)
                );
                setFilterPay(filteredMethods); // Filtra por banco
              } else {
                setFilterPay(fetchedMethods); // Si no hay banco, usa todos los métodos obtenidos
              }
            }
          } catch (err) {
            console.error("Error fetching payment methods:", err);
          }
        }
      }
    };

    useEffect(() => {
      handleSC();
      handleConsultation();
    }, []);
    // useEffect(() => {
    //   console.log(errors, "Error");
    // }, [errors]);
    useEffect(() => {
        setValue("service", data?.service.toString() || "");
      setValue("device", data?.device.toString());
      setValue("payment_method_cs", data?.payment_method_cs.toString() || "");
      setValue("address", data?.address);
      try {
        handleSCPM(data?.device);
      } catch (error) {
        console.log(error);
      }
    }, [data]);
    return (
      <>
        <form className="flex flex-col my-4 space-y-2">
          <div className="col-span-full"></div>
          {data ? (
            <div className="flex flex-col gap-2">
              <div className="flex justify-center border-2 border-primary  py-2 rounded-lg   items-center">
                Servico: {data?.service_name}
              </div>{" "}
              <div className="flex justify-center border-2 border-primary  py-2 rounded-lg   items-center">
                Dispositivo: {data?.device_serial + " / " + data?.device_mac}
              </div>{" "}
            </div>
          ) : (
            <>
              <Controller
                control={control}
                name="service"
                render={({ field }) => (
                  <>
                    <Select
                      {...field}
                      items={serviceCompany}
                      isInvalid={!!errors.service}
                      errorMessage={errors.service?.message}
                      placeholder="Selecciona un servicio"
                      variant="bordered"
                      selectedKeys={
                        field.value ? new Set([field.value]) : new Set()
                      } // Show placeholder if field.value is null or empty
                      onSelectionChange={handleConsultation}
                      onChange={(e) => {
                        field.onChange(e.target.value || field.value);
                      }}
                    >
                      {(service) => (
                        <SelectItem key={service.id}>
                          {service.service_name}
                        </SelectItem>
                      )}
                    </Select>
                  </>
                )}
              />
              <div className="my-4">
                <Controller
                  control={control}
                  name="device" // Controla el nombre del fabricante en el formulario
                  render={({ field }) => (
                    <div className="flex items-center">
                      <Autocomplete
                        {...field} // Pasa las propiedades del Controller
                        placeholder="Selecciona un dispositivo"
                        className="max-w"
                        variant="bordered"
                        defaultItems={warehouse}
                        isInvalid={!!errors.device}
                        errorMessage={errors.device?.message}
                        selectedKey={field.value}
                        onSelectionChange={(e) => {
                          field.onChange(e || field.value);
                          handleSCPM();
                        }}
                      >
                        {(item) => (
                          <AutocompleteItem key={item.id}>
                            {item.serial +
                              " /  " +
                              item.mac +
                              (item.bank != null
                                ? " /  " + item?.bank_name
                                : " /  MOVILPAY")}
                          </AutocompleteItem>
                        )}
                      </Autocomplete>
                    </div>
                  )}
                />
              </div>
            </>
          )}

          <div className="col-span-full">
            <Controller
              control={control}
              name="payment_method_cs"
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    items={filterPay}
                    isDisabled={data ? false : scpaymentMethod.length == 0}
                    selectedKeys={
                      field.value ? new Set([field.value]) : new Set()
                    } // Show placeholder if field.value is null or empty
                    isInvalid={!!errors.payment_method_cs}
                    errorMessage={errors.payment_method_cs?.message}
                    placeholder="Seleccione un método de pago"
                    variant="bordered"
                    onClick={() => {
                      data !== undefined && handleSCPM(data?.device);
                    }}
                  >
                    {(payment) => (
                      <SelectItem key={payment.id}>
                        {payment.account_number +
                          " - " +
                          payment.phone +
                          " - " +
                          payment.bank_achronym}
                      </SelectItem>
                    )}
                  </Select>
                </>
              )}
            />
          </div>
          <div className="col-span-full">
            <Controller
              control={control}
              name="address"
              render={({ field }) => (
                <Textarea
                  {...field}
                  isInvalid={!!errors.address}
                  errorMessage={errors.address?.message}
                  type="text"
                  autoFocus
                  placeholder="Agregar la dirección donde estará el equipo"
                  variant="bordered"
                />
              )}
            />
          </div>
        </form>
      </>
    );
  }
);

// ) : (
//   <>
//     <div className="flex items-center justify-center">
//       <div className="flex flex-col justify-center rounded-lg bg-gray-50 px-16 py-14">
//         <div className="flex  items-center w-full px-4">
//           {/* <img src="https://i.ibb.co/ck1SGFJ/Group.png" /> */}
//           <MdCrisisAlert className="mx-auto w-1/4 h-1/4" />
//         </div>
//         <h3 className="my-4 text-center text-3xl font-semibold text-gray-700">
//           Atención{" "}
//         </h3>
//         <p className=" text-center font-normal text-gray-600">
//           Debe tener el servico SNP para poder continuar con la
//           configuración del Servicio {item}
//         </p>
//         <Link to={`../`} className="w-full mx-auto text-center">
//           <Button
//             color="primary"
//             className=" mt-10  shadow-lg"
//             radius="full"
//             variant="solid"
//             startContent={<FaArrowUpRightFromSquare />}
//           >
//             Ir a servicio
//           </Button>
//         </Link>
//       </div>
//     </div>
//   </>
// )}
