import { FcKindle, FcOrganization, FcPlus } from "react-icons/fc";

export const companiesRouters = [
  {
    name: "Registrar compañía",
    path: "companies/create/",
    icon: FcPlus,
    superAdmin: false,
    show: true,
    children:null,
  },
  {
    name: "Todas las compañías",
    path: "companies/",
    icon: FcKindle,
    superAdmin: false,
    show: true,
    children: null,
  },
  // {
  //   name: "Compañía",
  //   path: "companies/company/126",
  //   icon: FcPlus,
  //   superAdmin: false,
  //   show: true,
  //   children: false,
  // },
];
