import { create } from "zustand";
import { ResultRates } from "../../pages/core/interfaces/RatesInterfaces";

interface RatesState {
  rates: ResultRates[];
  count: number;
  setRates: (rates: ResultRates[], count: number) => void;
  reset: () => void;
}

export const useStoreRates = create<RatesState>((set) => ({
  rates: [],
  count: 0,
  setRates: (rates: ResultRates[], count: number) =>
    set(() => ({ rates, count })),
  reset: () => set(() => ({ rates: [], count: 0 })),
}));


