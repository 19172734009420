import { movilPayAPI } from "../../api/movilPayAPI";
import { FinancesServicesParams } from "../../pages/core/params/finances/FinancesServicesParams";
import { InvoicesParams } from "../../pages/core/params/finances/InvoicesParams";


export const getStatusThunks = () => {
  return movilPayAPI.get(`/base/status/?type=2`);
};

export const getInvoicesThunks = (params?: InvoicesParams) => {
  return movilPayAPI.get(`/invoices/`, { params });
};

export const getInvoiceCompanyServicesThunks = (params?: FinancesServicesParams) => {
    return movilPayAPI.get(`/services/`, { params });
  };

  export const getInvoiceCompanyDevicesThunks = (params?: FinancesServicesParams) => {
    return movilPayAPI.get(`/companies/device_service/`, { params });
  };


  export const createInvoiceThunks = (invoice:any) => {
    return movilPayAPI.post(`/invoices/`,  invoice );
  };

  export const deleteInvoiceThunks = (id: number) => {
    return movilPayAPI.delete(`/invoices/${id}/`);
  };

  export const annulmentInvoiceThunks = (invoice:any,body:any) => {
    return movilPayAPI.patch(`/invoices/${invoice}/`,  body );
  };

  export const getInvoiceDetailThunks = (id:number) => {
    return movilPayAPI.get(`/invoices/${id}/`);
  };


  export const editInvoiceThunks = (invoice:any,body:any) => {
    return movilPayAPI.patch(`/invoices/${invoice}/`,  body );
  };

  export const addItemDevicesThunks = (body:any) => {
    return movilPayAPI.post(`/invoices/item_devices/`,  body );
  };

  export const addItemServicesThunks = (body:any) => {
    return movilPayAPI.post(`/invoices/item_services/`,  body );
  };

  export const editItemDevicesThunks = (item:any,body:any) => {
    return movilPayAPI.patch(`/invoices/item_devices/${item}/`,  body );
  };

  export const editItemServicesThunks = (item:any,body:any) => {
    return movilPayAPI.patch(`/invoices/item_services/${item}/`,  body );
  };

  export const deleteItemDevicesThunks = (id: number) => {
    return movilPayAPI.delete(`/invoices/item_devices/${id}/`);
  };

  export const deleteItemServicesThunks = (id: number) => {
    return movilPayAPI.delete(`/invoices/item_services/${id}/`);
  };
