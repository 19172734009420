import * as yup from 'yup';

export const schemaRates = yup.object({
    service: yup.number().required("Este campo es requerido"),
    company_type: yup.number().required("Este campo es requerido"),
    data: yup.array().min(1, "Debes agregar al menos una tarifa."),
});


export const schemaPatchRates = yup.object({
    price: yup.number().required("Precio es requerido"),

});
