// Importar las dependencias necesarias
import { useSelector } from "react-redux";
import { TableLayout, valueType } from "../../../../layout/TableLayout";
import { useStoreRates } from "../../../../../../store/rates/useStoreRates";
import { PaginatorTable } from "../../../../components/table/PaginatorTable";
// import { ActionDelete, ActionPatch } from "./ActionsRates";
import { usePermissions } from "../../../../../../context/PermissionContext";

type Props = {
  reload: () => void;
};

export const TableRetentions = ({ reload }: Props) => {
  const { rates, count } = useStoreRates((state: any) => state);
  const { permissions, loading } = usePermissions();
  const actionPermissionEdit =
    permissions && permissions.includes("action_edit_retention");
  const actionPermissionDelete =
    permissions && permissions.includes("action_delete_retention");
  const dataTable: valueType[] = [
    { name: "ID", value: "id" },
    { name: "Nombre", value: "service_name" },
    { name: "Descripcion", value: "min" },
    { name: "Porcentaje", value: "max" },
    { name: "Calculo", value: "price" },
    {
      name: "Acción",
      value: (data: any) => (
        <>
          {/* {actionPermissionEdit && <ActionPatch data={data} reload={reload} />}
          {actionPermissionDelete && (
            <ActionDelete data={data} reload={reload} />
          )} */}
        </>
      ),
    },
  ];

  return (
    <div className="space-y-7">
      <div>
        {/* {count > 0 && ( */}
        <TableLayout
          isLoading={false}
          data={rates} // Usar datos de dispositivos
          columns={dataTable}
          Paginator={<PaginatorTable count={count} />}
        />
        {/* )} */}
      </div>
    </div>
  );
};
