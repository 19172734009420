import { HeaderCompany } from "./components/HeaderCompany";
import { configTaiwind } from "../../../../../utils/configTaiwind";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { CompaniesIDThunks } from "../../../../../store/companies/thunks";
import { AppDispatch } from "../../../../../store/store";
import { TabsCompany } from "./components/TabsCompany";
import {
  usePermissionsMovilPay,
  useUserMovilPay,
} from "../../../../../hooks/useUserMovilPay";
import { InvoiceInfo } from "./components/InvoiceInfo";
import { useStorePermissions } from "../../../../../store/permission/useStorePermission";
import React from "react";

export const CompanyCore = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();
  const { company, is_superuser } = useUserMovilPay();
  const handleSearch = () => {
    const option = id ? id : company;
    dispatch(CompaniesIDThunks({ id: option }));
  };
  const permissionCompany = usePermissionsMovilPay();
  const { setPermissions } = useStorePermissions((state) => state);
  React.useEffect(() => {
    setPermissions(permissionCompany);
  }, [permissionCompany]);
  useEffect(() => {
    handleSearch();
  }, []);

  return (
    <>
      <main className={configTaiwind.animateView}>
        {/* Page header */}
        <HeaderCompany />
        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-full">
            {/* Información de la compañía */}
            <InvoiceInfo />
          </div>
          <div className="col-span-full">
            {/* Gráfica de los ingresos de la compañía */}
            {/* <ChartIncomeCompany /> */}
          </div>
          <div className="col-span-full ">
            {/* Método de pago */}
            <TabsCompany reload={handleSearch} />
            {/* <Outlet /> */}
          </div>
        </div>
      </main>
    </>
  );
};
