import {
  FcAssistant,
  FcComboChart,
  FcConferenceCall,
  FcFactory,
  FcInfo,
  FcSalesPerformance,
  FcSettings,
  FcSupport,
  FcViewDetails,
  
} from "react-icons/fc";
import { financesRouters } from "../views/finances/routers/finances";
import { companiesRouters } from "../views/companies/routers/companiesRouters";
import { settingRouters } from "../views/setting/routers/settingRouters";
import { supportsRouters } from "../views/supports/routers/supportsRouters";
import {  DeviceManagementRouters } from "../views/deviceManagement/router/deviceManagement";
import { accountRouters } from "../views/accounts/routers/accountRouters";

export const navigation = [
  {
    name: "Estadísticas",
    path: "dashboard/",
    icon: FcComboChart,
    show: true,
    children: false,
  },
  {
    name: "Cuentas",
    path: "accounts/",
    icon: FcInfo,
    show: true,
    children: accountRouters,
  },
  {
    name: "Compañías",
    path: "companies/",
    icon: FcFactory,
    show: true,
    children: companiesRouters
  },
  {
    name: "Configuración",
    path: "setting/",
    icon: FcSettings,
    show: true,
    children: settingRouters
  },
  {
    name: "Finanzas",
    path: "finance/",
    icon: FcSalesPerformance,
    show: true,
    children:financesRouters
  },
  {
    name: "Soporte",
    path: "supports/",
    icon: FcAssistant,
    show: true,
    children:supportsRouters
  },
  {
    name: "Reportes",
    path: "reports/",
    icon: FcViewDetails,
    show: true,
    children: null,
  },
  {
    name: "Administrador de Dispositivos",
    path: "management/",
    icon: FcViewDetails,
    show: true,
    children: DeviceManagementRouters,
  },
];
