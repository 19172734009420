import React, { useEffect, useState } from 'react';
import { getPaymentMethodsThunks } from '../../../../../../../../store/finances/PaymentInvoiceThunsks';
import { queryOptions, useQueryClient } from '@tanstack/react-query';
import { PaymentMethodsParams } from '../../../../../../params/finances/PaymentMethodsParams';
import { FaMoneyBillAlt, FaCreditCard, FaMobileAlt } from 'react-icons/fa';
import { AiFillBank } from 'react-icons/ai';
import { NationalCurrencyForm } from './NationalCurrencyForm';
import UsdCurrencyForm from './UsdCurrency';
import ZelleForm from './Zelle';

interface PaymentMethod {
    id: number;
    currency: string;
    name: string;
    status: number;
    status_name: string;
}



const PaymentMethods = () => {
    const queryClient = useQueryClient();
    const param = new PaymentMethodsParams();
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
    const [selectedMethod, setSelectedMethod] = useState<number | null>(null);

    const getPaymentMethods = async () => {
        param.finance = true;
        const result = await queryClient.fetchQuery(
            queryOptions({
                queryKey: ["invoice/details"],
                queryFn: () => getPaymentMethodsThunks(param).then((d) => {
                    if (d && d.data) {
                        let methods = d.data.results;

                        // Combina "PAGOMOVIL" y "TRANSFERENCIA" en "Moneda Nacional"
                        const combinedMethod = methods.find(
                            (method: PaymentMethod) => method.name === "PAGOMOVIL" || method.name === "TRANSFERENCIA"
                        );
                        
                        // Crear nueva lista con solo tres opciones
                        const filteredMethods = [
                            combinedMethod ? { ...combinedMethod, name: "Moneda Nacional" } : null, // Renombrado a "Moneda Nacional"
                            methods.find((method: PaymentMethod) => method.name === "ZELLE"),
                            methods.find((method: PaymentMethod) => method.name === "EFECTIVO USD"),
                        ].filter(Boolean) as PaymentMethod[]; // Eliminar valores nulos

                        // Establecer la selección predeterminada en "Moneda Nacional" si existe
                        if (combinedMethod) {
                            setSelectedMethod(combinedMethod.id);
                        }
                        
                        setPaymentMethods(filteredMethods);
                        return d.data;
                    } else {
                        return { results: [], count: 0 };
                    }
                }),
                retry: false,
            })
        );
        return result;
    };

    useEffect(() => {
        getPaymentMethods();
    }, []);

    const handleSelectMethod = (methodId: number) => {
        setSelectedMethod(methodId);
    };

    const getIcon = (name: string) => {
        switch (name) {
            case 'EFECTIVO USD':
                return <FaMoneyBillAlt />;
            case 'ZELLE':
                return <FaCreditCard />;
            case 'Moneda Nacional': // Icono combinado para "PAGOMOVIL / TRANSFERENCIA"
                return <div className='flex justify-between'><FaMobileAlt /> <AiFillBank   /></div>;
            default:
                return <FaMoneyBillAlt />;
        }
    };

    const renderSelectedForm = () => {
        switch (selectedMethod) {
            case paymentMethods.find((method) => method.name === "Moneda Nacional")?.id:
                return <NationalCurrencyForm />;
            case paymentMethods.find((method) => method.name === "EFECTIVO USD")?.id:
                return <UsdCurrencyForm />;
            case paymentMethods.find((method) => method.name === "ZELLE")?.id:
                return <ZelleForm />;
            default:
                return null;
        }
    };

    return (
        <div>
            <h2 className="text-xl font-semibold text-center mb-4 mt-4">Elija Método de Pago</h2>
            <div className="grid grid-cols-3 gap-2 my-4">
                {paymentMethods.map((method) => (
                    <div
                        key={method.id}
                        onClick={() => handleSelectMethod(method.id)}
                        className={`flex flex-col items-center justify-center p-2 rounded-md cursor-pointer transition 
                            ${selectedMethod === method.id ? 'bg-primary text-white' : 'bg-gray-200 text-gray-500'}`}
                    >
                        <div className={`text-2xl mb-1 ${selectedMethod === method.id ? 'text-white' : 'text-gray-500'}`}>
                            {getIcon(method.name)}
                        </div>
                        <span className="text-xs font-medium">{method.name}</span>
                    </div>
                ))}
            </div>
            <div className="mt-6">{renderSelectedForm()}</div>
        </div>
    );
};

export default PaymentMethods;
