import { yupResolver } from '@hookform/resolvers/yup';
import { Input, Select, SelectItem, Textarea } from '@nextui-org/react';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { queryOptions, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { getDevicesRequest } from '../../../../../../../store/managements/devices/thunks';
import { DevicesParams } from '../../../../../params/management/devicesParams';
import { getCompaniesThunks } from '../../../../../../../store/managements/warehouse/thunks';
import { CompaniesParams } from '../../../../../params/companies/companiesParams';

type Props = {
    data?: any | {};
    setData?: (data: any) => void;
    onSubmit: (data: any) => void;
    ref?: React.MutableRefObject<(() => void) | null>;
};

let schemaWarehouse = yup.object({
    
    detail: yup
        .string()
        .min(6, 'El detalle debe tener al menos 6 caracteres')
        .max(100, 'El detalle no puede tener más de 100 caracteres')
        .required('Este campo es requerido'),
});

export const FormConfigDevice = React.forwardRef((props: Props, ref) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: yupResolver(schemaWarehouse),
    });

    React.useImperativeHandle(ref, () => handleSubmit(props.onSubmit));
    const [devices, setDevices] = useState([])



    return (
        <form className="flex flex-col my-4">

            <div className="my-4">
                <Controller
                    control={control}
                    name="detail"
                    render={({ field }) => (
                        <>
                            <Textarea
                                {...field}
                                isInvalid={!!errors.detail}
                                type="text"
                                autoFocus
                                labelPlacement="outside"
                                label="Detalle"
                                placeholder="Detalle del motivo de desvinculacion"
                                variant="bordered"
                                
                            />
                            {errors.detail && (
                                <span style={{ color: 'red', fontSize: '12px' }}>
                                    {errors.detail.message}
                                </span>
                            )}
                        </>
                    )}
                />
            </div>
        
     

        </form>
    );
});
