// src/stores/usePaymentMethodStore.ts
import { create } from 'zustand';

// Definimos la interfaz para un método de pago
interface PaymentMethod {
  id: number;
  amount: number;
  name: string;
  amountBs:number;
  rate:number;
  date:string;
  method:string;
}

// Definimos el estado del store
interface PaymentMethodState {
  paymentMethods: PaymentMethod[];
  totalM: number;
  totalMBs:number;
  addPaymentMethod: (paymentMethod: PaymentMethod) => void;
  clearPaymentMethods: () => void;
  removePaymentMethodById: (id: number) => void;
}

const usePaymentMethodStore = create<PaymentMethodState>((set) => ({
  paymentMethods: [],
  totalM: 0,
  totalMBs: 0,

  // Función para agregar un método de pago
  addPaymentMethod: (paymentMethod) =>
    set((state) => {
      // Verificar si el ID ya existe en el estado
      const existingMethod = state.paymentMethods.find(
        (method) => method.id === paymentMethod.id
      );

      // Si ya existe, no lo agregues y retorna el estado actual
      if (existingMethod) {
        console.warn(`El método de pago con ID ${paymentMethod.id} ya existe.`);
        return state;
      }

      // Si no existe, agrega el nuevo método y actualiza los totales
      return {
        paymentMethods: [...state.paymentMethods, paymentMethod],
        totalM: state.totalM + paymentMethod.amount,
        totalMBs: state.totalMBs + paymentMethod.amountBs,
      };
    }),

  // Función para limpiar todos los métodos de pago
  clearPaymentMethods: () => set({ paymentMethods: [], totalM: 0, totalMBs:0 }),

  // Función para eliminar un método de pago específico por ID
  removePaymentMethodById: (id) =>
    set((state) => {
      const updatedPaymentMethods = state.paymentMethods.filter(
        (method) => method.id !== id
      );
      const updatedTotal = updatedPaymentMethods.reduce(
        (acc, method) => acc + method.amount,
        0
      );
      const updatedTotalBs = updatedPaymentMethods.reduce(
        (acc, method) => acc + method.amountBs,
        0
      );

      return { paymentMethods: updatedPaymentMethods, totalM: updatedTotal, totalMBs: updatedTotalBs };
    }),
}));

export default usePaymentMethodStore;
