// @flow
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  AutocompleteItem,
  Input,
  Select,
  SelectItem,
  Switch,
} from "@nextui-org/react";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { IoEyeSharp, IoEyeOffSharp, IoEye, IoEyeOff } from "react-icons/io5";
import * as yup from "yup";
import { schemaUsersCompany } from "./Validators/shemaUsersCompany";
import { type } from "../../../../components/methodPayments/typeIdentification";
import { queryOptions, useQueryClient } from "@tanstack/react-query";
import { useStoreCodePhones } from "../../../../../../store/companies/useStoreCodePhone";
import { ErrorToast } from "../../../../../../libs/Notifications";
import {
  CompaniesThunks,
  GetCodePhoneThunks,
} from "../../../../../../store/companies/thunks";
import { keyFilterNumber } from "../../../../../../helpers/KeyFIlterMovilPay";
import { useStoreRoles } from "../../../../../../store/accounts/roles/useStoreRoles";
import { GetRolesThunks } from "../../../../../../store/accounts/roles/thunks";
import { UserParams } from "../../../../params/settings/userParams";
import { CompaniesParams } from "../../../../params/companies/companiesParams";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../store/store";
import { Company } from "../../../../interfaces/CompaniesInterfaces";

type Props = {
  data?: any | {};
  onSubmit: (data: any) => void;
  ref?: React.MutableRefObject<(() => void) | null>;
};

export const FormUser = React.forwardRef(({ data, onSubmit }: Props, ref) => {
  const [isVisible, setIsVisible] = React.useState(false);
  const toggleVisibility = () => setIsVisible(!isVisible);
  const [isSelected, setIsSelected] = React.useState(false);
  const { codePhone, setCodePhone } = useStoreCodePhones((state) => state);
  const { roles, setRoles } = useStoreRoles((state) => state);
  const queryClient = useQueryClient();
  const { companies } = useSelector((d: RootState) => d.companies);
  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaUsersCompany),
    defaultValues: {
      code: "58",
      type: "V",
    },
  });
  const dispatch = useDispatch<AppDispatch>();

  React.useImperativeHandle(ref, () => handleSubmit(onSubmit));
  const typeItems = type;
  const handleCodePhone = () => {
    queryClient.fetchQuery(
      queryOptions({
        queryKey: ["codePhone"],
        queryFn: () =>
          GetCodePhoneThunks()
            .then((d) => {
              setCodePhone(d.data);
            })
            .catch(({ response }) => {
              if (response?.status === 400) {
                const errorMessage =
                  Object.keys(response.data || {})
                    .join(", ")
                    .concat(
                      ": " + Object.values(response.data || {}).join(", ")
                    ) ||
                  Object.values(response.data || {}).join(", ") ||
                  "Error desconocido";
                return ErrorToast(errorMessage);
              }
            }),
        retry: false,
      })
    );
  };
  const handleRoles = () => {
    const params: UserParams = new UserParams();
    params.remove_pagination = true;
    queryClient.fetchQuery(
      queryOptions({
        queryKey: ["roles"],
        queryFn: () =>
          GetRolesThunks(params)
            .then((d) => {
              setRoles(d.data, d.data.length);
            })
            .catch(({ response }) => {
              if (response?.status === 400) {
                const errorMessage =
                  Object.keys(response.data || {})
                    .join(", ")
                    .concat(
                      ": " + Object.values(response.data || {}).join(", ")
                    ) ||
                  Object.values(response.data || {}).join(", ") ||
                  "Error desconocido";
                return ErrorToast(errorMessage);
              }
            }),
        retry: false,
      })
    );
  };
  const handleCompanies = () => {
    const param = new CompaniesParams();
    param.remove_pagination = true;
    dispatch(CompaniesThunks(param));
  };
  React.useEffect(() => {
    handleCodePhone();
    handleRoles();
    handleCompanies();
  }, []);
  React.useEffect(() => {
    console.log(errors);
  }, [errors]);
  React.useEffect(() => {
    if (data) {
      const validacion = typeItems.includes(data?.identification?.charAt(0));
      setValue("type", validacion ? data?.identification?.charAt(0) : "V");
      setValue("identification", data?.identification?.slice(1) || "");
      setValue("name", data?.name || "");
      setValue("lastname", data?.lastname || "");
      setValue("email", data?.email || "");
      setValue("phone", data?.phone?.slice(2) || "");
      const groupsResult = roles.filter(
        (role) => role.name == data?.groups[0]
      )[0]?.id;
      console.log(groupsResult, "RES");
      setValue("groups", groupsResult?.toString() || "");
      setValue("company", data?.company?.toString() || "");
    }
  }, [data]);
  return (
    <>
      <div className="flex flex-col space-y-2 my-2">
        <div className="col-span-full flex items-center justify-center">
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <>
                <Select
                  {...field}
                  placeholder="Tipo"
                  variant="bordered"
                  items={typeItems}
                  selectedKeys={
                    field.value ? new Set([field.value]) : new Set()
                  }
                  className="w-28"
                  isInvalid={!!errors.type}
                  errorMessage={errors.type?.message}
                >
                  {(type) => (
                    <SelectItem key={type.label}>{type.label}</SelectItem>
                  )}
                </Select>
                <span></span>
              </>
            )}
          />

          <Controller
            control={control}
            name="identification"
            render={({ field }) => (
              <Input
                {...field}
                aria-placeholder="Identificación"
                isInvalid={!!errors.identification}
                errorMessage={errors.identification?.message}
                type="text"
                autoFocus
                placeholder="Introduce la Identificación del usuario"
                variant="bordered"
              />
            )}
          />
        </div>
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <Input
              {...field}
              placeholder="Nombre"
              isInvalid={!!errors.name}
              errorMessage={errors.name?.message}
              type="text"
              autoFocus
              variant="bordered"
            />
          )}
        />
        <Controller
          control={control}
          name="lastname"
          render={({ field }) => (
            <Input
              {...field}
              placeholder="Apellido"
              isInvalid={!!errors.lastname}
              errorMessage={errors.lastname?.message}
              type="text"
              autoFocus
              variant="bordered"
            />
          )}
        />
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <Input
              {...field}
              placeholder="Correo"
              isInvalid={!!errors.email}
              errorMessage={errors.email?.message}
              type="text"
              autoFocus
              variant="bordered"
            />
          )}
        />{" "}
        <div className="flex w-full gap-2">
          <Controller
            name="code"
            control={control}
            render={({ field }) => (
              <>
                <div className="flex items-center">
                  <Autocomplete
                    {...field} // Pasa las propiedades del Controller
                    className="max-w"
                    variant="bordered"
                    defaultItems={codePhone}
                    placeholder="58"
                    isInvalid={!!errors.code}
                    errorMessage={errors.code?.message}
                    selectedKey={field.value || null}
                    onSelectionChange={(e) => {
                      field.onChange(e);
                    }}
                  >
                    {(item) => (
                      <AutocompleteItem key={item.phone_code}>
                        {item.phone_code + "/" + item.iso3_code}
                      </AutocompleteItem>
                    )}
                  </Autocomplete>
                </div>
              </>
            )}
          />
          <Controller
            control={control}
            name="phone"
            render={({ field: { onChange, value, onBlur } }) => (
              <Input
                isInvalid={!!errors.phone}
                errorMessage={errors.phone?.message}
                maxLength={10}
                onChange={(e) => {
                  keyFilterNumber({
                    value: e,
                    onChange,
                  });
                }}
                onBlur={onBlur}
                value={value}
                autoFocus
                placeholder="Teléfono de contacto"
                variant="bordered"
              />
            )}
          />
        </div>
        <Controller
          control={control}
          name="groups"
          render={({ field }) => (
            <>
              <Select
                {...field}
                placeholder="Seleccione los roles"
                items={roles}
                variant="bordered"
                selectedKeys={field.value ? new Set([field.value]) : new Set()}
              >
                {(rol) => <SelectItem key={rol.id}>{rol.name}</SelectItem>}
              </Select>
            </>
          )}
        />
        <Controller
          control={control}
          name="company"
          render={({ field }) => (
            <>
              <Autocomplete
                {...field} // Pasa las propiedades del Controller
                className="max-w"
                variant="bordered"
                defaultItems={companies}
                placeholder="Seleccione la compañia"
                isInvalid={!!errors.company}
                errorMessage={errors.company?.message}
                selectedKey={field.value || null}
                onSelectionChange={(e) => {
                  field.onChange(e);
                }}
              >
                {(item: Company) => (
                  <AutocompleteItem key={item.id}>{item.name}</AutocompleteItem>
                )}
              </Autocomplete>
            </>
          )}
        />
        {data && (
          <div className="flex col-span-full ">
            <Switch isSelected={isSelected} onValueChange={setIsSelected}>
              Cambiar contraseña
            </Switch>
          </div>
        )}
        {(data && isSelected) || data == undefined ? (
          <div className="flex w-full items-center gap-1">
            <Controller
              control={control}
              name="password"
              rules={{
                required: {
                  value: (data && isSelected) || data == undefined,
                  message: "Este campo es requerido",
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  isInvalid={!!errors.password}
                  errorMessage={errors.password?.message}
                  autoFocus
                  className="w-full"
                  placeholder="Contraseña"
                  variant="bordered"
                  endContent={
                    <button
                      className="focus:outline-none"
                      type="button"
                      onClick={toggleVisibility}
                      aria-label="toggle password visibility"
                    >
                      {isVisible ? (
                        <IoEyeOff className="text-2xl text-default-400 pointer-events-none" />
                      ) : (
                        <IoEye className="text-2xl text-default-400 pointer-events-none" />
                      )}
                    </button>
                  }
                  type={isVisible ? "text" : "password"}
                />
              )}
            />
            <Controller
              control={control}
              name="confirmPassword"
              rules={{
                required: {
                  value: (data && isSelected) || data == undefined,
                  message: "Este campo es requerido",
                },
              }}
              render={({ field }) => (
                <Input
                  {...field}
                  isInvalid={!!errors.confirmPassword}
                  errorMessage={errors.confirmPassword?.message}
                  type={isVisible ? "text" : "password"}
                  autoFocus
                  variant="bordered"
                  placeholder="Confirmar contraseña"
                />
              )}
            />
          </div>
        ) : null}
      </div>
    </>
  );
});
