import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Textarea } from '@nextui-org/react'; // Importar Textarea en lugar de Input
import AnnulmentSchema from '../../validators/annulmentSchema';

type Props = {
    data?: any | {};
    setData?: (data: any) => void;
    onSubmit: (data: any) => void; 
    ref?: React.MutableRefObject<(() => void) | null>;
};

export const FormAnnulmentInvoice = React.forwardRef(({data, onSubmit}: Props, ref) => {
  
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm({
        resolver: yupResolver(AnnulmentSchema),
    });

    // Usamos useImperativeHandle para exponer el handleSubmit a través del ref.
    React.useImperativeHandle(ref, () => handleSubmit(onSubmit));

    // Efecto para setear los valores del formulario cuando `props.data` cambia
    React.useEffect(() => {
        // Setear date_canceled con la fecha actual
        const currentDate = new Date().toISOString().split('T')[0]; // Formato YYYY-MM-DD
        setValue("date_canceled", currentDate);
        
    
    }, [data, setValue]);
      
    return (
        <form className="flex flex-col my-4">
            <div className="my-4">
                <Controller
                    control={control}
                    name="detail_canceled"
                    render={({ field }) => (
                        <>
                            <Textarea
                                {...field}
                                isInvalid={!!errors.detail_canceled}
                                label="Detalle"
                                placeholder="Describa el motivo de anulación."
                                variant="bordered"
                                rows={4} // Puedes ajustar el número de filas
                            />
                            {errors.detail_canceled && (
                                <span style={{ color: 'red', fontSize: '12px' }}>
                                    {errors.detail_canceled.message}
                                </span>
                            )}
                        </>
                    )}
                />
            </div>
        </form>
    );
});
