// store/deviceStore.ts
import { create } from "zustand";
import { Device } from "../../../pages/core/interfaces/DevicesInterface";

interface DeviceState {
  devices: Device[];
  count: number;
  setDevices: (devices: Device[], count: number) => void;
  reset: () => void;
}

export const useStoreDeviceCore = create<DeviceState>((set) => ({
  devices: [],
  count: 0,
  setDevices: (devices: Device[], count: number) =>
    set(() => ({ devices, count })),
  reset: () => set(() => ({ devices: [], count: 0 })),
}));

