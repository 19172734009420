// @flow
import * as React from "react";
import { Input, Select, SelectItem } from "@nextui-org/react";
import { useParams } from "react-router-dom";
import { ServicesConfigParams } from "../../../../params/services-configs/ServicesConfigParams";
import { AddServices } from "./AddServices";
import { GetServicesCompaniesThunks } from "../../../../../../store/companies/servicesCompany/thunks";
import { IoBanOutline } from "react-icons/io5";
import { FcSearch } from "react-icons/fc";
import { Controller, useForm } from "react-hook-form";
import { queryOptions, useQueryClient } from "@tanstack/react-query";
import { useAllParams } from "../../../../../../hooks/useAllParams";
import { useStoreServiceCompanyCore } from "../../../../../../store/companies/servicesCompany/useStoreServiceCompanyCore";
import { TableServicesCompany } from "./table/tableServicesCompany";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../../store/store";
import { ServicesThunks } from "../../../../../../store/services/thunks";
import { usePermissions } from "../../../../../../context/PermissionContext";
import { useUserMovilPay } from "../../../../../../hooks/useUserMovilPay";

export const ServicesCompanies = () => {
  const { params, deleteParams, setSearchParams } = useAllParams();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      is_active: params.is_active || "",
      finish: params.finish || "",
      service: params.service || "",
    },
  }); 
  const { setServiceCompany } = useStoreServiceCompanyCore((state) => state);

  const { id } = useParams();
  const { company} = useUserMovilPay();

  const queryClient = useQueryClient();
  const { count } = useStoreServiceCompanyCore((state) => state);
  const { services } = useSelector( 
    (state: RootState) => state.services
  );
  
  const dispatch = useDispatch<AppDispatch>();
  const { permissions } = usePermissions();
  const actionPermissionAdd =permissions && permissions.includes('action_add_service_to_the_company');
  const handleConsultation = () => {
    const option = id ? id : company
    const param = new ServicesConfigParams();
    if (params.is_active) param.is_active = params.is_active;
    if (params.finish) param.finish = params.finish;
    if (params.service) param.service = params.service;
    queryClient.fetchQuery(
      queryOptions({
        queryKey: ["serviceCompany"],
        queryFn: () =>
          GetServicesCompaniesThunks(param, option).then((d) => {
            setServiceCompany(d.data, d.data.length);
          }),
        retry: false,
      })
    );
  };

  React.useEffect(() => {
    handleConsultation();
  }, [params]);

  const handleStatus = (e: any) => {
    const is_active: any = e;
    if (is_active == "") {
      deleteParams(["is_active"]);
    } else {
      const { page, ...rest } = params;
      setSearchParams({ ...rest, is_active: is_active });
    }
  };
  const handleFinish = (e: any) => {
    const finish: any = e;
    if (finish == "") {
      deleteParams(["finish"]);
    } else {
      const { page, ...rest } = params;
      setSearchParams({ ...rest, finish: finish });
    }
  };
  const handleService = (e: any) => {
    const service: any = e;
    if (service == "") {
      deleteParams(["service"]);
    } else {
      const { page, ...rest } = params;
      setSearchParams({ ...rest, service: service });
    }
  };
  React.useEffect(() => {
    dispatch(ServicesThunks());
  }, [])
  return (
    <>
      <div className="flex justify-between my-4 ">
        <form className="flex w-full  gap-x-2">
          <Controller
            control={control}
            name="is_active"
            render={({ field }) => (
              <Select
                {...field}
                items={statusData}
                placeholder="Filtrar por estado"
                className="max-w-xs"
                variant="bordered"
                key={field.value}
                onChange={(e) => handleStatus(e.target.value)}
              >
                {(status) => (
                  <SelectItem key={status.value}>{status.label}</SelectItem>
                )}
              </Select>
            )}
          />
          <Controller
            control={control}
            name="finish"
            render={({ field }) => (
              <Select
                {...field}
                items={finishData}
                variant="bordered"
                placeholder="Filtrar por finalizados"
                className="max-w-xs"
                key={field.value}
                onChange={(e) => handleFinish(e.target.value)}
              >
                {(finish) => (
                  <SelectItem key={finish.value}>{finish.label}</SelectItem>
                )}
              </Select>
            )}
          />
            <Controller
            control={control}
            name="service"
            render={({ field }) => (
              <Select
                {...field}
                items={services}
                variant="bordered"
                placeholder="Filtrar por servicios"
                className="max-w-xs"
                key={field.value}
                onChange={(e) => handleService(e.target.value)}
              >
                {(finish) => (
                  <SelectItem key={finish.id}>{finish.name}</SelectItem>
                )}
              </Select>
            )}
          />
        </form>
       {actionPermissionAdd && <AddServices reload={handleConsultation} />}
       {/* { <AddServices reload={handleConsultation} />} */}
      </div>
      <div className="flex justify-end gap-4"></div>
      <div className="my-10 flex justify-center items-center w-full space-x-4">
        {count > 0 ? (
          <TableServicesCompany reload={handleConsultation} />
        ) : (
          <div className="text-center">
            <IoBanOutline className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-xl font-semibold text-gray-500">
              No hay resultado
            </h3>
          </div>
        )}
      </div>
    </>
  );
};
const statusData = [
  { label: "Todos", value: "" },
  { label: "Activos", value: "true" },
  { label: "Inactivos", value: "false" },
];

const finishData = [
  { label: "Todos", value: "" },
  { label: "No finalizados", value: "false" },
  { label: "Finalizados", value: "true" },
];
